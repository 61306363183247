import { observable, action, flow } from "mobx";
import moment from "moment";

import { PaginationStore } from "@modules/base/PaginationStore";

import { RiskService } from "@api/risks";
import { RiskStore } from "./RiskStore";

export class TreatmentOverdueStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public riskId: string | null;
    @observable public treatments: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks",
            onClick: () => this._goToUrl(`/risks/dashboard`)
        },
        { text: "Overdue Treatments", key: "risk-treatments-overdue", isCurrentItem: true }
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    public reset = flow(function* () {
        this.loading = false;
        this.query = {};
        this.treatments = [];
        this.error = null;
    });

    public loadAllTreatments = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, pageSize: 25, keywords: null, query: {} }, query);

        this.query = {
            ...{ reviewDate: { before: moment.utc().format() }, sort: [{ property: "reviewDate" }] },
            ...options.query,
            ...{ isPending: true }
        };

        try {
            const result = yield this.riskService.searchRiskTreatments({
                pageSize: options.pageSize,
                startIndex: (options.page - 1) * options.pageSize,
                keywords: options.keywords,
                query: {
                    ...this.query
                }
            });
            this.treatments = result.items;
            this.query = result.query;
            this.keywords = result.query.keywords;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);

            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    @action
    public loadRiskTreatments(id) {
        this.loading = true;
        this.riskId = id;
        this.treatments = [];
        return this.riskService
            .getRiskTreatments(id)
            .then(this.loadRiskTreatmentsOnSuccess, this.loadRiskTreatmentsOnError);
    }

    @action.bound
    public loadRiskTreatmentsOnSuccess(result) {
        this.treatments = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadRiskTreatmentsOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
