import { observable, action, computed, flow } from "mobx";

import { RiskService } from "../../../api/risks";

import { RiskStore } from "./RiskStore";

export class RiskTypeNewFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;

    @observable public formData: any;
    @observable public visible: boolean = false;
    @observable public isSaving: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public hide() {
        this.visible = false;
        this.formData = {};
    }

    @action
    public show() {
        this.visible = true;
        this.formData = {};
        this.formData.inherithCodeFormat = true;
    }

    @action
    public reset() {
        this.formData = {};
    }

    @computed public get isChanged() {
        return (JSON.stringify(this.formData) === '{}')  ? false : true;
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }
        return (
            this.formData.name && this.formData.state && this.formData.template && this.formData.flowId
        );
    }

    save = flow(function*(){
        this.isSaving = true
        try {
            if (this.formData.inherithCodeFormat === true) this.formData.codeFormat = null;
            this.riskService.createType(this.formData)
            this.hide()
        } catch (e) {
            console.error(e)
        } finally {
            this.isSaving = false
        }
    })
}
