import React, { PureComponent } from 'react';
import { cx, css } from 'emotion';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Link } from 'office-ui-fabric-react/lib/Link';

const FooterClassName = cx(
  css `
      display: flex;
      align-content: flex-end;
      align-items: flex-end;
      width: 100%;
      `
);

function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
export class ViewerPartFooter extends PureComponent {
  render () {
    const { className, instance, part, theme } = this.props;
    let displayButton = part.properties && part.properties.displayLinkButton && part.properties.linkButtonLink;
    const displayText = part.properties && part.properties.displayLastUpdate && part.properties.lastUpdateText;
    let link = part.properties && part.properties.linkButtonLink && part.properties.linkButtonLink;
    if (link && (!(link.startsWith("https://") || link.startsWith("http://")))) {
      link = "https://" + link;
    }
    if (!isValidHttpUrl(link)) {
      displayButton = false;
    }
    return (
      <div className={cx(className, FooterClassName, "part-footer", css`
        justify-content: ${displayButton? `space-between` : `flex-end`};
        justify-items: ${displayButton? `space-between` : `flex-end`};
      `)}>
        {displayButton && <Link href={link} target="_blank" underline>
          View Details
        </Link>
        // <DefaultButton 
        //   text={'View details'}
        //   style={{
        //     fontSize: '11px'
        //   }}
        //   onClick={() => { window.open(part.properties.linkButtonLink, '_blank'); }}
        // />
        }
        {displayText && 
          <Text 
            variant={part.properties.lastUpdateTextSize ? part.properties.lastUpdateTextSize:`tiny`}
          >Updated {part.properties.lastUpdateText}</Text>}
      </div>
    );
  }
}