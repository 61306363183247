import { observable, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { EntityStore } from "./EntityStore";

export class EntityWorkItemStore {

    private parentStore: EntityStore;

    @observable public loading: boolean = false;
    @observable public workItems: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.pagination = new PaginationStore();
    }

    public loadWorkItems = flow(function*(options: any) {
        const q = Object.assign({}, options.query, { entity: { id: [options.entityId] } });
        const o = Object.assign({ page: 1 }, options, { query: q });
        const startIndex = (o.page - 1) *  this.pagination.itemsPerPage;

        if(!this.query || this.query.entity.id[0] !== q.entity.id[0] || this.pagination.startIndex !== startIndex) {
            this.loading = true;
            this.workItems = [];
            this.query = o.query;
            this.error = null;
            
            try {
                const workItemService = this.parentStore.rootStore.workItemStore.workItemService;

                const result = yield workItemService.searchWorkItems({ 
                    pageSize: this.pagination.itemsPerPage, 
                    startIndex: startIndex,
                    query: o.query,
                });
                this.workItems = result.items;
                this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
                this.query = result.query;
            } catch (e) {
                this.error = e;
                console.error(e)
            } finally {
                this.loading = false;
            }
        }
    });
}
