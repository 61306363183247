import { observable, flow, computed, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ProductService } from "../../../api/graph";
import { ProductStore } from "./ProductStore";
import { ProductStatus } from "./ProductContants";

export class ProductCatalogueStore {
    public parentStore: ProductStore;
    public productService: ProductService;

    @observable public loading: boolean = false;
    @observable public isSimplifiedView: boolean = true;
    @observable public products: any[] = [];
    @observable public keywords: string;
    @observable public category: string = "All";
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Demand Management", key: "demand", onClick: () => this.parentStore.rootStore.routing.push(`/demand/dashboard`) });
        builder.push({ text: "Service Catalogue", key: "catalogue", onClick: () => this.parentStore.rootStore.routing.push(`/demand/catalogue`) });

        return builder;
    }

    @computed
    public get promotedProducts() {
        return this.products.filter((p) => {
            return p.isPromoted && p.state === ProductStatus.Operational;
        });
    }

    @computed
    public get filteredProducts() {
        let operationalProducts = this.products.filter((p) => p.state === ProductStatus.Operational);

        if (this.category !== "All") {
            operationalProducts = operationalProducts.filter((p) => p.category === this.category);
        }

        if (this.keywords) {
            const k = this.keywords.toLowerCase();
            operationalProducts = operationalProducts.filter(
                (p) =>
                    p.name.toLowerCase().indexOf(k) !== -1 ||
                    p.provider.toLowerCase().indexOf(k) !== -1 ||
                    p.category.toLowerCase().indexOf(k) !== -1 ||
                    (p.friendlyName && p.friendlyName.toLowerCase().indexOf(k) !== -1) ||
                    (p.summary && p.summary.toLowerCase().indexOf(k) !== -1)
            );
        }

        return operationalProducts;
    }

    @action
    public setCategory(category: string) {
        this.category = category;
    }

    @action
    public onKeywordsChange(keywords: string) {
        this.keywords = keywords;
    }

    @action
    public setSimplifiedView(isSimplifiedView: boolean) {
        this.isSimplifiedView = isSimplifiedView;
    }

    public loadProducts = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null, pageSize: 250 }, query);

        this.products = [];
        this.keywords = null;
        this.error = null;

        try {
            this.pagination.itemsPerPage = options.pageSize;
            const result = yield this.productService.getProducts({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
            });

            this.products = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.keywords = result.keywords;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
