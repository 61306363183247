export const TruncatedText = ({ text, words, html }) => {
    if (!text) {
        return text;
    }

    let updated = text;
    if (html) {
        updated = updated.replace(/<\/?[^>]+(>|$)/g, "");
    }

    if (words) {
        const parts = updated.split(" ");

        updated = parts.splice(0, words).join(" ");

        if (parts.length > words) {
            updated = updated + " ...";
        }
    }

    return updated;
};
