import { computed, action } from "mobx";

import { RootStore } from "./RootStore";

export class RoutingManager {
    public rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    public push(url) {
        if (window.top != window.self) {
            this.rootStore.routing.push(`/teams${url}`);
        } else {
            this.rootStore.routing.push(url);
        }
    }

    @computed
    public get queryString(): any {
        const query = this.rootStore.routing.location.search;
        const params = query
            ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
                  let [key, value] = param.split("=");
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
                  return params;
              }, {})
            : {};

        return params;
    }

    public serializeQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    };

    @computed
    public get isTasksActive() {
        const params: any = this.queryString;
        return !!params.tasks;
    }

    public setTasksActive(active) {
        let url = this.rootStore.routing.location.pathname;
        const { tasks, ...rest } = this.queryString;
        if (active) {
            url = `${url}?${this.serializeQueryString({ ...rest, tasks: 1 })}`;
        } else {
            url = `${url}?${this.serializeQueryString({ ...rest })}`;
        }

        this.rootStore.routing.push(url);
    }

    @computed
    public get isAutomationsActive() {
        const params: any = this.queryString;
        return !!params.automations;
    }

    public setAutomationActive(active) {
        let url = this.rootStore.routing.location.pathname;
        const { automations, ...rest } = this.queryString;
        if (active) {
            url = `${url}?${this.serializeQueryString({ ...rest, automations: 1 })}`;
        } else {
            url = `${url}?${this.serializeQueryString({ ...rest })}`;
        }

        this.rootStore.routing.push(url);
    }
}
