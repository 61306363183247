import { observable, flow, action, computed } from "mobx";
import { metaModel } from "@/configProvider";

import { AssetService } from "@api/assets";
import { AssetStore } from "./AssetStore";

export class AssetSelectionStore {
    public parentStore: AssetStore;
    public assetService: AssetService;

    @observable public asset: any = null;
    @observable public assetType: any = null;
    @observable public assetClass: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    @action
    public setAsset(asset) {
        this.asset = asset;
    }

    @action
    public setAssetType(assetType) {
        this.assetType = assetType;
    }

    @action
    public setAssetClass(assetClass) {
        this.assetClass = assetClass;
    }

    @computed
    public get isStakeholder() {
        const q = metaModel.stakeholder.query.typeClass.map((t) => `${t.code}:`);
        return this.isAssetOfClassUsingTypePrefix(q);
    }

    @computed
    public get isAsset() {
        const q = metaModel.asset.query.typeClass.map((t) => `${t.code}:`);
        return this.isAssetOfClassUsingTypePrefix(q);
    }

    @computed
    public get isLossScenario() {
        const q = metaModel.lossScenario.query.typeClass.map((t) => `${t.code}:`);
        return this.isAssetOfClassUsingTypePrefix(q);
    }

    public isAssetOfClassUsingTypePrefix(types) {
        if (!this.asset || !this.asset.type || !this.asset.type.code) return false;

        return types.findIndex((c) => this.asset.type.code.indexOf(c) === 0) !== -1;
    }

    public loadAsset = flow(function* (id: string) {
        this.loading = true;

        if (!this.asset || this.asset.id !== id) {
            this.asset = null;
        }

        try {
            this.asset = yield this.assetService.getAsset(id);
        } finally {
            this.loading = false;
        }
    });
}
