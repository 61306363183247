import { observable, action, computed, flow } from "mobx";
import download from "downloadjs";
import { utc } from "moment";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentQuestionnaireStore {
    public parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public moduleId: string;
    @observable public manifest: any;
    @observable public module: any;
    @observable public measure: any;
    @observable public values: any;
    @observable public latestUpdate: Date;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;
    @observable public disabled: any = {};

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            { text: "Assurance Management", key: "assurance", onClick: () => this._goToUrl(`/assurance/dashboard`) },
            { text: "Browse Assessments", key: "browse", onClick: () => this._goToUrl(`/assurance/browse`) },
        ];

        if (!this.assessment) {
            return builder;
        }

        builder.push({
            text: this.assessment.code,
            key: this.assessment.code,
            onClick: () => this._goToUrl(`/assurance/browse/${this.assessment.id}`),
        });

        if (this.parentStore.lifecycleStore.isReadOnly) {
            builder.push({ text: "Questionnaire (read-only)", key: "questionnaire", isCurrentItem: true });
        } else {
            builder.push({ text: "Questionnaire", key: "questionnaire", isCurrentItem: true });
        }

        return builder;
    }

    @computed
    public get assessment() {
        return this.parentStore.selectionStore.assessment;
    }

    @computed
    public get modules() {
        return this.manifest ? this.manifest.modules : [];
    }

    @computed
    public get measures() {
        if (!this.manifest || !this.manifest.modules) {
            return [];
        }

        const result = {};

        this.manifest.modules.forEach((module) => {
            module.groups.forEach((group) => {
                if (group.measures) {
                    group.measures.forEach((measure) => {
                        if (!result[measure.id]) {
                            result[measure.id] = measure;
                            result[measure.id].tags = [];
                        }
                        result[measure.id].tags.push({
                            id: module.id,
                            title: module.title,
                            type: "module",
                        });
                    });
                }
            });
        });

        return Object.values(result);
    }

    @computed
    public get lastSaved() {
        if (!this.values) {
            return null;
        }

        let last = null;
        Object.values(this.values).forEach((value: any) => {
            if (value.modified) {
                if (!last || utc(last).isBefore(utc(value.modified))) {
                    last = value.modified;
                }
            }
        });

        return last;
    }

    public loadAssessmentQuestionnaire = flow(function* (id: string) {
        this.loading = true;
        this.manifest = null;
        this.measure = null;
        this.values = null;
        this.latestUpdate = null;
        this.disabled = {};
        this.error = null;

        try {
            yield this.parentStore.selectionStore.loadAssessment(id);
            this.manifest = yield this.assessmentService.getAssessmentManifest(id);
            this.values = yield this.assessmentService.getAssessmentValues(id);
            //this.latestUpdate = this.lastSaved;
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public updateAssessmentValue = flow(function* (measure: any, value: any) {
        this.saving = true;

        try {
            if (this.values) {
                this.disabled[measure.name] = true;
                this.values[measure.name].value = value.value;
            }
            const result = yield this.assessmentService.updateAssessmentValue({
                assessmentId: this.assessment.id,
                measureName: measure.name,
                measureId: measure.id,
                measureType: measure.type,
                value: value.value,
                completed: value.completed,
                config: value.config,
            });
            this.latestUpdate = result.modified;
            if (this.values) {
                this.disabled[result.measureName] = false;
                this.values[result.measureName] = result;
            }
        } catch (error) {
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @action
    public setSelectedMeasure(measure: any) {
        if (!this.measure || this.measure.id != measure.id) {
            this.measure = measure;
        }
    }

    @action
    public onAssessment() {
        this._goToUrl(`/assurance/browse/${this.assessment.id}`);
    }

    @action
    public onDownload() {
        return this.assessmentService.downloadAssessmentValues(this.assessment.id, null).then((blob) => {
            download(blob, `${this.assessment.code}.csv`, "text/csv");
        });
    }

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
