import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { ManualInsightContainer } from '../common/ManualInsightContainer';
import { Gauge } from '../../../../../charting/components/Gauge';

class ProgressIndicatorViewerPartClass extends PureComponent {
  get isReady() {
    return this.isConfigured
  }

  get isConfigured() {
    return true
  }

  render () {
    const { className, instance, part, themeProvider, dataSourceStore, ragStatusStore } = this.props;
    const mode = instance.lifecycleStore.mode;
    const theme = themeProvider.getTheme();
    return (
      <ManualInsightContainer
        className={className}
        instance={instance}
        part={part}
        theme={theme}
        mode={mode}
        isReady={this.isReady}
        isConfigured={this.isConfigured}
      >
        <div className={cx("progressIndicator", css `
          & .c3 {
            text-align: center
          }
        `)}>
          {part.properties.style === "progressbar" && <ProgressIndicator
            barHeight={parseInt(part.properties.barThickness) || 15}
            className={cx(css`
                & .ms-ProgressIndicator-progressBar {
                  background-color: ${part.properties.color || '#3788d8'}
                }
            `)}
            percentComplete={parseInt(part.properties.value)/100}
          />}
          {part.properties.style === "gauge" && <Gauge
                showTitle={false}
                theme={theme}
                dataSeries={[{
                  dataValue:"count",
                  max:100,
                  label: part.properties.chartLabel || "Count",
                  dataSource:[
                    { count:parseInt(part.properties.value) },
                  ]
                }]}
                title={`Title`}
                showPNG={false}
                height={part.height / 2}
                width={part.width}
                onDataChange={() => {}}
                onRendered={() => {}}
                onSVGChange={() => {}}
          />}
        </div>
      </ManualInsightContainer>
    );
  }
}

export const ProgressIndicatorViewerPart = inject('themeProvider','dataSourceStore')(observer(ProgressIndicatorViewerPartClass));
