import { observable, action } from "mobx";

import { RiskService } from '../../../api/risks';
import { RiskStore } from "./RiskStore";

export class RiskSummaryStore {

    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public visible: boolean = false;
    @observable public riskId: string = null;
    @observable public selectedRisk: any = null;
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public show(options) {
        this.riskId = options.id;
        this.selectedRisk = options.risk;
        this.visible = true;
        this.loadRisk(options.id);
    }

    @action
    public hide() {
        this.riskId = null;
        this.selectedRisk = null;
        this.visible = false;
    }

    @action
    public loadRisk(id) {
        this.loading = true;
        this.selectedRisk = null;
        this.riskService.getRisk(id)
            .then(this.loadRiskOnSuccess, this.loadRiskOnError);
    }

    @action.bound
    public loadRiskOnSuccess(result) {
        this.selectedRisk = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadRiskOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }
}
