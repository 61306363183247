const assessmentFilters = (getPortfolios) => [
    {
        id: "timeLine",
        name: "Date Range",
        type: "dateRangePicker",
    },
    {
        id: "assignedToUser",
        title: "Assigned To User",
        type: "userpicker",
    },
    {
        id: "portfolios",
        name: "Portfolios",
        type: "multiselectList",
        getValues: getPortfolios,
    },
    {
        id: "assessmentState",
        name: "Status",
        type: "multiselectList",
        values: [
            { key: "Draft", text: "Triage" },
            { key: "Started", text: "Deep Dive" },
            { key: "Remediation", text: "Remediation" },
            { key: "Reviewing", text: "Risk Review" },
            { key: "Closed", text: "Closed" },
            { key: "Cancelled", text: "Cancelled" },
        ],
        default: "false",
    },
    {
        id: "assessmentPrority",
        name: "Priority",
        type: "multiselectList",
        values: [
            { key: "Critical", text: "Critical" },
            { key: "High", text: "High" },
            { key: "Medium", text: "Medium" },
            { key: "Low", text: "Low" },
        ],
        default: "false",
    },
    {
        id: "requestedBy",
        title: "Requested By",
        type: "userpicker",
    },
    {
        id: "created",
        name: "Created Range",
        type: "dateRangePicker",
    },
    {
        id: "started",
        name: "Started Range",
        type: "dateRangePicker",
    },
    {
        id: "dueDate",
        name: "Due Date Range",
        type: "dateRangePicker",
    },
    {
        id: "closed",
        name: "Closed Range",
        type: "dateRangePicker",
    },
];

export { assessmentFilters };
