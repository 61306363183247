import { observable, action, computed, flow } from "mobx";

import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export enum ViewTabType {
    Summary = "summary",
    Documents = "documents",
    Advisories = "analysis",
    Risks = "risks",
    Exceptions = "exceptions",
    Properties = "properties",
}

export class AssessmentViewerStore {
    public parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public visible: boolean = false;
    @observable public modules: any[] = [];
    @observable public loading: boolean = false;
    @observable public selectedTab: ViewTabType = ViewTabType.Summary;
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @action
    public show(options) {
        this.loadAssessment(options.assessmentId);
        this.visible = true;
    }

    @action
    public hide() {
        this.visible = false;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            {
                text: "Assurance Management",
                key: "assurance",
                onClick: () => this._goToUrl(`/assurance/dashboard`),
            },
            {
                text: "Browse Assessments",
                key: "browse",
                onClick: () => this._goToUrl(`/assurance/browse`),
            },
        ];

        if (!this.assessment) {
            return builder;
        }

        builder.push({
            text: this.assessment.code,
            key: this.assessment.code,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get assessment() {
        return this.parentStore.selectionStore.assessment;
    }

    @computed
    public get workItem() {
        return this.parentStore.selectionStore.workItem;
    }

    public loadAssessment = flow(function* loadAssessment(id: string) {
        this.error = null;
        this.loading = true;
        this.modules = [];

        try {
            yield this.parentStore.selectionStore.loadAssessment(id);
            this.modules = yield this.assessmentService.getAssessmentModules(id);
            //if (!tab) {
            //    this.autoSelectTab();
            //}
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    @action
    public reset() {
        this.visible = true;
        this.parentStore.selectionStore.setSelected(null);
        this.selectedTab = ViewTabType.Summary;
    }

    @action
    public selectTab(tab: ViewTabType) {
        this.selectedTab = tab || ViewTabType.Summary;
    }

    @action
    public autoSelectTabOld() {
        if (this.assessment) {
            switch (this.assessment.state) {
                case "Draft":
                    this.selectedTab = ViewTabType.Summary;
                    break;
                case "Started":
                    this.selectedTab = ViewTabType.Summary;
                    break;
                case "Remediation":
                    this.selectedTab = ViewTabType.Advisories;
                    break;
                case "Reviewing":
                    this.selectedTab = ViewTabType.Advisories;
                    break;
                case "Closed":
                    this.selectedTab = ViewTabType.Summary;
                    break;
                default:
                    this.selectedTab = ViewTabType.Summary;
                    break;
            }
        }
    }

    @action
    public autoSelectTab() {
        if (this.assessment) {
            switch (this.assessment.state) {
                case "Remediation":
                case "Reviewing":
                    this._goToUrl(`/assurance/browse/${this.assessment.id}/${ViewTabType.Advisories}`);
                    break;
                default:
                    this._goToUrl(`/assurance/browse/${this.assessment.id}`);
                    break;
            }
        }
    }

    @action
    public onModule(module) {
        this._goToUrl(`/assurance/browse/${this.assessment.id}/-/${module.id}`);
    }

    public onUpload = flow(function* onUpload(files: any[]) {
        this.saving = true;

        try {
            const documentStore = this.parentStore.documentStore;
            const documents = files
                .map((file) => {
                    return {
                        sourceId: this.workItem.id,
                        internalOnly: false,
                        mode: "Major",
                        file: file,
                    };
                })
                .map((document) => {
                    return documentStore.uploadDocument(document);
                });

            yield Promise.all(documents);
            this.parentStore.rootStore.layoutStore.displayToastNotification("Document uploaded successfully.");
        } catch (error) {
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
