import { observable, flow } from "mobx";

import { AssetService } from "@api/assets";
import { AssetStore } from "./AssetStore";

export class AssetRiskTrendStore {
    private parentStore: AssetStore;
    private assetService: AssetService;

    @observable public loading: boolean = false;
    @observable public trend: any[] = [];
    @observable public error: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    public reset = flow(function* () {
        this.loading = false;
        this.trend = [];
        this.error = null;
    });

    public loadAssetRiskTrend = flow(function* (options: any) {
        this.loading = true;
        this.trend = [];
        this.error = null;

        try {
            const result = yield this.assetService.getAssetRiskTrend(options.id);
            this.trend = result;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
