import { observable, flow, action, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ControlService } from '../../../api/modelling';
import { ControlStore } from "./ControlStore";

export class ControlMapBrowseStore {

    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public sets: any[] = [];
    @observable public keywords: string;
    @observable public pagination: PaginationStore;
    @observable public error: any;
    @observable public maps: any[] = [];

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
        this.pagination = new PaginationStore();
    }
    
    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: 'Settings', key: 'settings', onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: 'Control Maps', key: 'control-maps', onClick: () => this.parentStore.rootStore.routing.push(`/settings/control-maps`) });

        return builder;
    }

    public loadControlMaps = flow(function*(query: any) {
        const options = Object.assign({ page: 1, keywords: null, activeOnly: false }, query);

        this.loading = true;
        this.maps = [];
        this.keywords = options.keywords;
        this.error = null;
        
        try {
            const result = yield this.controlService.getControlMaps({ 
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                keywords: options.keywords,
                activeOnly: options.activeOnly,
            });
            this.maps = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.keywords = result.keywords;
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    @action
    public replace(map) {
        const index = this.maps.findIndex(p => p.id === map.id);

        if(index !== -1) {            
            this.maps = [...this.maps.splice(index, 1, map)];
        }
    }
}
