import React from "react";

import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";

export function TaskResponseActions({ task, onResponse, disabled }) {
    const getResponseOptions = () => {
        if (!task) {
            return [];
        }

        if (task.useCase == "Approval") {
            return [
                {
                    label: "Approve",
                    value: "Approved",
                    success: true,
                },
                {
                    label: "Reject",
                    value: "Rejected",
                    success: false,
                },
            ];
        } else if (task.useCase == "Confirmation") {
            return [
                {
                    label: "Yes",
                    value: "Yes",
                    success: true,
                },
                {
                    label: "No",
                    value: "No",
                    success: false,
                },
            ];
        } else if (task.useCase == "Completion") {
            return [
                {
                    label: "Mark as completed",
                    value: "Mark as completed",
                    success: true,
                },
            ];
        } else if (task.settings) {
            return task.settings.responses;
        } else {
            return [];
        }
    };

    return (
        <>
            {getResponseOptions().map((r) => {
                if (r.success) {
                    return <PrimaryButton key={r.value} onClick={() => onResponse(r)} text={r.label} disabled={disabled} />;
                } else {
                    return <DefaultButton key={r.value} onClick={() => onResponse(r)} text={r.label} disabled={disabled} />;
                }
            })}
        </>
    );
}
