import { observable, computed, flow } from "mobx";

import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentReportStore {
    public parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public loading: boolean = false;
    @observable public rendered: boolean = false;
    @observable public modules: any[] = [];
    @observable public advisories: any[] = [];
    @observable public exceptions: any[] = [];
    @observable public risks: any[] = [];
    @observable public evidences: any[] = [];
    @observable public documents: any[] = [];
    @observable public schema: any;
    @observable public hasRisks: boolean = false;
    @observable public hasExceptions: boolean = false;
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            {
                text: "Assurance Management",
                key: "assurance",
                onClick: () => this._goToUrl(`/assurance/dashboard`),
            },
            {
                text: "Browse Assessments",
                key: "browse",
                onClick: () => this._goToUrl(`/assurance/browse`),
            },
        ];

        if (!this.assessment) {
            return builder;
        }

        builder.push({
            text: this.assessment.code,
            key: this.assessment.code,
            onClick: () => this._goToUrl(`/assurance/browse/${this.assessment.id}`),
        });

        builder.push({
            text: "Report",
            key: "report",
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get assessment() {
        return this.parentStore.selectionStore.assessment;
    }

    @computed
    public get workItem() {
        return this.parentStore.selectionStore.workItem;
    }

    public loadAssessment = flow(function* loadAssessment(id: string) {
        this.loading = true;
        this.modules = [];
        this.advisories = [];
        this.exceptions = [];
        this.risks = [];
        this.evidences = [];
        this.documents = [];
        this.schema = null;
        this.error = null;

        try {
            yield this.parentStore.selectionStore.loadAssessment(id);
            this.modules = yield this.assessmentService.getAssessmentModules(id);
            this.advisories = yield this.assessmentService.getAssessmentAdvisories(id);
            this.risks = yield this.assessmentService.getAssessmentRisks(id, "zerodai:risks:generic");
            this.exceptions = yield this.assessmentService.getAssessmentRisks(id, "zerodai:risks:exception");
            this.evidences = yield this.assessmentService.getAssessmentEvidences(id);

            const workItemService = this.parentStore.rootStore.workItemStore.workItemService;
            this.documents = yield workItemService.getWorkItemDocuments(this.workItem.id);

            const productService = this.parentStore.rootStore.productStore.productService;
            this.schema = yield productService.getProductSchema(this.workItem.product.id, this.workItem.schemaVersion);

            const types = yield this.parentStore.rootStore.riskStore.riskService.getTypes({});
            this.hasRisks = types.items.some((t) => t.requestable && t.linkToAssessment && t.template === "zerodai:risks:generic");
            this.hasExceptions = types.items.some((t) => t.requestable && t.linkToAssessment && t.template === "zerodai:risks:exception");
        } catch (e) {
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
