import { RichText } from "@lib/RichText";
import { observable, action, computed } from "mobx";

import { RiskService } from "../../../api/risks";

import { RiskStore } from "./RiskStore";

export type SectionName = "Risk" | "Review";

export class RiskEditStore {
    private riskService: RiskService;
    public parentStore: RiskStore;

    @observable public visible: boolean = false;
    @observable public selectedAdvisories: any;
    @observable public evidences: any[] = [];
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public section: SectionName = "Risk";
    @observable public allowReview: boolean = false;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public isDialogVisible: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        const review = this.formData.review;
        return (
            this.isSectionValid &&
            (!this.allowReview ||
                (review &&
                    review.grossScore.score &&
                    review.netScore.score &&
                    review.treatments &&
                    review.treatments.length == 1 &&
                    review.treatments[0].type &&
                    RichText.isNotNullOrEmpty(review.treatments[0].description) &&
                    RichText.isNotNullOrEmpty(review.grossScore.description) &&
                    RichText.isNotNullOrEmpty(review.netScore.description)))
        );
    }

    @computed
    public get isSectionValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.title &&
            this.formData.type &&
            this.formData.type.id &&
            this.formData.registry &&
            this.formData.registry.id &&
            this.formData.portfolio &&
            this.formData.portfolio.id &&
            this.formData.target &&
            this.formData.target.type &&
            this.formData.target.id &&
            this.formData.lifecycle &&
            this.formData.lifecycle.businessContact &&
            this.formData.lifecycle.businessContact.id &&
            this.formData.lifecycle.technicalContact &&
            this.formData.lifecycle.technicalContact.id &&
            RichText.isNotNullOrEmpty(this.formData.description) &&
            RichText.isNotNullOrEmpty(this.formData.context)
        );
    }

    @action
    public setDialogVisible(visible) {
        this.isDialogVisible = visible;
    }

    @action
    public nextSection() {
        if (this.allowReview && this.section == "Risk") {
            this.section = "Review";
        }
    }

    @action
    public previousSection() {
        if (this.allowReview && this.section == "Review") {
            this.section = "Risk";
        }
    }

    @action
    public show(options) {
        this.visible = true;
        this.load(options);
    }

    @action
    public load(options) {
        this.allowReview = options.allowReview;
        this.section = "Risk";
        this.selectedAdvisories = options.selectedAdvisories;

        if (this.formData && !options.clearData) {
            return;
        }

        this.formOptions = {
            hideTarget: options.hideTarget,
            typeName: options.type.name,
            scoreTooltip: options.type.scoreTooltip,
            allowReview: options.allowReview,
            allowSubmit: options.allowSubmit && !options.assessment,
            disableTarget: options.disableTarget && options.target && options.target.type && options.target.id,
            disableTargetType:
                (options.disableTarget || options.disableTargetType) && options.target && options.target.type,
            disablePortfolio: options.disablePortfolio && options.portfolio && options.portfolio.id,
            disableRegistry:
                options.disableRegistry &&
                ((options.registry && options.registry.id) || options.type.defaultRegistryId),
            advisorySelection: options.advisories,
            registryFilter: (r) => this.parentStore.permissionStore.requestorRegistryFilter(r),
        };
        console.log("options.advisories", options.advisories);

        let additionalAdvisoryData = {};
        if (options.advisories && options.advisories.count === 1) {
            const advisory = options.advisories.selected[0];
            additionalAdvisoryData = {
                title: advisory.title,
                description: [
                    {
                        type: "paragraph",
                        children: [
                            {
                                text: advisory.description,
                            },
                        ],
                    },
                ],
            };
        }

        this.formData = {
            portfolio: options.portfolio,
            description: options.type.descriptionTemplate,
            context: options.type.contextTemplate,
            ...additionalAdvisoryData,
            registry: options.registry
                ? options.registry
                : options.type.defaultRegistryId
                ? {
                      id: options.type.defaultRegistryId,
                  }
                : null,
            type: options.type,
            target: options.target,
            trigger: options.trigger || "Manual",
            lifecycle: options.lifecycle || {},

            advisories:
                options.assessment && options.advisories
                    ? options.advisories.selected.map((a) => ({ id: a.id, title: a.title }))
                    : null,
            assessment: options.assessment
                ? {
                      id: options.assessment.id,
                      title: options.assessment.title,
                  }
                : null,
            review: !this.allowReview
                ? null
                : {
                      grossScore: {},
                      netScore: {},
                      treatments: [
                          {
                              type: "Mitigate",
                              description: null,
                          },
                      ],
                  },
        };
    }

    @action
    public hide(options) {
        this.visible = false;

        if (options && options.clearData) {
            this.selectedAdvisories = null;
            this.formData = null;
            this.formOptions = null;
        }
    }

    @action
    public setSelectedAdvisories(selection) {
        this.selectedAdvisories = selection;
    }
}
