import { observable, action, flow } from "mobx";
import download from "downloadjs";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export type DocumentGridView = "all" | "internalOnly";

export class AssessmentDocumentStore {
    private assessmentService: AssessmentService;
    public parentStore: AssessmentStore;

    @observable public documents: any[] = [];
    @observable public selectedDocument: any;
    @observable public gridView: DocumentGridView = "all";
    @observable public selectedDocuments: any;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    // @action
    // public setSelectedDocuments(selection) {
    //     this.selectedDocuments = selection;
    // }

    // @action
    // public setGridView(view: DocumentGridView) {
    //     this.gridView = view;
    // }

    // @action
    // public setSearchKeywords(keywords: string) {
    //     this.keywords = keywords;
    // }

    // @action
    // public downloadDocument(document: any) {
    //     return this.assessmentService.downloadAssessmentDocument(document.sourceId, document.id).then((blob) => {
    //         download(blob, `${document.document.name}${document.document.extension}`, document.contentType);
    //     });
    // }

    // @action
    // public loadDocuments(assessmentId: string) {
    //     this.loading = true;
    //     this.documents = [];
    //     return this.assessmentService
    //         .getAssessmentDocuments(assessmentId)
    //         .then(this.loadDocumentsOnSuccess, this.loadDocumentsOnError);
    // }

    // @action.bound
    // public loadDocumentsOnSuccess(result) {
    //     this.documents = result;
    //     this.loading = false;
    //     return result;
    // }

    // @action.bound
    // public loadDocumentsOnError(error) {
    //     console.error(error);
    //     this.loading = false;
    //     throw error;
    // }

    // @action
    // public uploadDocument(document) {
    //     this.saving = true;
    //     return this.assessmentService
    //         .uploadAssessmentDocument(document)
    //         .then(this.uploadDocumentOnSuccess, this.uploadDocumentOnError);
    // }

    // @action.bound
    // public uploadDocumentOnSuccess(result) {
    //     const index = this.documents.findIndex((d) => d.id == result.id);
    //     if (index == -1) {
    //         this.documents.unshift(result);
    //     } else {
    //         this.documents.splice(index, 1, result);
    //     }
    //     this.saving = false;
    //     return result;
    // }

    // @action.bound
    // public uploadDocumentOnError(error) {
    //     console.error(error);
    //     this.saving = false;
    //     throw error;
    // }

    @action
    public setSelectedDocuments(selection) {
        this.selectedDocuments = selection;
    }

    @action
    public setGridView(view: DocumentGridView) {
        this.gridView = view;
    }

    @action
    public setSearchKeywords(keywords: string) {
        this.keywords = keywords;
    }

    public downloadDocument = flow(function* (document: any) {
        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const blob = yield this.assessmentService.downloadAssessmentDocument(assessment.id, document.id);
            download(blob, `${document.document.name}${document.document.extension}`, document.contentType);
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });

    public loadDocuments = flow(function* (assessmentId: string) {
        this.loading = true;
        this.documents = [];

        try {
            this.documents = yield this.assessmentService.getAssessmentDocuments(assessmentId);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public uploadDocument = flow(function* (document: any) {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const result = yield this.assessmentService.uploadAssessmentDocument(assessment.id, document);
            const index = this.documents.findIndex((d) => d.id == result.id);
            if (index == -1) {
                this.documents.unshift(result);
            } else {
                this.documents.splice(index, 1, result);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public uploadDocuments = flow(function* (files, assessment, notification) {
        this.saving = true;

        const documents = files
            .map((file) => ({ sourceId: assessment.id, internalOnly: false, mode: "Major", file: file }))
            .map((document) => this.assessmentService.uploadAssessmentDocument(document));

        try {
            const results = yield Promise.all(documents);
            results.forEach(this.updateDocumentList);

            if (notification !== false) {
                if (results.length == 1) {
                    this.parentStore.rootStore.layoutStore.displayToastNotification(
                        `${documents.length} document uploaded successfully`
                    );
                } else {
                    this.parentStore.rootStore.layoutStore.displayToastNotification(
                        `${documents.length} documents uploaded successfully`
                    );
                }
            }

            return results;
        } catch (e) {
            console.error(e);
        } finally {
            this.saving = false;
        }
    });

    private updateDocumentList = (result) => {
        const index = this.documents.findIndex((d) => d.id == result.id);
        if (index == -1) {
            this.documents.unshift(result);
        } else {
            this.documents.splice(index, 1, result);
        }
    };
}
