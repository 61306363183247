import { json2csvAsync } from 'json-2-csv';

export const exportToCSV = (csvData, csvFilename) => {
  if (csvData)
    json2csvAsync(csvData)
    .then((csv) => { 
        var uri = 'data:application/csv;charset=utf-8,' + escape(csv);
        var link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = csvFilename || 'data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
    .catch((err) => { console.error(err) });
}