import { ServiceBase, Constants } from "../graph/serviceBase";

export class StateFlowService extends ServiceBase {
    public async getStateFlows(options) {
        return this.get(`${Constants.baseUrl}/state-flows?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${options.keywords || ""}`);
    }

    public async searchStateFlows(options) {
        return this.post(`${Constants.baseUrl}/state-flows/search`, options);
    }

    public async getStateFlow(id) {
        return this.get(`${Constants.baseUrl}/state-flows/${id}`);
    }

    public async getStateFlowVersions(options) {
        return this.get(
            `${Constants.baseUrl}/state-flows/${options.stateFlowId}/versions?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async getStateFlowSummary(id, version) {
        return this.get(`${Constants.baseUrl}/state-flows/${id}/summary?version=${version || ""}`);
    }

    public async getStateFlowManifest(id, version) {
        return this.get(`${Constants.baseUrl}/state-flows/${id}/manifest?version=${version || ""}`);
    }

    public async getStateFlowVersionManifest(id) {
        return this.get(`${Constants.baseUrl}/state-flows/manifests/${id}`);
    }

    public async createStateFlow(data) {
        return this.post(`${Constants.baseUrl}/state-flows`, data);
    }

    public async updateStateFlow(data) {
        return this.put(`${Constants.baseUrl}/state-flows/${data.entity.id}`, data);
    }

    public async deleteStateFlow(id) {
        return this.delete(`${Constants.baseUrl}/state-flows/${id}`, {});
    }
}
