import { computed } from 'mobx'
import { RootStore } from '../../base/RootStore';
import { DataSourceLifecycleStore } from './DataSourceLifecycleStore';
import { DataSourcePermissionStore } from './DataSourcePermissionStore';
import { DataSourceSelectionStore } from './DataSourceSelectionStore';
import { DataSourceBrowseStore } from './DataSourceBrowseStore';
import { DataSourceDataStore } from './DataSourceDataStore';


export class DataSourceStore {

    public rootStore: RootStore;

    public selectionStore: DataSourceSelectionStore;
    public permissionStore: DataSourcePermissionStore;
    public lifecycleStore: DataSourceLifecycleStore;
    public browseStore: DataSourceBrowseStore;
    public dataStore: DataSourceDataStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // shared stores
        this.selectionStore = new DataSourceSelectionStore(this);
        this.permissionStore = new DataSourcePermissionStore(this);
        this.lifecycleStore = new DataSourceLifecycleStore(this);
        this.dataStore = new DataSourceDataStore(this);

        // page store
        this.browseStore = new DataSourceBrowseStore(this);

    }

    @computed public get coreDataSources() {
        return this.dataStore.coreDataSources
    }

    public coreDataSourceDataSource = (datasourceId) => {
        const selectedDataSource = datasourceId ? this.selectionStore.dataSources.find(D => D.id === datasourceId) : null;
        const selectedCoreDataSource = selectedDataSource ? this.coreDataSources.find(D => D.id === selectedDataSource.coreDataSourceId) : null; 
        return selectedCoreDataSource
    }

}
