import { observable, flow } from "mobx";

import { WorkflowService } from "../../../api/workflows";
import { TaskStore } from "./TaskStore";

export class TaskSelectionStore {
    public parentStore: TaskStore;
    public workflowService: WorkflowService;

    @observable public id: string = null;
    @observable public task: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    public loadTask = flow(function* loadTask(id: string) {
        if (this.id !== id || !this.task) {
            this.loading = true;
            this.id = id;
            this.task = null;
        }

        try {
            this.task = yield this.workflowService.getTaskDefinition(id);
            return this.task;
        } finally {
            this.loading = false;
        }
    });
}
