import { observable, flow, action, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";

export class AssetDashboardStore {
    private parentStore: AssetStore;
    private assetService: AssetService;

    @observable public loading: boolean = false;
    @observable public assets: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Inventory Management",
            key: "inventory",
            onClick: () => this.parentStore.rootStore.routing.push(`/inventory/dashboard`),
        });
        builder.push({ text: "Inventory Dashboard", key: "inventory-dashboard", isCurrentItem: true });

        return builder;
    }

    public loadDashboard = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);

        this.loading = true;

        try {
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    @action
    public replace(asset) {
        const index = this.assets.findIndex((p) => p.id === asset.id);

        if (index !== -1) {
            this.assets = [...this.assets.splice(index, 1, asset)];
        }
    }
}
