import { observable, action, computed, flow } from "mobx";
import { PortfolioStore } from './PortfolioStore';
import { PortfolioService } from '../../../api/graph';

export class PortfolioEditFormStore {
    public portfolioService: PortfolioService;
    public parentStore: PortfolioStore;

    @observable public visible: boolean = false;
    @observable public originalFormData: any;
    @observable public formData: any;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any = null;

    constructor(parentStore: PortfolioStore) {
        this.parentStore = parentStore;
        this.portfolioService = parentStore.portfolioService;
    }

    @computed 
    public get isValid() {
        return this.formData && this.formData.name && this.formData.id;
    }

    @computed 
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }
    
    @action 
    public hide() {
        this.formData = null;
        this.originalFormData = null;
        this.visible = false;
    }

    @action 
    public show(options) {
        this.visible = true;      
        this.formData = {...options.portfolio};
        this.originalFormData = {...this.formData};
    }

    public updatePortfolio = flow(function*() {
        this.saving = true;
        this.error = null;

        try {
            const portfolio = yield this.portfolioService.updatePortfolio(this.formData); 
            this.parentStore.selectionStore.portfolio = portfolio;
            this.parentStore.browseStore.mergePortfolio(portfolio);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
