import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';

import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';

import { PagePartPlaceholder } from  '../../pages/components/elements/PagePartPlaceholder';
import { TableField } from './components/TableField';

const rootClassName = cx(
  'cygraph-ImageViewerPart-root',
  css`
  `
);

export const DatasourceViewerPart = inject('dataSourceStore')(observer(
  class DatasourceViewerPart extends PureComponent {

    constructor(props) {
      super(props)
      this.state = {
        selectedDataSourceDescription : null,
        showAllRecords: false,
      }
    }

    componentWillMount() {
      this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
      this.loadData(nextProps);
    }

    async loadData(partProps) {
      const { part } = partProps;
      const { instance, dataSourceStore } = partProps;      
      const { selectionStore } = dataSourceStore;
      let data = [];
      let count = 0;
      if (part.properties.mainSource) {
        const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === part.properties.mainSource);
        const coreDataSource = selectedDataSource ? dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId) : null;
        if (coreDataSource && selectedDataSource) {
          try {
            // debugger
            const groupBy = part.properties.groupBy ? part.properties.groupBy : (selectedDataSource.groupBy ? selectedDataSource.groupBy : null) 
            const query = selectedDataSource.query;
            // console.log('part.properties', part.properties);
            // console.log('part.properties.filters', part.properties.filters);
            if (part.properties.filters){
              part.properties.filters.forEach(F => {
                query[F.id] = F.value;
              })
            }
            const sentQuery = {};
            const finalQuery = Object.keys(query)
              .map(F => {
                  const filter = part.properties.filters.find(FF => FF.id ===F);
                  if (filter && filter.value) {
                      return filter
                  }
                  return null;
              })
              .filter(F => F);
            finalQuery.forEach(F => sentQuery[F.id]=F.value);
            //filtering and sorting
            // console.log('part.properties',part.properties);
            // console.log('part.properties.orderBy',part.properties.orderBy);
            let sortBy = null;
            if (part.properties.orderBy) {
              sortBy = {
                property: part.properties.orderBy,
                asc: part.properties.orderType === "ascending" ? true : false,
                numberOfResults: part.properties.numberOfResults,
              };
              sentQuery.sortBy = sortBy;
            };
            // console.log('sentQuery',sentQuery);
            // debugger
            part.properties.query = sentQuery;
            part.properties.groupBy = groupBy;
            const results = await coreDataSource.searchService(sentQuery, groupBy);
            data = results.items || [];
            count =  results.totalItems ? results.totalItems : 0;
            this.setState({
              selectedDataSourceDescription : selectedDataSource.description
            })
          } catch (e) {
            console.error(e);
            data = []
            count = 0;
          }
        }
      }
      const dataS = {
        id: part.id,
        data: data,
        count: count,
      }      
      if (instance.selectionStore.data.find(D => D.id === part.id)) {
        let dataIndex = instance.selectionStore.data.findIndex(D => D.id === part.id);
        instance.selectionStore.data[dataIndex] = dataS;
      } else {
        instance.selectionStore.data.push(dataS);
      }
    }

    get _columns() {
      const { part, instance, dataSourceStore } = this.props;
      const { selectionStore } = dataSourceStore;
      if (part.properties.mainSource) {
        const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === part.properties.mainSource);
        const coreDataSource = selectedDataSource ? dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId) : null;
        const partData = instance.selectionStore.data.find(D => D.id === part.id);
        if (partData && coreDataSource && partData.data[0]) {
          return Object.keys(partData.data[0])
            .filter(DS => coreDataSource.fields.find(F => F.id === DS))
            .map(DS => {
              const field = coreDataSource.fields.find(F => F.id === DS);
              return {
                  key: field.id,
                  name: field.title,
                  fieldName: field.id,                   
                  maxWidth: 150,
                  data: field.type,
                  isPadded: true,
                  onRender: (item) => {
                    return <TableField field={field} value={item[field.id]} item={item} />;
                  },
              }
          });  
        }
      }
      return [];
    }
    
    render () {
      const { className, instance, part, zone, section, registry, dataSourceStore } = this.props;
      const { selectionStore } = dataSourceStore;
      const mode = instance.lifecycleStore.mode;
      const data = instance.selectionStore.data ? instance.selectionStore.data.find(D=> D.id === part.id) : null;
      return (
        mode === 'edit' ? <div className={cx(rootClassName, className, mode)}> 
          {this.state.selectedDataSourceDescription} <br />
          (Data preview, visible only on edit mode) <br />       
          <PagePartPlaceholder {...this.props} isConfigured={part.properties.mainSource}>
            { data && <div style={{maxWidth:'750px'}}>
              Total records in database: {data.count} <br />
              This data source has been configured to show: {data.data.length} records<br />
              {data.data.length > 5 && <div> Preview below shows a sample of records <a href="#" onClick={(e)=>{e.preventDefault(); this.setState({showAllRecords: !this.state.showAllRecords})}}>{this.state.showAllRecords ? `[Show Sample Records]` : `[Show All]`}</a></div> }
              <ShimmeredDetailsList
                items={this.state.showAllRecords ? data.data : data.data.slice(0, 5)}
                compact={false}
                columns={this._columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item ? item.id : index}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                ariaLabelForShimmer="Data being fetched"
                ariaLabelForGrid="Sample data"
                />
            </div> }
            {!data && <div style={{maxWidth:'750px'}}>
              The data souce doesn't seems to b e configured correctly  
            </div>}
          </PagePartPlaceholder>
        </div> : null
      );
    }
  }
));