import { observable, flow } from "mobx";
import { RootStore } from "../../base/RootStore";

import { IdentityService } from "../../../api/identity";
import { PrincipalEditUserStore } from "./PrincipalEditUserStore";
import { PrincipalEditGroupStore } from "./PrincipalEditGroupStore";

export const RoleNames = [
    { text: "Agent", key: "agent" },
    { text: "Admin", key: "admin" },
    { text: "Assessments", key: "assessments" },
    { text: "WorkItems", key: "work-items" },
    { text: "Risks", key: "risks" },
    { text: "Insights", key: "insights" },
    { text: "Portfolios", key: "portfolios" },
    { text: "Assets", key: "assets" },
    { text: "Entities", key: "entities" },
    { text: "Controls", key: "controls" },
];

export class PrincipalContextStore {
    private rootStore: RootStore;
    private identityService: IdentityService;
    public editUserStore: PrincipalEditUserStore;
    public editGroupStore: PrincipalEditGroupStore;

    @observable public current: any = null;
    @observable public photo: any = null;
    @observable public loading: boolean = false;
    @observable public error: any = null;
    @observable public roles = RoleNames;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.identityService = new IdentityService(rootStore.authProvider);
        this.editUserStore = new PrincipalEditUserStore(this, this.identityService);
        this.editGroupStore = new PrincipalEditGroupStore(this, this.identityService);
    }

    // public loadCurrentPrincipal = async () => {
    //     if(!this.current && !this.loading) {
    //         this.loading = true;
    //         try {
    //             runInAction(async () => {
    //                 this.current = await this.identityService.getPrincipalSelf();
    //             });
    //             try {
    //                 const photo = await this.identityService.getPrincipalPhoto(this.current.id);
    //                 runInAction(() => {
    //                     this.current.photo = URL.createObjectURL(photo);
    //                 });
    //             } catch(exi) {
    //                 console.warn('user photo is not found');
    //             }
    //         } catch (error) {
    //             runInAction(() => {
    //                 this.error = error;
    //             });
    //         } finally {
    //             runInAction(() => {
    //                 this.loading = false;
    //             });
    //         }
    //     }
    // };

    public loadCurrentPrincipal = flow(function* () {
        if (!this.current && !this.loading) {
            this.loading = true;
            try {
                this.current = yield this.identityService.getPrincipalSelf();
                try {
                    const photo = yield this.identityService.getPrincipalPhoto(this.current.id);
                    this.photo = URL.createObjectURL(photo);
                    this.current.photo = this.photo;
                } catch (exi) {
                    console.warn("user photo is not found");
                }
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        }
    });
}
