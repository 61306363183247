import { observable, flow, computed } from "mobx";

import { WorkflowService } from "../../../api/workflows";
import { WorkflowStore } from "./WorkflowStore";

export class WorkflowHistoryBrowseStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;

    @observable public history: any[] = [];
    @observable public loading: boolean = false;
    @observable public options: any = { showHidden: false };
    @observable public error: any = null;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    @computed
    public get filteredHistory() {
        if (this.options.showHidden) {
            return this.history;
        }
        return this.history.filter((h) => h.activityCount > 0);
    }

    public loadWorkflowHistory = flow(function* (options: any) {
        const o = Object.assign(
            {
                viewType: "context",
                modifiedAfter: null,
                modifiedBefore: null,
                maxResults: 25,
                continuationToken: null,
                showHidden: this.options.showHidden,
            },
            options
        );
        this.loading = true;
        if (!o.continuationToken) {
            this.history = [];
        }
        this.options = o;
        this.error = null;

        try {
            do {
                const { items, ...rest } = yield this.workflowService.getWorkflowInstances(o);
                this.history = this.history.concat(items);
                this.options = { ...rest, showHidden: this.options.showHidden };
                o.visibleCount = items.filter((h) => h.activityCount > 0);
                o.continuationToken = rest.continuationToken;
            } while (o.visibleCount == 0 && o.continuationToken);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
