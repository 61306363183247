import { ServiceBase, Constants } from "../graph/serviceBase";
import { WorkItemLink } from "../../modules/workitems/stores/types/WorkItemLink";
import { useStaticRendering } from "mobx-react";

export class WorkItemService extends ServiceBase {
    /**
     * Get active work items
     */
    public async getWorkItems(options) {
        return this.get(
            `${Constants.baseUrl}/work-items?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }&query.templateId=${options.templateId ? options.templateId : ""}`
        );
    }

    /**
     * Search work items
     */
    public async searchWorkItems(options) {
        // const portfolio = options.portfolio ? options.portfolio.map(P => {return {id:P}}) : null;
        // const status = options.status ? options.status : null;
        // const assignedToUser = options.assignedToUser ? options.assignedToUser.map(U => {return {id:U}}): null;
        // const postData = {
        //     pageSize: options.pageSize,
        //     startIndex:options.startIndex,
        //     templateId: options.templateId ? options.templateId: null,
        //     query : {
        //         keywords: options.keywords ? options.keywords : '',
        //         assignedToUser,
        //         portfolio,
        //         status,
        //     }
        // };
        return this.post(`${Constants.baseUrl}/work-items/search`, options);
    }

    public async exportWorkItems(options) {
        return this.post(`${Constants.baseUrl}/work-items/export`, options);
    }

    /**
     * Create work items
     */
    public async createWorkItem(workItem) {
        return this.post(`${Constants.baseUrl}/work-items`, workItem);
    }

    public async updateWorkItem(workItem) {
        return this.put(`${Constants.baseUrl}/work-items/${workItem.id}`, workItem);
    }

    public async getWorkItem(id) {
        return this.get(`${Constants.baseUrl}/work-items/${id}`);
    }

    public async getWorkItemDefaults(productId) {
        return this.get(`${Constants.baseUrl}/work-items/defaults?productId=${productId}`);
    }

    public async getWorkItemDocuments(workItemId) {
        return this.get(`${Constants.baseUrl}/work-items/${workItemId}/documents`);
    }

    public async getWorkItemDocument(workItemId, id) {
        return this.get(`${Constants.baseUrl}/work-items/${workItemId}/documents/${id}`);
    }

    public async downloadWorkItemDocument(workItemId, id) {
        return this.getBlob(`${Constants.baseUrl}/work-items/${workItemId}/documents/${id}/content`);
    }

    public async createWorkItemDocument(document) {
        return this.post(`${Constants.baseUrl}/work-items/${document.sourceId}/documents`, document);
    }

    public async updateWorkItemDocument(document) {
        return this.put(`${Constants.baseUrl}/work-items/${document.sourceId}/documents/${document.id}`, document);
    }

    public async uploadWorkItemDocument(document) {
        const formData = new FormData();
        formData.set("internalOnly", document.internalOnly);
        formData.set("mode", document.mode);
        if (document.notes) {
            formData.set("notes", document.notes);
        }

        formData.set("entity", document.file);
        if (document.name) {
            formData.set("name", document.name);
        }

        if (!document.id) {
            return this.postForm(`${Constants.baseUrl}/work-items/${document.sourceId}/documents/upload`, formData);
        }

        formData.append("id", document.id);
        return this.putForm(
            `${Constants.baseUrl}/work-items/${document.sourceId}/documents/${document.id}/upload`,
            formData
        );
    }

    public async getWorkItemLinks(workItemId: string, biDirectional: boolean = false) {
        return this.get(`${Constants.baseUrl}/work-items/${workItemId}/links?biDirectional=${biDirectional}`);
    }

    public async createWorkItemLink(link: any) {
        return this.post(`${Constants.baseUrl}/work-items/${link.sourceId}/links`, link);
    }

    public async updateWorkItemLink(link: any) {
        return this.put(`${Constants.baseUrl}/work-items/${link.sourceId}/links/${link.id}`, link);
    }

    public async deleteWorkItemLink(link: any) {
        return this.delete(`${Constants.baseUrl}/work-items/${link.sourceId}/links/${link.id}`, link);
    }

    /**
     * Create a new link between work items
     */
    public async createNewLinkWorkItem(workItemId: string, workItemToLink: any, linkType: WorkItemLink) {
        return this.post(`${Constants.baseUrl}/work-items/${workItemId}/links`, {
            target: {
                id: workItemToLink.key,
            },
            linkType: {
                id: linkType.id,
                name: linkType.name,
            },
            sourceId: workItemId,
        });
    }

    /**
     * Get related work items
     */
    public async getRelatedWorkItems(workItemId: string, biDirectional: boolean = false) {
        return this.get(`${Constants.baseUrl}/work-items/${workItemId}/links?biDirectional=${biDirectional}`);
    }

    /**
     * Delete related work items
     */
    public async deleteRelatedWorkItems(workItemId: string, linkId: string) {
        return this.delete(`${Constants.baseUrl}/work-items/${workItemId}/links/${linkId}`, {
            target: {
                id: workItemId,
            },
            linkType: {
                id: linkId,
            },
            sourceId: workItemId,
        });
    }

    /**
     * Update the relation between 2 work item
     */
    public async updateWorkItemRelation(
        workItemId: string,
        linkId: string,
        linkTypeId: string,
        targetWorkItemId: string
    ) {
        return this.put(`${Constants.baseUrl}/work-items/${workItemId}/links/${linkId}`, {
            target: {
                id: targetWorkItemId,
            },
            linkType: {
                id: linkTypeId,
            },
            id: linkId,
            sourceId: workItemId,
        });
    }

    public async updateWorkItemStatus(workItemId: string, status: string, comment: any) {
        return this.post(`${Constants.baseUrl}/work-items/${workItemId}/${status}`, {
            id: workItemId,
            comment: comment,
        });
    }

    public async assignWorkItem(options) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/assign`, options);
    }

    public async startWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/start`, options);
    }

    public async resolveWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/resolve`, options);
    }

    public async reopenWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/reopen`, options);
    }

    public async blockWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/block`, options);
    }

    public async unblockWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/unblock`, options);
    }

    public async closeWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/close`, options);
    }

    public async cancelWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/cancel`, options);
    }

    public async flowWorkItem(options: any) {
        return this.post(`${Constants.baseUrl}/work-items/${options.id}/flow`, options);
    }

    public async deleteWorkItems(workItemId: string) {
        return this.delete(`${Constants.baseUrl}/work-items/${workItemId}`, {});
    }

    public async getWorkItemActivities(workItemId: string, continuationToken: string = null, maxResults: number = 25) {
        const requestContinuationToken = continuationToken ? `&continuationToken=${continuationToken}` : "";
        return this.get(
            `${Constants.baseUrl}/work-items/${workItemId}/activities?maxResults=${maxResults}&${requestContinuationToken}`
        );
    }

    public async getWorkItemLinkTypes() {
        return this.get(`${Constants.baseUrl}/work-items/link-types`);
    }

    /* Comments */
    public async getWorkItemComments(workItemId: string) {
        return this.get(`${Constants.baseUrl}/work-items/${workItemId}/comments`);
    }

    public async getWorkItemComment(workItemId: string, id: string) {
        return this.get(`${Constants.baseUrl}/work-items/${workItemId}/comments/${id}`);
    }

    public async createWorkItemComment(data: any) {
        return this.post(`${Constants.baseUrl}/work-items/${data.sourceId}/comments`, data);
    }

    public async updateWorkItemComment(data: any) {
        return this.put(`${Constants.baseUrl}/work-items/${data.sourceId}/comments/${data.id}`, data);
    }

    public async removeWorkItemComment(data: any) {
        return this.delete(`${Constants.baseUrl}/work-items/${data.sourceId}/comments/${data.id}`, {});
    }

    // TODO : remove this shit!
    public async postNewWorkItemComment(workItemId: string, comment: any) {
        return this.post(`${Constants.baseUrl}/work-items/${workItemId}/comments`, comment);
    }

    /* End Comments */

    public async getWorkItemOpenedClosed(query) {
        return this.post(`${Constants.baseUrl}/work-items/open-close`, query);
    }

    public async getWorkItemGroupByCount(query) {
        return this.post(`${Constants.baseUrl}/work-items/group-by-count`, query);
    }

    public async getSubStatuses() {
        return this.get(`${Constants.baseUrl}/work-items/sub-statuses`);
    }
}
