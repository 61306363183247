import { observable, action, flow } from "mobx";

import { TriageService } from '../../../api/triage';
import { RootStore } from "../../base/RootStore";
import { TriageHelpStore } from '../stores/TriageHelpStore';
//import { TriageFormData } from './TriageFormData';

//import { triage } from './triage';

export class TriageStore {
    public triageService: TriageService;
    public rootStore: RootStore;
    public helpStore: TriageHelpStore;

    @observable public selectedTriage: any = null;
    @observable public selectedIndex: number = 0;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public configuration: any;
    @observable public breadcrumb: any[] = [
        { text: 'Assurance Management', key: 'assurance', onClick: () => this._goToUrl(`/assurance/dashboard`) },
        { text: 'Security Assessment Triage', key: 'new-triage', as: 'h4', isCurrentItem: true },
    ];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        //this.configuration = triage;
        this.formData = {};
        this.formOptions = {};
        this.triageService = new TriageService(rootStore.authProvider);
        this.helpStore = new TriageHelpStore(this);
    }

    @action
    public resetFormData() {
        this.selectedTriage = null;
        this.selectedIndex = 0;
        this.loading = false;
        this.saving = false;
        this.formData = {}; //new TriageFormData();
        this.formOptions = {};
        this.helpStore.hide({});
        
        this.configuration.pages.forEach(page => {
            page.sections.forEach(section => {
                section.fields.forEach(field => {
                    if(typeof(field.defaultValue) !== 'undefined') {
                        this.formData[field.name] = field.defaultValue;
                    }
                });
            });
        });
    }

    public loadSchema = flow(function*(query: any) {
        this.loading = true;        
        const options = Object.assign({ version: null }, query);

        try {
            this.configuration = yield this.triageService.getTriageSchema(options.version);
            this.resetFormData();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    @action
    public registerTriage(triage) {
        this.saving = true;
        return this.triageService.registerTriage(triage)
            .then(this.registerTriageOnSuccess, this.registerTriageOnError);
    }

    @action.bound
    public registerTriageOnSuccess(result) {        
        this.selectedTriage = result;
        this.saving = false;
        return result;
    }

    @action.bound
    public registerTriageOnError(error) {
        this.saving = false;
        throw error;
    }

    @action
    public loadTriage(id) {
        this.loading = true;
        return this.triageService.getTriage(id)
            .then(this.loadTriageOnSuccess, this.loadTriageOnError);
    }

    @action.bound
    public loadTriageOnSuccess(result) {        
        this.selectedTriage = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadTriageOnError(error) {
        this.loading = false;
        throw error;
    }

    _goToUrl = (url) => {
        if(url) {
            this.rootStore.routing.push(url);
        }
    }
}
