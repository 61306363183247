import { observable, flow } from "mobx";
import { RootStore } from '../../base/RootStore';

import { IdentityService } from '../../../api/identity';

export class PrincipalActivityStore {

    public rootStore: RootStore;
    public identityService: IdentityService;
    
    @observable public activities: any[] = [];
    @observable public principal: any;
    @observable public loading: boolean = false;
    @observable public error: any = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.identityService = new IdentityService(rootStore.authProvider);
    }
    
    public loadActivities = flow(function*(id: string, loadActivities: boolean = true) {
        this.loading = true;

        try {
            this.principal = yield this.identityService.getPrincipal(id);
            if(loadActivities) {
                const result = yield this.identityService.getPrincipalActivities(id);
                this.activities = result.items;
            }
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
