import { RootStore } from "../../base/RootStore";
import { ModellingService } from "../../../api/modelling";
import { PatternSelectionStore } from "./PatternSelectionStore";
import { PatternBrowseStore } from "./PatternBrowseStore";
import { PatternViewerStore } from "./PatternViewerStore";
import { PatternModuleStore } from "./PatternModuleStore";
import { PatternEditorStore } from "./PatternEditorStore";

export class PatternStore {
    public modellingService: ModellingService;
    public rootStore: RootStore;

    public selectionStore: PatternSelectionStore;
    public browseStore: PatternBrowseStore;
    public viewerStore: PatternViewerStore;
    public moduleStore: PatternModuleStore;
    public editorStore: PatternEditorStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.modellingService = new ModellingService(rootStore.authProvider);

        this.selectionStore = new PatternSelectionStore(this);
        this.browseStore = new PatternBrowseStore(this);
        this.viewerStore = new PatternViewerStore(this);
        this.moduleStore = new PatternModuleStore(this);
        this.editorStore = new PatternEditorStore(this);
    }
}
