import { observable, flow, action } from "mobx";

import { PaginationStore } from "@modules/base/PaginationStore";
import { PropertyService } from "@api/properties";
import { PropertyStore } from "./PropertyStore";

export class PropertyBrowseStore {
    public propertyService: PropertyService;
    public parentStore: PropertyStore;

    @observable public loading: boolean = false;
    @observable public properties: any[] = [];
    @observable public query: any = {};
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
        this.pagination = new PaginationStore();
    }

    public loadProperties = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);

        this.loading = true;
        this.properties = [];
        this.query = o.query;
        this.error = null;

        try {
            const result = yield this.propertyService.searchProperties({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            this.properties = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    @action
    public replace(property) {
        const index = this.properties.findIndex((p) => p.id === property.id);

        if (index !== -1) {
            this.properties = [...this.properties.splice(index, 1, property)];
        }
    }
}
