import { observable, computed, action, flow } from "mobx";

import { WorkflowService } from "@api/workflows";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";

import { TaskStore } from "@modules/workflows/stores/TaskStore";
import { TaskSelectionStore } from "@modules/workflows/stores/TaskSelectionStore";
import { TaskPermissionStore } from "@modules/workflows/stores/TaskPermissionStore";
export class TaskLifecycleStore {
    public parentStore: TaskStore;
    public workflowService: WorkflowService;
    public selectionStore: TaskSelectionStore;
    public permissionStore: TaskPermissionStore;
    public principalContext: PrincipalContextStore;

    @observable public saving: boolean = false;
    @observable public error: any;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    public onCreate = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.newFormStore;
            const { success, formData } = yield store.show(options);

            if (success && formData) {
                this.saving = true;

                const { assignedTos, notify } = formData;
                const created = yield this.workflowService.createTaskDefinition({
                    ...formData,
                    assignments: assignedTos.map((assignedTo) => ({ assignedTo, notify })),
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                //this.selectionStore.loadTask(created.id);

                this.parentStore.rootStore.layoutStore.displayToastNotification(`Task created successfully`);

                store.hide();
                return { success: true, task: created };
            }

            store.hide();
            return { success: false };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canEdit() {
        const task = this.selectionStore.task;

        if (!task) {
            return false;
        }

        return task.status === "Pending" && this.permissionStore.canEdit;
    }

    public onEdit = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.editFormStore;
            const { success, formData, formOptions } = yield store.show(options);

            if (success && formData && formOptions) {
                this.saving = true;

                const { assignedTos, notify } = formData;
                const updated = yield this.workflowService.updateTaskDefinition({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                const originalIds = formOptions.originalAssignedToIds;
                const newAssignments = assignedTos.filter((a) => originalIds.indexOf(a.id) === -1);
                const expireAssignments = originalIds.filter((id) => assignedTos.findIndex((a) => a.id === id) === -1);

                if (newAssignments.length > 0) {
                    yield this.workflowService.bulkCreateAssignments({
                        definitionId: formData.id,
                        force: true,
                        assignments: newAssignments.map((a) => ({
                            assignedTo: { id: a.id },
                            notify: notify,
                        })),
                    });
                }

                if (expireAssignments.length > 0) {
                    yield this.workflowService.bulkRevokeAssignments({
                        definitionId: formData.id,
                        force: true,
                        completeIfRequired: true,
                        assignments: expireAssignments.map((id) => ({
                            assignedTo: { id: id },
                            notify: notify,
                        })),
                    });
                }

                this.selectionStore.loadTask(updated.id);

                this.parentStore.rootStore.layoutStore.displayToastNotification(`Task updated successfully`);

                store.hide();
                return { success: true, task: updated };
            }

            store.hide();
            return { success: false };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canRespond() {
        const task = this.selectionStore.task;

        if (!task) {
            return false;
        }

        return task.status === "Pending" && this.permissionStore.canRespond;
    }

    public onRespond = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.responseFormStore;
            const { success, formData } = yield store.show(options);

            if (success && formData) {
                this.saving = true;

                const updated = yield this.workflowService.updateTaskAssignment({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                //this.selectionStore.loadTask(updated.id);

                this.parentStore.rootStore.layoutStore.displayToastNotification(`Task response saved successfully`);

                store.hide();
                return { success: true, assignment: updated };
            }

            store.hide();
            return { success: false };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get haveResponded() {
        const user = this.principalContext.current;
        const task = this.selectionStore.task;

        if (!task || !user) {
            return false;
        }

        return task.assignments.find((a) => a.assignedTo.id === user.id && a.status === "Completed");
    }

    @computed
    public get isPending() {
        const task = this.selectionStore.task;

        if (!task) {
            return false;
        }

        return task.status === "Pending";
    }
}
