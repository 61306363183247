import { observable, flow, computed, action } from "mobx";

import { SettingsStore } from "./SettingsStore";

const DefaultTab = "general";
const KnownTabs = [DefaultTab, "frameworks", "maps", "automations", "recycle-bin"];

export class ControlsSettingsStore {
    public parentStore: SettingsStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public defaultTab: string = DefaultTab;
    @observable public selectedTab: string = DefaultTab;

    constructor(parentStore: SettingsStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings`),
        });
        builder.push({
            text: "Assurance Management",
            key: "assurance-settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings/assurance`),
        });

        switch (this.selectedTab) {
            case "general":
                builder.push({ text: "General", key: "assurance-general", isCurrentItem: true });
                break;
            case "frameworks":
                builder.push({ text: "Frameworks", key: "assurance-frameworks", isCurrentItem: true });
                break;
            case "map":
                builder.push({ text: "Maps", key: "assurance-maps", isCurrentItem: true });
                break;
            case "automations":
                builder.push({ text: "Automations", key: "assurance-automations", isCurrentItem: true });
                break;
            case "recycle-bin":
                builder.push({ text: "Recycle Bin", key: "assurance-recycle-bin", isCurrentItem: true });
                break;
        }

        return builder;
    }

    @computed
    public get activeTabs() {
        return KnownTabs;
    }

    @action
    public setSelectedTab(tab: string) {
        if (KnownTabs.indexOf(tab) !== -1) {
            this.selectedTab = tab;
        } else {
            this.selectedTab = this.defaultTab;
        }
    }
}
