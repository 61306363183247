import { observable, action, computed, flow } from "mobx";
import { RichText } from "@lib/RichText";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemCommentStore {
    public workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public workItemId: string;
    @observable public comments: any[] = [];
    @observable public loading: boolean;
    @observable public comment: any;
    @observable public saving: boolean;
    @observable public error: any;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    @action
    public reset(workItemId: string, loading: boolean = false) {
        this.comments = [];
        this.comment = null;
        this.workItemId = null;
        this.loading = loading;
    }

    public loadComments = flow(function* (workItemId: string) {
        if (this.workItemId !== workItemId) {
            this.loading = true;
            this.comments = [];
            this.comment = null;
            this.workItemId = workItemId;
        }

        try {
            this.comments = yield this.workItemService.getWorkItemComments(workItemId);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    @action
    public onCommentChanged(comment) {
        this.comment = comment;
    }

    @computed
    public get canAddComment() {
        return true; // permission store to be implemented fully.
    }

    @computed
    public get canSave() {
        return RichText.isNotNullOrEmpty(this.comment) && !this.loading && !this.saving;
    }

    public onSave = flow(function* () {
        if (!this.canSave) {
            return;
        }

        this.saving = true;
        try {
            const comment = yield this.workItemService.createWorkItemComment({
                sourceId: this.workItemId,
                internalOnly: false,
                uniqueId: null,
                content: this.comment,
            });

            this.comments = [comment, ...this.comments];
            //this.comments.splice(0, 0, comment);
            this.comment = null;
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Comment added, stakeholders have been notified.`
            );
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
