import { observable, action, computed, flow } from "mobx";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class ControlQuestionEditFormStore {
    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public loading: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const question = yield this.assessmentService.getControlQuestion(options.question.id);

        this.formOptions = {
            ...options,
            pageType: options.pageType || "Edit Control Question",
            editMode: true,
        };

        this.formData = question;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.resolve({
                success,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @action
    public reject(error) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.reject({
                error,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        const ok =
            this.formData && this.formData.id && this.formData.title && this.formData.type && this.formData.schema;

        if (ok) {
            switch (this.formData.type) {
                case "Choice":
                    return (
                        this.formData.schema.options &&
                        this.formData.schema.options.length > 0 &&
                        this.formData.schema.options.filter((o) => !!o.key && !!o.text).length ===
                            this.formData.schema.options.length
                    );
            }
        }

        return ok;
    }
}
