import React from 'react';
import { observer } from 'mobx-react';

import { Dropdown, Toggle } from '../../../base/ui';

import { ObservableSeparator } from '../../../base/components/ObservableSeparator';
import { PrincipalPicker } from '../../containers/PrincipalPicker';

export const UserInfoPartEditor = observer((props) => {
    const { formData } = props;

    return (
        <div>
            <r-grid columns="2">
                <r-cell span="2">
                    <ObservableSeparator>User Setup</ObservableSeparator>
                </r-cell>
                <r-cell span="2">
                    <Dropdown
                        required
                        label="User info style"
                        selectedKey={formData.properties.style}
                        onChange={(ev, op) => {
                            if(op) {
                                formData.properties.style = op.key;
                            }
                        }}
                        options={[
                            {
                                key: 'currentUser',
                                text: "Current user",
                            },{
                                key: 'specificUser',
                                text: "Specific user",
                            }
                        ]}
                    />
                </r-cell>
                {formData.properties.style === 'specificUser' && <r-cell span="2">
                    <PrincipalPicker 
                        placeholder={'Pick a user'}
                        onSelected={(user) => formData.properties.user = user }
                        selected={formData.properties.user ? formData.properties.user : null}
                    />
                </r-cell>}
                {formData.properties.style === 'currentUser' && <r-cell span="1">
                    <Toggle
                        label="Show edit profile button"
                        checked={formData.properties.showEditProfileButton}
                        onChange={(ev, checked) => {
                            formData.properties.showEditProfileButton = checked;
                        }}
                    />
                </r-cell>}
                <r-cell span="1">
                    <Toggle
                        label="Show salutation"
                        checked={formData.properties.showSalutation}
                        onChange={(ev, checked) => {
                            formData.properties.showSalutation = checked;
                        }}
                    />
                </r-cell>
        </r-grid>
    </div>
    );
})
