import { observable, action, computed, flow } from "mobx";

import { WorkItemService } from '../../../api/workitems';
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemTaskModalStore {
    public workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public visible: boolean = false;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = this.parentStore.workItemService;
    }

    @computed public get isVisible() {
        return this.visible
    }

}
