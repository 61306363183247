import { observable, action, computed, flow } from "mobx";
import download from "downloadjs";

import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export type EvidenceType = "Note" | "Document" | "WorkItem" | "Link";

export class AssessmentEvidenceStore {
    private assessmentService: AssessmentService;
    public parentStore: AssessmentStore;

    @observable public visible: boolean = false;
    @observable public selectedAdvisories: any;
    @observable public evidences: any[] = [];
    @observable public formData: any;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any = null;
    @observable public advisoryId: string = null;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed
    public get isValid() {
        if (!this.formData || !this.formData.evidenceType) {
            return false;
        }

        switch (this.formData.evidenceType) {
            case "Note":
                return !!this.formData.notes;
            case "Document":
                return !!this.formData.file;
            case "WorkItem":
                return !!this.formData.workItemId && !!this.formData.linkTypeId;
            case "Link":
                return !!this.formData.link;
        }
    }

    @action
    public show(options) {
        this.visible = true;
        this.selectedAdvisories = options.selectedAdvisories;
        this.formData = {
            notes: null,
            evidenceType: options.evidenceType || "Note",
            file: null,
        };
    }

    @action
    public hide(options) {
        this.visible = false;
        this.selectedAdvisories = null;
        this.formData = null;
    }

    @action
    public setSelectedAdvisories(selection) {
        this.selectedAdvisories = selection;
    }

    @action
    public downloadEvidence(document: any) {
        return this.assessmentService.downloadEvidence(document.id).then((blob) => {
            download(blob, `${document.document.name}${document.document.extension}`, document.contentType);
        });
    }

    public createEvidences = flow(function* (evidences) {
        this.saving = true;

        try {
            const result = [];

            for (let i = 0; i < evidences.length; i++) {
                const evidence = evidences[i];
                const r = yield this.assessmentService.createEvidence(evidence);
                result.push(r);
                this.evidences.unshift(evidence);
            }
            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    /*
    @action
    public createEvidences(evidences) {
        this.saving = true;

        const promises = evidences.map(evidence => {
            return this.assessmentService.createEvidence(evidence);
        });

        return Promise.all(promises).then(this.createEvidencesOnSuccess, this.createEvidencesOnError);
    }

    @action.bound
    public createEvidencesOnSuccess(result) {
        result.forEach(evidence => {
            this.evidences.unshift(evidence);
        });
        this.saving = false;
        return result;
    }

    @action.bound
    public createEvidencesOnError(error) {
        console.error(error);
        this.saving = false;
        throw error;
    } */

    public loadAdvisoryEvidences = flow(function* (advisoryId: string) {
        this.loading = true;

        try {
            this.evidences = yield this.assessmentService.getAdvisoryEvidences(advisoryId);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
