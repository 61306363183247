import React from "react";

export function AssuranceIcon({ className }) {
    return (
        <svg
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="none"
            className={className}
        >
            <path
                d="M5,11 C5,7.13401 8.13401,4 12,4 C15.866,4 19,7.13401 19,11 C19,13.3224 17.728,15.3608 16.1286,16.996 C14.576,18.5834 12.8218,19.6789 12,20.1475 C11.1782,19.6789 9.42404,18.5834 7.87141,16.996 C6.27202,15.3608 5,13.3224 5,11 Z M12,2 C7.02944,2 3,6.02944 3,11 C3,14.0738 4.6759,16.5892 6.44162,18.3945 C8.2176,20.2102 10.1996,21.4278 11.0729,21.921 C11.6521,22.2481 12.3479,22.2481 12.9271,21.921 C13.8004,21.4278 15.7824,20.2102 17.5584,18.3945 C19.3241,16.5892 21,14.0738 21,11 C21,6.02944 16.9706,2 12,2 Z M16.4935,9.39682 C16.884,9.0063 16.884,8.37314 16.4935,7.98261 C16.103,7.59209 15.4698,7.59209 15.0793,7.98261 L10.8348,12.2271 L9.06698,10.4594 C8.67646,10.0689 8.04329,10.0689 7.65277,10.4594 C7.26225,10.8499 7.26225,11.4831 7.65277,11.8736 L10.0569,14.2778 C10.4865,14.7073 11.183,14.7073 11.6126,14.2778 L16.4935,9.39682 Z"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="currentColor"
            ></path>
        </svg>
    );
}
