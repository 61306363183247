import { ServiceBase, Constants } from "../graph/serviceBase";

export class EntityService extends ServiceBase {
    public async getEntities(options) {
        return this.get(
            `${Constants.baseUrl}/entities?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }`
        );
    }

    public async searchEntities(options) {
        return this.post(`${Constants.baseUrl}/entities/search`, options);
    }

    public async getEntity(id) {
        return this.get(`${Constants.baseUrl}/entities/${id}`);
    }

    public async createEntity(data) {
        return this.post(`${Constants.baseUrl}/entities`, data);
    }

    public async updateEntity(data) {
        return this.put(`${Constants.baseUrl}/entities/${data.id}`, data);
    }

    public async deleteEntity(id) {
        return this.delete(`${Constants.baseUrl}/entities/${id}`, {});
    }

    public async addToEntityWatchlist(options) {
        return this.post(`${Constants.baseUrl}/entities/${options.entityId}/watchlist`, options);
    }

    public async removeFromEntityWatchlist(options) {
        return this.delete(`${Constants.baseUrl}/entities/${options.entityId}/watchlist`, options);
    }

    public async getEntityDocuments(workItemId) {
        return this.get(`${Constants.baseUrl}/entities/${workItemId}/documents`);
    }

    public async getEntityDocument(workItemId, id) {
        return this.get(`${Constants.baseUrl}/entities/${workItemId}/documents/${id}`);
    }

    public async downloadEntityDocument(workItemId, id) {
        return this.getBlob(`${Constants.baseUrl}/entities/${workItemId}/documents/${id}/content`);
    }

    public async createEntityDocument(document) {
        return this.post(`${Constants.baseUrl}/entities/${document.sourceId}/documents`, document);
    }

    public async updateEntityDocument(document) {
        return this.put(`${Constants.baseUrl}/entities/${document.sourceId}/documents/${document.id}`, document);
    }

    public async uploadEntityDocument(document) {
        const formData = new FormData();
        formData.set("internalOnly", document.internalOnly);
        formData.set("mode", document.mode);
        if (document.notes) {
            formData.set("notes", document.notes);
        }

        formData.set("entity", document.file);
        if (document.name) {
            formData.set("name", document.name);
        }

        if (!document.id) {
            return this.postForm(`${Constants.baseUrl}/entities/${document.sourceId}/documents/upload`, formData);
        }

        formData.append("id", document.id);
        return this.putForm(
            `${Constants.baseUrl}/entities/${document.sourceId}/documents/${document.id}/upload`,
            formData
        );
    }
}
