import React, { Fragment, useCallback } from "react";
import classNames from "classnames";
import { Float } from "@headlessui-float/react";

import { IconButton } from "@ui/elements/Button";

import { Dropdown as FabricDropDown } from "office-ui-fabric-react/lib/Dropdown";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, LockClosedIcon } from "@heroicons/react/20/solid";

const Dropdown = ({ allowClear, ...props }) => {
    if (props.legacy) {
        return <FabricDropDown {...props} />;
    }

    const idPropertyName = props.by || "key";
    const displayPropertyName = props.display || "text";
    const multiple = props.multiSelect || props.multiple;

    const isOptionDisabled = useCallback(
        (option) => {
            if (typeof props.disabledProperty === "string") {
                return option[props.disabledProperty];
            }
            if (typeof props.disabledProperty === "function") {
                return props.disabledProperty(option);
            }
            return option.itemType === 2 || option.itemType === "group";
        },
        [props.disabledProperty]
    );

    let value = props.value
        ? props.options.find((o) => o[idPropertyName] === props.value[idPropertyName])
        : props.selected
        ? props.options.find((o) => o[idPropertyName] === props.selected[idPropertyName])
        : props.selectedKey
        ? props.options.find((o) => o[idPropertyName] === props.selectedKey)
        : null;

    if (multiple) {
        value = props.value
            ? props.options.filter((o) => props.value.findIndex((v) => v[idPropertyName] === o[idPropertyName]) !== -1)
            : props.selected
            ? props.options.filter(
                  (o) => props.selected.findIndex((v) => v[idPropertyName] === o[idPropertyName]) !== -1
              )
            : props.selectedKeys
            ? props.options.filter((o) => props.selectedKeys.indexOf(o[idPropertyName]) !== -1)
            : [];
    }

    const onReset = useCallback(() => {
        if (props.onChange) {
            const result = multiple ? [] : null;
            props.onChange(result, result);
        }
    }, [props.onChange, multiple]);

    return (
        <Listbox
            as="div"
            className={classNames("zerodai-dropdown", props.className)}
            by={idPropertyName}
            value={value || null}
            onChange={(op) => (props.onChange ? props.onChange(op, op) : null)}
            disabled={props.disabled}
            multiple={multiple}
        >
            {props.label && (
                <Listbox.Label
                    className={classNames("h-8 flex items-center text-sm text-gray-700 pointer-events-none")}
                >
                    <div className="flex items-center justify-between h-full w-full">
                        <span
                            className={classNames(
                                props.required && "after:content-['*'] after:text-red-700 after:pl-1"
                            )}
                        >
                            {props.label}
                        </span>
                        {allowClear && (multiple ? value && value.length > 0 : !!value) && (
                            <span className="flex-shrink pointer-events-auto">
                                <IconButton
                                    className="border-none"
                                    styles={{ root: { width: 24, height: 24 }, icon: { fontSize: 13, lineHeight: 13 } }}
                                    iconProps={{ iconName: "ClearFilter" }}
                                    onClick={onReset}
                                />
                            </span>
                        )}
                    </div>
                </Listbox.Label>
            )}
            <Float
                as="div"
                floatingAs={Fragment}
                className="relative"
                placement="bottom-start"
                flip={10}
                tailwindcssOriginClass
            >
                <Listbox.Button
                    className={classNames(
                        "relative w-full cursor-default rounded-sm border border-gray-300  py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm",
                        "focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
                        props.disabled ? "bg-gray-50" : "bg-white"
                    )}
                >
                    {(multiple ? value.length === 0 : !value) && props.placeholder ? (
                        <span className="block truncate h-5 text-gray-500">{props.placeholder}</span>
                    ) : (
                        <span className="block truncate h-5">
                            {multiple
                                ? value.map((v) => v[displayPropertyName]).join("; ")
                                : value
                                ? value[displayPropertyName]
                                : null}
                        </span>
                    )}
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>

                <Listbox.Options className="max-h-60 w-full overflow-auto rounded-sm border border-gray-300 bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {props.options.map((option) => (
                        <Listbox.Option
                            key={option[idPropertyName]}
                            className={({ active }) =>
                                classNames(
                                    active ? "bg-gray-100 text-gray-600" : "bg-white text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                    option.itemType !== 2 && option.itemType !== "group"
                                        ? "py-2"
                                        : "py-2 text-gray-400 bg-gray-50 text-xs uppercase"
                                )
                            }
                            value={option}
                            disabled={isOptionDisabled(option)}
                        >
                            {({ selected, active }) => (
                                <>
                                    {props.onRenderOption ? (
                                        props.onRenderOption(option, { active, selected })
                                    ) : (
                                        <span
                                            className={classNames(
                                                selected ? "font-semibold" : "font-normal",
                                                "block truncate"
                                            )}
                                        >
                                            {option[displayPropertyName]}
                                        </span>
                                    )}

                                    {selected ? (
                                        <span
                                            className={classNames(
                                                active ? "text-gray-600" : "text-indigo-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                        >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}

                                    {props.disabledProperty && option[props.disabledProperty] ? (
                                        <span
                                            className={classNames(
                                                "text-gray-400",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                        >
                                            <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Float>
        </Listbox>
    );
};

export { Dropdown };
