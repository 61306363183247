import moment from 'moment';
import { computeTimelineFilter } from './computeTimelineFilter';
import { generateRiskQuery } from '../queries';

async function getRisks(query, api) {
    const generatedQuery = generateRiskQuery(query)
    // console.log(generatedQuery)
    const results = await api({
        pageSize: query.sortBy && query.sortBy.numberOfResults ?  query.sortBy.numberOfResults : 250,
        startIndex: 0,
        query: generatedQuery,
    });
    return results
}

export { getRisks }