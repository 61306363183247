import { observable, flow, computed, action } from "mobx";

import { SettingsStore } from "./SettingsStore";

const DefaultTab = "general";
const KnownTabs = [DefaultTab, "users", "groups", "automations", "recycle-bin"];

export class IdentitySettingsStore {
    public parentStore: SettingsStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public defaultTab: string = DefaultTab;
    @observable public selectedTab: string = DefaultTab;

    constructor(parentStore: SettingsStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings`),
        });
        builder.push({
            text: "Users & Groups",
            key: "identity-settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings/identity`),
        });

        switch (this.selectedTab) {
            case "general":
                builder.push({ text: "General", key: "identity-general", isCurrentItem: true });
                break;
            case "users":
                builder.push({ text: "Users", key: "identity-users", isCurrentItem: true });
                break;
            case "groups":
                builder.push({ text: "Groups", key: "identity-groups", isCurrentItem: true });
                break;
            case "automations":
                builder.push({ text: "Automations", key: "identity-automations", isCurrentItem: true });
                break;
            case "recycle-bin":
                builder.push({ text: "Recycle Bin", key: "identity-recycle-bin", isCurrentItem: true });
                break;
        }

        return builder;
    }

    @computed
    public get activeTabs() {
        return KnownTabs;
    }

    @action
    public setSelectedTab(tab: string) {
        if (KnownTabs.indexOf(tab) !== -1) {
            this.selectedTab = tab;
        } else {
            this.selectedTab = this.defaultTab;
        }
    }
}
