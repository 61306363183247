import { select, event } from 'd3';

export function generateInlineTitle(chartWrapperRef, theme) {
  // console.log('theme', theme);
  let newSVG = chartWrapperRef.current.getElementsByTagName('svg')[0];
  newSVG.style.backgroundColor = "var(--white)";
  newSVG.style.fontFamily = "var(--font-family)";
  newSVG.style.fontSize = "10px";
  const pathElements = newSVG.getElementsByClassName("domain");
  for (let item of pathElements) {
      item.style.fill = 'none';
      item.style.stroke = 'var(--black)';
  }
  const mainPathElement = newSVG.getElementsByClassName("c3-shape c3-shape c3-line");
  if (mainPathElement) { 
    for (let item of mainPathElement) {
        item.style.fill = 'none';
    }
  }
  const legendElement = newSVG.getElementsByClassName("c3-legend-item");
  if (legendElement) {
    for (let item of legendElement) {
      const textElement = item.getElementsByTagName("text");
      for (let textItem of textElement) {
        textItem.style.fill = theme.palette.neutralSecondary;
      }
    }
  }
  const ticksElements = newSVG.getElementsByClassName("tick");
  if (ticksElements) {
    for (let ticksElement of ticksElements) {
      const textElements = ticksElement.getElementsByTagName("text");
      for (let textItem of textElements) {
        textItem.style.fill = theme.palette.neutralSecondary;
      }
      const lineElements = ticksElement.getElementsByTagName("line");
      for (let lineElement of lineElements) {
        lineElement.style.stroke = theme.palette.neutralSecondary;
      }
    }
  }
  //gauge text values
  const gaugeValueElement = newSVG.getElementsByClassName("c3-gauge-value");
  if (gaugeValueElement) {
    for (let element of gaugeValueElement) {
      element.style.fill = theme.palette.neutralSecondary;
    }
  }

  // if (showTitle) {
  //   const d3Svg = select(newSVG);
  //   const titleDOM = newSVG.getElementsByClassName("c3-title")[0];
  //   if (titleDOM && newSVG) {
  //       const title = select(titleDOM);
  //       title
  //         .attr('class','chartTitle')
  //         .attr("fill", theme.palette.neutralSecondary)
  //         .style("text-transform", "uppercase")
  //         .style("font-size", "13px")
  //         .style("font-family", "var(--font-family)")
  //         .style("font-weight", "600");
  //       const svgWidth = newSVG.clientWidth;
  //       const titleBox = title.node().getBBox();     
  //       d3Svg.append("line")
  //         .attr("class","titleBottomBorderGrey")
  //         .attr("stroke", theme.palette.neutralSecondary)
  //         .attr("opacity", "0.4")
  //         .attr("stroke-width", "0.5px")
  //         .attr("shape-rendering", "crispedges")
  //         .attr("x1", (titleBox.x + titleBox.width))
  //         .attr("y1", 39)
  //         .attr("x2", svgWidth)
  //         .attr("y2", 39);
  //       d3Svg.append("line")
  //         .attr("class","titleBottomBorderGrey")
  //         .attr("stroke", theme.palette.neutralSecondary)
  //         .attr("opacity", "0.4")
  //         .attr("stroke-width", "0.5px")
  //         .attr("shape-rendering", "crispedges")
  //         .attr("x1", 0)
  //         .attr("y1", 39)
  //         .attr("x2", titleBox.x)
  //         .attr("y2", 39);
  //       d3Svg.append("line")
  //         .attr("class","titleBottomBorderGreen")
  //         .attr("stroke", "var(--nav-color-accent)")
  //         .attr("opacity", "1")
  //         .attr("stroke-width", "0.5px")
  //         .attr("shape-rendering", "crispedges")
  //         .attr("x1", titleBox.x)
  //         .attr("y1", 39)
  //         .attr("x2", (titleBox.x + titleBox.width))
  //         .attr("y2", 39);
  //       let tooltipTriggerX = titleBox.x + titleBox.width;
  //       // console.log('tooltipTriggerX', tooltipTriggerX)
  //       if (showTooltip && tooltip) {
  //         d3Svg.append("circle")
  //             .attr("id", "circleCustomTooltip")
  //             .attr("class", "circleTooltip")
  //             .attr("cx", tooltipTriggerX + 20)
  //             .attr("cy", 21)
  //             .attr("r", 6)
  //             .attr("fill", "var(--nav-color-accent)")
  //             .style("cursor", "pointer");
  //         d3Svg.append("text")
  //             .attr("id", "circleCustomTooltipLetter")
  //             .attr("class", "circleTooltip")
  //             .attr("x", tooltipTriggerX + 17.5)
  //             .attr("y", 24)
  //             .attr("fill", "var(--nav-background)")
  //             .style("cursor", "pointer")
  //             .style("font-size", "10px")
  //             .style("font-family", "var(--font-family)")
  //             .style("font-weight", "600")
  //             .html("?");
  //         d3Svg.append("circle")
  //             .attr("class", "circleCustomTooltipContainer circleTooltip")
  //             .attr("cx", tooltipTriggerX + 20)
  //             .attr("cy", 21)
  //             .attr("r", 6)
  //             .attr("fill", "black")
  //             .attr("fill-opacity", "0.0")
  //             .style("cursor", "pointer");
  
  //         if (chartWrapperRef.current.getElementsByClassName(".chartTooltip").length === 0) {
  //             select(chartWrapperRef.current)
  //               .append("div")
  //               .attr("class", "chartTooltip")
  //               .style("position", "fixed")
  //               .style("visibility", "hidden")
  //               .style("background-color", "white")
  //               .style("border", "solid")
  //               .style("border-width", "1px")
  //               .style("border-radius", "5px")
  //               .style("z-index", "999")
  //               .style("padding", "10px")
  //               .html(tooltip);
  //         }
  //         select(chartWrapperRef.current)
  //           .select(".circleCustomTooltipContainer")
  //             .on("mouseover", (function () {
  //               this.current.getElementsByClassName("chartTooltip")[0].style.visibility = "visible";
  //             }).bind(chartWrapperRef))
  //             .on("mousemove", (function() {
  //                 var x = event.pageX + 10;
  //                 var y = event.pageY - window.scrollY + 20;
  //                 this.current.getElementsByClassName("chartTooltip")[0].style.top = `${y}px`;
  //                 this.current.getElementsByClassName("chartTooltip")[0].style.left = `${x}px`;
  //             }).bind(chartWrapperRef))
  //             .on("mouseout", (function() {
  //               this.current.getElementsByClassName("chartTooltip")[0].style.visibility = "hidden";
  //             }).bind(chartWrapperRef));
  //     }
  //   }
  // }
  
  return newSVG;
}