export const settings: any[] = [
    {
        name: "Users & Groups",
        key: "users-and-groups",
        description: "Manage users and groups within your organisation",
        isExpanded: true,
        links: [
            {
                name: "Manage Users",
                url: `/settings/identity/users`,
                icon: "Contact",
                key: "/settings/identity/users",
                isSelected: (url) => url.indexOf("/settings/identity/users") === 0,
            },
            {
                name: "Manage Groups",
                url: `/settings/identity/groups`,
                icon: "Teamwork",
                key: "/settings/identity/groups",
                isSelected: (url) => url.indexOf("/settings/identity/groups") === 0,
            },
        ],
    },
    {
        name: "Configuration",
        key: "configuration",
        description: "Configure reference information specific to your InfoSec team",
        isExpanded: true,
        links: [
            {
                name: "Demand Management",
                url: `/settings/demand`,
                icon: "ExternalTFVC",
                key: "/settings/demand",
                isSelected: (url) => url.indexOf("/settings/demand") === 0,
            },
            {
                name: "Assurance Management",
                url: `/settings/assurance`,
                icon: "WorkItem",
                key: "/settings/assurance",
                isSelected: (url) => url.indexOf("/settings/assurance") === 0,
            },
            {
                name: "Risk Management",
                url: `/settings/risks`,
                icon: "ReportHacked",
                key: "/settings/risks",
                isSelected: (url) => url.indexOf("/settings/risks") === 0,
            },
            {
                name: "Inventory Management",
                url: `/settings/inventory`,
                icon: "Org",
                key: "/settings/inventory",
                isSelected: (url) => url.indexOf("/settings/inventory") === 0,
            },
            // {
            //     name: "Entity Management",
            //     url: `/settings/entities`,
            //     icon: "Org",
            //     key: "/settings/entities",
            //     isSelected: (url) => url.indexOf("/settings/entities") === 0,
            // },
            {
                name: "Controls Management",
                url: `/settings/controls`,
                icon: "TestPlan",
                key: "/settings/controls",
                isSelected: (url) => url.indexOf("/settings/controls") === 0,
            },
            {
                name: "Portfolios",
                url: `/settings/portfolios`,
                icon: "FolderQuery",
                key: "/settings/portfolios",
                isSelected: (url) => url.indexOf("/settings/portfolios") === 0,
            },
            // {
            //     name: "Patterns Library",
            //     url: `/settings/patterns`,
            //     icon: "WebComponents",
            //     key: "/settings/patterns",
            //     isSelected: (url) => url.indexOf("/settings/patterns") === 0,
            // },
            {
                name: "Page Libraries",
                url: `/settings/page-libraries`,
                icon: "DocLibrary",
                key: "/settings/page-libraries",
                isSelected: (url) =>
                    url.indexOf("/settings/page-libraries") === 0 || url.indexOf("/settings/pages") === 0,
            },
            {
                name: "Data Sources",
                url: `/settings/data-sources`,
                icon: "PageData",
                key: "/settings/data-sources",
                isSelected: (url) => url.indexOf("/settings/data-sources") === 0,
            },
            // {
            //     name: "Control Sets",
            //     url: `/settings/control-sets`,
            //     icon: "Equalizer",
            //     key: "/settings/control-sets",
            //     isSelected: (url) => url.indexOf("/settings/control-sets") === 0,
            // },
            // {
            //     name: "Control Maps",
            //     url: `/settings/control-maps`,
            //     icon: "Communications",
            //     key: "/settings/control-maps",
            //     isSelected: (url) => url.indexOf("/settings/control-maps") === 0,
            // },
            // {
            //     name: "Risk Registries",
            //     url: `/settings/risk-registries`,
            //     icon: "ReportWarning",
            //     key: "/settings/risk-registries",
            //     isSelected: (url) => url.indexOf("/settings/risk-registries") === 0,
            // },
            // {
            //     name: "Risk Types",
            //     url: `/settings/risk-types`,
            //     icon: "BulletedTreeList",
            //     key: "/settings/risk-types",
            //     isSelected: (url) => url.indexOf("/settings/risk-types") === 0,
            // },
            // {
            //     name: "Workflows",
            //     url: `/settings/workflows`,
            //     icon: "VisioDiagram",
            //     key: "/settings/workflows",
            //     isSelected: (url) => url.indexOf("/settings/workflows") === 0,
            // },
        ],
    },
];
