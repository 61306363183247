import { observable, action, computed, flow } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { PropertyService } from "@api/properties";
import { PropertyStore } from "@modules/properties/stores/PropertyStore";
import { PropertyTypes, TextFormats } from "@modules/properties/stores/PropertyConstants";

export class PropertyEditFormStore {
    private propertyService: PropertyService;
    public parentStore: PropertyStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const property = yield this.propertyService.getProperty(options.property.id);

        this.formOptions = {
            ...options,
            pageType: options.pageType || `Edit Property`,
            pageDescription: `${property.name} (${property.staticName})`,
            disabled: property.locked,
        };

        this.formData = { ...property };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        let valid =
            this.formData &&
            this.formData.id &&
            !this.formData.locked &&
            this.formData.name &&
            this.formData.type &&
            this.formData.order >= 0 &&
            this.formData.section &&
            this.formData.section.id &&
            this.formData.scope &&
            this.formData.scopeType &&
            this.formData.schema &&
            this.formData.target;

        if (valid) {
            switch (this.formData.type) {
                case PropertyTypes.Choice:
                    valid = valid && this.formData.schema.options && this.formData.schema.options.length > 0;
                    return valid;
                case PropertyTypes.Number:
                    if (typeof this.formData.schema.min === "number" && typeof this.formData.schema.max === "number") {
                        valid = valid && this.formData.schema.min < this.formData.schema.max;
                    }
                    if (
                        typeof this.formData.schema.min === "number" &&
                        typeof this.formData.schema.default === "number"
                    ) {
                        valid = valid && this.formData.schema.min <= this.formData.schema.default;
                    }
                    if (
                        typeof this.formData.schema.max === "number" &&
                        typeof this.formData.schema.default === "number"
                    ) {
                        valid = valid && this.formData.schema.max >= this.formData.schema.default;
                    }
                    return valid;
                case PropertyTypes.Text:
                    valid = valid && this.formData.schema.format;

                    if (
                        this.formData.schema.format === TextFormats.Plain &&
                        typeof this.formData.schema.maxLength === "number" &&
                        typeof this.formData.schema.default === "string"
                    ) {
                        valid = valid && this.formData.schema.maxLength >= this.formData.schema.default.length;
                    }
                    return valid;
            }
        }

        return valid;
    }
}
