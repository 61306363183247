import { observable, action, computed } from "mobx";

import { StateFlowEditorStore } from "./StateFlowEditorStore";
import { TargetMetadata } from "./TargetMetadata";

export class StateFlowPreviewStore {
    public parentStore: StateFlowEditorStore;

    @observable public mock: any = null;
    @observable public state: string = null;
    @observable public subState: string = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;

    public options: any;

    constructor(parentStore: StateFlowEditorStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get manifest() {
        return this.parentStore.manifest;
    }

    @computed
    public get triggers() {
        if (!this.manifest || !this.mock) {
            return [];
        }

        const currentSubState = this.manifest.states.find((s) => s.name === this.subState);

        if (!currentSubState) {
            return [];
        }

        return currentSubState.triggers || [];
    }

    @computed
    public get conditions() {
        if (!this.manifest || !this.mock) {
            return [];
        }

        const defaultCondition = () => true;
        const conditions = {};
        TargetMetadata[this.manifest.target].conditions.forEach((condition) => {
            conditions[condition.id] = defaultCondition;
        });

        return conditions;
    }

    @action
    public onTrigger(options) {
        if (typeof options === "string") {
            this.parentStore.parentStore.rootStore.layoutStore.displayToastNotification(`${options} clicked`);
        } else if (options && typeof options.name === "string") {
            this.parentStore.parentStore.rootStore.layoutStore.displayToastNotification(`${options.name} clicked`);
        }

        if (options && options.command && options.command.to) {
            const state = this.manifest.states.find((s) => s.id === options.command.to);
            this.subState = state.name;
        }
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        this.title = options.title;
        this.mock = options.mock || {
            type: this.manifest.target,
            code: "XYZ010",
            title: `Praesent euismod nisl sem, vulputate faucibus nibh porta et`,
            tabs: ["Summary", "Related Document", "Related Work"],
        };

        if (options.state) {
            this.subState = this.manifest.states.find((s) => s.id === options.state.id)?.name;
        } else {
            this.subState = this.manifest.states.find((s) => s.id === this.manifest.initialStateId)?.name;
        }

        this.visible = true;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.mock = null;
        this.visible = false;
    }

    @action
    public resolve(success) {
        this.options.resolve({ success });
    }

    @action
    public reject(error) {
        this.options.reject({ success: false, error });
    }
}
