import { observable, action, flow } from "mobx";
import download from "downloadjs";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export type DocumentGridView = "all" | "internalOnly";

export class WorkItemDocumentStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public documents: any[] = [];
    @observable public selectedDocument: any;
    @observable public gridView: DocumentGridView = "all";
    @observable public selectedDocuments: any;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    @action
    public setSelectedDocuments(selection) {
        this.selectedDocuments = selection;
    }

    @action
    public setGridView(view: DocumentGridView) {
        this.gridView = view;
    }

    @action
    public setSearchKeywords(keywords: string) {
        this.keywords = keywords;
    }

    @action
    public downloadDocument(document: any) {
        return this.workItemService.downloadWorkItemDocument(document.sourceId, document.id).then((blob) => {
            download(blob, `${document.document.name}${document.document.extension}`, document.contentType);
        });
    }

    public loadDocuments = flow(function* (workItemId: string) {
        this.loading = true;
        this.documents = [];

        try {
            const result = yield this.workItemService.getWorkItemDocuments(workItemId);
            this.documents = result;
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public uploadDocument = flow(function* (document) {
        this.saving = true;

        try {
            const result = yield this.workItemService.uploadWorkItemDocument(document);
            this.updateDocumentList(result);
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.saving = false;
        }
    });

    public uploadDocuments = flow(function* (files, workItemId, notification) {
        this.saving = true;

        const documents = files
            .map((file) => ({ sourceId: workItemId, internalOnly: false, mode: "Major", file: file }))
            .map((document) => this.workItemService.uploadWorkItemDocument(document));

        try {
            const results = yield Promise.all(documents);
            results.forEach(this.updateDocumentList);

            if (notification !== false) {
                if (results.length == 1) {
                    this.parentStore.rootStore.layoutStore.displayToastNotification(
                        `${documents.length} document uploaded successfully`
                    );
                } else {
                    this.parentStore.rootStore.layoutStore.displayToastNotification(
                        `${documents.length} documents uploaded successfully`
                    );
                }
            }

            return results;
        } catch (e) {
            console.error(e);
        } finally {
            this.saving = false;
        }
    });

    private updateDocumentList = (result) => {
        const index = this.documents.findIndex((d) => d.id == result.id);
        if (index == -1) {
            this.documents.unshift(result);
        } else {
            this.documents.splice(index, 1, result);
        }
    };
}
