import React from "react";
import classNames from "classnames";
import { TextField as FabricTextField } from "office-ui-fabric-react/lib/TextField";

import { FontIcon } from "@ui/elements/Icon";
import { useId } from "@fluentui/react-hooks";

const TextField = ({ label, multiline, className, onChange, autoAdjustHeight, iconProps, componentRef, ...props }) => {
    const inputId = useId("textfield");
    return (
        <div>
            {label && (
                <div className="flex justify-between items-center">
                    <label
                        htmlFor={inputId}
                        className={classNames({
                            "h-8 flex items-center text-sm text-gray-700": true,
                            "after:content-['*'] after:text-red-700 after:pl-1": props.required,
                        })}
                    >
                        {label}
                    </label>
                    {!props.required && <span className="text-sm text-gray-400">Optional</span>}
                </div>
            )}

            <div>
                {multiline ? (
                    <textarea
                        ref={componentRef}
                        rows={props.rows || 3}
                        id={inputId}
                        onChange={onChange ? (ev) => onChange(ev, ev.target.value) : null}
                        className={classNames(
                            "block w-full rounded-sm border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                            className,
                            {
                                "bg-white": !props.disabled,
                                "bg-gray-50": props.disabled,
                            }
                        )}
                        {...props}
                    />
                ) : (
                    <div className="relative rounded-md shadow-sm">
                        {iconProps && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                {iconProps.iconName && (
                                    <FontIcon {...iconProps} className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                )}
                                {iconProps.icon && (
                                    <iconProps.icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                )}
                            </div>
                        )}
                        <input
                            ref={componentRef}
                            id={inputId}
                            type={props.type || "text"}
                            onChange={onChange ? (ev) => onChange(ev, ev.target.value) : null}
                            className={classNames(
                                "block w-full rounded-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                                className,
                                {
                                    "bg-white": !props.disabled,
                                    "bg-gray-50": props.disabled,
                                    "pr-10": !!iconProps,
                                }
                            )}
                            {...props}
                        />
                    </div>
                )}
            </div>
        </div>
    );

    return <FabricTextField {...props} />;
};

export { TextField };
