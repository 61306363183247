import { observable, flow, computed } from "mobx";
import moment from "moment";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class AuditActivityCalendarStore {
    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public events: any[] = [];
    @observable public error: any;
    @observable public view: string = "Month";
    @observable public date: Date = new Date();

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Control Registry",
            key: "controls-dashboard",
            onClick: () => this.parentStore.rootStore.routing.push(`/controls/dashboard`),
        });

        builder.push({
            text: "Audit Calendar",
            key: "audit-calendar",
            onClick: () => this.parentStore.rootStore.routing.push(`/controls/calendar`),
        });

        return builder;
    }

    public loadEvents = flow(function* (options) {
        console.log("loading events", options);
        const o = Object.assign({ keywords: null, view: "Month" }, options);
        this.loading = true;
        this.events = [];
        this.view = o.view;
        this.date = moment.utc(o.date).toDate();

        this.events = [
            {
                id: "1",
                title: "Review control 1.5 - Maintain Asset Inventory Information",
                date: "2022-03-23",
            },
            {
                id: "2",
                title: "Review control 5.5 - Implement Automated Configuration Monitoring Systems",
                date: "2022-03-31",
            },
            {
                id: "3",
                title: "Review control 7.1 - Ensure Use of Only Fully Supported Browsers and Email Clients",
                date: "2022-04-11",
            },
        ];

        try {
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
