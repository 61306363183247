import { observable, action, computed, flow } from "mobx";
import download from "downloadjs";

import { RiskService } from "@api/risks";
import { RiskStore } from "./RiskStore";

export type DocumentGridView = "all" | "internalOnly";

export class RiskDocumentStore {
    private riskService: RiskService;
    public parentStore: RiskStore;

    @observable public documents: any[] = [];
    @observable public selectedDocument: any;
    @observable public gridView: DocumentGridView = "all";
    @observable public selectedDocuments: any;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public setSelectedDocuments(selection) {
        this.selectedDocuments = selection;
    }

    @action
    public setGridView(view: DocumentGridView) {
        this.gridView = view;
    }

    @action
    public setSearchKeywords(keywords: string) {
        this.keywords = keywords;
    }

    public downloadDocument = flow(function* (document: any) {
        try {
            const risk = this.parentStore.selectionStore.risk;
            const blob = yield this.riskService.downloadRiskDocument(risk.id, document.id);
            download(blob, `${document.document.name}${document.document.extension}`, document.contentType);
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });

    public loadDocuments = flow(function* (riskId: string) {
        this.loading = true;
        this.documents = [];

        try {
            this.documents = yield this.riskService.getRiskDocuments(riskId);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public uploadDocument = flow(function* (document: any) {
        this.saving = true;

        try {
            const risk = this.parentStore.selectionStore.risk;
            const result = yield this.riskService.uploadRiskDocument(risk.id, document);
            const index = this.documents.findIndex((d) => d.id == result.id);
            if (index == -1) {
                this.documents.unshift(result);
            } else {
                this.documents.splice(index, 1, result);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canUpload() {
        const { risk, lifecycle } = this.parentStore.selectionStore;

        if (!risk || !risk.lifecycle) {
            return false;
        }

        const status = risk.lifecycle.status;

        return (
            status != "Closed" &&
            status != "Cancelled" &&
            status != "Transferred" &&
            this.parentStore.permissionStore.canAddDocument
        );
    }

    // public onUpload = flow(function* (files: any[]) {
    //     this.saving = true;

    //     try {
    //         const risk = this.parentStore.selectionStore.risk;

    //         const documents = files
    //             .map((file) => {
    //                 return {
    //                     sourceId: risk.lifecycleId,
    //                     internalOnly: false,
    //                     mode: "Major",
    //                     file: file,
    //                 };
    //             })
    //             .map((document) => {
    //                 return this.uploadDocument(document);
    //             });

    //         yield Promise.all(documents).then(() => {
    //             if (documents.length == 1) {
    //                 this.parentStore.rootStore.layoutStore.displayToastNotification(
    //                     `${documents.length} document uploaded successfully`
    //                 );
    //             } else {
    //                 this.parentStore.rootStore.layoutStore.displayToastNotification(
    //                     `${documents.length} documents uploaded successfully`
    //                 );
    //             }
    //         });
    //     } catch (error) {
    //         console.error(error);
    //         this.error = error;
    //     } finally {
    //         this.saving = false;
    //     }
    // });

    public uploadDocuments = flow(function* (files, risk, notification) {
        this.saving = true;

        const documents = files
            .map((file) => ({ sourceId: risk.lifecycleId, internalOnly: false, mode: "Major", file: file }))
            .map((document) => this.riskService.uploadRiskDocument(risk.id, document));

        try {
            const results = yield Promise.all(documents);
            results.forEach(this.updateDocumentList);

            if (notification !== false) {
                if (results.length == 1) {
                    this.parentStore.rootStore.layoutStore.displayToastNotification(
                        `${documents.length} document uploaded successfully`
                    );
                } else {
                    this.parentStore.rootStore.layoutStore.displayToastNotification(
                        `${documents.length} documents uploaded successfully`
                    );
                }
            }

            return results;
        } catch (e) {
            console.error(e);
        } finally {
            this.saving = false;
        }
    });

    private updateDocumentList = (result) => {
        const index = this.documents.findIndex((d) => d.id == result.id);
        if (index == -1) {
            this.documents.unshift(result);
        } else {
            this.documents.splice(index, 1, result);
        }
    };
}
