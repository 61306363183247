import { Constants, ServiceBase } from "../graph/serviceBase";

export class AssessmentService extends ServiceBase {
    public async getAssessments(options) {
        return this.get(
            `${Constants.baseUrl}/assessments?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }`
        );
    }

    public async searchAssessments(options) {
        return this.post(`${Constants.baseUrl}/assessments/search`, options);
    }

    public async exportAssessments(options) {
        return this.post(`${Constants.baseUrl}/assessments/export`, options);
    }

    public async createAssessment(assessment) {
        return this.post(`${Constants.baseUrl}/assessments`, assessment);
    }

    public async startAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/start`, {});
    }

    public async submitAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/submit`, {});
    }

    public async reviewAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/review`, {});
    }

    public async closeAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/close`, {});
    }

    public async cancelAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/cancel`, {});
    }

    public getAssessment(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}`);
    }

    public getAssessmentTriageState(assetId) {
        return this.get(`${Constants.baseUrl}/assessments/triage-state/${assetId}`);
    }

    public getAssessmentManifest(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/manifest`);
    }

    public async assignAssessment(options) {
        return this.post(`${Constants.baseUrl}/assessments/${options.id}/assign`, options);
    }

    public getAssessmentWorkItem(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/work-item`);
    }

    public async getAssessmentValues(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/values`);
    }

    public async downloadAssessmentValues(id, moduleId = null) {
        return this.getBlob(`${Constants.baseUrl}/assessments/${id}/values.csv?moduleId=${moduleId || ""}`);
    }

    public async updateAssessmentValue(value) {
        return this.put(`${Constants.baseUrl}/assessments/${value.assessmentId}/values/${value.measureId}`, value);
    }

    public async getAssessmentModules(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/modules`);
    }

    public async getAssessmentAdvisories(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/advisories`);
    }

    public async getAssessmentRequirements(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/requirements`);
    }

    public async closeAssessmentAdvisories(id, advisories) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/advisories`, advisories);
    }

    public async getAssessmentComments(id, uniqueId) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/comments?uniqueId=${uniqueId}`);
    }

    public async createAssessmentComment(comment) {
        return this.post(`${Constants.baseUrl}/assessments/${comment.assessmentId}/comments`, comment);
    }

    public async getAssessmentCommentCount(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/comments/count`);
    }

    public async getAssessmentEvidences(id) {
        return this.get(`${Constants.baseUrl}/evidences?assessmentId=${id}`);
    }

    public async searchAdvisories(queryOptions: any = {}) {
        return this.post(`${Constants.baseUrl}/advisories/search`, queryOptions);
    }

    public async getAdvisoryEvidences(id) {
        return this.get(`${Constants.baseUrl}/evidences?advisoryId=${id}`);
    }

    public async getEvidence(id) {
        return this.get(`${Constants.baseUrl}/evidences/${id}`);
    }

    public async createEvidence(evidence) {
        return this.post(`${Constants.baseUrl}/evidences`, evidence);
    }

    public async updateEvidence(evidence) {
        return this.put(`${Constants.baseUrl}/evidences/${evidence.id}`, evidence);
    }

    public async removeEvidence(id) {
        return this.delete(`${Constants.baseUrl}/evidences/${id}`, {});
    }

    public async downloadEvidence(id) {
        return this.getBlob(`${Constants.baseUrl}/evidences/${id}/content`);
    }

    public async getAssessmentRisks(id, template) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/risks?template=${template || ""}`);
    }

    public async getAdvisoryRiskMapping(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/advisories/risks`);
    }

    public async getAdvisoryEvidenceMapping(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/advisories/evidences`);
    }

    public async getAssessmentActivities(
        assessmentId: string,
        continuationToken: string = null,
        maxResults: number = 25
    ) {
        const requestContinuationToken = continuationToken ? `&continuationToken=${continuationToken}` : "";

        /// not sure about this!! changed from work-items to assessments in url path
        return this.get(
            `${Constants.baseUrl}/assessments/${assessmentId}/activities?maxResults=${maxResults}&${requestContinuationToken}`
        );
    }

    public async getAssessmentsOpenedClosed(query) {
        return this.post(`${Constants.baseUrl}/assessments/open-close`, query);
    }

    public async getAssessmentsGroupByCount(query) {
        return this.post(`${Constants.baseUrl}/assessments/group-by-count`, query);
    }

    public async getAdvisoriesGroupByCount(query) {
        return this.post(`${Constants.baseUrl}/advisories/group-by-count`, query);
    }

    public async getAdvisoriesFlatByControlFinalCompliance(query) {
        return this.post(`${Constants.baseUrl}/advisories/flat/control-final-compliance`, query);
    }

    public async getAdvisoriesFlatByPortfolio(query) {
        return this.post(`${Constants.baseUrl}/advisories/flat/portfolios`, query);
    }

    public async getAdvisoriesControlMap(query) {
        return this.post(`${Constants.baseUrl}/advisories/control-map`, query);
    }

    /* assessment documents */

    public async getAssessmentDocuments(assessmentId) {
        return this.get(`${Constants.baseUrl}/assessments/${assessmentId}/documents`);
    }

    public async getAssessmentDocument(assessmentId, id) {
        return this.get(`${Constants.baseUrl}/assessments/${assessmentId}/documents/${id}`);
    }

    public async downloadAssessmentDocument(assessmentId, id) {
        return this.getBlob(`${Constants.baseUrl}/assessments/${assessmentId}/documents/${id}/content`);
    }

    public async createAssessmentDocument(document) {
        return this.post(`${Constants.baseUrl}/assessments/${document.sourceId}/documents`, document);
    }

    public async updateAssessmentDocument(document) {
        return this.put(`${Constants.baseUrl}/assessments/${document.sourceId}/documents/${document.id}`, document);
    }

    public async uploadAssessmentDocument(document) {
        const formData = new FormData();
        formData.set("internalOnly", document.internalOnly);
        formData.set("mode", document.mode);
        if (document.notes) {
            formData.set("notes", document.notes);
        }

        formData.set("entity", document.file);
        if (document.name) {
            formData.set("name", document.name);
        }

        if (!document.id) {
            return this.postForm(`${Constants.baseUrl}/assessments/${document.sourceId}/documents/upload`, formData);
        }

        formData.append("id", document.id);
        return this.putForm(
            `${Constants.baseUrl}/assessments/${document.sourceId}/documents/${document.id}/upload`,
            formData
        );
    }

    /* use cases */

    public async getUseCases(options) {
        return this.get(
            `${Constants.baseUrl}/use-cases?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}&query.templateId=${options.templateId ? options.templateId : ""}`
        );
    }

    public async searchUseCases(options) {
        return this.post(`${Constants.baseUrl}/use-cases/search`, options);
    }

    public async getUseCase(id) {
        return this.get(`${Constants.baseUrl}/use-cases/${id}`);
    }

    public async createUseCase(useCase) {
        return this.post(`${Constants.baseUrl}/use-cases`, useCase);
    }

    public async updateUseCase(useCase) {
        return this.put(`${Constants.baseUrl}/use-cases/${useCase.id}`, useCase);
    }

    public async deleteUseCase(id) {
        return this.delete(`${Constants.baseUrl}/use-cases/${id}`, {});
    }

    public async getControlModules(options) {
        return this.get(
            `${Constants.baseUrl}/control-modules?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}&query.templateId=${options.templateId ? options.templateId : ""}`
        );
    }

    public async searchControlModules(options) {
        return this.post(`${Constants.baseUrl}/control-modules/search`, options);
    }

    public async getControlModule(id) {
        return this.get(`${Constants.baseUrl}/control-modules/${id}`);
    }

    public async createControlModule(controlModule) {
        return this.post(`${Constants.baseUrl}/control-modules`, controlModule);
    }

    public async updateControlModule(controlModule) {
        return this.put(`${Constants.baseUrl}/control-modules/${controlModule.id}`, controlModule);
    }

    public async deleteControlModule(id) {
        return this.delete(`${Constants.baseUrl}/control-modules/${id}`, {});
    }

    public async getControlQuestions(options) {
        return this.get(
            `${Constants.baseUrl}/control-questions?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}&query.templateId=${options.templateId ? options.templateId : ""}`
        );
    }

    public async searchControlQuestions(options) {
        return this.post(`${Constants.baseUrl}/control-questions/search`, options);
    }

    public async getControlQuestion(id) {
        return this.get(`${Constants.baseUrl}/control-questions/${id}`);
    }

    public async createControlQuestion(controlQuestion) {
        return this.post(`${Constants.baseUrl}/control-questions`, controlQuestion);
    }

    public async updateControlQuestion(controlQuestion) {
        return this.put(`${Constants.baseUrl}/control-questions/${controlQuestion.id}`, controlQuestion);
    }

    public async deleteControlQuestion(id) {
        return this.delete(`${Constants.baseUrl}/control-questions/${id}`, {});
    }

    public async createFromTriage(options) {
        return this.post(`${Constants.baseUrl}/assessments/triage`, options);
    }

    /* assessment rules */

    public async getAssessmentRules(options) {
        return this.get(
            `${Constants.baseUrl}/assessment-rules?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}&query.templateId=${options.templateId ? options.templateId : ""}`
        );
    }

    public async searchAssessmentRules(options) {
        return this.post(`${Constants.baseUrl}/assessment-rules/search`, options);
    }

    public async getAssessmentRule(id) {
        return this.get(`${Constants.baseUrl}/assessment-rules/${id}`);
    }

    public async createAssessmentRule(rule) {
        return this.post(`${Constants.baseUrl}/assessment-rules`, rule);
    }

    public async updateAssessmentRule(rule) {
        return this.put(`${Constants.baseUrl}/assessment-rules/${rule.id}`, rule);
    }

    public async deleteAssessmentRule(id) {
        return this.delete(`${Constants.baseUrl}/assessment-rules/${id}`, {});
    }
}
