import { observable, action, computed, flow } from "mobx";

import { StateFlowEditorStore } from "./StateFlowEditorStore";
import { TargetMetadata } from "./TargetMetadata";

export class StateFlowTriggerEditStore {
    public parentStore: StateFlowEditorStore;

    @observable public formData: any = null;
    @observable public formOptions: any = null;
    @observable public snapshot: any = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;
    @observable public processing: boolean = false;

    public options: any;

    constructor(parentStore: StateFlowEditorStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get context() {
        let context = { type: null };
        switch (this.parentStore.manifest.target) {
            case "WorkItem":
                context.type = "work-item";
                break;
            case "Assessment":
                context.type = "assessment";
                break;
            case "RiskLifecycle":
                context.type = "risk";
                break;
        }

        return context;
    }

    @computed
    public get targetMetadata() {
        return TargetMetadata[this.parentStore.manifest.target];
    }

    @computed
    public get selectedCommand() {
        if (this.formData && this.formData.command && this.formData.command.type) {
            return this.targetMetadata.commands.find((c) => c.id === this.formData.command.type);
        }
        return null;
    }

    @computed
    public get filteredCommands() {
        if (!this.formOptions) {
            return [];
        }

        const sourceMappedTo = this.formOptions.state.mappedTo;

        if (this.formOptions.target && this.formOptions.target.id) {
            const target = this.parentStore.manifest.states.find((s) => s.id === this.formOptions.target.id);
            return this.targetMetadata.commands.filter(
                (c) => !!c.restrictions.find((r) => r.source === sourceMappedTo && r.target === target.mappedTo)
            );
        }

        return this.targetMetadata.commands.filter((c) => !!c.restrictions.find((r) => r.source === sourceMappedTo));
    }

    @computed
    public get filteredConditions() {
        if (!this.selectedCommand) {
            return [];
        }
        return this.targetMetadata.conditions.filter((c) => this.selectedCommand.conditions.indexOf(c.id) !== -1);
    }

    @computed
    public get filteredStates() {
        if (!this.formOptions || !this.formData) {
            return [];
        }

        if (this.formOptions.target && this.formOptions.target.id) {
            return this.parentStore.manifest.states.filter((s) => s.id === this.formOptions.target.id);
        }

        if (!this.selectedCommand) {
            return [];
        }

        let destinationMappedTos = [];
        this.selectedCommand.restrictions
            .filter((r) => r.source === this.formOptions.state.mappedTo)
            .forEach((restriction) => {
                if (destinationMappedTos.indexOf(restriction.target) === -1) {
                    destinationMappedTos.push(restriction.target);
                }
            });

        return this.parentStore.manifest.states.filter((s) => destinationMappedTos.indexOf(s.mappedTo) !== -1);
    }

    @action
    public setCommand(id) {
        this.formData.command = this.formData.command || {};
        this.formData.command.type = id;

        if (
            !this.formData.command.condition ||
            this.selectedCommand.conditions.indexOf(this.formData.command.condition) === -1
        ) {
            this.formData.command.condition = this.selectedCommand.conditions[0];
        }
        if (
            !this.formData.command.to ||
            this.filteredStates.findIndex((s) => s.id === this.formData.command.to) === -1
        ) {
            if (this.filteredStates.length > 0) {
                if (this.filteredStates.findIndex((s) => s.id === this.formOptions.state.id) !== -1) {
                    this.formData.command.to = null;
                } else {
                    this.formData.command.to = this.filteredStates[0].id;
                    if (this.formData.command.to === this.formOptions.state.id) {
                        this.formData.command.to = null;
                    }
                }
            } else {
                this.formData.command.to = null;
            }
        }
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.formData);
    }

    @computed
    public get isValid() {
        return (
            this.formData &&
            this.formData.id &&
            this.formData.name &&
            this.formData.variant &&
            this.formData.location &&
            (!this.formData.confirmProps || this.formData.confirmProps.title) &&
            (!this.formData.iconProps || this.formData.iconProps.iconName) &&
            this.formData.command &&
            this.formData.command.id &&
            this.formData.command.type &&
            this.formData.command.condition
        );
    }

    public show = flow(function* (options: any) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });
        this.title = options.title;
        this.snapshot = options.value;
        this.formData = JSON.parse(JSON.stringify(options.value));
        this.formData.command = this.formData.command || {};
        if (options.target && options.target.id) {
            this.formData.command.to = options.target.id;
        }

        const targetMetadata = TargetMetadata[this.parentStore.manifest.target];
        this.formOptions = {
            state: options.state,
            target: options.target,
            targetMetadata: targetMetadata,
        };
        this.visible = true;
        this.processing = false;

        return promise;
    });

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.formData = null;
        this.formOptions = null;
        this.visible = false;
        this.processing = false;
    }

    @action
    public onChange(state) {
        this.formData = state;
    }

    @action
    public resolve(success) {
        this.processing = success;
        this.options.resolve({ success, value: this.formData });
    }

    @action
    public reject(error) {
        this.options.reject({ success: false, error });
    }
}
