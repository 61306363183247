import { observable, flow, action, computed } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";
import { PaginationStore } from "../../base/PaginationStore";

export class RiskRegistryBrowseStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public registries: any[] = [];
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public keywords: string;
    @observable public pagination: PaginationStore;


    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
        this.keywords = '';
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: 'Settings', key: 'settings', onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: 'Risk Registries', key: 'risk-registries', onClick: () => this.parentStore.rootStore.routing.push(`/settings/risk-registries`) });

        return builder;
    }

    public loadRegistries = flow(function* (query: any) {
        const options = { ...{ cached: false }, ...query };
        if (options.cached && this.registries.length > 0) {
            return;
        }

        this.loading = true;
        this.registries = [];

        try {
            this.keywords = query ? query.keywords : '';
            this.registries = yield this.riskService.getRegistries(query);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

}
