import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';

import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ObservableSeparator } from '@baseComponents/ObservableSeparator';
import { DataSourceFiltersForm } from './components/DataSourceFiltersForm';
import { TableField } from './components/TableField';

export const DataSourcePartEditor = inject('dataSourceStore')(observer(
  class DataSourcePartEditor extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            count: 0,
            coreDataSource: null,
            showAllRecords: false,
        }
    }

    get _columns() {
        const { coreDataSource, data } = this.state;
        if (data && coreDataSource) {
            if (data.length > 0)
                return Object.keys(data[0])
                    .filter(DS => coreDataSource.fields.find(F => F.id === DS))
                    .map(DS => {
                        const field = coreDataSource.fields.find(F => F.id === DS);
                        return {
                            key: field.id,
                            name: field.title,
                            fieldName: field.id,
                            maxWidth: 150,
                            data: field.type,
                            isPadded: true,
                            onRender: (item) => {
                                return <TableField field={field} value={item[field.id]} item={item} />;
                            },
                        }
                    });
            else return []
        } else {
            return [];
        }
    }

    componentWillMount() {
        this.loadData()
    }

    componentWillReceiveProps() {
        this.loadData()
    }

    onChangeOrderType = (event, item) => {
        const { formData } = this.props;
        if (item) {
            formData.properties.orderType = item ? item.key : null;
            this.loadData();
        }        
    };

    onChangeOrderBy = (event, item) => {
        const { formData } = this.props;
        if (item) {
            formData.properties.orderBy = item ? item.key : null;
            this.loadData();
        }        
    };

    async loadData() {
        const { dataSourceStore, formData} = this.props;
        const { selectionStore } = dataSourceStore;
        // debugger
        if (formData.properties.mainSource) {
            const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === formData.properties.mainSource);
            const coreDataSource = dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId);
            const groupBy = formData.properties.groupBy ? formData.properties.groupBy : (selectedDataSource.groupBy ? selectedDataSource.groupBy : null);
            const query = Object.assign({}, selectedDataSource.query);
            //get all the props from filters and flatten the struct to a query
            if (formData.properties.filters){
                formData.properties.filters.forEach((F,i) => {
                    query[F.id] =F.value;
                })
            }
            const sentQuery = {};
            //checking that all the fields of the query are in the filter array
            //remove from the query old fields now null 
            //console.log('query', query, Object.keys(query))
            const finalQuery = Object.keys(query)
                .map(F => {
                    const filter = (formData && formData.properties && formData.properties.filters) ? formData.properties.filters.find(FF => FF.id ===F) : null;
                    if (filter && filter.value) {
                        return filter
                    }
                    return null;
                })
                .filter(F => F);
                finalQuery.forEach(F => sentQuery[F.id]=F.value);
                let sortBy = null;
                if (formData.properties.orderBy) {
                  sortBy = {
                    property: formData.properties.orderBy,
                    asc: formData.properties.orderType === "ascending" ? true : false,
                    numberOfResults: formData.properties.numberOfResults,
                  };
                  sentQuery.sortBy = sortBy;
                };
            try {
                const results = await coreDataSource.searchService(sentQuery, groupBy);
                this.setState({
                    coreDataSource,
                    data: results.items ? results.items : [],
                    count: results.totalItems ? results.totalItems : 0,
                })    
            } catch (e) {
                console.error(e);
            }
        }
    }

    get groupBy() {
        const { formData, dataSourceStore} = this.props;
        const { selectionStore } = dataSourceStore;
        if (formData.properties.mainSource) {
            const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === formData.properties.mainSource);
            const coreDataSource = dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId);
            return coreDataSource.groupByFields.map(DS => {                                        
                return {
                    text: DS.text,
                    key: DS.key,
                }
            })
        }
        return []
    }

    get defaultGroupByKey() {
        const { formData, dataSourceStore} = this.props;
        const { selectionStore } = dataSourceStore;
        if (formData.properties.mainSource) {
            const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === formData.properties.mainSource);
            const coreDataSource = dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId);
            const defaultKey = coreDataSource.groupByFields.find(DS => DS.isDefault);
            if (defaultKey) return defaultKey.key
            else return null
        }
        return null
    }

    render () {
        const { formData, dataSourceStore} = this.props;
        const { selectionStore } = dataSourceStore;
        const { coreDataSource, data } = this.state;
        // console.log('->', this.defaultGroupByKey);
        return (
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <ObservableSeparator>Data Source</ObservableSeparator>
                    </r-cell>
                    <r-cell span="2">
                        <div>
                            <Dropdown
                                    required
                                    selectedKey={formData.properties.mainSource}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            formData.properties.mainSource = op.key;
                                            if (formData.title === 'Data Source') formData.title = `Data: ${op.text}`;
                                            this.loadData();
                                        }
                                    }}
                                    options={selectionStore.dataSources.map(DS => {                                        
                                        return {
                                            text: DS.name,
                                            key: DS.id,
                                        }
                                    })}
                                />
                        </div>
                    </r-cell>
                    <r-cell span="2">
                        <ObservableSeparator>Data Source Configuration</ObservableSeparator>
                    </r-cell>
                    {formData.properties.mainSource && this.groupBy.length > 0 && <r-cell span="2">
                        <div>
                            <Dropdown
                                    required
                                    label="Group by"
                                    selectedKey={formData.properties.groupBy || this.defaultGroupByKey}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            formData.properties.groupBy = op.key;
                                            this.loadData();
                                        }
                                    }}
                                    options={this.groupBy}
                                />
                            </div>
                    </r-cell> }
                    {formData.properties.mainSource && <DataSourceFiltersForm 
                        formData={formData} 
                        dataSourceStore={dataSourceStore}
                        onFilterChange={() => this.loadData()}
                    /> }
                    {coreDataSource && coreDataSource.showSortingOptions && <r-cell span="2">
                        <ObservableSeparator>Data Source Sorting</ObservableSeparator>
                    </r-cell> }
                    {coreDataSource && coreDataSource.showSortingOptions &&<r-cell span="1">
                        <div>
                            <Dropdown
                                required
                                label="Order by"
                                selectedKey={formData.properties.orderBy}
                                onChange={this.onChangeOrderBy}
                                options={
                                    coreDataSource ? coreDataSource.fields.map(F =>{
                                    return {
                                        text: F.title,
                                        key: F.id,
                                    }
                                }) : []
                                }
                            />
                        </div>
                    </r-cell>}
                    {coreDataSource && coreDataSource.showSortingOptions && <r-cell span="1">
                        <div>
                            <Dropdown
                                required
                                label="Order type"
                                selectedKey={formData.properties.orderType}
                                onChange={this.onChangeOrderType}
                                options={[{
                                        text: "Ascending",
                                        key: 'ascending',
                                    },{
                                        text: "Descending",
                                        key: 'descending',
                                    }]
                                }
                            />
                        </div>
                    </r-cell> }
                    {coreDataSource && coreDataSource.showSortingOptions && <r-cell span="2">
                        <div>
                            <TextField 
                                label="Displayed results"
                                value={formData.properties.numberOfResults}
                                onChange={(ev, val) => {
                                    formData.properties.numberOfResults = val.length > 0 ? isNaN(parseInt(val)) ? "" : parseInt(val)  : "";
                                    this.loadData();
                            }} />
                        </div>
                        </r-cell> }
                    {formData.properties.mainSource && <r-cell span="2">
                        <ObservableSeparator>Data Source Sample </ObservableSeparator>
                    </r-cell>}
                    {formData.properties.mainSource && <r-cell span="2">
                        <div>
                            Total results: {this.state.count} (showing a sample of up to 5 records <a href="#" onClick={(e)=>{e.preventDefault(); this.setState({showAllRecords: !this.state.showAllRecords})}}>[{this.state.showAllRecords ? `Show Sample Records` : `Show All`}]</a>)
                        </div>
                    </r-cell> }
                    {formData.properties.mainSource && <r-cell span="2">
                        <div>
                            <ShimmeredDetailsList
                                items={this.state.showAllRecords ? this.state.data : this.state.data ? this.state.data.slice(0, 5) : []}
                                compact={false}
                                columns={this._columns}
                                selectionMode={SelectionMode.none}
                                getKey={(item, index) => item ? item.id : index}
                                setKey="single"
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.horizontalConstrained}
                                isHeaderVisible={true}
                                enterModalSelectionOnTouch={true}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="Row checkbox"
                                ariaLabelForShimmer="Data being fetched"
                                ariaLabelForGrid="Sample data"
                            />
                        </div>
                    </r-cell> }                    
                </r-grid>
            </div>
      );
    }
  }
));
