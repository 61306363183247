import { computed } from "mobx";

import { PropertyService } from "@api/properties";
import { PropertyStore } from "./PropertyStore";
import { PrincipalContextStore } from "@modules/identity/stores/PrincipalContextStore";
import { RoleNames, isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";

export class PropertyPermissionStore {
    private propertyService: PropertyService;
    public parentStore: PropertyStore;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAdmin() {
        const user = this.principalContext.current;
        if (!user) {
            return false;
        }
        return isUserInAllRoles(user, [RoleNames.Admin]);
    }
}
