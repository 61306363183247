import moment from 'moment';
import { computeTimelineFilter } from './computeTimelineFilter';

/**
 * 
 * @param query 
 * @param groupBy 
 * @param api 
 * 
    {
        "id": "24a6e7c9-039e-4dda-93c9-e9407b0b7b38",
        "label": "App Sec Support",
        "count": 0
    },
 */
async function getAssessments(query, api) {
    // console.log(JSON.stringify(query))
    // console.log('query.created', query.created);
    let timeLineFilterObject = null;
    let createdFilterObject = null;
    let startedFilterObject = null;
    let dueDateFilterObject = null;
    let closedFilterObject = null;
    if (query.timeLine) {
        timeLineFilterObject = computeTimelineFilter(query.timeLine[0]?query.timeLine[0]:query.timeLine);
    }
    if (query.created) {
        createdFilterObject = computeTimelineFilter(query.created[0]?query.created[0]:query.created);
    }
    if (query.started) {
        startedFilterObject = computeTimelineFilter(query.started[0]?query.started[0]:query.started);
    }
    if (query.dueDate) {
        dueDateFilterObject = computeTimelineFilter(query.dueDate[0]?query.dueDate[0]:query.dueDate);
    }
    if (query.closed) {
        closedFilterObject = computeTimelineFilter(query.closed[0]?query.closed[0]:query.closed);
    }
    // portfolio
    let portfolioFilter = null;
    if (query.portfolios) {
        portfolioFilter = query.portfolios.map(P => { return {
            id: P
        }})
    }
    // assessmentState
    let assessmentStateFilter = null;
    if (query.assessmentState) {
        assessmentStateFilter = query.assessmentState.map(s => s);
    }
    // user
    let userFilter = null;
    if (query.assignedToUser) {
        const selectedId = query.assignedToUser[0] ? query.assignedToUser[0].id : query.assignedToUser.id;
        if (selectedId) userFilter = [{id: selectedId}];
    }
    // sort
    let sortFilter = null;
    if (query.sort) {
        sortFilter = [{
            property: query.sort.property,
            asc: query.sort.asc,
        }]
    }
    const sentQuery = {
        pageSize: query.sort && query.sort.numberOfResults ?  query.sort.numberOfResults : 250,
        startIndex: 0,
        query: {
            "targetType": null,
            "status": assessmentStateFilter,
            "targetId": null,
            "portfolio": portfolioFilter,
            "assignedTo": null,
            "timeline": timeLineFilterObject,
            "created": createdFilterObject,
            "started": startedFilterObject,
            "dueDate": dueDateFilterObject,
            "closed": closedFilterObject,
            "assignedToUser": userFilter,
            "keywords": null,
            "templateId": null,
            "sort": null,
        },
    };
    // console.log('sentQuery', JSON.stringify(sentQuery))
    const results = await api(sentQuery);
    return results;
}

export { getAssessments }