import { observable, flow } from "mobx";

import { WorkItemService } from "@api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemLinkStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public links: any[] = [];
    @observable public linkTypes: any[] = [];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    public loadWorkItemLinks = flow(function* (options) {
        this.loading = true;
        this.links = [];

        try {
            const workItemId = options.workItem.id;
            const links = yield this.workItemService.getWorkItemLinks(workItemId, true);
            const workItemIds = links.map((link) => {
                if (link.sourceId === workItemId) {
                    link.incoming = false;
                    return link.target.id;
                }
                link.incoming = true;
                return link.sourceId;
            });
            const workItems = yield this.workItemService.searchWorkItems({ query: { id: workItemIds } });
            links.forEach((link) => {
                if (!link.incoming) {
                    link.workItem = workItems.items.find((w) => w.id === link.target.id) || link.target;
                } else {
                    link.workItem = workItems.items.find((w) => w.id === link.sourceId) || link.target;
                }
            });
            this.links = links;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
