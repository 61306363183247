import { observable, action, computed, flow } from "mobx";
import moment from "moment";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { WorkItemService } from "@api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemNewFormStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const productId = options.workItem.product.id;
        const defaults = yield this.workItemService.getWorkItemDefaults(productId);
        const workItem = { ...defaults, ...options.workItem, documents: [] };

        const productService = this.parentStore.rootStore.productStore.productService;
        const schema = yield productService.getProductSchema(productId, null);

        this.formOptions = {
            ...options,
            pageType: options.pageType || `New ${options.workItem.product.name}`,
            pageDescription: options.workItem.product.summary,
            schema,
        };

        if (schema && schema.overrides && schema.overrides.length > 0) {
            const titleField = schema.overrides.find((o) => o.name === "title");
            const user = this.parentStore.rootStore.principalContext.current;
            if (titleField && titleField.defaultValue) {
                const now = moment();
                workItem.title = titleField.defaultValue
                    .replace("{PRODUCT-NAME}", options.workItem.product.name)
                    .replace("{DATE-TIME}", now.format("lll"))
                    .replace("{DATE-ONLY}", now.format("ll"))
                    .replace("{ACTOR-NAME}", user.name);
            }
            const dueDateField = schema.overrides.find((o) => o.name === "dueDate");
            if (dueDateField && dueDateField.defaultValue) {
                const dueDate = moment().add(moment.duration(dueDateField.defaultValue));
                workItem.dueDate = dueDate.toDate();
            }
        }

        this.formData = workItem;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData &&
            this.formData.title &&
            (!this.formData.asset || this.formData.asset.id) &&
            this.formData.stakeholder &&
            this.formData.stakeholder.id &&
            this.formData.product &&
            this.formData.product.id &&
            this.formData.priority &&
            this.formData.dueDate &&
            this.formData.source &&
            this.formData.requestedBy &&
            this.formData.requestedBy.id &&
            this.formData.documents &&
            this.formData.documents.length >= 0
        );
    }
}
