import { observable, action, computed, flow } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { AssetService } from "@api/assets";
import { AssetStore } from "./AssetStore";

export class AssetClassEditFormStore {
    private assetService: AssetService;
    public parentStore: AssetStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const category = yield this.assetService.getAssetClass(options.class.id);
        this.formData = { ...category };

        this.formOptions = {
            ...options,
            pageType: options.pageType || `Edit Asset Category`,
            pageDescription: category.name,
        };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData &&
            this.formData.id &&
            this.formData.name &&
            this.formData.group &&
            this.formData.colour &&
            typeof this.formData.isEnabled === "boolean" &&
            this.formData.order >= 0
        );
    }
}
