import { observable, flow } from "mobx";

import { WorkflowService } from "../../../api/workflows";
import { PaginationStore } from "../../base/PaginationStore";
import { TaskStore } from "./TaskStore";

export class TaskBrowseStore {
    public parentStore: TaskStore;
    public workflowService: WorkflowService;

    @observable public tasks: any[] = [];
    @observable public taskDefinitions: any[] = [];
    @observable public taskSummary: any = { overdueCount: 0, pendingCount: 0, personalCount: 0, totalCount: 0 };
    @observable public loading: boolean = false;
    @observable public query: any = {};
    @observable public options: any = {};
    @observable public pagination: PaginationStore;
    @observable public error: any = null;
    @observable public lastQueryId: string = null;
    @observable public lastQueryIdMyTask: string = null;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
        this.pagination = new PaginationStore();
    }

    public loadMyTasks = flow(function* (options: any) {
        this.loading = true;
        const o = Object.assign({ page: 1, pageSize: 25, query: { keywords: null } }, options);
        this.tasks = [];
        this.query = o.query;
        this.error = null;
        //this.lastQueryIdMyTask = new Date().getTime().toString();
        // console.log("Set queryId", this.lastQueryIdMyTask);
        try {
            //o.query.queryId = this.lastQueryIdMyTask;
            const result = yield this.workflowService.searchMyTaskDefinitions({
                pageSize: o.pageSize,
                startIndex: ((o.page || 1) - 1) * o.pageSize,
                query: o.query,
            });
            // console.log('Check if the query is the last query', result.query.queryId, this.lastQueryIdMyTask)
            //if (result.query.queryId === this.lastQueryIdMyTask) {
            // console.log('Match ', result.query.queryId, this.lastQueryId)
            this.tasks = result.items;
            const { templateId, ...rest } = result.query;
            this.query = rest;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            //}
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public searchTasks = flow(function* (options: any) {
        this.loading = true;
        this.options = options;
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);
        this.pagination.itemsPerPage = 100;
        this.taskDefinitions = [];
        this.query = o.query;
        this.error = null;
        //this.lastQueryId = new Date().getTime().toString();
        // console.log("Set queryId", this.lastQueryId);
        try {
            //o.query.queryId = this.lastQueryId;
            const result = yield this.workflowService.searchTaskDefinitions({
                pageSize: this.pagination.itemsPerPage,
                startIndex: ((o.page || 1) - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            // console.log('Check if the query is the last query', result.query.queryId, this.lastQueryId)
            //if (result.query.queryId === this.lastQueryId) {
            // console.log("Query id match, update everything");
            this.taskDefinitions = result.items.sort(
                (A, B) =>
                    B.status.localeCompare(A.status) || new Date(A.dueDate).getTime() - new Date(B.dueDate).getTime()
            );
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            //}
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public getTaskSummary = flow(function* (entityId: string) {
        //this.taskSummary = { overdueCount: 0, pendingCount: 0, personalCount: 0, totalCount: 0 };
        try {
            const result = yield this.workflowService.getWorkflowEntitySummary(entityId);
            this.taskSummary = result.tasks;
        } catch (e) {
            this.error = e;
            console.log(e);
        }
    });
}
