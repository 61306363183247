import { observable } from "mobx";

import { PageService } from '../../../api/pages';

import { RootStore } from '../../base/RootStore';
import { PaginationStore } from '../../base/PaginationStore';

import { PageLibraryStore } from "./PageLibraryStore";
import { PageInstanceStore } from './PageInstanceStore';
import { PageEditFormStore } from './PageEditFormStore';
import { PageNewFormStore } from './PageNewFormStore';
import { PageDuplicateFormStore } from './PageDuplicateFormStore';
import { PageViewerStore } from './PageViewerStore';
import { PageLayoutStore } from './PageLayoutStore';
import { PageRegistryStore } from './PageRegistryStore';
import { PageBrowseStore } from './PageBrowseStore';

export class PageStore {
    public pageService: PageService;
    public rootStore: RootStore;
    
    public libraryStore: PageLibraryStore;
    public editFormStore: PageEditFormStore;
    public duplicateFormStore: PageDuplicateFormStore;
    public newFormStore: PageNewFormStore;
    public layoutStore: PageLayoutStore;
    public registryStore: PageRegistryStore;
    public instanceStore: PageInstanceStore;
    public browseStore: PageBrowseStore;
    public viewerStore: PageViewerStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.pageService = new PageService(rootStore.authProvider);

        this.libraryStore = new PageLibraryStore(this);
        this.editFormStore = new PageEditFormStore(this);
        this.duplicateFormStore = new PageDuplicateFormStore(this);
        this.newFormStore = new PageNewFormStore(this);
        this.registryStore = new PageRegistryStore(this);
        this.layoutStore = new PageLayoutStore(this);
        this.instanceStore = new PageInstanceStore(this);
        this.browseStore = new PageBrowseStore(this);
        this.viewerStore = new PageViewerStore(this.instanceStore);
    }
}
