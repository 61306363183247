import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import { useStores } from "@hooks/useStores";
import { Constants } from "@api/graph/serviceBase";

import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

export const PhotoPersona = observer((props) => {
    const { authProvider } = useStores();
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        if (props.principalId) {
            let isCurrent = true;
            authProvider.getAccessToken().then((token) => {
                if (isCurrent) {
                    setPhoto(
                        `${Constants.baseUrl}/principals/${props.principalId}/photo/$value?token=${token.accessToken}`
                    );
                }
            });
            return () => (isCurrent = false);
        }
    }, [authProvider, props.principalId]);

    return <Persona {...props} imageUrl={photo} size={props.size || PersonaSize.size32} />;
});
