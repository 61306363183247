import { RootStore } from "../../base/RootStore";
import { EntityService } from "../../../api/entities";

import { EntitySettingsStore } from "./EntitySettingsStore";

import { EntitySelectionStore } from "./EntitySelectionStore";
import { EntityPermissionStore } from "./EntityPermissionStore";
import { EntityLifecycleStore } from "./EntityLifecycleStore";

import { EntityDashboardStore } from "./EntityDashboardStore";
import { EntityBrowseStore } from "./EntityBrowseStore";
import { EntityWatchlistStore } from "./EntityWatchlistStore";
import { EntityViewerStore } from "./EntityViewerStore";
import { EntityDocumentStore } from "./EntityDocumentStore";
import { EntityNewStore } from "./EntityNewStore";
import { EntityEditStore } from "./EntityEditStore";

import { EntityAssessmentStore } from "./EntityAssessmentStore";
import { EntityWorkItemStore } from "./EntityWorkItemStore";

export class EntityStore {
    public rootStore: RootStore;
    public entityService: EntityService;

    public settingsStore: EntitySettingsStore;

    public selectionStore: EntitySelectionStore;
    public permissionStore: EntityPermissionStore;
    public lifecycleStore: EntityLifecycleStore;

    public dashboardStore: EntityDashboardStore;
    public browseStore: EntityBrowseStore;
    public watchlistStore: EntityWatchlistStore;
    public viewerStore: EntityViewerStore;
    public documentStore: EntityDocumentStore;
    public newStore: EntityNewStore;
    public editStore: EntityEditStore;

    public assessmentStore: EntityAssessmentStore;
    public workItemStore: EntityWorkItemStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.entityService = new EntityService(rootStore.authProvider);

        this.settingsStore = new EntitySettingsStore(this);

        this.selectionStore = new EntitySelectionStore(this);
        this.permissionStore = new EntityPermissionStore(this);
        this.lifecycleStore = new EntityLifecycleStore(this);

        this.dashboardStore = new EntityDashboardStore(this);
        this.browseStore = new EntityBrowseStore(this);
        this.watchlistStore = new EntityWatchlistStore(this);
        this.viewerStore = new EntityViewerStore(this);
        this.documentStore = new EntityDocumentStore(this);
        this.newStore = new EntityNewStore(this);
        this.editStore = new EntityEditStore(this);

        this.assessmentStore = new EntityAssessmentStore(this);
        this.workItemStore = new EntityWorkItemStore(this);
    }
}
