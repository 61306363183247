import { observable, action } from "mobx";

import { ModellingService } from "../../../api/modelling";
import { PatternEditorStore } from "./PatternEditorStore";

export class PatternAdvisoryListStore {
    public parentStore: PatternEditorStore;
    public modellingService: ModellingService;

    @observable public measure: any = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;
    public options: any;

    constructor(parentStore: PatternEditorStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        this.title = options.title || options.measure.title;
        this.measure = options.measure;
        this.visible = true;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.measure = null;
        this.visible = false;
    }

    @action
    public onCancel(options) {
        this.options.resolve({ success: true });
        this.hide(options);
    }
}
