import { observable, action } from "mobx";
import { RootStore } from "../base/RootStore";
import { IPageContext, IPage } from "./components/IPageContext";

export class PageStore implements IPageContext {
    public rootStore: RootStore;
    @observable public current: IPage;
    @observable public queryStringParams: any = {};
    @observable public queryStringParamChanged: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        this.current = new Page({
            id: "not-set",
            title: "Not Set",
            hasPageNav: false,
            //showPageNav: true,
            hasSidebar: false,
            showSidebar: true,
        });
    }

    @action
    public setCurrent(page: IPage) {
        if (typeof page.showSidebar === "undefined") {
            page.showSidebar = this.current.showSidebar;
        }

        this.current = new Page(page);
    }

    // @action
    // public togglePageNav() {
    //     if (this.current) {
    //         this.current.togglePageNav();
    //     }
    // }

    @action
    public toggleSidebar() {
        if (this.current) {
            this.current.toggleSidebar();
        }
    }

    @action public updateQueryStringParams = (query: any) => {
        const params = query
            ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
                  let [key, value] = param.split("=");
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
                  return params;
              }, {})
            : {};
        //check if the new params are different from the stored one
        const keys = Object.getOwnPropertyNames(params);
        this.queryStringParamChanged = false;
        keys.forEach((key) => {
            if (params[key] !== this.queryStringParams[key]) {
                this.queryStringParamChanged = true;
            }
        });
        // console.log(params)
        this.queryStringParams = params;
    };
}

export class Page implements IPage {
    readonly id: string;
    @observable title: string;
    @observable subTitle: string;
    @observable hasPageNav: boolean = false;
    //@observable showPageNav: boolean = true;
    @observable hasSidebar: boolean = false;
    @observable showSidebar: boolean = true;
    @observable breadcrumb: any[];
    @observable loading: boolean = false;
    @observable className: string;
    @observable error: any;
    @observable _onTitleClick?: () => void;

    constructor(other: Partial<IPage>) {
        this.id = other.id;
        this.title = other.title;
        this.subTitle = other.subTitle;
        this.hasPageNav = !!other.hasPageNav;
        //this.showPageNav = !!other.showPageNav;
        this.hasSidebar = !!other.hasSidebar;
        this.showSidebar = !!other.showSidebar;
        this.breadcrumb = other.breadcrumb;
        this.loading = !!other.loading;
        this.className = other.className;
        this.error = other.error;
        this._onTitleClick = other.onTitleClick;
    }

    @action
    public onTitleClick() {
        if (this._onTitleClick) {
            this._onTitleClick();
        }
    }

    // @action
    // public togglePageNav() {
    //     this.showPageNav = !this.showPageNav;
    // }

    @action
    public toggleSidebar() {
        this.showSidebar = !this.showSidebar;
    }
}
