import { observable, action, computed, flow } from "mobx";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class AssessmentRuleNewFormStore {
    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public loading: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        this.formOptions = {
            ...options,
            pageType: options.pageType || "New Assessment Rule",
        };

        this.formData = {
            title: null,
            description: null,
            mitigations: null,
            level: "Medium",
            isEnabled: true,
            question: null,
            control: null,
            scenario: null,
            expression: null,
            version: 1,
            ...options.question,
        };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.resolve({
                success,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @action
    public reject(error) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.reject({
                error,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        const result =
            this.formData &&
            this.formData.title &&
            this.formData.description &&
            this.formData.mitigations &&
            this.formData.level &&
            this.formData.question &&
            this.formData.control &&
            this.formData.expression &&
            this.formData.expression.type &&
            this.formData.version;

        if (result) {
            switch (this.formData.expression.type) {
                case "Boolean":
                    return typeof this.formData.expression.compliantWhen === "boolean";
                case "Text":
                    return typeof this.formData.expression.compliantWhen === "string";
                case "Choice":
                    return (
                        typeof this.formData.expression.compliantWhenAll === "boolean" &&
                        this.formData.expression.compliantWhen &&
                        this.formData.expression.compliantWhen.length > 0
                    );
            }
        }

        return result;
    }
}
