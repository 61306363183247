import { observable, flow, computed } from "mobx";

import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";

export class AssetSettingsStore {
    private parentStore: AssetStore;
    private assetService: AssetService;

    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings`),
        });
        builder.push({
            text: "Asset Management",
            key: "assets",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings/assets`),
        });

        return builder;
    }

    @computed
    public get properties() {
        const propertyStore = this.parentStore.rootStore.propertyStore;
        return propertyStore.browseStore.properties;
    }

    @computed
    public get sections() {
        const propertyStore = this.parentStore.rootStore.propertyStore;
        return propertyStore.sectionBrowseStore.sections;
    }

    @computed
    public get propertiesQuery() {
        const propertyStore = this.parentStore.rootStore.propertyStore;
        return propertyStore.browseStore.query;
    }

    @computed
    public get sectionsQuery() {
        const propertyStore = this.parentStore.rootStore.propertyStore;
        return propertyStore.sectionBrowseStore.query;
    }

    public loadPropertySections = flow(function* (options: any) {
        const o = Object.assign({ query: null }, options);
        o.query = Object.assign({ keywords: null, target: ["Asset"] }, options.query);

        this.loading = true;
        this.error = null;

        try {
            const propertyStore = this.parentStore.rootStore.propertyStore;
            yield propertyStore.sectionBrowseStore.loadPropertySections(o);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public loadProperties = flow(function* (options: any) {
        const o = Object.assign({ query: null }, options);
        o.query = Object.assign({ keywords: null, target: ["Asset"] }, options.query);

        this.loading = true;
        this.error = null;

        try {
            const propertyStore = this.parentStore.rootStore.propertyStore;
            yield propertyStore.browseStore.loadProperties(o);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
