import { observable, action, computed, flow } from "mobx";
import { ProductStore } from "./ProductStore";
import { ProductService } from "../../../api/graph";

import { ProductStatus } from "./ProductContants";

export class ProductNewFormStore {
    private productService: ProductService;
    public parentStore: ProductStore;

    @observable public visible: boolean = false;
    @observable public error: any = null;
    @observable public showSuccessTicker = false;
    @observable public isCloseDialogOpen = false;
    @observable public formData: any;
    @observable public originalFormData: any;
    @observable public saving: boolean = false;

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
    }

    @computed
    public get isValid() {
        return (
            this.formData &&
            this.formData.name &&
            this.formData.availability &&
            this.formData.category &&
            this.formData.provider &&
            this.formData.state &&
            this.formData.assignedToGroup &&
            (!this.formData.isRequestable || !this.formData.isInternal || !!this.formData.flowId) &&
            (!this.formData.autoAssignmentMode ||
                this.formData.autoAssignmentMode !== "workItem.assignedToUser" ||
                this.formData.assignedToUser)
        );
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }

    @action
    public show() {
        this.visible = true;
        this.formData = {
            state: ProductStatus.Operational,
            isCustomerFacing: false,
            isInternal: false,
            isPromoted: true,
            isRequestable: true,
        };
        this.originalFormData = { ...this.formData };
    }

    @action
    public hide() {
        this.formData = null;
        this.originalFormData = null;
        this.visible = false;
    }

    @action public resetFormData() {
        this.formData = { ...this.originalFormData };
    }

    public createProduct = flow(function* () {
        this.saving = true;
        this.error = null;
        try {
            if (this.isValid) {
                const result = yield this.productService.createProduct(this.formData);
                this.parentStore.selectionStore.setSelected(result);
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Product ${result.name} created successfully`
                );
                return result;
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
