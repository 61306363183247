import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { useId } from "@fluentui/react-hooks";

import { Label } from "@ui/elements/Label";
import { IconButton } from "@ui/elements/Button";
import { Stack } from "@ui/elements/Stack";
import { Spinner, SpinnerSize } from "@ui/elements/Spinner";

import { NormalPeoplePicker, ValidationState } from "office-ui-fabric-react/lib/Pickers";

const propertyMap = {
    "work-item": [
        {
            key: "contextData.requestedBy",
            text: "Requested by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.requestedBy"
            }
        },
        {
            key: "contextData.assignedToUser",
            text: "Assigned to user",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.assignedToUser"
            }
        },
        {
            key: "contextData.businessContact",
            text: "Business owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.businessContact"
            }
        },
        {
            key: "contextData.technicalContact",
            text: "Technical owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.technicalContact"
            }
        },
        {
            key: "contextData.startedBy",
            text: "Started by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.startedBy"
            }
        },
        {
            key: "contextData.resolvedBy",
            text: "Resolved by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.resolvedBy"
            }
        },
        {
            key: "contextData.closedBy",
            text: "Closed by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.closedBy"
            }
        }
    ],
    assessment: [
        {
            key: "scopeData.workItem.createdBy",
            text: "Related demand created by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.workItem.createdBy"
            }
        },
        {
            key: "scopeData.workItem.requestedBy",
            text: "Requested by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.workItem.requestedBy"
            }
        },
        {
            key: "scopeData.workItem.assignedToUser",
            text: "Assigned to user",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.workItem.assignedToUser"
            }
        },
        {
            key: "scopeData.workItem.businessContact",
            text: "Business owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.workItem.businessContact"
            }
        },
        {
            key: "scopeData.workItem.technicalContact",
            text: "Technical owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.workItem.technicalContact"
            }
        },
        {
            key: "contextData.portfolioContact",
            text: "Portfolio business contact",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.portfolioContact"
            }
        },
        {
            key: "contextData.portfolioTechnical",
            text: "Portfolio technical contact",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.portfolioTechnical"
            }
        },
        {
            key: "contextData.submittedBy",
            text: "Submitted by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.submittedBy"
            }
        },
        {
            key: "contextData.startedBy",
            text: "Started by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.startedBy"
            }
        },
        {
            key: "contextData.reviewedBy",
            text: "Reviewed by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.reviewedBy"
            }
        },
        {
            key: "contextData.closedBy",
            text: "Closed by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.closedBy"
            }
        }
    ],
    risk: [
        {
            key: "contextData.createdBy",
            text: "Created by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.createdBy"
            }
        },
        {
            key: "contextData.requestedBy",
            text: "Requested by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.requestedBy"
            }
        },
        {
            key: "scopeData.lifecycle.assignedToUser",
            text: "Assigned to user",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.assignedToUser"
            }
        },
        {
            key: "scopeData.lifecycle.businessContact",
            text: "Business owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.businessContact"
            }
        },
        {
            key: "scopeData.lifecycle.technicalContact",
            text: "Technical owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.technicalContact"
            }
        },
        {
            key: "contextData.portfolioContact",
            text: "Portfolio business contact",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.portfolioContact"
            }
        },
        {
            key: "contextData.portfolioTechnical",
            text: "Portfolio technical contact",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.portfolioTechnical"
            }
        },
        {
            key: "scopeData.lifecycle.submittedBy",
            text: "Submitted by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.submittedBy"
            }
        },
        {
            key: "scopeData.lifecycle.reviewedBy",
            text: "Reviewed by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.reviewedBy"
            }
        },
        {
            key: "scopeData.lifecycle.closedBy",
            text: "Closed by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.closedBy"
            }
        },
        {
            key: "scopeData.treatment.assignedTo",
            text: "Treatment assigned to",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.treatment.assignedTo"
            }
        }
    ],
    exception: [
        {
            key: "contextData.createdBy",
            text: "Created by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.createdBy"
            }
        },
        {
            key: "contextData.requestedBy",
            text: "Requested by",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.requestedBy"
            }
        },
        {
            key: "scopeData.lifecycle.assignedToUser",
            text: "Assigned to user",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.assignedToUser"
            }
        },
        {
            key: "scopeData.lifecycle.businessContact",
            text: "Business owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.businessContact"
            }
        },
        {
            key: "scopeData.lifecycle.technicalContact",
            text: "Technical owner",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.technicalContact"
            }
        },
        {
            key: "contextData.portfolioContact",
            text: "Portfolio business contact",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.portfolioContact"
            }
        },
        {
            key: "contextData.portfolioTechnical",
            text: "Portfolio technical contact",
            secondaryText: "Based on current item",
            data: {
                $eval: "contextData.portfolioTechnical"
            }
        },
        {
            key: "scopeData.lifecycle.submittedBy",
            text: "Submitted by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.submittedBy"
            }
        },
        {
            key: "scopeData.lifecycle.reviewedBy",
            text: "Reviewed by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.reviewedBy"
            }
        },
        {
            key: "scopeData.lifecycle.closedBy",
            text: "Closed by",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.lifecycle.closedBy"
            }
        },
        {
            key: "scopeData.treatment.assignedTo",
            text: "Treatment assigned to",
            secondaryText: "Based on current item",
            data: {
                $eval: "scopeData.treatment.assignedTo"
            }
        }
    ]
};

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const WorkflowUserPicker = observer((props) => {
    // const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const { principalContext } = useStores();
    const pickerId = useId("picker");

    const { label, required, disabled, multiple, itemLimit, value, onChange } = props;

    const suggestionProps = {
        suggestionsHeaderText: "Suggested people from directory, current item or email address",
        mostRecentlyUsedHeaderText: "Properties from current item",
        noResultsFoundText: "No matching people found",
        loadingText: "Loading...",
        showRemoveButtons: false,
        suggestionsAvailableAlertText: "People picker suggestions available",
        suggestionsContainerAriaLabel: "Suggested people from directory"
    };

    const validateInput = useCallback((input) => {
        if (validateEmail(input)) {
            return ValidationState.valid;
        } else if (input.length > 1) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    }, []);

    const getPersona = useCallback(
        (principal) => {
            if (principal.$eval) {
                return propertyMap[props.propertyModel || "work-item"].find((p) => principal.$eval === p.data.$eval);
            }

            if (principal.email && !principal.id) {
                return {
                    key: principal.email,
                    text: principal.email,
                    secondaryText: "External user",
                    data: principal
                };
            }

            var empty = principal.id == "00000000-0000-0000-0000-000000000000";
            return {
                key: empty ? principal.externalId : principal.id,
                text: principal.name,
                secondaryText: principal.email,
                //size: PersonaSize.size32,
                data: principal
            };
        },
        [props.propertyModel]
    );

    return (
        <div>
            {label && (
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Label required={required} disabled={disabled} htmlFor={pickerId}>
                            {label}
                        </Label>
                        {loading && <Spinner size={SpinnerSize.xSmall} />}
                    </Stack>
                    {props.allowClear && (multiple ? value && value.length > 0 : !!value) && (
                        <IconButton
                            styles={{ root: { width: 24, height: 24 }, icon: { fontSize: 13, lineHeight: 13 } }}
                            iconProps={{ iconName: "ClearFilter" }}
                            onClick={() => {
                                onChange(multiple ? [] : null);
                            }}
                        />
                    )}
                </Stack>
            )}
            <NormalPeoplePicker
                id={pickerId}
                required={required}
                selectedItems={value.map((p) => getPersona(p))}
                onResolveSuggestions={(text, current, limit) => {
                    if (text) {
                        setLoading(true);
                        return principalContext.identityService
                            .searchPrincipals({ pageSize: 15, startIndex: 0, keywords: text })
                            .then((result) => {
                                const search = text.toLowerCase();
                                const data = result.items.map((s) => getPersona(s));
                                if (props.allowEmail && validateEmail(text)) {
                                    if (!result.items.find((p) => p.email.toLowerCase() === search)) {
                                        data.push(getPersona({ email: text }));
                                    }
                                }
                                //if (props.propertyModel && propertyMap[props.propertyModel]) {
                                propertyMap[props.propertyModel || "work-item"]
                                    .filter((p) => p.text.toLowerCase().indexOf(search) !== -1)
                                    .filter((p) => {
                                        return !value.find((v) => v.$eval === p.data.$eval);
                                    })
                                    .forEach((p) => {
                                        data.push(p);
                                    });
                                //}
                                setLoading(false);

                                console.log(data);
                                return data;
                            });
                    }
                }}
                onEmptyInputFocus={() => {
                    return propertyMap[props.propertyModel || "work-item"].filter((p) => {
                        return !value.find((v) => v.$eval === p.data.$eval);
                    });
                }}
                pickerSuggestionsProps={suggestionProps}
                onValidateInput={validateInput}
                removeButtonAriaLabel={"Remove"}
                itemLimit={multiple ? itemLimit : 1}
                inputProps={{
                    placeholder: props.placeholder
                }}
                // pickerCalloutProps={{
                //     className: cx("cygraph-PrincipalPicker-callout"),
                // }}
                onChange={(items) => {
                    console.log("onChange", items);
                    if (onChange) {
                        setLoading(true);
                        const current = items.map((i) => i.data);
                        const needsResolving = current.filter((p) => p.id === "00000000-0000-0000-0000-000000000000");
                        if (needsResolving.length > 0) {
                            principalContext.identityService.resolvePrincipals(needsResolving).then((resolved) => {
                                const updated = [];
                                current.forEach((p) => {
                                    if (p.id === "00000000-0000-0000-0000-000000000000") {
                                        const a = resolved.find(
                                            (r) => r.externalId === p.externalId && r.provider === p.provider
                                        );
                                        if (a) {
                                            updated.push(a);
                                        }
                                    } else {
                                        updated.push(p);
                                    }
                                });
                                setLoading(false);
                                onChange(multiple ? updated : updated.length ? updated[0] : null);
                            });
                        } else {
                            setLoading(false);
                            onChange(multiple ? current : current.length ? current[0] : null);
                        }
                    }
                    return items;
                }}
                resolveDelay={300}
                disabled={disabled}
            />
        </div>
    );
});
