import { observable, action } from "mobx";

import { StateFlowEditorStore } from "./StateFlowEditorStore";
import { isTargetEndState, isTargetStartState } from "./TargetMetadata";

export class StateFlowEditorMenuStore {
    public parentStore: StateFlowEditorStore;

    @observable public menuItems: any[] = [];
    @observable public position: any = null;
    @observable public visible: boolean = false;

    public options: any;

    constructor(parentStore: StateFlowEditorStore) {
        this.parentStore = parentStore;
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        const items = [];
        switch (options.type) {
            case "stage":
                items.push({
                    key: "addState",
                    text: "New State",
                    iconProps: { iconName: "Add" },
                });
                if (options.stage.stage !== "start") {
                    items.push({
                        key: "addStageBefore",
                        text: "Add Stage Before",
                        iconProps: { iconName: "PaddingLeft" },
                    });
                }
                if (options.stage.stage !== "end") {
                    items.push({
                        key: "addStageAfter",
                        text: "Add Stage After",
                        iconProps: { iconName: "PaddingRight" },
                    });
                }
                items.push({
                    key: "editStage",
                    text: "Edit Stage",
                    iconProps: { iconName: "Edit" },
                });
                items.push({
                    key: "divider_1",
                    itemType: 1,
                });
                items.push({
                    key: "removeStage",
                    text: "Remove Stage",
                    iconProps: { iconName: "Delete", style: { color: "var(--red-10)" } },
                });
                break;
            case "state":
                if (!isTargetEndState(options.target, options.state)) {
                    items.push({
                        key: "addTrigger",
                        text: "New Trigger",
                        iconProps: { iconName: "Add" },
                    });
                }

                const current = options.manifest.initialStateId === options.state.id;
                if (!current && isTargetStartState(options.target, options.state)) {
                    const inCount = options.manifest.states.reduce((a, s) => {
                        if (s.triggers) {
                            return a + s.triggers.filter((t) => t.command && t.command.to === options.state.id).length;
                        }
                        return a;
                    }, 0);

                    if (inCount === 0) {
                        items.push({
                            key: "setInitialState",
                            text: "Set Initial State",
                            iconProps: { iconName: "Location" },
                        });
                    }
                }
                items.push({
                    key: "editState",
                    text: "Edit State",
                    iconProps: { iconName: "Edit" },
                });
                items.push({
                    key: "duplicateState",
                    text: "Duplicate State",
                    iconProps: { iconName: "DependencyAdd" },
                });
                items.push({
                    key: "previewState",
                    text: "Preview State",
                    iconProps: { iconName: "VisioDiagram" },
                });
                if (!current) {
                    items.push({
                        key: "divider_1",
                        itemType: 1,
                    });
                    items.push({
                        key: "removeState",
                        text: "Remove State",
                        iconProps: { iconName: "Delete", style: { color: "var(--red-10)" } },
                    });
                }
                break;
            case "trigger":
                items.push({
                    key: "editTrigger",
                    text: "Edit Trigger",
                    iconProps: { iconName: "Edit" },
                });
                items.push({
                    key: "divider_1",
                    itemType: 1,
                });
                items.push({
                    key: "removeTrigger",
                    text: "Remove Trigger",
                    iconProps: { iconName: "Delete", style: { color: "var(--red-10)" } },
                });
                break;
        }

        this.menuItems = items;
        this.position = options.position;
        this.visible = true;

        return promise;
    }

    @action
    public hide(options) {
        this.position = null;
        this.menuItems = [];
        this.options = null;
        this.visible = false;
    }

    @action
    public resolve(success, action) {
        this.options.resolve({ success, action });
    }

    @action
    public reject(error) {
        this.options.reject({ success: false, error });
    }
}
