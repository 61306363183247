import { observable, computed } from "mobx";

import { EntityService } from "@api/entities";
import { EntityStore } from "./EntityStore";
import { EntitySelectionStore } from "./EntitySelectionStore";
import { EntityPermissionStore } from "./EntityPermissionStore";

export class EntityLifecycleStore {
    public parentStore: EntityStore;
    public entityService: EntityService;
    public selectionStore: EntitySelectionStore;
    public permissionStore: EntityPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @computed
    public get showAgentActions() {
        return this.permissionStore.isAgent;
    }

    @computed
    public get canEdit() {
        const entity = this.selectionStore.entity;

        if (!entity) {
            return false;
        }

        return this.permissionStore.canEdit;
    }
}
