import { ServiceBase, Constants } from "../graph/serviceBase";

export class ActivityService extends ServiceBase {
    public async searchActivityMessages(options) {
        return this.post(`${Constants.baseUrl}/activity-messages/search`, options);
    }

    public async getActivityMessage(id: string) {
        return this.get(`${Constants.baseUrl}/activity-messages/${id}`);
    }

    public async createActivityMessage(data: any) {
        return this.post(`${Constants.baseUrl}/activity-messages`, data);
    }

    public async updateActivityMessage(data: any) {
        return this.put(`${Constants.baseUrl}/activity-messages/${data.id}`, data);
    }

    public async removeActivityMessage(data: any) {
        return this.delete(`${Constants.baseUrl}/activity-messages/${data.id}`, {});
    }
}
