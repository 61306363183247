import { observable, action, computed } from "mobx";

import { PageService } from "../../../api/pages";
import { PageInstanceStore } from "./PageInstanceStore";
import { registrations } from "../components/parts/registrations";

export class PagePartPickerStore {
    private pageService: PageService;
    public parentStore: PageInstanceStore;

    @observable public visible: boolean = false;
    @observable public keywords: string;
    @observable public formData: any;
    @observable public formOptions: any;
    public targetElement: any;

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
    }

    @action
    public setKeywords(keywords) {
        this.keywords = keywords;
    }

    @computed
    public get featured() {
        var registrations = this.parentStore.parentStore.registryStore.registrations;
        return Object.values(registrations)
            .filter((registration: any) => registration.selectable && registration.featured);
    }

    @computed
    public get all() {
        var registrations = this.parentStore.parentStore.registryStore.registrations;
        return Object.values(registrations)
            .filter((registration: any) => registration.selectable);
    }

    @action
    public show(options) {
        console.log(options);
        this.keywords = null;
        this.targetElement = options.targetElement;
        this.formData = { 
            zoneName: options.zoneName, 
            sectionId: options.section.id 
        };

        const promise = new Promise( (resolve, reject) => {
            this.formOptions = { resolve, reject };
        });

        this.visible = !!this.targetElement;

        return promise;
    }

    @action
    public hide(options) {
        if(this.formOptions && this.formData && options && options.registration) {
            this.formOptions.resolve({
                zoneName: this.formData.zoneName,
                sectionId: this.formData.sectionId,
                registration: options.registration
            });
        } else {
            this.formOptions.resolve();
        }
        this.visible = false;
        this.keywords = null;
        this.targetElement = null;
        this.formData = null;
        this.formOptions = null;
    }
}
