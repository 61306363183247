import { observable, flow, computed, action } from "mobx";

import { PageInstanceStore } from "./PageInstanceStore";

export class PagePartDataStore {

    public parentStore: PageInstanceStore;

    @observable public data: any = {};

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
    }

    @action public save(partId, data) {
        this.data[partId] = data;
    }    

}
