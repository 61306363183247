import { ServiceBase, Constants } from "../graph/serviceBase";

export class PageService extends ServiceBase {
    public async getPageLibraries(options) {
        return this.get(
            `${Constants.baseUrl}/page-libraries?pageSize=${options.pageSize}&startIndex=${
                options.startIndex
            }&keywords=${options.keywords || ""}`
        );
    }

    public async getPageLibrary(libraryId: string) {
        return this.get(`${Constants.baseUrl}/page-libraries/${libraryId}`);
    }

    public async getPageLibraryPermission(libraryId: string) {
        return this.get(`${Constants.baseUrl}/page-libraries/${libraryId}/permission`);
    }

    public async getPageLibrarySharing(libraryId: string) {
        return this.get(`${Constants.baseUrl}/page-libraries/${libraryId}/sharing`);
    }

    public async grantPageLibrarySharing(libraryId: string, actions: any[]) {
        return this.post(`${Constants.baseUrl}/page-libraries/${libraryId}/sharing/grant`, actions);
    }

    public async revokePageLibrarySharing(libraryId: string, actions: any[]) {
        return this.post(`${Constants.baseUrl}/page-libraries/${libraryId}/sharing/revoke`, actions);
    }

    public async getPageContent(pageId: string, published: boolean) {
        return this.get(
            `${Constants.baseUrl}/pages/${pageId}/content?published=${
                typeof published === "undefined" ? true : published
            }`
        );
    }

    public async getPages(options: any) {
        return this.get(
            `${Constants.baseUrl}/pages?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }&query.parentId=${options.parentId || ""}`
        );
    }

    public async getPage(pageId: string) {
        return this.get(`${Constants.baseUrl}/pages/${pageId}`);
    }

    public async getPagePermission(pageId: string) {
        return this.get(`${Constants.baseUrl}/pages/${pageId}/permission`);
    }

    public async getPageSharing(pageId: string) {
        return this.get(`${Constants.baseUrl}/pages/${pageId}/sharing`);
    }

    public async grantPageSharing(pageId: string, actions: any[]) {
        return this.post(`${Constants.baseUrl}/pages/${pageId}/sharing/grant`, actions);
    }

    public async revokePageSharing(pageId: string, actions: any[]) {
        return this.post(`${Constants.baseUrl}/pages/${pageId}/sharing/revoke`, actions);
    }

    public async getPageByPath(path: string) {
        return this.get(`${Constants.baseUrl}/pages/by-path?path=${path}`);
    }

    public async getPageContentByPath(path: string, optional: boolean) {
        return this.get(`${Constants.baseUrl}/pages/by-path/content?path=${path}&optional=${optional || false}`);
    }

    public async createPage(page) {
        return this.post(`${Constants.baseUrl}/pages`, page);
    }

    public async updatePage(page) {
        return this.put(`${Constants.baseUrl}/pages/${page.entity.id}`, page);
    }

    public async deletePage(pageId) {
        return this.delete(`${Constants.baseUrl}/pages/${pageId}`, {});
    }

    public async getPageBreadcrumb(query: any) {
        return this.get(
            `${Constants.baseUrl}/pages/breadcrumb?libraryId=${query.libraryId}&pageId=${query.pageId || ""}&maxLevel=${
                query.maxLevel || 2
            }&isPublished=${query.isPublished || false}`
        );
    }

    public async getPageHierarchy(query: any) {
        return this.get(
            `${Constants.baseUrl}/pages/hierarchy?libraryId=${query.libraryId}&pageId=${query.pageId || ""}&maxLevel=${
                query.maxLevel || 2
            }&isPublished=${query.isPublished || false}`
        );
    }

    public async copyPage(sourceId, page) {
        return this.put(`${Constants.baseUrl}/pages/${sourceId}/copy`, page);
    }
}
