export const AutoAssignmentModes = {
    'none': 'None',
    'workItem.assignedToUser': 'Pick a User',
    'portfolio.assurancePrimaryOtherwiseSecondary': 'Pick Portfolio Assurance Lead',
};

export const ProductStatus = {
    Conceptual: 'Conceptual',
    InTesting: 'In Testing',
    Operational: 'Operational',
    Deprecated: 'Deprecated',
    Retired: 'Retired',
};

export const autoAssignmentModeOrDefault = (mode) => {
    if ((mode === 'workItem.assignedToUser') || (mode === 'portfolio.assurancePrimaryOtherwiseSecondary')) {
        return mode
    } else {
        return 'none'
    }
};