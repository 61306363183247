/**
 *
 * @param getPortfolios
 *
    "control": null,
    "controlSet": null,
    "keywords": null,
    "templateId": null,
    "sort": null,

 */

import { assessmentFilters } from "./assessmentFilters";

const controlMapsFilters = (getPortfolios, getControlMapList, getControlSets, getControls) => [
    {
        id: "mapId",
        name: "Control Map",
        type: "list",
        getValues: () => getControlMapList(),
    },
    {
        id: "timeLine",
        name: "Date Range",
        type: "dateRangePicker",
    },
    {
        id: "initialCompliances",
        name: "Initial Compliance",
        type: "multiselectList",
        values: [
            { key: "Compliant", text: "Compliant" },
            { key: "NonCompliant", text: "Non Compliant" },
            { key: "Pending", text: "Pending" },
        ],
        default: "false",
    },
    {
        id: "finalCompliances",
        name: "Final Compliance",
        type: "multiselectList",
        values: [
            { key: "Compliant", text: "Compliant" },
            { key: "NonCompliant", text: "Non Compliant" },
            { key: "Pending", text: "Pending" },
        ],
        default: "false",
    },
    {
        id: "created",
        name: "Created Range",
        type: "dateRangePicker",
    },
    {
        id: "closedBy",
        title: "Closed By",
        type: "userpicker",
    },
    {
        id: "closed",
        name: "Closed Range",
        type: "dateRangePicker",
    },
    {
        id: "level",
        name: "Level",
        type: "multiselectList",
        values: [
            { key: "Critical", text: "Critical" },
            { key: "High", text: "High" },
            { key: "Medium", text: "Medium" },
            { key: "Low", text: "Low" },
            { key: "Note", text: "Note" },
        ],
        default: "false",
    },
    {
        id: "assessmentState",
        name: "Status",
        type: "multiselectList",
        values: [
            { key: "Draft", text: "Triage" },
            { key: "Started", text: "Deep Dive" },
            { key: "Remediation", text: "Remediation" },
            { key: "Reviewing", text: "Risk Review" },
            { key: "Closed", text: "Closed" },
            { key: "Cancelled", text: "Cancelled" },
        ],
        default: "false",
    },
    {
        id: "controlSets",
        name: "Control Sets",
        type: "multiselectList",
        getValues: getControlSets,
    },
    {
        id: "controls",
        name: "Controls",
        type: "multiselectList",
        getValues: getControls,
    },
    {
        id: "assessment",
        name: "Filter by assessment",
        type: "group",
        filters: assessmentFilters(getPortfolios).map((F) => {
            return {
                id: `assessment_${F.id}`,
                name: F.name,
                title: F.title,
                type: F.type,
                getValues: F.getValues,
                values: F.values,
                default: F.default,
            };
        }),
    },
];

export { controlMapsFilters };
