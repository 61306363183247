const workItemFilters = (getPortfolios, getProducts, getGroups, getSubStatuses) => [
    {
        id:'timeLine',
        name:'Date Range',
        type:'dateRangePicker'
    },
    // {
    //     id:'dateRangeType',
    //     name:'Date Range',
    //     type:'list',
    //     values: [
    //         { key: 'dynamicRange', text: 'Dynamic Date Range'},
    //         { key: 'fixedRange', text: 'Fixed Date Range'},
    //     ],
    //     default: 'dynamicRange',
    // },
    // {
    //     id:'dateRange',
    //     name:'Dynamic Date Range',
    //     type:'list',
    //     hideLabel: true,
    //     displayCondition: (formData) => {
    //         const dataRangeType = (formData.properties && formData.properties.filters) ? formData.properties.filters.find(F => F.id === 'dateRangeType') : null;
    //         if (dataRangeType) return dataRangeType.value === "dynamicRange"
    //         else return true
    //     },
    //     values: [
    //         { key: 'LastMonth', text: 'Last Month'},
    //         { key: 'Last3Months', text: 'Last 3 Months'},
    //         { key: 'Last6Months', text: 'Last 6 Month'},
    //         { key: 'LastYear', text: 'Last Year'},
    //     ]
    // },
    // {
    //     id:'fixedDateRange',
    //     name:'Fixed Date Range',
    //     type:'datePicker',
    //     displayCondition: (formData) => {
    //         const dataRangeType = (formData.properties && formData.properties.filters) ? formData.properties.filters.find(F => F.id === 'dateRangeType') : null;
    //         if (dataRangeType) return dataRangeType.value === "fixedRange"
    //         else return false
    //     }
    // },
    {
        id:'portfolios',
        name:'Portfolios',
        type:'multiselectList',
        getValues: getPortfolios,
    },
    {
        id:'products',
        name:'Products',
        type:'multiselectList',
        getValues: getProducts,
    },
    { 
        id: 'assignedToUser',
        title:'Assigned To User',
        type:'userpicker'
    },
    {
        id:'assignedToGroups',
        name:'Assigned to groups',
        type:'multiselectList',
        getValues: getGroups,
    },
    {
        id:'subStatus',
        name:'Status',
        type:'multiselectList',
        getValues: getSubStatuses,
    },
    {
        id:'status',
        name:'Internal Status',
        type:'multiselectList',
        values: [
            { key: 'Open', text: 'Open'},
            { key: 'Assigned', text: 'Assigned'},
            { key: 'InProgress', text: 'InProgress'},
            { key: 'Blocked', text: 'Blocked'},
            { key: 'Resolved', text: 'Resolved'},
            { key: 'Closed', text: 'Closed'},
            { key: 'Cancelled', text: 'Cancelled'},
            
        ],
        default: 'false',
    },
    {
        id:'priority',
        name:'Priority',
        type:'multiselectList',
        values: [
            { key: 'Critical', text: 'Critical'},
            { key: 'High', text: 'High'},
            { key: 'Medium', text: 'Medium'},
            { key: 'Low', text: 'Low'},
        ],
        default: 'false',
    },
    { 
        id: 'requestedBy',
        title:'Requested By',
        type:'userpicker'
    },
    {
        id:'created',
        name:'Created Range',
        type:'dateRangePicker'
    },
    {
        id:'started',
        name:'Started Range',
        type:'dateRangePicker'
    },
    {
        id:'dueDate',
        name:'Due Date Range',
        type:'dateRangePicker'
    },
    {
        id:'resolved',
        name:'Resolved Range',
        type:'dateRangePicker'
    },
    {
        id:'closed',
        name:'Closed Range',
        type:'dateRangePicker'
    },
    {
        id:'isActiveOnly',
        name:'Active Only',
        type:'list',
        values: [
            { key: 'false', text: 'False'},
            { key: 'true', text: 'True'},
        ],
        default: 'false',
    },
]

export { workItemFilters }