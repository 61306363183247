import { IMenuItem } from "./components/IMenuStore";
import { isUserInAnyRoles, isUserInAllRoles, isGuest } from "../identity/stores/PrincipalContextUtilities";
import { ModuleNames, isModuleEnabled } from "../../configProvider";

export const menu: IMenuItem[] = [
    {
        id: "/",
        title: "Home",
        url: "/",
        icon: "Home",
    },
    {
        id: "/demand/dashboard",
        title: "Demand",
        url: "/demand/dashboard",
        icon: "ExternalTFVC",
        isVisible: (user) => true,
        isSelected: (url) => url.indexOf("/boards") === 0 || url.indexOf("/demand") === 0,
    },
    // {
    //     id: '/demand/browse/my',
    //     title: 'Demand',
    //     url: '/demand/browse/my',
    //     icon: 'ExternalTFVC',
    //     isVisible: (user) => !isUserInAnyRoles(user, ['admin', 'agent']) && isGuest(user),
    //     isSelected: (url) => url.indexOf('/work-item') === 0 || url.indexOf('/demand') === 0
    // },
    {
        id: "/assurance/dashboard",
        title: "Assurance",
        url: "/assurance/dashboard",
        icon: "WorkItem",
        isVisible: (user) => true,
        isSelected: (url) =>
            url.indexOf("/assurance") === 0 || url.indexOf("/assessments") === 0 || url.indexOf("/triage") === 0,
    },
    {
        id: "/inventory/browse",
        title: "Inventory",
        url: "/inventory/browse",
        icon: "Org",
        isVisible: (user) => isUserInAnyRoles(user, ["admin", "agent"]),
        isSelected: (url) => url.indexOf("/inventory") === 0,
    },
    // {
    //     id: "/entities/browse",
    //     title: "Entities",
    //     url: "/entities/browse",
    //     icon: "Org",
    //     isVisible: (user) => isUserInAnyRoles(user, ["admin", "agent"]),
    //     isSelected: (url) => url.indexOf("/entities") === 0,
    // },
    {
        id: "/calendar",
        title: "Calendar",
        url: "/calendar",
        icon: "Calendar",
        isVisible: (user) => false && isUserInAnyRoles(user, ["admin", "insights"]),
        isSelected: (url) => url.indexOf("/calendar") === 0,
    },
    {
        id: "/risks",
        title: "Risks",
        url: "/risks/dashboard",
        icon: "ReportHacked",
        isVisible: (user) => isModuleEnabled(ModuleNames.Risks), // && isUserInAllRoles(user, ["admin", "agent"]),
        isSelected: (url) => url.indexOf("/risks") === 0,
    },
    {
        id: "/exceptions",
        title: "Exceptions",
        url: "/exceptions/dashboard",
        icon: "Shield",
        isVisible: (user) => true,
        isSelected: (url) => url.indexOf("/exceptions") === 0,
    },
    {
        id: "/controls",
        title: "Controls",
        url: "/controls/dashboard",
        icon: "TestPlan",
        isVisible: (user) => isModuleEnabled(ModuleNames.Controls) && isUserInAllRoles(user, ["admin", "agent"]),
        isSelected: (url) => url.indexOf("/controls") === 0,
    },
    {
        id: "/insights",
        title: "Insights",
        url: "/insights",
        icon: "BIDashboard",
        isVisible: (user) => isUserInAnyRoles(user, ["admin", "insights"]),
        isSelected: (url) => url.indexOf("/insights") === 0,
    },
    {
        id: "$divide",
        title: "divide",
        url: "/divide",
        icon: "Unknown",
        isVisible: (user) => true,
        isSelected: (url) => false,
    },
    {
        id: "/settings",
        title: "Settings",
        url: "/settings",
        icon: "Settings",
        isVisible: (user) => isUserInAllRoles(user, ["admin"]),
        isSelected: (url) => url.indexOf("/settings") === 0,
    },
    {
        id: "/help",
        title: "Help",
        url: "/help",
        icon: "Unknown",
        isVisible: (user) => true,
        isSelected: (url) => url.indexOf("/help") === 0,
    },
];
