import { RootStore } from "@modules/base/RootStore";
import { PropertyService } from "@api/properties";

import { PropertySelectionStore } from "./PropertySelectionStore";
import { PropertyPermissionStore } from "./PropertyPermissionStore";
import { PropertyLifecycleStore } from "./PropertyLifecycleStore";

import { PropertyBrowseStore } from "./PropertyBrowseStore";
import { PropertyNewFormStore } from "./PropertyNewFormStore";
import { PropertyEditFormStore } from "./PropertyEditFormStore";

import { PropertySectionBrowseStore } from "./PropertySectionBrowseStore";
import { PropertySectionNewFormStore } from "./PropertySectionNewFormStore";
import { PropertySectionEditFormStore } from "./PropertySectionEditFormStore";

export class PropertyStore {
    public rootStore: RootStore;
    public propertyService: PropertyService;

    public selectionStore: PropertySelectionStore;
    public permissionStore: PropertyPermissionStore;
    public lifecycleStore: PropertyLifecycleStore;

    public browseStore: PropertyBrowseStore;
    public newFormStore: PropertyNewFormStore;
    public editFormStore: PropertyEditFormStore;

    public sectionBrowseStore: PropertySectionBrowseStore;
    public sectionNewFormStore: PropertySectionNewFormStore;
    public sectionEditFormStore: PropertySectionEditFormStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.propertyService = new PropertyService(rootStore.authProvider);

        this.selectionStore = new PropertySelectionStore(this);
        this.permissionStore = new PropertyPermissionStore(this);
        this.lifecycleStore = new PropertyLifecycleStore(this);

        this.browseStore = new PropertyBrowseStore(this);
        this.newFormStore = new PropertyNewFormStore(this);
        this.editFormStore = new PropertyEditFormStore(this);

        this.sectionBrowseStore = new PropertySectionBrowseStore(this);
        this.sectionNewFormStore = new PropertySectionNewFormStore(this);
        this.sectionEditFormStore = new PropertySectionEditFormStore(this);
    }
}
