import { WorkflowService } from "../../../api/workflows";
import { RootStore } from "../../base/RootStore";

import { TaskSelectionStore } from "./TaskSelectionStore";
import { TaskPermissionStore } from "./TaskPermissionStore";
import { TaskLifecycleStore } from "./TaskLifecycleStore";

import { TaskNewFormStore } from "./TaskNewFormStore";
import { TaskEditFormStore } from "./TaskEditFormStore";
import { TaskResponseFormStore } from "./TaskResponseFormStore";

import { TaskSummaryStore } from "./TaskSummaryStore";
import { TaskBrowseStore } from "./TaskBrowseStore";

export class TaskStore {
    public workflowService: WorkflowService;
    public rootStore: RootStore;

    public selectionStore: TaskSelectionStore;
    public permissionStore: TaskPermissionStore;
    public lifecycleStore: TaskLifecycleStore;

    public newFormStore: TaskNewFormStore;
    public editFormStore: TaskEditFormStore;
    public responseFormStore: TaskResponseFormStore;

    public summaryStore: TaskSummaryStore;
    public browseStore: TaskBrowseStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.workflowService = new WorkflowService(rootStore.authProvider);

        this.selectionStore = new TaskSelectionStore(this);
        this.permissionStore = new TaskPermissionStore(this);
        this.lifecycleStore = new TaskLifecycleStore(this);

        this.newFormStore = new TaskNewFormStore(this);
        this.editFormStore = new TaskEditFormStore(this);
        this.responseFormStore = new TaskResponseFormStore(this);

        this.summaryStore = new TaskSummaryStore(this);
        this.browseStore = new TaskBrowseStore(this);
    }
}
