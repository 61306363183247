import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import moment from "moment";

import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Calendar } from "office-ui-fabric-react/lib/Calendar";
import { Callout, DirectionalHint } from "office-ui-fabric-react/lib/Callout";
import { FocusTrapZone } from "office-ui-fabric-react/lib/FocusTrapZone";

const calloutClassName = cx(
    "cygraph-DateRangePicker-callout",
    "ms-DatePicker-callout",
    css`
        & .container {
            display: flex;
            flex-direction: row;
        }
        & .separator {
            border-right: 1px solid #edebe9;
        }
    `
);

const DEFAULT_STRINGS = {
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["S", "M", "T", "W", "T", "F", "S"],
    goToToday: "Go to today",
    prevMonthAriaLabel: "Go to previous month",
    nextMonthAriaLabel: "Go to next month",
    prevYearAriaLabel: "Go to previous year",
    nextYearAriaLabel: "Go to next year",
    prevYearRangeAriaLabel: "Previous year range",
    nextYearRangeAriaLabel: "Next year range",
    closeButtonAriaLabel: "Close date picker",
    weekNumberFormatString: "Week number {0}",
};

export const DateRangePicker = observer(
    class DateRangePicker extends Component {
        constructor(props) {
            super(props);
            this.state = {
                showCalendar: props.showCalendar,
                selectedStartDate: props.selectedStartDate,
                selectedEndDate: props.selectedEndDate,
                textFieldValue: this._parseDtText(props.selectedStartDate, props.selectedEndDate, props.textFieldValue),
                startDisplayName: props.startDisplayName,
                endDisplayName: props.endDisplayName,
            };
        }

        _parseDtText(selectedStartDate, selectedEndDate, textFieldValue) {
            return textFieldValue == "" && selectedEndDate != undefined && selectedStartDate != undefined
                ? this._parseDateTime(selectedStartDate) + " - " + this._parseDateTime(selectedEndDate)
                : textFieldValue;
        }

        _onClick(event) {
            this.setState((prevState) => {
                prevState.showCalendar = !prevState.showCalendar;
                return prevState;
            });
        }

        _onDismiss(event) {
            this.setState((prevState) => {
                prevState.showCalendar = false;
                return prevState;
            });
        }

        _handleRangeChange(payload) {
            this.setState((prevState) => {
                prevState.selectedStartDate = payload.startDate;
                prevState.selectedEndDate = payload.endDate;
                prevState.textFieldValue =
                    this._parseDateTime(payload.startDate) + " - " + this._parseDateTime(payload.endDate);
                return prevState;
            });

            if (this.props.inputDateChanged) {
                this.props.inputDateChanged(payload);
            }
        }

        _parseDateTime(dt) {
            if (!dt) {
                return "N/A";
            }

            var day = dt.getDate();
            var month = dt.getMonth() + 1;
            var year = dt.getFullYear();

            return day + "/" + month + "/" + year;
        }

        render() {
            return (
                <div>
                    <div ref={(calendarBtn) => (this._calendarButtonElement = calendarBtn)}>
                        <TextField
                            readOnly
                            value={this.state.textFieldValue}
                            onClick={this._onClick.bind(this)}
                            iconProps={{ iconName: "Calendar" }}
                        />
                    </div>
                    <div>
                        {this.state.showCalendar && (
                            <Callout
                                isBeakVisible={false}
                                className={cx(calloutClassName)}
                                gapSpace={0}
                                doNotLayer={false}
                                target={this._calendarButtonElement}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                onDismiss={this._onDismiss.bind(this)}
                                setInitialFocus={false}
                            >
                                <FocusTrapZone isClickableOutsideFocusTrap={true}>
                                    <div className="container">
                                        <Calendar
                                            {...this.props.calendarProps}
                                            onSelectDate={(date) =>
                                                this._handleRangeChange({
                                                    startDate: date,
                                                    endDate: this.state.selectedEndDate,
                                                })
                                            }
                                            onDismiss={this._onDismiss.bind(this)}
                                            isMonthPickerVisible={false}
                                            today={this.props.today}
                                            strings={DEFAULT_STRINGS}
                                            value={this.state.selectedStartDate}
                                            showWeekNumbers={this.props.showWeekNumbers}
                                            showGoToToday={this.props.showGoToToday}
                                            dateTimeFormatter={this.props.dateTimeFormatter}
                                            minDate={this.props.minDate}
                                            maxDate={this.state.selectedEndDate}
                                            componentRef={(c) => (this._startCalendar = c)}
                                        />
                                        <div className="separator"></div>
                                        <Calendar
                                            {...this.props.calendarProps}
                                            onSelectDate={(date) =>
                                                this._handleRangeChange({
                                                    startDate: this.state.selectedStartDate,
                                                    endDate: date,
                                                })
                                            }
                                            onDismiss={this._onDismiss.bind(this)}
                                            isMonthPickerVisible={false}
                                            today={this.props.today}
                                            strings={DEFAULT_STRINGS}
                                            value={this.state.selectedEndDate}
                                            showWeekNumbers={this.props.showWeekNumbers}
                                            showGoToToday={this.props.showGoToToday}
                                            dateTimeFormatter={this.props.dateTimeFormatter}
                                            minDate={this.state.selectedStartDate}
                                            maxDate={this.props.maxDate}
                                            componentRef={(c) => (this._endCalendar = c)}
                                            showCloseButton={true}
                                        />
                                    </div>
                                </FocusTrapZone>
                            </Callout>
                        )}
                    </div>
                </div>
            );
        }
    }
);
