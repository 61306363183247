import React, { Component } from "react";
import { observer } from "mobx-react";

import { mergeStyleSets } from "@uifabric/styling";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Link } from "office-ui-fabric-react/lib/Link";
import { HoverCard, HoverCardType } from "office-ui-fabric-react/lib/HoverCard";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { List } from "office-ui-fabric-react/lib/List";

import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import RichTextViewer from "@modules/editors/components/RichTextViewer";

const classNames = mergeStyleSets({
    compactCard: {
        padding: "16px 24px",
    },
    expandedCard: {
        padding: "16px 24px",
        overflow: "auto",
        height: "382px",
        selectors: {
            "& section": {
                marginBottom: "15px",
            },
            "& i": {
                fontSize: "12px",
                lineHeight: "20px",
                marginRight: "10px",
            },
        },
    },
    commandBar: {
        marginTop: "25px",
        selectors: {
            "& .ms-CommandBar": {
                padding: "0px",
            },
            "& a.ms-CommandBarItem-link": {
                textDecoration: "none",
            },
        },
    },
    itemCell: {
        padding: 5,
        boxSizing: "border-box",
        borderBottom: "1px solid var(--border-color)",
        display: "flex",
    },
    itemImage: {
        flexShrink: 0,
    },
    itemContent: {
        overflow: "hidden",
        flexGrow: 1,
    },
});

const onRenderCompactCard = (item) => {
    const { principal } = item;

    const items = [];

    if (principal.email) {
        items.push({
            key: "email",
            text: "Email",
            iconOnly: true,
            iconProps: { iconName: "Mail" },
            tooltipHostProps: { content: `Send an email to ${principal.name}` },
            href: `mailto:${principal.email}`,
        });
    }

    if (principal.userPrincipalName) {
        items.push({
            key: "chat",
            text: "Chat",
            iconOnly: true,
            iconProps: { iconName: "Chat" },
            tooltipHostProps: { content: `Start a chat with ${principal.userPrincipalName}` },
            href: `sip:${principal.userPrincipalName}`,
        });
    }

    return (
        <div className={classNames.compactCard}>
            <div>
                <PhotoPersona
                    principalId={principal.id}
                    size={PersonaSize.size56}
                    text={principal.name}
                    showSecondaryText={true}
                    secondaryText={principal.jobTitle}
                    tertiaryText={principal.department}
                />
            </div>
            <div>
                <CommandBar
                    className={classNames.commandBar}
                    items={items}
                    overflowButtonProps={{ ariaLabel: "More actions" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                />
            </div>
        </div>
    );
};

const onRenderExpandedCard = (item) => {
    const { principal, activities } = item;

    return (
        <div className={classNames.expandedCard}>
            <section>
                <ObservableSeparator className="row--xxs">Contact Details</ObservableSeparator>
                {principal.email && (
                    <div>
                        <Icon iconName="Mail" />
                        <Link href={`mailto:${principal.email}`}>{principal.email}</Link>
                    </div>
                )}
                {principal.userPrincipalName && (
                    <div>
                        <Icon iconName="Chat" />
                        <Link href={`sip:${principal.userPrincipalName}`}>{principal.userPrincipalName}</Link>
                    </div>
                )}
            </section>
            <section>
                <ObservableSeparator className="row--xxs">Recent Activities (beta)</ObservableSeparator>
                {!activities || activities.length == 0 ? (
                    "No recent activities"
                ) : (
                    <List
                        items={activities}
                        onRenderCell={(activity) => {
                            return (
                                <div className={classNames.itemCell} data-is-focusable={true}>
                                    <div className={classNames.itemContent}>
                                        <div>
                                            <RichTextViewer className="description" value={activity.summary.value} />
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    />
                )}
            </section>
        </div>
    );
};

export const UserHoverCard = observer(
    class UserHoverCard extends Component {
        render() {
            const { children, plain, principal, activities, onCardVisible } = this.props;

            const expandingCardProps = {
                onRenderCompactCard: onRenderCompactCard,
                onRenderExpandedCard: onRenderExpandedCard,
                renderData: { principal, activities },
            };

            const plainCardProps = {
                onRenderPlainCard: onRenderCompactCard,
                renderData: { principal, activities },
            };

            return (
                <HoverCard
                    expandingCardProps={expandingCardProps}
                    plainCardProps={plainCardProps}
                    instantOpenOnClick={false}
                    onCardVisible={onCardVisible}
                    type={plain ? HoverCardType.plain : HoverCardType.expanding}
                >
                    {children}
                </HoverCard>
            );
        }
    }
);
