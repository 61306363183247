import { observable, flow } from "mobx";

import { PaginationStore } from "@modules/base/PaginationStore";
import { RiskService } from "@api/risks";
import { RiskStore } from "./RiskStore";

export class RiskHeatmapStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public baselineRisks: any[] = [];
    @observable public grossRisks: any[] = [];
    @observable public netRisks: any[] = [];
    @observable public total: number = 0;
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks-dashboard",
            onClick: () => this._goToUrl(`/risks/dashboard`),
        },
        { text: "Risk Heatmap", key: "risks-heatmap", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    public reset = flow(function* () {
        this.loading = false;
        this.query = {};
        this.error = null;
        this.baselineRisks = [];
        this.grossRisks = [];
        this.netRisks = [];
        this.total = 0;
    });

    public loadRisks = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null }, query);

        this.query = {
            ...options.query,
        };

        try {
            const baselineResult = yield this.riskService.getRisksGroupByCount({
                groupBy: "baselineScoreCalculated",
                query: {
                    ...this.query,
                    ...{ type: [{ template: "zerodai:risks:generic" }] },
                },
            });

            this.baselineRisks = baselineResult.items.map((i) => {
                const parts = i.id.split("-");
                return {
                    text: `${i.count > 99 ? "99+" : i.count}`,
                    score: {
                        impact: parseInt(parts[1]),
                        likelihood: parseInt(parts[2]),
                    },
                };
            });

            const grossResult = yield this.riskService.getRisksGroupByCount({
                groupBy: "grossScoreCalculated",
                query: {
                    ...this.query,
                    ...{ type: [{ template: "zerodai:risks:generic" }] },
                },
            });

            this.grossRisks = grossResult.items.map((i) => {
                const parts = i.id.split("-");
                return {
                    text: `${i.count > 99 ? "99+" : i.count}`,
                    score: {
                        impact: parseInt(parts[1]),
                        likelihood: parseInt(parts[2]),
                    },
                };
            });

            const netResult = yield this.riskService.getRisksGroupByCount({
                groupBy: "netScoreCalculated",
                query: {
                    ...this.query,
                    ...{ type: [{ template: "zerodai:risks:generic" }] },
                },
            });

            this.netRisks = netResult.items.map((i) => {
                const parts = i.id.split("-");
                return {
                    text: `${i.count > 99 ? "99+" : i.count}`,
                    score: {
                        impact: parseInt(parts[1]),
                        likelihood: parseInt(parts[2]),
                    },
                };
            });

            this.total = grossResult.items.map((i) => i.count).reduce((partialSum, a) => partialSum + a, 0);

            const { type, ...rest } = grossResult.query;
            this.query = rest;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
