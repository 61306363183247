import { observable, action, computed } from "mobx";

import { RootStore } from "../base/RootStore";

import { NotificationStore } from "./NotificationStore";
import { MenuStore } from "./MenuStore";
import { IMenuStore } from "./components/IMenuStore";

import { menu } from "./menu";

export enum NavMode {
    top,
    left,
    mini,
}

export class LayoutStore {
    rootStore: RootStore;
    public menu: IMenuStore;
    public notificationStore: NotificationStore;
    @observable public navMode: NavMode = NavMode.top;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.menu = new MenuStore(this, menu);
        this.notificationStore = rootStore.notificationStore;
    }

    @action public setNavMode(mode: NavMode) {
        this.navMode = mode;
    }

    @computed public get notifications() {
        return this.notificationStore.notifications;
    }

    @computed public get unreadMessages() {
        return this.notificationStore.unreadMessages;
    }

    @action public markAllNotificationsRead = () => {
        this.notificationStore.markAllNotificationsRead();
    };

    @action public displayToastNotification = (message: any) => {
        this.notificationStore.displayToastNotification(message);
    };
}
