import { observable } from "mobx";

import { PageService } from '../../../api/pages';
import { PageStore } from "./PageStore";

import { registrations as core } from '../components/parts/registrations';

const DefaultRegistration: string = 'pages:core:rich-text-editor@latest';

export class PageRegistryStore {

    public parentStore: PageStore;
    public pageService: PageService;

    @observable public registrations: any = {};

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;

        this.registrations = {...core};
    }

    public getRegistration(part) {
        const fullName = `${part.type}@${part.version||'latest'}`;
        let registration = this.registrations[fullName];

        if(typeof registration === 'string') {
            registration = this.registrations[registration];
        }

        if(registration) {
            return registration;
        }

        registration = this.registrations[DefaultRegistration];

        if(typeof registration === 'string') {
            registration = this.registrations[registration];
        }

        return registration;
    }
}
