import moment from 'moment';
import { computeTimelineFilter } from './computeTimelineFilter';
import { generateAssessmentQuery } from '../queries';

async function getControlMap(query, api) {
  // statusFilter
  let statusFilter = null;
  if (query.assessmentState) {
    statusFilter = query.assessmentState.map(s => s);
  }
  // mapId
  let mapId = null;
  if (query.mapId) {
    mapId = query.mapId;
  }

  let createdFilter = null;
  let closedFilter = null;
  let timeLineFilter = null;
  if (query.created) {
      createdFilter = computeTimelineFilter(query.created[0]?query.created[0]:query.created);
  }
  if (query.closed) {
      closedFilter = computeTimelineFilter(query.closed[0]?query.closed[0]:query.closed);
  }
  if (query.timeLine) {
    timeLineFilter = computeTimelineFilter(query.timeLine[0]?query.timeLine[0]:query.timeLine);
  }

  let initialCompliances = null;
  if (query.initialCompliances && query.initialCompliances.length > 0) {
      initialCompliances = query.initialCompliances;
  }
  let finalCompliances = null;
  if (query.finalCompliances && query.finalCompliances.length > 0) {
      finalCompliances = query.finalCompliances;
  }
  let level = null;
  if (query.level && query.level.length > 0) {
      level = query.finalCompliances;
  }

  let controlSetFilter = null;
  if (query.controlSets && query.controlSets.length > 0) {
      controlSetFilter = query.controlSets.map(CS => {return {
          id: CS
      }})
  }
  let controlsFilter = null;
  if (query.controls && query.controls.length > 0) {
      controlSetFilter = query.controls.map(C => {return {
          id: C
      }})
  }
  let assessmentQuery = null;
  let assessmentFilters = {
      timeLine: query.assessment_timeLine,
      created: query.assessment_created,
      started: query.assessment_started,
      dueDate: query.assessment_dueDate,
      closed: query.assessment_closed,
      portfolios: query.assessment_portfolios,
      control: controlsFilter,
      controlSet: controlSetFilter,
      assessmentState: query.assessment_assessmentState,
      assignedToUser: query.assessment_assignedToUser,
      requestedBy: query.assessment_requestedBy,
      priority: query.assessment_priority,
  };
  assessmentQuery = generateAssessmentQuery(assessmentFilters);

  const sentQuery = {
    query:{
      status: statusFilter,
      mapId: mapId,
      controlSet: controlSetFilter,
      initialCompliances,
      finalCompliances,
      level,
      timeline: timeLineFilter,
      created: createdFilter,
      closed: closedFilter,
      assessment: assessmentQuery,
    }
  };
  
  if (mapId) {
    const results = await api(sentQuery);
    return results
  } else {
    return {
      items: []
    }
  }
}

export { getControlMap }