import { ServiceBase, Constants } from "../graph/serviceBase";

export class IdentityService extends ServiceBase {
    public async searchPrincipals(options) {
        return this.get(
            `${Constants.baseUrl}/principals/search?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchPrincipalsByGroup(options) {
        return this.get(
            `${Constants.baseUrl}/principals/${options.groupId}/members?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async resolvePrincipals(data) {
        return this.post(`${Constants.baseUrl}/principals/resolve`, data);
    }

    public async getPrincipalSelf() {
        return this.get(`${Constants.baseUrl}/principals/me`);
    }

    public async getPrincipal(id) {
        return this.get(`${Constants.baseUrl}/principals/${id}`);
    }

    public async getPrincipalActivities(id) {
        return this.get(`${Constants.baseUrl}/principals/${id}/activities`);
    }

    public async getPrincipalsByIds(ids) {
        return this.get(`${Constants.baseUrl}/principals/bulk?${ids.map((id) => `ids=${id}`).join("&")}`);
    }

    public async getPrincipalPhoto(id, options) {
        return this.getBlob(`${Constants.baseUrl}/principals/${id}/photo/$value`, options);
    }

    public async searchPrincipalsGroups(options) {
        return this.get(
            `${Constants.baseUrl}/principals/groups?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchPrincipalsUsers(options) {
        return this.get(
            `${Constants.baseUrl}/principals/users?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async getUserGroups(options) {
        return this.get(
            `${Constants.baseUrl}/principals/${options.userId}/groups?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async addUserToGroup(userId, groupId) {
        return this.post(`${Constants.baseUrl}/principals/${groupId}/members/${userId}`, {});
    }

    public async removeUserFromGroup(userId, groupId) {
        return this.delete(`${Constants.baseUrl}/principals/${groupId}/members/${userId}`, {});
    }

    public async getGroupMembers(options) {
        return this.get(
            `${Constants.baseUrl}/principals/${options.groupId}/members?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async getUserInAGroups(groupId, options) {
        return this.get(
            `${Constants.baseUrl}/principals/${groupId}/members?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async updateUser(user) {
        return this.put(`${Constants.baseUrl}/principals/users/${user.id}`, user);
    }

    public async createGroup(group) {
        return this.post(`${Constants.baseUrl}/principals/groups`, group);
    }

    public async updateGroup(group) {
        return this.put(`${Constants.baseUrl}/principals/groups/${group.id}`, group);
    }

    public async searchGroups(options) {
        return this.get(
            `${Constants.baseUrl}/principals/groups?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchUsers(options) {
        return this.get(
            `${Constants.baseUrl}/principals/users?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }
}
