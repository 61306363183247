import { observable, flow, computed } from "mobx";

import { PortfolioService } from "../../../api/graph";

import { PortfolioStore } from "./PortfolioStore";

export class PortfolioViewerStore {
    public portfolioService: PortfolioService;
    public parentStore: PortfolioStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: string = "summary";

    constructor(parentStore: PortfolioStore) {
        this.parentStore = parentStore;
        this.portfolioService = parentStore.portfolioService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: "Portfolios", key: "portfolios", onClick: () => this.parentStore.rootStore.routing.push(`/settings/portfolios`) });

        if (!this.portfolio) {
            return builder;
        }

        builder.push({ text: this.portfolio.name, key: this.portfolio.id, isCurrentItem: true });

        return builder;
    }

    @computed
    public get portfolio() {
        return this.parentStore.selectionStore.portfolio;
    }

    @computed
    public get permission() {
        return this.parentStore.selectionStore.permission;
    }

    public loadPortfolio = flow(function* (id) {
        this.loading = true;
        this.error = null;

        if (this.portfolio && this.portfolio.id !== id) {
            this.parentStore.selectionStore.portfolio = null;
        }

        try {
            yield this.parentStore.selectionStore.loadPortfolio(id);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
