import { observable, action, computed } from "mobx";

import { ModellingService } from "../../../api/modelling";
import { PatternEditorStore } from "./PatternEditorStore";

export class PatternJsonEditorStore {
    public parentStore: PatternEditorStore;
    public modellingService: ModellingService;

    @observable public value: any = null;
    @observable public snapshot: any = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;
    public options: any;

    constructor(parentStore: PatternEditorStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.value);
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        this.title = options.title;
        this.snapshot = options.value;
        this.value = JSON.parse(JSON.stringify(options.value));
        this.visible = true;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.value = null;
        this.visible = false;
    }

    @action
    public onChange(value) {
        this.value = value;
    }

    @action
    public onApply(options) {
        this.options.resolve({ success: true, value: this.value });
        this.hide(options);
    }

    @action
    public onCancel(options) {
        this.options.resolve({ success: false });
        this.hide(options);
    }
}
