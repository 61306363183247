import { observable, computed, flow } from "mobx";

import { RiskService } from "@api/risks";
import { RiskStore } from "./RiskStore";

export class TreatmentViewerStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public loading: boolean = false;
    @observable public activities: any[] = [];
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            {
                text: "Risk Management",
                key: "risks",
                onClick: () => this._goToUrl(`/risks/dashboard`)
            },
            {
                text: "Risk Treatments",
                key: "risk-treatments",
                onClick: () => this._goToUrl(`/risks/treatments`)
            }
        ];

        if (!this.treatment) {
            return builder;
        }

        builder.push({
            text: this.treatment.code,
            key: this.treatment.id,
            isCurrentItem: true
        });

        return builder;
    }

    @computed
    public get treatment() {
        return this.parentStore.treatmentSelectionStore.treatment;
    }

    @computed
    public get context() {
        return this.parentStore.treatmentSelectionStore.context;
    }

    public addTreatmentActivity = flow(function* (activity) {
        this.activities.unshift(activity);
    });

    public loadRiskTreatment = flow(function* (id: string) {
        this.loading = true;
        this.activities = [];
        this.error = null;

        try {
            yield this.parentStore.treatmentSelectionStore.loadRiskTreatment(id);
            const result = yield this.riskService.getRiskTreatmentActivities(id);
            this.activities = result.items;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
