import { observable, action } from "mobx";

import { ModellingService } from "../../../api/modelling";
import { ModellingStore } from "./ModellingStore";

export class ModellingControlStore {
    private modellingService: ModellingService;
    public parentStore: ModellingStore;

    @observable public controls: any[] = [];
    @observable public loading: boolean = false;

    constructor(parentStore: ModellingStore, modellingService: ModellingService) {
        this.parentStore = parentStore;
        this.modellingService = modellingService;
    }

    @action
    public loadControls(query) {
        this.loading = true;
        const options = Object.assign(
            {
                pageSize: 250,
                startIndex: 0,
                keywords: null,
                nested: true,
            },
            query
        );
        return this.modellingService.getControls(options).then(this.loadControlsOnSuccess, this.loadControlsOnError);
    }

    @action.bound
    public loadControlsOnSuccess(result) {
        this.controls = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadControlsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }
}
