import { observable, flow, computed, action } from "mobx";

import { WorkflowService } from "@api/workflows";
import { WorkflowStore } from "./WorkflowStore";
import { getEntityTypeFromModule, WorkflowConstants } from "@modules/workflows/stores/WorkflowConstants";
import { automations } from "@modules/workflows/stores/automations";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class WorkflowBuilderStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;
    private responseHandle?: ResponseHandle;

    @observable public loading: boolean = false;
    @observable public query: any = {};
    @observable public error: any = null;

    @observable public visible: boolean = false;
    @observable public formOptions: any;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    @computed
    public get filteredAutomations() {
        let result = automations;

        if (this.query) {
            if (this.query.activity && this.query.activity.length > 0) {
                result = result.filter((a) => this.query.activity.indexOf(a.definition.uniqueId) !== -1);
            }
            if (this.query.triggerType && this.query.triggerType.length > 0) {
                result = result.filter((a) => this.query.triggerType.indexOf(a.trigger.type) !== -1);
            }
            if (this.query.scope && this.query.scope.length > 0) {
                const groups = WorkflowConstants.scopes
                    .filter((s) => this.query.scope.indexOf(s.id) !== -1)
                    .map((s) => s.group);
                result = result.filter((a) => groups.indexOf(a.trigger.group) !== -1);
            }
            if (this.query.keywords) {
                const k = this.query.keywords.toLowerCase();
                result = result.filter((a) => a.name.toLowerCase().indexOf(k) !== -1);
            }
        }

        return result;
    }

    public loadAutomations = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);

        this.loading = true;
        this.query = o.query;
        this.error = null;

        try {
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    @action
    public show(options) {
        let propertyModel = options.context.type;
        if (options.context.type === "module") {
            propertyModel = getEntityTypeFromModule(options.context.id);
        }

        this.formOptions = { trigger: options.trigger, propertyModel };

        this.visible = true;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success, instance) {
        this.responseHandle.resolve({
            success,
            instance,
        });
    }

    @action
    public reject(error) {
        this.responseHandle.reject(error);
    }
}
