import { ControlService } from "../../../api/modelling";

import { RootStore } from "../../base/RootStore";

import { ControlSelectionStore } from "./ControlSelectionStore";

import { ControlDashboardStore } from "./ControlDashboardStore";
import { StrategicControlSelectionStore } from "./StrategicControlSelectionStore";
import { StrategicControlBrowseStore } from "./StrategicControlBrowseStore";
import { StrategicControlViewerStore } from "./StrategicControlViewerStore";
import { TacticalControlSelectionStore } from "./TacticalControlSelectionStore";
import { TacticalControlBrowseStore } from "./TacticalControlBrowseStore";
import { TacticalControlViewerStore } from "./TacticalControlViewerStore";
import { AuditActivityBrowseStore } from "./AuditActivityBrowseStore";
import { AuditActivityCalendarStore } from "./AuditActivityCalendarStore";

import { ControlViewerStore } from "./ControlViewerStore";

import { ControlSetViewerStore } from "./ControlSetViewerStore";
import { ControlSetBrowseStore } from "./ControlSetBrowseStore";

import { ControlMapBrowseStore } from "./ControlMapBrowseStore";
import { ControlMapViewerStore } from "./ControlMapViewerStore";
import { ControlMapNewStore } from "./ControlMapNewStore";
import { ControlMapEditStore } from "./ControlMapEditStore";

import { ControlOptionsStore } from "./ControlOptionsStore";

import { DeploymentNewFormStore } from "./DeploymentNewFormStore";
import { MilestoneNewFormStore } from "./MilestoneNewFormStore";
import { ControlReviewFormStore } from "./ControlReviewFormStore";
export class ControlStore {
    public controlService: ControlService;
    public rootStore: RootStore;

    public selectionStore: ControlSelectionStore;
    public dashboardStore: ControlDashboardStore;

    public strategicSelectionStore: StrategicControlSelectionStore;
    public strategicBrowseStore: StrategicControlBrowseStore;
    public strategicViewerStore: StrategicControlViewerStore;
    public tacticalSelectionStore: TacticalControlSelectionStore;
    public tacticalBrowseStore: TacticalControlBrowseStore;
    public tacticalViewerStore: TacticalControlViewerStore;
    public auditBrowseStore: AuditActivityBrowseStore;
    public auditCalendarStore: AuditActivityCalendarStore;

    public setBrowseStore: ControlSetBrowseStore;
    public setViewerStore: ControlSetViewerStore;
    public viewerStore: ControlViewerStore;

    public mapBrowseStore: ControlMapBrowseStore;
    public mapViewerStore: ControlMapViewerStore;
    public mapNewStore: ControlMapNewStore;
    public mapEditStore: ControlMapEditStore;

    public optionsStore: ControlOptionsStore;
    public deploymentNewFormStore: DeploymentNewFormStore;
    public milestoneNewFormStore: MilestoneNewFormStore;
    public controlReviewFormStore: ControlReviewFormStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.controlService = new ControlService(rootStore.authProvider);

        this.selectionStore = new ControlSelectionStore(this);

        this.dashboardStore = new ControlDashboardStore(this);
        this.strategicSelectionStore = new StrategicControlSelectionStore(this);
        this.strategicBrowseStore = new StrategicControlBrowseStore(this);
        this.strategicViewerStore = new StrategicControlViewerStore(this);
        this.tacticalSelectionStore = new TacticalControlSelectionStore(this);
        this.tacticalBrowseStore = new TacticalControlBrowseStore(this);
        this.tacticalViewerStore = new TacticalControlViewerStore(this);
        this.auditBrowseStore = new AuditActivityBrowseStore(this);
        this.auditCalendarStore = new AuditActivityCalendarStore(this);

        this.setBrowseStore = new ControlSetBrowseStore(this);
        this.setViewerStore = new ControlSetViewerStore(this);
        this.mapBrowseStore = new ControlMapBrowseStore(this);
        this.mapViewerStore = new ControlMapViewerStore(this);
        this.mapNewStore = new ControlMapNewStore(this);
        this.mapEditStore = new ControlMapEditStore(this);
        this.viewerStore = new ControlViewerStore(this);

        this.optionsStore = new ControlOptionsStore(this);

        this.deploymentNewFormStore = new DeploymentNewFormStore(this);
        this.milestoneNewFormStore = new MilestoneNewFormStore(this);
        this.controlReviewFormStore = new ControlReviewFormStore(this);
    }
}
