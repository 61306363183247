import { observable } from "mobx";

//APIs
import { WorkItemService } from "../../../api/workitems";
import { SystemService } from "../../../api/graph";
import { IdentityService } from "../../../api/identity";
//Types
import { WorkItem } from "./types/WorkItem";
//Stores
import { RootStore } from "../../base/RootStore";
import { PaginationStore } from "../../base/PaginationStore";

import { StateFlowInstanceStore } from "@modules/stateflows/stores/StateFlowInstanceStore";

import { WorkItemPermissionStore } from "./WorkItemPermissionStore";
import { WorkItemLifecycleStore } from "./WorkItemLifecycleStore";
import { WorkItemSelectionStore } from "./WorkItemSelectionStore";

import { WorkItemDocumentStore } from "./WorkItemDocumentStore";
import { WorkItemLinkStore } from "./WorkItemLinkStore";
import { WorkItemLinkTypeStore } from "./WorkItemLinkTypeStore";
import { WorkItemLinkNewFormStore } from "./WorkItemLinkNewFormStore";
import { WorkItemLinkEditFormStore } from "./WorkItemLinkEditFormStore";

import { WorkItemNewFormStore } from "./WorkItemNewFormStore";

import { WorkItemAssignFormStore } from "./WorkItemAssignFormStore";
import { WorkItemReAssignFormStore } from "./WorkItemReAssignFormStore";
import { WorkItemEditFormStore } from "./WorkItemEditFormStore";
import { WorkItemDashboardStore } from "./WorkItemDashboardStore";
import { WorkItemCalendarStore } from "./WorkItemCalendarStore";
import { WorkItemTimelineStore } from "./WorkItemTimelineStore";
import { WorkItemRequestStore } from "./WorkItemRequestStore";

import { WorkItemViewerStore } from "./WorkItemViewerStore";
import { WorkItemSummaryStore } from "./WorkItemSummaryStore";

import { WorkItemRelatedWIStore } from "./WorkItemRelatedWIStore";
import { WorkItemReviewPage } from "./WorkItemReviewPage";
import { WorkItemBrowseStore } from "./WorkItemBrowseStore";
import { WorkItemHeaderStore } from "./WorkItemHeaderStore";
import { WorkItemTabsStore } from "./WorkItemTabsStore";
import { WorkItemTaskModalStore } from "./WorkItemTaskModalStore";
import { WorkItemCommentStore } from "./WorkItemCommentStore";

export class WorkItemStore {
    public workItemService: WorkItemService;
    public identityService: IdentityService;
    public rootStore: RootStore;

    public permissionStore: WorkItemPermissionStore;
    public lifecycleStore: WorkItemLifecycleStore;
    public selectionStore: WorkItemSelectionStore;

    public documentStore: WorkItemDocumentStore;
    public linkStore: WorkItemLinkStore;
    public linkTypeStore: WorkItemLinkTypeStore;

    public linkNewFormStore: WorkItemLinkNewFormStore;
    public linkEditFormStore: WorkItemLinkEditFormStore;

    public stateFlowStore: StateFlowInstanceStore;
    public viewerStore: WorkItemViewerStore;
    public summaryStore: WorkItemSummaryStore;
    public newFormStore: WorkItemNewFormStore;
    public editFormStore: WorkItemEditFormStore;
    public requestStore: WorkItemRequestStore;

    public assignFormStore: WorkItemAssignFormStore;
    public reAssignFormStore: WorkItemReAssignFormStore;
    public relatedWorkItemStore: WorkItemRelatedWIStore;
    public reviewPage: WorkItemReviewPage;
    public browseStore: WorkItemBrowseStore;
    public headerStore: WorkItemHeaderStore;
    public tabsStore: WorkItemTabsStore;
    public dashboardStore: WorkItemDashboardStore;
    public calendarStore: WorkItemCalendarStore;
    public timelineStore: WorkItemTimelineStore;
    public taskModalStore: WorkItemTaskModalStore;

    public commentStore: WorkItemCommentStore;

    public systemService: SystemService;

    @observable public workItems: any[] = [];
    @observable public relatedWorkItem: {};
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public loadingRelatedWorkItem: boolean = false;
    @observable public saving: boolean = false;
    @observable public errorTitle: string = null;
    @observable public errorMessage: string = null;
    @observable public workItemStatusList: any[] = [
        {
            key: "Open",
            text: "Open",
            checked: true,
        },
        {
            key: "Assigned",
            text: "Assigned",
            checked: true,
        },
        {
            key: "InProgress",
            text: "In Progress",
            checked: true,
        },
        {
            key: "Blocked",
            text: "Blocked",
            checked: true,
        },
        {
            key: "Resolved",
            text: "Resolved",
            checked: true,
        },
        {
            key: "Closed",
            text: "Closed",
            checked: false,
        },
        {
            key: "Cancelled",
            text: "Cancelled",
            checked: false,
        },
    ];
    @observable public workItemAgeList: any[] = [
        {
            key: "week",
            text: "Older than a week",
        },
        {
            key: "month",
            text: "Older than a month",
        },
        {
            key: "3months",
            text: "Older than 3 months",
        },
    ];
    @observable public relatedWorkItemSuggestion: WorkItem[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.pagination = new PaginationStore();

        this.workItemService = new WorkItemService(rootStore.authProvider);
        this.systemService = new SystemService(rootStore.authProvider);
        this.identityService = new IdentityService(rootStore.authProvider);

        this.selectionStore = new WorkItemSelectionStore(this);
        this.permissionStore = new WorkItemPermissionStore(this);
        this.lifecycleStore = new WorkItemLifecycleStore(this);

        this.documentStore = new WorkItemDocumentStore(this);
        this.linkStore = new WorkItemLinkStore(this);
        this.linkTypeStore = new WorkItemLinkTypeStore(this);
        this.linkNewFormStore = new WorkItemLinkNewFormStore(this);
        this.linkEditFormStore = new WorkItemLinkEditFormStore(this);

        this.stateFlowStore = new StateFlowInstanceStore(rootStore.stateFlowStore, {
            properties: {
                target: "WorkItem",
                state: "status",
                subState: "subStatus",
            },
            commands: {
                "zerodai:commands:work-items:edit": (trigger, options) => this.lifecycleStore.onEdit(trigger, options),
                "zerodai:commands:work-items:assign": (trigger, options) =>
                    this.lifecycleStore.onAssign(trigger, options),
                "zerodai:commands:work-items:start": (trigger, options) =>
                    this.lifecycleStore.onStart(trigger, options),
                "zerodai:commands:work-items:resolve": (trigger, options) =>
                    this.lifecycleStore.onResolve(trigger, options),
                "zerodai:commands:work-items:reopen": (trigger, options) =>
                    this.lifecycleStore.onReopen(trigger, options),
                "zerodai:commands:work-items:block": (trigger, options) =>
                    this.lifecycleStore.onBlock(trigger, options),
                "zerodai:commands:work-items:unblock": (trigger, options) =>
                    this.lifecycleStore.onUnblock(trigger, options),
                "zerodai:commands:work-items:close": (trigger, options) =>
                    this.lifecycleStore.onClose(trigger, options),
                "zerodai:commands:work-items:cancel": (trigger, options) =>
                    this.lifecycleStore.onCancel(trigger, options),
                "zerodai:commands:work-items:delete": (trigger, options) =>
                    this.lifecycleStore.onDelete(trigger, options),
                "zerodai:commands:work-items:flow": (trigger, options) => this.lifecycleStore.onFlow(trigger, options),
            },
            conditions: {
                "zerodai:commands:work-items:canEdit": () => this.lifecycleStore.canEdit,
                "zerodai:commands:work-items:canAssign": () => this.lifecycleStore.canAssign,
                "zerodai:commands:work-items:canStart": () => this.lifecycleStore.canStart,
                "zerodai:commands:work-items:canResolve": () => this.lifecycleStore.canResolve,
                "zerodai:commands:work-items:canReopen": () => this.lifecycleStore.canReopen,
                "zerodai:commands:work-items:canBlock": () => this.lifecycleStore.canBlock,
                "zerodai:commands:work-items:canUnblock": () => this.lifecycleStore.canUnblock,
                "zerodai:commands:work-items:canClose": () => this.lifecycleStore.canClose,
                "zerodai:commands:work-items:canCancel": () => this.lifecycleStore.canCancel,
                "zerodai:commands:work-items:canDelete": () => this.lifecycleStore.canDelete,
                "zerodai:commands:work-items:canFlow": () => this.lifecycleStore.canFlow,
                "zerodai:commands:work-items:isReader": () => this.permissionStore.isReader,
                "zerodai:commands:work-items:isContributor": () => this.permissionStore.isContributor,
                "zerodai:commands:work-items:isOwner": () => this.permissionStore.isOwner,
            },
        });

        this.viewerStore = new WorkItemViewerStore(this);
        this.summaryStore = new WorkItemSummaryStore(this);

        this.newFormStore = new WorkItemNewFormStore(this);
        this.editFormStore = new WorkItemEditFormStore(this);
        this.taskModalStore = new WorkItemTaskModalStore(this);
        this.requestStore = new WorkItemRequestStore(this);

        this.assignFormStore = new WorkItemAssignFormStore(this);
        this.reAssignFormStore = new WorkItemReAssignFormStore(this);
        this.relatedWorkItemStore = new WorkItemRelatedWIStore(this);
        this.dashboardStore = new WorkItemDashboardStore(this);
        this.calendarStore = new WorkItemCalendarStore(this);
        this.timelineStore = new WorkItemTimelineStore(this);

        this.reviewPage = new WorkItemReviewPage(this);
        this.browseStore = new WorkItemBrowseStore(this);
        this.headerStore = new WorkItemHeaderStore(this);
        this.tabsStore = new WorkItemTabsStore(this);

        this.commentStore = new WorkItemCommentStore(this);
    }
}
