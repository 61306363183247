import React from "react";

export function DemandIcon({ className }) {
    return (
        <svg
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="none"
            className={className}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="currentColor"
                d="M12.7022,2.19546 L19.7022,4.82046 C20.4829,5.11318 21,5.85943 21,6.69312 L21,12.0558 C21,15.4648 19.074,18.5812 16.0249,20.1057 L12.6708,21.7827 C12.2485,21.9939 11.7515,21.9939 11.3292,21.7827 L7.97508,20.1057 C4.92602,18.5812 3,15.4648 3,12.0558 L3,6.69312 C3,5.85943 3.51715,5.11318 4.29775,4.82046 L11.2978,2.19546 C11.7505,2.02567 12.2495,2.02567 12.7022,2.19546 Z M12,4.06812 L5,6.69312 L5,12.0558 C5,14.7072 6.49802,17.1311 8.8695,18.3168 L12,19.8821 L15.1305,18.3168 C17.502,17.1311 19,14.7072 19,12.0558 L19,6.69312 L12,4.06812 Z M12,8 C12.5523,8 13,8.44772 13,9 L13,11 L15,11 C15.5523,11 16,11.4477 16,12 C16,12.5523 15.5523,13 15,13 L13,13 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,13 L9,13 C8.44772,13 8,12.5523 8,12 C8,11.4477 8.44772,11 9,11 L11,11 L11,9 C11,8.44772 11.4477,8 12,8 Z"
            ></path>
        </svg>
    );
}
