import { observable, flow, computed, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { PageService } from "../../../api/pages";
import { PageStore } from "./PageStore";

import { buildRoles } from "../../securables/stores/utilities";

const roles = buildRoles({
    "Page Content Roles": [
        { key: "Page.Reader.All", text: "All Page Reader" },
        { key: "Page.Contributor.All", text: "All Page Contributor" },
        { key: "Page.Owner.All", text: "All Page Owner" },
    ],
});

export class PageLibraryStore {
    public parentStore: PageStore;
    public pageService: PageService;

    @observable public loading: boolean = false;
    @observable public libraries: any[] = [];
    @observable public keywords: string;
    @observable public library: any;
    @observable public root: any;
    @observable public permission: any;
    @observable public scopes: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public error: any;
    @observable public saving: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public visible: boolean = false;

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });

        if (this.library) {
            builder.push({ text: "Page Libraries", key: "libraries", onClick: () => this.parentStore.rootStore.routing.push(`/settings/page-libraries`) });
            builder.push({ text: this.library.name, key: this.library.id, as: "h4", isCurrentItem: true });
        } else {
            builder.push({ text: "Page Libraries", key: "libraries", as: "h4", isCurrentItem: true });
        }

        return builder;
    }

    @computed
    public get canShare() {
        return this.permission && this.permission.canShare;
    }

    @computed
    public get isValid() {
        return this.formData && this.formData.principals && this.formData.principals.length > 0 && this.formData.roles && this.formData.roles.length > 0;
    }

    @action
    public show(options) {
        this.visible = true;
        this.formData = options.share || { roles: [] };
        this.formOptions = { roles: roles, pageTitle: this.library.name };
    }

    @action
    public hide() {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.error = null;
    }

    public loadLibraries = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null }, query);
        this.error = null;

        try {
            const result = yield this.pageService.getPageLibraries({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
            });
            this.libraries = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.keywords = result.keywords;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public loadLibrary = flow(function* (id: string) {
        this.loading = true;
        this.error = null;

        if (this.library && this.library.id !== id) {
            this.library = null;
            this.permission = null;
        }

        try {
            this.library = yield this.pageService.getPageLibrary(id);
            this.root = yield this.pageService.getPage(this.library.rootId);
            this.permission = yield this.pageService.getPageLibraryPermission(this.library.id);
            yield this.loadSharingScopes();
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public loadSharingScopes = flow(function* () {
        this.saving = true;

        try {
            if (this.permission && this.permission.canShare) {
                this.scopes = yield this.pageService.getPageLibrarySharing(this.library.id);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public onGrant = flow(function* () {
        this.saving = true;

        try {
            if (this.permission && this.permission.canShare && this.isValid) {
                const actions = [];

                this.formData.principals.forEach((p) => {
                    this.formData.roles.forEach((role) => {
                        actions.push({
                            securableId: this.library.id,
                            principalId: p.id,
                            role: role,
                            message: this.formData.message,
                        });
                    });
                });

                this.scopes = yield this.pageService.grantPageLibrarySharing(this.library.id, actions);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public onRevoke = flow(function* (action) {
        this.saving = true;

        try {
            if (this.permission && this.permission.canShare) {
                this.scopes = yield this.pageService.revokePageLibrarySharing(this.library.id, [
                    {
                        securableId: this.library.id,
                        principalId: action.principal.id,
                    },
                ]);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });
}
