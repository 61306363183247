import { observable, computed, flow } from "mobx";

import { AssetService } from "@api/assets";
import { AssetStore } from "./AssetStore";
import { AssetSelectionStore } from "./AssetSelectionStore";
import { AssetPermissionStore } from "./AssetPermissionStore";

export class AssetLifecycleStore {
    public parentStore: AssetStore;
    public assetService: AssetService;
    public selectionStore: AssetSelectionStore;
    public permissionStore: AssetPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @computed
    public get showAgentActions() {
        return this.permissionStore.isAgent;
    }

    @computed
    public get canUpload() {
        const asset = this.selectionStore.asset;

        if (!asset) {
            return false;
        }

        return this.permissionStore.canEdit;
    }

    @computed
    public get canAddComment() {
        const asset = this.selectionStore.asset;

        if (!asset) {
            return false;
        }

        return this.permissionStore.canAddComment;
    }

    @computed
    public get canCreate() {
        if (this.saving) {
            return false;
        }

        return this.permissionStore.canCreate;
    }

    @computed
    public get canCreateTriage() {
        if (this.saving) {
            return false;
        }

        const store = this.parentStore.selectionStore;
        const asset = store.asset;

        return !!asset && (store.isStakeholder || store.isAsset);
    }

    public onCreateTriage = flow(function* (options) {
        const routing = this.parentStore.rootStore.routing;
        const store = this.parentStore.selectionStore;
        const asset = store.asset;

        if (
            store.isStakeholder ||
            !asset ||
            !asset.extension ||
            !asset.extension.relAppToProduct ||
            asset.extension.relAppToProduct.length === 0
        ) {
            routing.push(`/assurance/triage?${store.isStakeholder ? "stakeholder" : "asset"}Id=${asset.id}`);
        } else {
            routing.push(
                `/assurance/triage?assetId=${asset.id}&stakeholderId=${asset.extension.relAppToProduct[0].id}`
            );
        }
    });

    @computed
    public get canCreateRisk() {
        if (this.saving) {
            return false;
        }

        const store = this.parentStore.selectionStore;
        const asset = store.asset;

        return !!asset && store.isAsset;
    }

    public onCreateRisk = flow(function* (options) {
        const routing = this.parentStore.rootStore.routing;
        const store = this.parentStore.selectionStore;
        const asset = store.asset;

        if (
            store.isStakeholder ||
            !asset ||
            !asset.extension ||
            !asset.extension.relAppToProduct ||
            asset.extension.relAppToProduct.length === 0
        ) {
            routing.push(`/risks/catalogue?${store.isStakeholder ? "stakeholder" : "asset"}Id=${asset.id}`);
        } else {
            routing.push(`/risks/catalogue?assetId=${asset.id}&stakeholderId=${asset.extension.relAppToProduct[0].id}`);
        }
    });

    public onCreate = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.newFormStore;
            const { success, formData } = yield store.show({
                ...{ asset: options.asset },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const { documents, ...asset } = formData;

                const created = yield this.assetService.createAsset({
                    ...asset,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setAsset(created);
                //yield this.parentStore.documentStore.uploadDocuments(documents, created.id, false);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `${created.type ? created.type.name : "Asset"} ${created.name} created successfully.`
                );

                store.hide();
                return { success, asset: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canEdit() {
        const asset = this.selectionStore.asset;

        if (!asset || this.saving) {
            return false;
        }

        return this.permissionStore.canEdit;
    }

    public onEdit = flow(function* (trigger, options) {
        const asset = this.selectionStore.asset;
        const supportsLifecycle = this.selectionStore.isAsset;

        if (!asset) {
            return { success: false };
        }

        try {
            const store = this.parentStore.editFormStore;
            const { success, formData } = yield store.show({
                ...{ asset, supportsLifecycle },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;
                const { documents, ...asset } = formData;

                const updated = yield this.assetService.updateAsset({
                    ...asset,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setAsset(updated);
                //yield this.parentStore.documentStore.uploadDocuments(documents, updated.id, false);

                //yield this.parentStore.commentStore.loadComments(updated.id);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `${updated.type ? updated.type.name : "Asset"} ${updated.name} updated successfully.`
                );

                store.hide();
                return { success, asset: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    /* asset type */

    @computed
    public get canCreateType() {
        if (this.saving) {
            return false;
        }

        return this.permissionStore.canCreateType;
    }

    public onCreateType = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.typeNewFormStore;
            const { success, formData } = yield store.show({
                ...{ type: options.type },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const { documents, ...type } = formData;

                const created = yield this.assetService.createAssetType({
                    ...type,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setAssetType(created);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Asset type ${created.name} created successfully.`
                );

                store.hide();
                return { success, type: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canEditType() {
        if (this.saving) {
            return false;
        }

        return this.permissionStore.canEditType;
    }

    public onEditType = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.typeEditFormStore;
            const { success, formData } = yield store.show({
                ...{ type: options.type },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.assetService.updateAssetType({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setAssetType(updated);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Asset type ${updated.name} updated successfully.`
                );

                store.hide();
                return { success, type: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    /* asset class */

    @computed
    public get canCreateClass() {
        if (this.saving) {
            return false;
        }

        return this.permissionStore.canCreateClass;
    }

    public onCreateClass = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.classNewFormStore;
            const { success, formData } = yield store.show({
                ...{ class: options.class },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.assetService.createAssetClass({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setAssetClass(created);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Asset category ${created.name} created successfully.`
                );

                store.hide();
                return { success, class: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canEditClass() {
        if (this.saving) {
            return false;
        }

        return this.permissionStore.canEditClass;
    }

    public onEditClass = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.classEditFormStore;
            const { success, formData } = yield store.show({
                ...{ class: options.class },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.assetService.updateAssetClass({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setAssetClass(updated);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Asset category ${updated.name} updated successfully.`
                );

                store.hide();
                return { success, class: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });
}
