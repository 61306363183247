import { RichText } from "@lib/RichText";
import { observable, action, computed, toJS } from "mobx";

import { RiskService } from "@api/risks";

import { RiskStore } from "./RiskStore";

export type SectionName = "Risk" | "Review";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class RiskTransferFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public section: SectionName = "Risk";

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public show(options) {
        this.visible = true;
        this.section = "Risk";

        const defaultActionName = "Transfer";
        this.formOptions = {
            actionName: options.actionName || defaultActionName,
            pageTitle: options.pageTitle || `${options.risk.code} - ${options.risk.title}`,
            pageType: options.pageType || `${options.risk.type.name} ${options.actionName || defaultActionName}`,
            typeName: options.typeName || `${options.risk.type.name}`,
            currentRegistry: options.risk.registry,
            allowReview: options.allowReview
        };

        let current = options.review;
        if (current) {
            current = toJS(current);
            current.nextReviewDue = null;
        }

        this.formData = {
            id: options.risk.id,
            registry: options.risk.registry,
            title: options.risk.title,
            description: options.risk.description,
            context: options.risk.context,
            lifecycle: {
                businessContact: options.lifecycle.businessContact,
                technicalContact: options.lifecycle.technicalContact
            },
            review: !this.formOptions.allowReview ? null : current
        };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(formData) {
        this.responseHandle.resolve(toJS(formData));
    }

    @action
    public reject(error) {
        this.responseHandle.reject(toJS(error));
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        const review = this.formData.review;

        return (
            this.isSectionValid &&
            (!this.formOptions.allowReview ||
                (review &&
                    review.grossScore.score &&
                    review.netScore.score &&
                    review.treatments &&
                    review.treatments.length == 1 &&
                    review.treatments[0].type &&
                    RichText.isNotNullOrEmpty(review.treatments[0].description) &&
                    RichText.isNotNullOrEmpty(review.grossScore.description) &&
                    RichText.isNotNullOrEmpty(review.netScore.description)))
        );
    }

    @computed
    public get isSectionValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.id &&
            this.formData.title &&
            this.formData.registry &&
            this.formData.registry.id &&
            this.formData.lifecycle &&
            this.formData.lifecycle.businessContact &&
            this.formData.lifecycle.businessContact.id &&
            this.formData.lifecycle.technicalContact &&
            this.formData.lifecycle.technicalContact.id &&
            RichText.isNotNullOrEmpty(this.formData.description) &&
            RichText.isNotNullOrEmpty(this.formData.context)
        );
    }

    @action
    public nextSection() {
        if (this.formOptions.allowReview && this.section == "Risk") {
            this.section = "Review";
        }
    }

    @action
    public previousSection() {
        if (this.formOptions.allowReview && this.section == "Review") {
            this.section = "Risk";
        }
    }
}
