import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { cx, css} from 'emotion';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

const dataSourceDivClassName = cx(css`
border: 1px solid black;
padding: 5px;
min-width: 250px;

.dataSourceDropDownContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.ms-Dropdown {
    width: 200px;
    margin-right: 5px;
}
`);

export const StackedBarEditor = observer(
  class StackedBarEditor extends PureComponent {
    render () {
        const { formData, dataSources, dataSourceStore, instance} = this.props;
        const { coreDataSources, selectionStore } = dataSourceStore;
        return (
            <r-grid columns="2">                
                {formData.properties.dataSeries && formData.properties.dataSeries.length > 0 && formData.properties.dataSeries.map((DS,i) => {

                    const selectedPartDataSource = DS.dataSourceId ? dataSources.find(D => D.id === DS.dataSourceId) : null;
                    const selectedCoreDataSource = selectedPartDataSource ? dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource) : null;

                    return(
                    <div className={dataSourceDivClassName} key={`DS_${i}`}>
                        <r-cell span="2">
                            <div className="dataSourceDropDownContainer">
                                <Dropdown
                                    required
                                    label="Data source"
                                    selectedKey={DS.dataSourceId}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            DS.dataSourceId = op.key;
                                            const selectedPartDataSource = DS.dataSourceId ? dataSources.find(D => D.id === DS.dataSourceId) : null;
                                            const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
                                            DS.isDynamic = (selectedCoreDataSource.type === 'dynamicGroup') ? true : false;
                                            if (selectedCoreDataSource.type === 'dynamicGroup') {
                                                formData.properties.dataSeries = [formData.properties.dataSeries.pop()];
                                            }
                                        }
                                    }}
                                    options={dataSources
                                            .filter(DSource => {
                                                const selectedDataSeries = formData.properties.dataSeries.filter(D => D.dataSourceId !== null).length;
                                                if (selectedDataSeries > 0 && i > 0)  {
                                                    const selectedPartDataSource = DSource.id ? dataSources.find(D => D.id === DSource.id) : null;
                                                    const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
                                                    if (selectedCoreDataSource.type === 'dynamicGroup') return false
                                                }
                                                return true
                                            })
                                            .map(DSource => {
                                                return {
                                                    key: DSource.id,
                                                    text: DSource.title,
                                                }
                                    })}                                            
                                />
                                {DS.dataSourceId && <TooltipHost
                                    content="Edit data source"
                                    id={`editTooltipId`}
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{display:'inline-block', maginLeft: '5px'}}
                                >
                                    <IconButton 
                                        iconProps={{ iconName: 'Edit' }}
                                        title="Edit"
                                        ariaLabel="Edit"
                                        onClick={() => {
                                                const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                                if (idx > -1) {
                                                    const pagePart = instance.selectionStore.getPagePart(DS.dataSourceId);
                                                    instance.lifecycleStore.onEditPart(pagePart.section, pagePart.zone, pagePart.part);
                                                }
                                            }
                                        }
                                    />
                                </TooltipHost> }
                            </div>
                        </r-cell>
                        {selectedCoreDataSource && <r-grid columns="2">
                            
                            { selectedCoreDataSource.type !== 'dynamicGroup' && <r-cell span="2">
                                <div>
                                    <Dropdown
                                        required
                                        label="Field type"
                                        selectedKey={DS.fieldType || 'data'}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.fieldType = op.key;
                                            }
                                        }}
                                        options={[
                                            {
                                                key: 'data',
                                                text: 'Data',
                                            },
                                            {
                                                key: 'xField',
                                                text: 'X Field',
                                            }
                                        ]}
                                    />
                                </div>
                                    </r-cell> }
                            { selectedCoreDataSource.type === 'dynamicGroup' &&  <r-cell span="2">
                                <div>
                                    <Dropdown
                                        required
                                        label="Stack by"
                                        selectedKey={DS.stackByField}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.stackByField = op.key;
                                                if (DS.label === 'Label') DS.label = op.text;
                                            }
                                        }}
                                        options={selectedCoreDataSource.fields.map(F => {
                                            return {
                                                key: F.id,
                                                text: F.title,
                                            }
                                        })}
                                    />
                                </div>
                            </r-cell> }
                            { selectedCoreDataSource.type === 'dynamicGroup' &&  <r-cell span="2">
                                <div>
                                    <Dropdown
                                        required
                                        label="Value field"
                                        selectedKey={DS.valueField}
                                        onChange={(ev, op) => {
                                            if(op) {                                                
                                                DS.valueField = op.key;
                                            }
                                        }}
                                        options={selectedCoreDataSource.fields.map(F => {
                                            return {
                                                key: F.id,
                                                text: F.title,
                                            }
                                        })}
                                    />
                                </div>
                            </r-cell> }
                            <r-cell span="2">
                                <div>
                                    <Dropdown
                                        required
                                        label={(selectedCoreDataSource.type === 'dynamicGroup')?"X Field":"Field"}
                                        selectedKey={DS.dataX}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.dataX = op.key;
                                                if (DS.label === 'Label') DS.label = op.text;
                                            }
                                        }}
                                        options={selectedCoreDataSource.fields.map(F => {
                                            return {
                                                key: F.id,
                                                text: F.title,
                                            }
                                        })}
                                    />
                                </div>
                            </r-cell>
                            <r-cell span="2">
                                <div>
                                    <TextField 
                                        label="Label"
                                        required
                                        value={DS.label}
                                        onChange={(ev, val) => DS.label = val} 
                                    />
                                </div>
                            </r-cell>
                        </r-grid>}
                        <r-cell span="2">
                            <DefaultButton text="Remove data series" styles={{marginTop:'5px'}} onClick={() => {
                                const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                if (idx > -1) {
                                    formData.properties.dataSeries.splice(idx, 1);
                                }
                            }} />
                        </r-cell>
                    </div>)})}
            </r-grid>
      );
    }
  }
);
