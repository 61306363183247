import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import ContainerDimensions from 'react-container-dimensions';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import RichTextViewer from '../../../../../editors/components/RichTextViewer';
import { PagePartPlaceholder } from  '../../../../../pages/components/elements/PagePartPlaceholder';
import { Button } from '../../../../../editors/components/helpers';
import { DefaultButton,  } from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { ManualInsightContainer } from '../common/ManualInsightContainer';

const RAGClassName= cx(css`
display:flex;
align-items: center;
& .RAGIndicator {
  cursor: 'context-menu';
  width: 32px;
  margin-right: 16px;
}
& .ms-TextField-fieldGroup {
  border: 0px;
}

`);
class RagStatusSnapshotViewerPartClass extends PureComponent {

  get isReady() {
    return this.isConfigured
  }

  get isConfigured() {
    return true
  }

  get RAGColor() {
    const { part } = this.props;

    switch (part.properties.RAGColor) {
      case 'green': return 'green';
      case 'amber': return '#FFBF00';
      case 'red': return 'red';
    }

  }

  get RAGIndicator() {
    const { part } = this.props;

    switch (part.properties.RAGStyle) {
      case 'circle': 
          return <TextField 
              readOnly
              className="RAGIndicator"
              style={{
                color: this.RAGColor,
                backgroundColor: this.RAGColor,
                borderRadius: '50%',
                border: '0px',
              }}
          />
      case 'arrow':
        let icon = null;
        switch (part.properties.RAGColor) {
          case 'amber':
            icon = 'Split';
          break;
          case 'red':
            icon = 'SortDown';
          break;
          default:
          case 'green': 
            icon = 'SortUp';
        }

        return <FontIcon
          iconName={icon}
          style={{
            color: this.RAGColor,
            fontSize: 32,
            height: 32,
            width: 32,
            marginRight: 32
          }}
        />
        case 'noChangeArrow':
          return <FontIcon
            iconName={'Split'}
            style={{
              color: this.RAGColor,
              fontSize: 32,
              height: 32,
              width: 32,
              marginRight: 32
            }}
          />
        case 'downArrow':
          return <FontIcon
            iconName={'SortDown'}
            style={{
              color: this.RAGColor,
              fontSize: 32,
              height: 32,
              width: 32,
              marginRight: 32
            }}
          />
        case 'upArrow':
          return <FontIcon
            iconName={'SortUp'}
            style={{
              color: this.RAGColor,
              fontSize: 32,
              height: 32,
              width: 32,
              marginRight: 32
            }}
          />
        case 'square': 
          return <TextField 
              readOnly
              className="RAGIndicator"
              style={{
                color: this.RAGColor,
                backgroundColor: this.RAGColor,
                border: '0px',
              }}
          />
        default:
          return <></>
      } 
  }

  render () {
    const { className, instance, part, themeProvider, dataSourceStore, ragStatusStore } = this.props;
    const mode = instance.lifecycleStore.mode;
    const theme = themeProvider.getTheme();
    return (
      <ManualInsightContainer
        className={className}
        instance={instance}
        part={part}
        theme={theme}
        mode={mode}
        isReady={this.isReady}
        isConfigured={this.isConfigured}
      >
        <div className={cx('ragSnapshot',RAGClassName)}>
          {this.RAGIndicator}
          <Text block>{part.properties.RAGLabel}</Text>
        </div>
      </ManualInsightContainer>
    );
  }
}

export const RagStatusSnapshotViewerPart = inject('themeProvider','dataSourceStore')(observer(RagStatusSnapshotViewerPartClass));
