import { ServiceBase, Constants } from "../graph/serviceBase";

export class PropertyService extends ServiceBase {
    public async getPropertySections(options) {
        return this.get(
            `${Constants.baseUrl}/property-sections?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchPropertySections(options) {
        return this.post(`${Constants.baseUrl}/property-sections/search`, options);
    }

    public async getPropertySection(id) {
        return this.get(`${Constants.baseUrl}/property-sections/${id}`);
    }

    public async createPropertySection(data) {
        return this.post(`${Constants.baseUrl}/property-sections`, data);
    }

    public async updatePropertySection(data) {
        return this.put(`${Constants.baseUrl}/property-sections/${data.id}`, data);
    }

    public async deletePropertySection(id) {
        return this.delete(`${Constants.baseUrl}/property-sections/${id}`, {});
    }

    public async getProperties(options) {
        return this.get(
            `${Constants.baseUrl}/properties?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchProperties(options) {
        return this.post(`${Constants.baseUrl}/properties/search`, options);
    }

    public async getProperty(id) {
        return this.get(`${Constants.baseUrl}/properties/${id}`);
    }

    public async createProperty(data) {
        return this.post(`${Constants.baseUrl}/properties`, data);
    }

    public async updateProperty(data) {
        return this.put(`${Constants.baseUrl}/properties/${data.id}`, data);
    }

    public async deleteProperty(id) {
        return this.delete(`${Constants.baseUrl}/properties/${id}`, {});
    }
}
