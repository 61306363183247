import { observable, computed, flow, action } from "mobx";
import download from "downloadjs";

import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";
import { AssessmentPermissionStore } from "./AssessmentPermissionStore";

export class AssessmentLifecycleStore {
    private assessmentService: AssessmentService;
    public parentStore: AssessmentStore;
    public permissionStore: AssessmentPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.permissionStore = parentStore.permissionStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed get displayAdvisoriesFilterBar() {
        return this.parentStore.advisoryStore.displayFilterBar;
    }

    @computed
    public get canViewResourcePages() {
        return this.permissionStore.canViewResourcePages;
    }

    @computed
    public get canViewCalendarPages() {
        return this.permissionStore.canViewCalendarPages;
    }

    @computed
    public get canViewAdvisoryPages() {
        return this.permissionStore.canViewAdvisoryPages;
    }

    @computed
    public get isReadOnly() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        var readOnly = assessment.state != "Draft" && assessment.state != "Started";

        if (!readOnly) {
            return !this.permissionStore.canUpdateValues;
        }

        return readOnly;
    }

    @computed
    public get showSubmissionSuccessful() {
        const assessment = this.parentStore.selectionStore.assessment;
        const user = this.permissionStore.principalContext.current;

        if (!assessment || !user) {
            return false;
        }

        return (
            assessment.state == "Remediation" &&
            assessment.submittedBy &&
            assessment.submittedBy.id == user.id &&
            !this.permissionStore.isAssignedToUser
        );
    }

    @computed
    public get isRisksTabAvailable() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state == "Reviewing" || assessment.state == "Closed";
    }

    @computed
    public get isAdvisoriesTabAvailable() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state == "Remediation" || assessment.state == "Reviewing" || assessment.state == "Closed";
    }

    @computed
    public get isModulesComplete() {
        const modules = this.parentStore.viewerStore.modules;

        if (!modules) {
            return false;
        }

        return !modules.find((m) => m.percentComplete < 100);
    }

    @computed
    public get canStart() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state == "Draft" && this.permissionStore.canStart;
    }

    public onStart = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.startAssessment(assessment.id);
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewerStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
        }

        this.saving = false;
    });

    @computed
    public get canSubmit() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return (
            (assessment.state == "Draft" || assessment.state == "Started") &&
            this.isModulesComplete &&
            this.permissionStore.canSubmit
        );
    }

    public onSubmit = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.submitAssessment(assessment.id);
            // debugger
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Assessment ${assessment.code} has been sent for review.`
            );
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.advisoryStore.loadAssessmentAdvisories(updated.id);
            this.parentStore.viewerStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
        }

        this.saving = false;
    });

    @computed
    public get canReview() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { allAdvisoriesClosed, allRequiredEvidencesProvided, advisories } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        const count = advisories.filter((advisory) => {
            return advisory.state == "Closed" && advisory.finalCompliance == "NonCompliant";
        }).length;

        return (
            assessment.state == "Remediation" &&
            allAdvisoriesClosed &&
            allRequiredEvidencesProvided &&
            count > 0 &&
            this.permissionStore.canReview
        );
    }

    public onReview = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.reviewAssessment(assessment.id);
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewerStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
            this.parentStore.rootStore.layoutStore.displayToastNotification(error.message);
        }

        this.saving = false;
    });

    @computed
    public get canClose() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { allAdvisoriesClosed, allRequiredRisksRaised, allRequiredEvidencesProvided } =
            this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            (assessment.state == "Reviewing" || assessment.state == "Remediation") &&
            allAdvisoriesClosed &&
            allRequiredRisksRaised &&
            (assessment.state != "Remediation" || allRequiredEvidencesProvided) &&
            this.permissionStore.canClose
        );
    }

    public onClose = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.closeAssessment(assessment.id);
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Assessment ${assessment.code} has been closed`
            );
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewerStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
            this.parentStore.rootStore.layoutStore.displayToastNotification(error.message);
        }

        this.saving = false;
    });

    @computed
    public get canCancel() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state != "Closed" && assessment.state != "Cancelled" && this.permissionStore.canCancel;
    }

    public onCancel = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.cancelAssessment(assessment.id);
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewerStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
        }

        this.saving = false;
    });

    @computed
    public get canUpdateAdvisories() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { selectedAdvisories, requiredEvidencesProvided } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            assessment.state == "Remediation" &&
            selectedAdvisories &&
            selectedAdvisories.count > 0 &&
            requiredEvidencesProvided &&
            this.permissionStore.canUpdateAdvisories
        );
    }

    @computed
    public get canAddEvidence() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { selectedAdvisories } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            assessment.state == "Remediation" &&
            selectedAdvisories &&
            selectedAdvisories.count > 0 &&
            this.permissionStore.canAddEvidence
        );
    }

    @computed
    public get canAddRisk() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { selectedAdvisories } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            assessment.state == "Reviewing" &&
            selectedAdvisories &&
            selectedAdvisories.count > 0 &&
            this.permissionStore.canUpdateAdvisories
        );
    }

    @computed
    public get canExportCsv() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return this.permissionStore.canRead;
    }

    public onExportCsv = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const blob = yield this.assessmentService.downloadAssessmentValues(assessment.id);
            download(blob, `${assessment.code}.csv`, "text/csv");
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canUpload() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state != "Closed" && assessment.state != "Cancelled" && this.permissionStore.canUpload;
    }

    @computed
    public get canAssign() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state != "Closed" && assessment.state != "Cancelled" && this.permissionStore.canAssign;
    }

    public onAssign = flow(function* (trigger, options) {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return { success: false };
        }

        try {
            const store = this.parentStore.assignFormStore;
            const { success, formData } = yield store.show({
                ...{ assessment },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.assessmentService.assignAssessment({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                yield this.parentStore.selectionStore.setSelected(updated);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Assessment ${assessment.code} assigned to ${updated.assignedToUser.name} from ${updated.assignedToGroup.name}`
                );

                store.hide();
                return { success, assessment: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public onReport = flow(function* () {
        const assessment = this.parentStore.selectionStore.assessment;
        this.parentStore.rootStore.routing.push(`/assurance/browse/${assessment.id}/report`);
    });

    public hideAdvisoriesFilterBar = () => {
        this.parentStore.advisoryStore.displayFilterBar = false;
    };

    @computed
    public get canAddComments() {
        return this.permissionStore.canAddComments;
    }

    @computed
    public get canManageTasks() {
        return this.permissionStore.canManageTasks;
    }

    @computed
    public get canManageAutomations() {
        return this.permissionStore.canManageAutomations;
    }

    @computed
    public get canManageUseCases() {
        return this.permissionStore.canManageUseCases;
    }

    @computed
    public get canManageControlModules() {
        return this.permissionStore.canManageControlModules;
    }

    @computed
    public get canManageControlQuestions() {
        return this.permissionStore.canManageControlQuestions;
    }

    @computed
    public get canManageAssessmentRules() {
        return this.permissionStore.canManageAssessmentRules;
    }

    public onCreateControlQuestion = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.questionNewFormStore;
            const { success, formData } = yield store.show({
                ...{ question: options.question },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.assessmentService.createControlQuestion({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Control question '${created.title}' created successfully.`
                );

                store.hide();
                yield this.parentStore.questionBrowseStore.loadControlQuestions({});

                return { success, question: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onEditControlQuestion = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.questionEditFormStore;
            const { success, formData } = yield store.show({
                ...{ question: options.question },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.assessmentService.updateControlQuestion({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Control question '${created.title}' updated successfully.`
                );

                store.hide();

                yield this.parentStore.questionBrowseStore.loadControlQuestions({});
                return { success, question: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onCreateControlModule = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.moduleNewFormStore;
            const { success, formData } = yield store.show({
                ...{ module: options.module },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.assessmentService.createControlModule({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Control module '${created.name}' created successfully.`
                );

                store.hide();
                yield this.parentStore.moduleBrowseStore.loadControlModules({});

                return { success, module: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onEditControlModule = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.moduleEditFormStore;
            const { success, formData } = yield store.show({
                ...{ module: options.module },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.assessmentService.updateControlModule({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Control module '${updated.name}' updated successfully.`
                );

                store.hide();

                yield this.parentStore.moduleBrowseStore.loadControlModules({});
                return { success, module: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onCreateUseCase = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.useCaseNewFormStore;
            const { success, formData } = yield store.show({
                ...{ useCase: options.useCase },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.assessmentService.createUseCase({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Use case '${created.name}' created successfully.`
                );

                store.hide();
                yield this.parentStore.useCaseBrowseStore.loadUseCases({});

                return { success, useCase: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onEditUseCase = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.useCaseEditFormStore;
            const { success, formData } = yield store.show({
                ...{ useCase: options.useCase },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.assessmentService.updateUseCase({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Use case '${updated.name}' updated successfully.`
                );

                store.hide();

                yield this.parentStore.useCaseBrowseStore.loadUseCases({});
                return { success, useCase: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    /* assessment rules */

    public onCreateAssessmentRule = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.ruleNewFormStore;
            const { success, formData } = yield store.show({
                ...{ rule: options.rule },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.assessmentService.createAssessmentRule({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Assessment rule '${created.title}' created successfully.`
                );

                store.hide();
                yield this.parentStore.ruleBrowseStore.loadAssessmentRules({});

                return { success, rule: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onEditAssessmentRule = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.ruleEditFormStore;
            const { success, formData } = yield store.show({
                ...{ rule: options.rule },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.assessmentService.updateAssessmentRule({
                    ...formData,
                    triggerId: trigger.id,
                });

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Assessment rule '${created.title}' updated successfully.`
                );

                store.hide();

                yield this.parentStore.ruleBrowseStore.loadAssessmentRules({});
                return { success, rule: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });
}
