import { observable, computed, flow } from "mobx";
import moment from "moment";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

const UnassignedResourceId = "unassigned";

export class AssessmentProductStore {
    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;

    @observable public assessments: any[] = [];
    @observable public keywords: string = "";
    @observable public view: string = "Month";
    @observable public loading: boolean = false;
    @observable public date: Date = new Date();
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        { text: "Assurance Management", key: "assurance", onClick: () => this._goToUrl(`/assurance/dashboard`) },
        { text: "Product Timeline", key: "products", isCurrentItem: true },
    ];

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed
    public get events() {
        const now = moment.utc();
        const threeWeeksFromNow = now.add(3, "weeks").toDate();
        return this.assessments.map((a) => {
            let colour = "var(--green-9)";
            if (a.priority === "Medium") {
                colour = "var(--blue-9)";
            } else if (a.priority === "High") {
                colour = "var(--red-9)";
            }
            return {
                id: a.id,
                title: `${a.code} - ${a.title}`,
                start: a.started,
                end: a.closed
                    ? a.closed
                    : a.estimatedClose
                    ? a.estimatedClose
                    : now.isAfter(a.dueDate)
                    ? threeWeeksFromNow
                    : a.dueDate,
                assessment: { ...a },
                resourceId: `${a.context ? a.context.id : "not-set"}-${a.priority}`,
                backgroundColor: colour,
                borderColor: colour,
            };
        });
    }

    @computed
    public get resources() {
        if (this.assessments.length == 0) {
            return [];
        }

        const resourceMap = {};

        this.assessments.forEach((assessment) => {
            const resourceId = `${assessment.context ? assessment.context.id : "not-set"}-${assessment.priority}`;

            if (!resourceMap[resourceId]) {
                resourceMap[resourceId] = {
                    id: resourceId,
                    title: assessment.priority,
                    portfolioId: assessment.context ? assessment.context.id : "not-set",
                    groupId: assessment.context ? assessment.context.name : "Not Set",
                };
            }
        });

        return Object.values(resourceMap).sort((a: any, b: any) => {
            var nameA = a.groupId.toUpperCase();
            var nameB = b.groupId.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    public loadAssessments = flow(function* (query) {
        var options = Object.assign({ keywords: null, view: "Month" }, query);
        this.loading = true;
        //this.assessments = [];

        this.view = options.view;
        this.date = moment.utc(options.date).toDate();

        try {
            const result = yield this.assessmentService.searchAssessments({
                pageSize: 250,
                startIndex: 0,
                query: {
                    keywords: options.keywords,
                    status: ["Started", "Remediation", "Reviewing", "Closed"],
                    timeline: {
                        after: options.start,
                        before: options.end,
                    },
                },
            });
            this.assessments = result.items;
            this.keywords = result.keywords;
            this.loading = false;
            return result;
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
