export const RichText = {
    isNotNullOrEmpty: (content) => {
        return (
            content &&
            content.length > 0 &&
            (content.length != 1 ||
                content[0].type != "paragraph" ||
                content[0].children.length > 1 ||
                content[0].children.length == 0 ||
                content[0].children[0].text)
        );
    },
    toJsonFromString: (content) => {
        return content
            ? content.split("\n").map((s) => ({
                  type: "paragraph",
                  children: [{ text: s }],
              }))
            : null;
    },
    toStringFromJson: (content) => {
        return content && content.length > 0
            ? content
                  .filter((s) => s.type === "paragraph")
                  .map((s) => s.children.map((c) => c.text).join(" "))
                  .join("\n")
            : null;
    },
};
