import { observable, action, computed } from "mobx";

import { StateFlowStore } from "./StateFlowStore";

export class StateFlowEditFormStore {
    public parentStore: StateFlowStore;

    @observable public formData: any = null;
    @observable public formOptions: any = null;
    @observable public snapshot: any = null;
    @observable public visible: boolean = false;
    @observable public processing: boolean = false;

    public options: any;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.formData);
    }

    @computed
    public get isValid() {
        return (
            this.formData &&
            this.formData.id &&
            this.formData.name &&
            this.formData.stages &&
            this.formData.stages.length >= 0 &&
            this.formData.states &&
            this.formData.states.length >= 0
        );
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        this.snapshot = options.stateFlow;
        this.formData = JSON.parse(JSON.stringify(options.stateFlow));
        this.formOptions = {
            title: options.title || "Edit Workflow",
        };
        this.visible = true;
        this.processing = false;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.formData = null;
        this.formOptions = null;
        this.visible = false;
        this.processing = false;
    }

    @action
    public onChange(state) {
        this.formData = state;
    }

    @action
    public resolve(success) {
        this.processing = success;
        this.options.resolve({ success, stateFlow: this.formData });
    }

    @action
    public reject(error) {
        this.options.reject({ success: false, error });
    }
}
