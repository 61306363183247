import { observable, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskPriorityStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public risks: any[] = [];
    @observable public statusList: any[] = [];
    @observable public portfolios: any[] = [];
    @observable public assignedToObjects: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks",
            onClick: () => this._goToUrl(`/risks/dashboard`),
        },
        { text: "Priority Risks", key: "priority", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    public loadSubStatuses = flow(function* () {
        try {
            this.statusList = yield this.parentStore.riskFilterDataStore.loadSubStatuses(false);
        } catch (e) {
            console.error(e);
            this.error = e;
            this.statusList = [];
        }
    });

    public loadPortfolios = flow(function* () {
        try {
            this.portfolios = yield this.parentStore.riskFilterDataStore.loadPortfolios();
        } catch (e) {
            console.error(e);
            this.error = e;
            this.portfolios = [];
        }
    });

    loadAssignedUser = flow(function* (ids: any = null) {
        try {
            const principals = ids ? ids : [];
            if (principals.length > 0) {
                this.assignedToObjects = yield this.parentStore.rootStore.pickerStore.loadPrincipals(
                    principals.map((U) => U.id)
                );
            } else {
                this.assignedToObjects = [];
            }
        } catch (e) {
            this.assignedTo = [];
            console.error(e);
        }
    });

    public loadRisks = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null }, query);

        this.query = {
            ...options.query,
        };

        try {
            const result = yield this.riskService.searchRisks({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
                query: {
                    ...this.query,
                    ...{
                        grossScore: ["Critical"],
                        sort: [{ property: "grossScoreCalculated", asc: false }],
                    },
                    ...{ type: [{ template: "zerodai:risks:generic" }] },
                },
            });
            this.risks = result.items;
            const { type, grossScore, sort, ...rest } = result.query;
            this.query = rest;

            this.keywords = result.query.keywords;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public exportRisks = flow(function* (query) {
        try {
            const options = Object.assign({ page: 1, keywords: null }, query);
            const result = yield this.riskService.exportRisks({
                pageSize: 100,
                startIndex: 0,
                query: {
                    ...this.query,
                    ...options.query,
                    ...{
                        grossScore: ["Critical"],
                        sort: [{ property: "grossScoreCalculated", asc: false }],
                    },
                    ...{ type: [{ template: "zerodai:risks:generic" }] },
                },
            });

            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Risk export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
