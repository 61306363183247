import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { cx, css} from 'emotion';
import { TextField } from '@ui/elements/TextField';
import { Dropdown } from '@ui/elements/Dropdown';
import { Toggle } from '@ui/elements/Toggle';
import { DefaultButton, IconButton } from '@ui/elements/Button';
import { TooltipHost } from '@ui/elements/Tooltip';

import { ObservableSeparator } from '@baseComponents/ObservableSeparator';


const dataSourceDivClassName = cx(css`

border: 1px solid black;
padding: 5px;
min-width: 250px;

.dataSourceDropDownContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.ms-Dropdown {
    width: 200px;
    margin-right: 5px;
}
`);

export const ControlMapPartEditor = inject('dataSourceStore','controlStore')(observer(
  class ControlMapPartEditor extends PureComponent {

    componentWillMount() {
        this.loadData(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.loadData(nextProps)
    }

    loadData(props) {
        const { controlStore, formData } = props;
        controlStore.mapBrowseStore.loadControlMaps();
    }

    render () {
        const { formData, controlStore, dataSourceStore, dataSources, instance} = this.props;        
        const { maps } = controlStore.mapBrowseStore;
        const { model } = controlStore.mapViewerStore;
        const { coreDataSources, selectionStore } = dataSourceStore;
        // const { coreDataSources, selectionStore } = dataSourceStore;
        // const map = maps.find(M => M.id === formData.properties.controlMapId);
        // console.log( instance );
        return (
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <ObservableSeparator>Control Map Settings</ObservableSeparator>
                    </r-cell>
                    <r-cell span="2">
                        <Dropdown
                            required
                            label="Control Map"
                            selectedKey={formData.properties.controlMapId}
                            onChange={(ev, op) => {
                                if(op) {
                                    formData.properties.controlMapId = op.key;
                                    if (formData.properties.controlMapId) {
                                        controlStore.mapViewerStore.loadControlMap({ mapId: formData.properties.controlMapId }).then(()=>{
                                            formData.properties.selectedAxes = controlStore.mapViewerStore.model.axes.map(A => A.id)
                                        })                            
                                    }
                                }
                            }}
                            options={maps.map(M => {
                                return {
                                    key: M.id,
                                    text: M.name
                                }
                            })}
                        />
                    </r-cell>
                    <r-cell span="2">
                        <r-grid columns="2">
                            <r-cell span="1">
                                <Toggle label="Show export to PNG" checked={formData.properties.showPNG} onChange={(ev, checked) => {
                                    formData.properties.showPNG = checked;
                                }} />
                            </r-cell>
                            <r-cell span="1">
                                <Toggle label="Show export to CSV" checked={formData.properties.showCSV} onChange={(ev, checked) => {
                                    formData.properties.showCSV = checked;
                                }}  />
                            </r-cell>
                        </r-grid>
                    </r-cell>
                    { formData.properties.controlMapId && model && model.axes && <r-cell span="2">
                        <Dropdown
                            multiSelect
                            label="Displayed Axes"
                            selectedKeys={formData.properties.selectedAxes}
                            onChange={(ev, item) => {
                                if (item) {
                                    if (!formData.properties.selectedAxes) formData.properties.selectedAxes = [];
                                    formData.properties.selectedAxes = item.selected ? [...formData.properties.selectedAxes, item.key] : formData.properties.selectedAxes.filter(key => key !== item.key);
                                }
                            }}
                            options={model.axes.map(A => {
                                return {
                                    key: A.id,
                                    text: A.name
                                }
                            })}
                        />
                    </r-cell>
                    }

                    { formData.properties.controlMapId && <r-cell span="2">
                        <DefaultButton text="Add data series" onClick={() => {
                            if (!formData.properties.dataSeries) formData.properties.dataSeries = [];
                            formData.properties.dataSeries.push({
                                dataSourceId: null,
                                label: 'New Data Series',
                                id: uuidv4(),
                            })
                        }} />
                    </r-cell>}
                    <r-grid columns="2">
                {formData.properties.dataSeries && formData.properties.dataSeries.length > 0 && formData.properties.dataSeries.map((DS,i) => {

                    // const selectedPartDataSource = DS.dataSourceId ? dataSources.find(D => D.id === DS.dataSourceId) : null;
                    // const selectedCoreDataSource = selectedPartDataSource ? dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource) : null;

                    return(
                    <div className={dataSourceDivClassName}>
                        <r-cell span="2">
                            <div className="dataSourceDropDownContainer">
                                <Dropdown                               
                                    required
                                    label="Data source"
                                    selectedKey={DS.dataSourceId}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            DS.dataSourceId = op.key;
                                        }
                                    }}
                                    options={
                                        dataSources
                                            .filter(DSource => {
                                                const selectedDataSeries = formData.properties.dataSeries.filter(D => D.dataSourceId !== null).length;
                                                return (DSource.properties.mainSource === "dataSource:controlMaps:controls")
                                            })
                                            .map(DSource => {
                                                return {
                                                    key: DSource.id,
                                                    text: DSource.title,
                                                }
                                        })
                                    }
                                />
                                {DS.dataSourceId && <TooltipHost
                                        content="Edit data source"
                                        id={`editTooltipId`}
                                        calloutProps={{ gapSpace: 0 }}
                                        styles={{display:'inline-block', maginLeft: '5px'}}
                                    >
                                        <IconButton 
                                            iconProps={{ iconName: 'Edit' }}
                                            title="Edit"
                                            ariaLabel="Edit"
                                            onClick={() => {
                                                    const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                                    if (idx > -1) {
                                                        const pagePart = instance.selectionStore.getPagePart(DS.dataSourceId);
                                                        instance.lifecycleStore.onEditPart(pagePart.section, pagePart.zone, pagePart.part);
                                                    }
                                                }
                                            }
                                        />
                                    </TooltipHost> }
                            </div>
                        </r-cell>
                        {DS.dataSourceId && <r-grid columns="2">
                            <r-cell span="2">
                                <div>
                                    <TextField 
                                        label="Label"
                                        required
                                        value={DS.label}
                                        onChange={(ev, val) => DS.label = val}
                                    />
                                </div>
                            </r-cell>
                        </r-grid>}
                        <r-cell span="2">
                            <DefaultButton text="Remove data series" styles={{marginTop:'5px'}} onClick={() => {
                                const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                if (idx > -1) {
                                    formData.properties.dataSeries.splice(idx, 1);
                                }
                            }} />
                        </r-cell>
                    </div>)})}
            </r-grid>
                </r-grid>
          </div>
      );
    }
  }
));
