const upcomingActivitiesResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { due: "Today", asset: "SAP", type: "Risk" },
                { due: "2 Days", asset: "Xero", type: "Assurance" },
                { due: "5 Days", asset: "Delivery Router", type: "Pen Test" },
            ],
            query,
        });
    });
};

const openDemandsResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { label: "Pen testing", count: 7 },
                { label: "Assurance", count: 13 },
                { label: "Perimeter Security", count: 1 },
                { label: "Patterns and Blueprints", count: 4 },
                { label: "Data Privacy Impact Assessments", count: 6 },
            ],
            query,
        });
    });
};

const openDemandsStatusResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { label: "Triage", count: 20 },
                { label: "Deep Dive", count: 21 },
                { label: "Remediation", count: 11 },
                { label: "Risk Review", count: 5 },
            ],
            query,
        });
    });
};

const demandsResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { period: "Dec 2021", time: "2021-12-01", totalOpen: 5, opened: 3, closed: 2 },
                { period: "Jan 2022", time: "2022-01-01", totalOpen: 8, opened: 5, closed: 2 },
                { period: "Feb 2022", time: "2022-02-01", totalOpen: 4, opened: 1, closed: 5 },
                { period: "Mar 2022", time: "2022-03-01", totalOpen: 6, opened: 8, closed: 6 },
                { period: "Apr 2022", time: "2022-04-01", totalOpen: 14, opened: 12, closed: 4 },
                { period: "May 2022", time: "2022-05-01", totalOpen: 3, opened: 2, closed: 13 },
            ],
            query,
        });
    });
};

const totalIncomingRequestsResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { period: "Jun 2021", incomingRequests: 100 },
                { period: "Jul 2021", incomingRequests: 120 },
                { period: "Aug 2021", incomingRequests: 110 },
                { period: "Sep 2021 ", incomingRequests: 105 },
                { period: "Oct 2021 ", incomingRequests: 115 },
                { period: "Nov 2021 ", incomingRequests: 135 },
                { period: "Dec 2021 ", incomingRequests: 180 },
                { period: "Jan 2022 ", incomingRequests: 160 },
                { period: "Feb 2022 ", incomingRequests: 120 },
                { period: "Mar 2022 ", incomingRequests: 110 },
                { period: "Apr 2022 ", incomingRequests: 115 },
                { period: "May 2022 ", incomingRequests: 105 },
            ],
            query,
        });
    });
};

const engagmentResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { label: "Mar 2022", count: 25 }, //Display
                { label: "Apr 2022", count: 17 }, //Calculate
                { label: "May 2022", count: 25 },
            ],
            query,
        });
    });
};

const FoodKeyContactsResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { name: "John Smith", jobTitle: "Assurance Lead" },
                { name: "Mike Roberts", jobTitle: "Portfolio Lead" },
                { name: "Sarah Spellman", jobTitle: "BISO" },
            ],
            query,
        });
    });
};

const CISOEngagementTotalResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { label: "Apr 2022", count: 156 }, //Display
                { label: "May 2022", count: 100 }, //Calculate
            ],
            query,
        });
    });
};

const CISOPortfoliosAtGlanceResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                {
                    portfolio: "Logistics",
                    opened: 20,
                    closed: 15,
                    cost: 9000,
                    penTesting: 2,
                    assurance: 5,
                    perimeterSecurity: 4,
                    patternsAndBlueprints: 4,
                    dataPrivacyImpactAssessments: 5,
                },
                {
                    portfolio: "Cyber",
                    opened: 23,
                    closed: 21,
                    cost: 26000,
                    penTesting: 4,
                    assurance: 6,
                    perimeterSecurity: 4,
                    patternsAndBlueprints: 4,
                    dataPrivacyImpactAssessments: 5,
                },
                {
                    portfolio: "Food",
                    opened: 31,
                    closed: 27,
                    cost: 22000,
                    penTesting: 7,
                    assurance: 13,
                    perimeterSecurity: 1,
                    patternsAndBlueprints: 7,
                    dataPrivacyImpactAssessments: 3,
                },
                {
                    portfolio: "Retail",
                    opened: 37,
                    closed: 12,
                    cost: 18000,
                    penTesting: 7,
                    assurance: 25,
                    perimeterSecurity: 1,
                    patternsAndBlueprints: 2,
                    dataPrivacyImpactAssessments: 2,
                },
                {
                    portfolio: "Marketing",
                    opened: 49,
                    closed: 47,
                    cost: 26000,
                    penTesting: 7,
                    assurance: 2,
                    perimeterSecurity: 13,
                    patternsAndBlueprints: 5,
                    dataPrivacyImpactAssessments: 25,
                },
            ],
            query,
        });
    });
};

const FoodPortfolioRisksResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { riskLevel: "Critical", count: 1 },
                { riskLevel: "High", count: 3 },
                { riskLevel: "Medium", count: 2 },
                { riskLevel: "Low", count: 9 },
            ],
            query,
        });
    });
};

const CISORisksResponse = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                { riskLevel: "Critical", count: 4 },
                { riskLevel: "High", count: 6 },
                { riskLevel: "Medium", count: 9 },
                { riskLevel: "Low", count: 12 },
            ],
            query,
        });
    });
};

const CISOTeamActivity = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [{ activeUsers: 86, overdueRequests: 6, onTimeRequests: 22 }],
            query,
        });
    });
};

const ResponseNewStack = (query, groupBy) => {
    return new Promise((resolve, reject) => {
        resolve({
            items: [
                //last 6 months
                { riskLevel: "Critical", status: "open", count: 4 },
                { riskLevel: "Critical", status: "closed", count: 5 },
                { riskLevel: "High", status: "closed", count: 1 },
                { riskLevel: "High", status: "open", count: 3 },
                { riskLevel: "Medium", status: "open", count: 6 },
                { riskLevel: "Medium", status: "closed", count: 7 },
                { riskLevel: "Low", status: "open", count: 14 },
                { riskLevel: "Low", status: "closed", count: 19 },
            ],
            query,
        });
    });
};

const demoDataSource = {
    upcomingActivitiesResponse,
    openDemandsResponse,
    openDemandsStatusResponse,
    demandsResponse,
    totalIncomingRequestsResponse,
    engagmentResponse,
    FoodKeyContactsResponse,
    CISOEngagementTotalResponse,
    CISOPortfoliosAtGlanceResponse,
    FoodPortfolioRisksResponse,
    CISORisksResponse,
    CISOTeamActivity,
    ResponseNewStack,
};

export { demoDataSource };
