import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

export const BoxPanel = observer(
    ({ children, className, shadow, padding, stretch, attached, invert, small, mini, dataTest, onClick, ...rest }) => {
        return (
            <div
                onClick={onClick}
                className={classNames(
                    "border border-gray-200 max-w-full",
                    shadow && "shadow-sm",
                    padding && !small && "p-5",
                    padding && small && "p-4",
                    padding && mini && "p-3",
                    stretch && "h-full",
                    attached && "border-b-0 mb-0",
                    !!onClick && "cursor-pointer",
                    !invert && "rounded-sm bg-white",
                    invert && padding && "py-4 px-5",
                    className
                )}
                {...rest}
            >
                {children}
            </div>
        );
    }
);
