import { observable, flow } from "mobx";

import { RiskService } from "@api/risks";
import { RiskStore } from "./RiskStore";

export class TreatmentSelectionStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public id: string = null;
    @observable public context: any = null;
    @observable public treatment: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    public setRiskTreatment = flow(function* (treatment) {
        this.treatment = treatment;
    });

    public loadRiskTreatment = flow(function* loadRisk(id: string) {
        if (this.id !== id || !this.treatment) {
            this.loading = true;
            this.id = id;
            this.context = null;
            this.treatment = null;
        }

        try {
            this.treatment = yield this.riskService.getRiskTreatment(id);
            this.context = yield this.riskService.getRiskTreatmentContext(id);
            return this.treatment;
        } finally {
            this.loading = false;
        }
    });
}
