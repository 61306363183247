import { observable, flow } from "mobx";

import { RootStore } from '../../base/RootStore';
import { ConfigService } from '../../../api/config';

export class ConfigStore {
    public configService: ConfigService;
    public rootStore: RootStore;
    
    @observable public features: any = {};
    @observable public modules: any = {};
    @observable public loading: any = {};
    @observable public error: any = {};
    @observable public personal: any = {};

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.configService = new ConfigService(rootStore.authProvider);
    }

    public add(id: string, config: any) {
        this.modules[id] = config || {};
    }

    public merge(id: string, config: any) {
        this.modules[id] = {...this.modules[id], ...(config || {})};
    }

    public load = flow(function*(id: string) {
        this.loading[id] = true;
        this.error[id] = null;

        try {
            this.modules[id] = yield this.configService.getModuleConfigData(id);
        } catch (e) {
            this.error[id] = e;
            console.error(e)
        } finally {
            this.loading[id] = false;
        }
    });

    public save = flow(function*(id: string) {
        this.loading[id] = true;
        this.error[id] = null;

        try {
            this.modules[id] = yield this.configService.saveModuleConfigData(this.modules[id]);
        } catch (e) {
            this.error[id] = e;
            console.error(e)
        } finally {
            this.loading[id] = false;
        }
    });

    public get(id: string) {
        return this.modules[id];
    }
}
