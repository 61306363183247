import React from "react";
import { observer } from "mobx-react";
import { CommandBar as FabricCommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { DefaultButton } from "@ui/elements/Button";

const CommandBarV1 = (props) => {
    return <FabricCommandBar {...props} />;
};

const CommandBar = observer(({ items, farItems }) => {
    return (
        <div className="overflow-hidden h-12 px-2 flex items-center justify-between bg-white rounded-t-sm border border-gray-300">
            {items && (
                <div className="overflow-hidden flex items-center gap-1">
                    {items.map(({ showToGuest, iconOnly, ...item }) => (
                        <div key={item.key}>
                            {item.onRender ? (
                                item.onRender(item)
                            ) : (
                                <DefaultButton {...item} className="border-none" size="lg" />
                            )}
                        </div>
                    ))}
                </div>
            )}
            {farItems && (
                <div className="overflow-hidden flex items-center gap-1">
                    {farItems.map(({ showToGuest, iconOnly, ...item }) => (
                        <div key={item.key}>
                            {item.onRender ? (
                                item.onRender(item)
                            ) : (
                                <DefaultButton {...item} className="border-none" size="lg" />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export { CommandBarV1, CommandBar };
