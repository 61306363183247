import { observable, flow } from "mobx";

import { PaginationStore } from "@modules/base/PaginationStore";
import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentRuleBrowseStore {
    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;

    @observable public rules: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
        this.pagination = new PaginationStore();
    }

    public loadAssessmentRules = flow(function* (options) {
        const o = Object.assign({ page: 1, pageSize: 25, query: {} }, options);
        this.query = o.query;
        this.loading = true;

        try {
            const result = yield this.assessmentService.searchAssessmentRules({
                pageSize: o.pageSize,
                startIndex: (options.page - 1) * o.pageSize,
                query: o.query,
            });
            this.rules = result.items;
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
            return error;
        } finally {
            this.loading = false;
        }
    });
}
