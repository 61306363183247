import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import Moment from "react-moment";

import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";
import { IconButton, DefaultButton } from "office-ui-fabric-react/lib/Button";

import { TruncatedText } from "@modules/base/components/TruncatedText";
import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { ListItemReveal } from "@modules/base/components/ListItemReveal";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { PhotoPersonaStrip } from "@modules/identity/containers/utilities/PhotoPersonaStrip";

import { WorkflowContextLink } from "@modules/workflows/components/core/WorkflowContextLink";
import { TaskDueDateIndicator } from "@modules/workflows/components/tasks/TaskDueDateIndicator";

export const TaskDefinitionGridView = observer(
    ({ loading, tasks, canEdit, onEdit, canRespond, onRespond, displayColumns, displayContextLink }) => {
        const columns = [
            {
                key: "title",
                name: "Task",
                fieldName: "title",
                minWidth: 310,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                onRender: (item) => {
                    const canEditActual = canEdit(item);
                    const canRespondActual = canRespond(item);

                    return (
                        <WrappedContent>
                            <RoutingLink onClick={() => onRespond(item)} onClickEnabled={item.status !== "Completed"}>
                                {item.title}
                            </RoutingLink>
                            <ListItemReveal>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <DefaultButton
                                        onClick={(ev) => {
                                            onRespond(item);
                                        }}
                                        text={canRespondActual ? "Respond" : "View"}
                                        ariaLabel={canRespondActual ? "Respond" : "View"}
                                    />
                                    {canEditActual && (
                                        <IconButton
                                            iconProps={{ iconName: "Edit" }}
                                            onClick={(ev) => {
                                                onEdit(item);
                                            }}
                                            title="Edit"
                                            ariaLabel="Edit"
                                        />
                                    )}
                                </Stack>
                            </ListItemReveal>

                            {displayContextLink && item.context ? (
                                <Text variant="small" block>
                                    <WorkflowContextLink context={item.context}>
                                        <span>Go to {item.context.label}</span>
                                    </WorkflowContextLink>
                                </Text>
                            ) : (
                                <Text variant="small" block>
                                    <TruncatedText text={item.description} words={30} html />
                                </Text>
                            )}
                        </WrappedContent>
                    );
                },
                isPadded: true,
            },
            // {
            //     key: 'resource',
            //     name: 'Related Item',
            //     fieldName: 'RelatedItem',
            //     minWidth: 100,
            //     maxWidth: 150,
            //     isResizable: false,
            //     data: 'string',
            //     onRender: (item) => {
            //       let url = '';
            //       if (item.context) {
            //         if (item.context.type === "work-item") {
            //           url = `demand/browse/${item.context.id}`;
            //         } else {
            //           url = `assurance/browse/${item.context.id}`;
            //       }
            //     }
            //       return (item.context && <IconButton iconProps={{iconName:'NavigateExternalInline'}} href={url} target="_blank" /> );
            //     },
            //     isPadded: false
            //   },
            {
                key: "status",
                name: "Status",
                fieldName: "status",
                minWidth: 80,
                maxWidth: 120,
                isResizable: false,
                data: "string",
                onRender: (item) => {
                    return (
                        <Stack verticalFill verticalAlign="center">
                            <WrappedContent>{item.status}</WrappedContent>
                            {item.response && item.useCase !== "Completion" ? (
                                <WrappedContent>({item.response})</WrappedContent>
                            ) : null}
                        </Stack>
                    );
                },
                isPadded: true,
            },
            {
                key: "dueDate",
                name: "Due Date",
                fieldName: "dueDate",
                minWidth: 120,
                maxWidth: 160,
                isResizable: false,
                data: "date",
                onRender: (item) => {
                    return (
                        <Stack verticalFill verticalAlign="center">
                            <TaskDueDateIndicator definition={item} defaultValue="No due date set">
                                <Moment utc date={item.dueDate} format="Do MMM YYYY" />
                            </TaskDueDateIndicator>
                        </Stack>
                    );
                },
                isPadded: true,
            },
            {
                key: "assignedTo",
                name: "Assigned To",
                fieldName: "assignedTo",
                minWidth: 155,
                maxWidth: 280,
                isResizable: false,
                data: "string",
                onRender: (item) => {
                    return (
                        <PhotoPersonaStrip
                            personas={item.assignments.filter((persona) => persona.status !== "Revoked")}
                        />
                    );
                },
                isPadded: false,
            },
            {
                key: "created",
                name: "Created",
                fieldName: "created",
                minWidth: 185,
                maxWidth: 210,
                isResizable: false,
                data: "string",
                onRender: (item) => {
                    return (
                        <Stack verticalFill verticalAlign="center">
                            <UserHoverCard principal={item.createdBy}>
                                <PhotoPersona
                                    principalId={item.createdBy ? item.createdBy.id : null}
                                    size={PersonaSize.size28}
                                    text={item.createdBy ? item.createdBy.name : "Unassigned"}
                                    showUnknownPersonaCoin={!item.createdBy}
                                    showSecondaryText={true}
                                    secondaryText={moment.utc(item.created).fromNow()}
                                />
                            </UserHoverCard>
                        </Stack>
                    );
                },
                isPadded: false,
            },
        ].filter((field) => {
            if (!displayColumns) return true;
            return displayColumns.find((C) => C == field.key);
        });

        return (
            <BoxPanel shadow>
                <ShimmeredDetailsList
                    items={tasks}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={(item, index) => (item ? item.id : index)}
                    setKey="single"
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.horizontalConstrained}
                    isHeaderVisible={true}
                    onItemInvoked={(item, index, ev) => {
                        if (onRespond) {
                            onRespond(item);
                        }
                    }}
                    enableShimmer={loading}
                    removeFadingOverlay={true}
                    shimmerLines={10}
                    ariaLabelForShimmer="Tasks being fetched"
                    ariaLabelForGrid="Task list"
                />

                {!loading && !tasks.length && <MessageBar>There are no matching tasks.</MessageBar>}
            </BoxPanel>
        );
    }
);
