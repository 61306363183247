import React from 'react';
import { observer } from 'mobx-react';
import { cx, css} from 'emotion';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import moment from 'moment';

import { ObservableSeparator } from '../../../../../base/components/ObservableSeparator';
import RichTextEditor from '../../../../../editors/components/RichTextEditor';
import { PrincipalPicker } from '../../../../../identity/containers/PrincipalPicker';

export const RagStatusSnapshotPartEditor = observer((props) => {
    const { formData } = props;
    return (
        <div>
            <r-grid columns="3">
                <r-cell span="3">
                    <ObservableSeparator>RAG Indicator Setup</ObservableSeparator>
                </r-cell>
                <r-cell span="1" style={{color: 'var(--text-color-light)'}}>
                    <Dropdown
                        required
                        label="RAG Color"
                        selectedKey={formData.properties.RAGColor}
                        onChange={(ev, op) => {
                            if(op) {
                                formData.properties.RAGColor = op.key;
                            }
                        }}
                        options={[
                            {
                                key: 'green',
                                text: "Green",
                            },
                            {
                                key: 'amber',
                                text: "Amber",
                            },
                            {
                                key: 'red',
                                text: "Red",
                            },
                        ]}
                    />
                </r-cell>
                <r-cell span="1" style={{color: 'var(--text-color-light)'}}>
                    <Dropdown
                        required
                        label="RAG Indicator Style"
                        selectedKey={formData.properties.RAGStyle || 'square'}
                        onChange={(ev, op) => {
                            if(op) {
                                formData.properties.RAGStyle = op.key;
                            }
                        }}
                        options={[
                            {
                                key: 'square',
                                text: "Square",
                            },
                            {
                                key: 'noChangeArrow',
                                text: "No Change Arrow",
                            },
                            {
                                key: 'upArrow',
                                text: "Up Arrow",
                            },
                            {
                                key: 'downArrow',
                                text: "Down Arrow",
                            },
                            {
                                key: 'circle',
                                text: "Circle",
                            },
                            {
                                key: 'hide',
                                text: "Hide Icon",
                            },
                        ]}
                    />
                </r-cell>
                <r-cell span="1" style={{color: 'var(--text-color-light)'}}>
                    <TextField 
                        label="RAG Label"
                        value={formData.properties.RAGLabel}
                        onChange={(ev, val) => formData.properties.RAGLabel = val}
                    />
                </r-cell>
                <r-cell span="3" style={{color: 'var(--text-color-light)'}}>
                    <TextField 
                        label="Headline text"
                        value={formData.properties.headlineText || `{value}%`}
                        onChange={(ev, val) => formData.properties.headlineText = val}
                        style={{widht: '100%'}}
                    />
                </r-cell>
                <r-cell span="3">
                    <Text style={{marginBottom:'10px'}} block>Subtext</Text>
                    <div style={{border: '1px grey solid', padding:'10px'}}>
                        <RichTextEditor 
                            className={cx(css` border: '1px grey solid'; min-height:'175px' `)}
                            value={formData.properties.subtext}
                            onChange={(value) => formData.properties.subtext = value} />                        
                    </div>
                </r-cell>
                <r-cell span="3">
                    <PrincipalPicker 
                        placeholder={'Pick owner'}
                        onSelected={(user) => formData.properties.owner = user}
                        selected={formData.properties.owner}
                        multiple={false}
                    />
                </r-cell>
                <r-cell span="3">
                    <ObservableSeparator>Footer Setup</ObservableSeparator>
                </r-cell>
                <r-cell span="1">
                    <Toggle 
                        label="Show link button (will open in a new window)"
                        checked={formData.properties.displayLinkButton}
                        onChange={(ev, checked) => {
                            formData.properties.displayLinkButton = checked;
                        }}  
                    />
                </r-cell>
                <r-cell span="2">
                    <TextField 
                        label="Link"
                        value={formData.properties.linkButtonLink}
                        onChange={(ev, val) => formData.properties.linkButtonLink = val}
                    />
                </r-cell>
                <r-cell span="1">
                    <Toggle 
                        label="Show Last update"
                        checked={formData.properties.displayLastUpdate}
                        onChange={(ev, checked) => {
                            formData.properties.displayLastUpdate = checked;
                        }}  
                    />
                </r-cell>
                <r-cell span="2">
                    <div style={{display: 'flex'}}>
                        <TextField 
                            label="Last update"
                            value={formData.properties.lastUpdateText}
                            onChange={(ev, val) => formData.properties.lastUpdateText = val}
                        />
                        <IconButton
                            className={`colorPicker`}
                            style={{marginTop: '28px'}}
                            title="Pick today"
                            iconProps={{ iconName: 'DateTime' }}
                            onClick={()=>{
                                formData.properties.lastUpdateText = moment().utc().format('MMM Do YYYY')
                            }}
                        />
                    </div>
                </r-cell>
        </r-grid>
    </div>
    );
})
