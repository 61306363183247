import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';

import { PageZone } from '../elements/PageZone';

export const OneColumnLayout = observer(
  class OneColumnLayout extends PureComponent {
    render () {
        const { className, instance, section } = this.props;
        
        return (
          <div className={className}>
            <r-grid columns={1}>
              <r-cell>
                <PageZone instance={instance} section={section} name="main" title="Main Zone" />
              </r-cell>
            </r-grid>
          </div>
        );
    }
  }
);