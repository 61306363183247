import { observable, action, computed } from "mobx";

import { StateFlowService } from "../../../api/stateflows";
import { StateFlowEditorStore } from "./StateFlowEditorStore";

export class StateFlowPublishStore {
    public parentStore: StateFlowEditorStore;
    public stateFlowService: StateFlowService;

    @observable public value: any = null;
    @observable public snapshot: any = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    public options: any;

    constructor(parentStore: StateFlowEditorStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.value);
    }

    @computed
    public get isValid() {
        return this.value && this.value.mode;
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        this.title = options.title;
        this.snapshot = options.value;
        this.value = JSON.parse(JSON.stringify(options.value));
        this.visible = true;
        this.processing = false;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.value = null;
        this.processing = false;
        this.visible = false;
    }

    @action
    public onChange(value) {
        this.value = value;
    }

    @action
    public onSave(options) {
        this.processing = true;
        this.options.resolve({ success: true, value: options });
    }

    @action
    public onCancel(options) {
        this.processing = true;
        this.options.resolve({ success: false });
    }
}
