import { observable, action, computed, flow } from "mobx";

import { WorkflowService } from "../../../api/workflows";

import { TaskStore } from "./TaskStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class TaskEditFormStore {
    public parentStore: TaskStore;
    public workflowService: WorkflowService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    public show = flow(function* (options) {
        yield this.parentStore.selectionStore.loadTask(options.task.id);
        const task = this.parentStore.selectionStore.task;

        const assignedTos = task.assignments.filter((a) => a.status !== "Revoked").map((a) => a.assignedTo);

        this.formOptions = {
            originalAssignedToIds: assignedTos.map((a) => a.id),
        };

        const overrides = { assignedTos: assignedTos, notify: true };
        this.formData = { ...task, ...overrides };
        this.formData.settings = this.formData.settings || {};
        this.formData.settings.listeners = this.formData.settings.listeners || {};

        this.visible = true;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    public hide = flow(function* (options) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    });

    @action
    public resolve(success) {
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.responseHandle.reject(error);
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.id &&
            this.formData.title &&
            this.formData.description &&
            this.formData.context &&
            this.formData.context.id &&
            this.formData.context.type &&
            this.formData.dueDate &&
            this.formData.mode &&
            this.formData.useCase &&
            this.formData.settings &&
            this.formData.settings.listeners &&
            this.formData.assignedTos &&
            this.formData.assignedTos.filter((s) => !!s.id || !!s.email).length === this.formData.assignedTos.length
        );
    }
}
