import React, { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStores";
import { cx, css } from "emotion";

import { FontIcon } from "@ui/elements/Icon";
import { Dropdown } from "@ui/elements/Dropdown";

import { ObservableSeparator } from "@baseComponents/ObservableSeparator";

const iconClassSmall = cx(css`
    font-size: 20px;
    height: 20px;
    width: 20px;
`);

const onRenderItemColumn = (item, index, column) => {
    if (column.key === "icon") {
        return <FontIcon iconName={item.icon} className={iconClassSmall} />;
    }
    return item[column.key];
};

export const HeatmapPartEditor = observer((props) => {
    const { formData, dataSources } = props;
    const [selectedAction, setSelectedAction] = useState(null);
    const [showContextualMenu, setShowContextualMenu] = useState(null);
    const [isQuickActionModalOpen, setIsQuickActionModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState("edit");
    const { dataSourceStore } = useStores();
    return (
        <div>
            <r-grid columns="2">
                <r-cell span="2">
                    <ObservableSeparator>Quick Actions Setup</ObservableSeparator>
                </r-cell>
                <r-cell span="2" style={{ color: "var(--text-color-light)" }}>
                    <Dropdown
                        required
                        label="Heatmap size"
                        selectedKey={formData.properties.hm_size}
                        onChange={(ev, op) => {
                            if (op) {
                                formData.properties.hm_size = op.key;
                            }
                        }}
                        options={[
                            {
                                key: "tiny",
                                text: "Tiny",
                            },
                            {
                                key: "mini",
                                text: "Mini",
                            },
                            {
                                key: "medium",
                                text: "Medium",
                            },
                            {
                                key: "large",
                                text: "Large",
                            },
                        ]}
                    />
                </r-cell>
                <r-cell span="2">
                    <Dropdown
                        label="Data source"
                        selectedKey={formData.properties.dataSourceId}
                        onChange={(ev, op) => {
                            if (op) {
                                formData.properties.dataSourceId = op.key;
                            }
                        }}
                        options={dataSources
                            .filter((DSource) => {
                                const coreDS = dataSourceStore.coreDataSourceDataSource(DSource.properties.mainSource);
                                return coreDS.id === "coreDataSource:heatmap";
                            })
                            .map((DSource) => {
                                return {
                                    key: DSource.id,
                                    text: DSource.title,
                                };
                            })}
                    />
                </r-cell>
            </r-grid>
        </div>
    );
});
