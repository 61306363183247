import { observable } from "mobx";
import { v4 as uuidv4 } from 'uuid';

import { PageService } from '../../../api/pages';
import { PageStore } from "./PageStore";

import { layouts as core } from '../components/layouts/layouts';

const DefaultLayout: string = 'one-column';

export class PageLayoutStore {

    public parentStore: PageStore;
    public pageService: PageService;

    @observable public layouts: any = {};

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;

        this.layouts = {...core};
    }

    public getLayout(section) {
        const layoutName = section.layout || DefaultLayout;
        return this.layouts[layoutName] || this.layouts[DefaultLayout];
    }

    public rearrange(section) {
        const layout = this.getLayout(section);

        const orphanedParts2D = section.zones
          .filter(zone => layout.supportedZones.indexOf(zone.name) === -1)
          .map(zone => zone.parts || []);

        const orphanedParts = [].concat(...orphanedParts2D);

        let zone = section.zones.find(zone => zone.name === layout.defaultZone);

        if(!zone) {
            zone = { id: uuidv4(), name: layout.defaultZone, theme: 'none', parts: orphanedParts };
            section.zones = [...section.zones.filter(zone => layout.supportedZones.indexOf(zone.name) !== -1), zone];
        } else {
            zone.parts = [...zone.parts, ...orphanedParts];
            section.zones = section.zones.filter(zone => layout.supportedZones.indexOf(zone.name) !== -1);
        }
    }
}
