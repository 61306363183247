import { TeamsAuthProvider } from "@modules/teams/TeamsAuthProvider";
import { Constants } from "@api/graph/serviceBase";
import { User } from "oidc-client-ts";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

function getTenantInfo(hostname) {
    if (window.cygboot && window.cygboot.bootstrap) {
        return {
            tenantId: window.cygboot.tenantId,
            clientId: window.cygboot.clientId,
            authority: window.cygboot.authority,
            scope: window.cygboot.scope,
        };
    }

    switch (hostname) {
        case "cygraph-int-app.azurewebsites.net":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
        case "demo1.zerodayapp.com":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
        case "demo1.zerod.ai":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
        case "cyb-eun-nprd-cygraph-app1.azurewebsites.net":
            return {
                tenantId: "bd5c6713-7399-4b31-be79-78f2d078e543",
                clientId: "d200a7dc-106f-48b8-9cfa-a7097d2378d5",
                authority: "https://login.microsoftonline.com/bd5c6713-7399-4b31-be79-78f2d078e543",
                scope: "e87365f1-d141-4433-9dba-10c6914ca2fa/user_impersonation",
            };
        case "cyb-eun-prd-cygraph-app1.azurewebsites.net":
            return {
                tenantId: "bd5c6713-7399-4b31-be79-78f2d078e543",
                clientId: "9d4b6d0f-0fd6-4166-81a4-4a2076fc51c2",
                authority: "https://login.microsoftonline.com/bd5c6713-7399-4b31-be79-78f2d078e543",
                scope: "7805319d-b918-4b52-9304-c82d4be48396/user_impersonation",
            };
        case "app-cyg-uks-ih01.azurewebsites.net":
            return {
                tenantId: "dominos.co.uk",
                clientId: "a0933607-77af-4e15-a378-3e2760b9c6b5",
                authority: "https://login.microsoftonline.com/dominos.co.uk",
                scope: "3b7d47ba-b43b-4c00-a7b0-492aadd813b5/user_impersonation",
            };
        // default:
        //     return {
        //         tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
        //         clientId: "b09361ae-b3db-4f1a-8013-23f8383b5d5d",
        //         authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
        //         scope: null, // "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
        //     };
        // default:
        //     return {
        //         tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
        //         clientId: "0oay9log3zxaUFyvR0x7",
        //         authority: "https://rb.okta.com",
        //         scope: null, // "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
        //     };
        default:
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "0oa7zlj8x7H2V7OOB5d7",
                authority: "https://dev-05797279.okta.com",
                scope: null, // "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
    }
}

const tenantInfo = getTenantInfo(window.location.hostname);

export const oidcConfig = {
    authority: tenantInfo.authority,
    client_id: tenantInfo.clientId,
    redirect_uri: window.location.origin + "/sign-in",
    response_type: "code",
    scope: tenantInfo.scope || "openid profile email",
    automaticSilentRenew: true,
    // onSigninCallback: (_user) => {
    //     const url = sessionStorage.getItem("zerodai:auth:redirect-url") || "/";
    //     window.location.href = url;
    //     //window.history.replaceState({}, document.title, url);
    // },
};

const teamsAuthProvider = new TeamsAuthProvider({
    clientId: tenantInfo.clientId,
    tokenExchangeUri: `${Constants.baseUrl}/bot/auth`,
    cacheLocation: "localStorage",
});

export class StaticAuthProvider {
    constructor(config) {
        this.config = config;
    }

    getAccessToken = () => {
        try {
            const accessToken = localStorage.getItem(`${this.config.baseKey}:accessToken`);
            return {
                accessToken,
            };
        } catch (error) {
            console.error(error);
            return null;
        }
    };
}

export class OidcClientAuthProvider {
    constructor(config) {
        this.config = config;
    }

    getAccessToken = () => {
        try {
            const key = `oidc.user:${this.config.authority}:${this.config.clientId}`;
            const oidcStorage = sessionStorage.getItem(key);
            if (!oidcStorage) {
                return Promise.resolve({ accessToken: null });
            }

            var user = User.fromStorageString(oidcStorage);
            if (Math.floor(new Date().getTime() / 1000) - 300 > user.expires_at) {
                return Promise.resolve({ accessToken: null });
            }

            return Promise.resolve({
                accessToken: user?.id_token,
            });
        } catch (error) {
            console.error(error);
            return null;
        }
    };
}

const isCypress = !!localStorage.getItem("zerodai:cypress");
const cypressAuthProvider = new StaticAuthProvider({ baseKey: "zerodai:cypress" });
const oidcClientAuthProvider = new OidcClientAuthProvider(tenantInfo);

export const authProvider = isCypress
    ? cypressAuthProvider
    : window.top != window.self
    ? teamsAuthProvider
    : oidcClientAuthProvider; //msalAuthProvider;
