import React from "react";
import { observer } from "mobx-react";

import { TextField } from "@ui/elements/TextField";
import { Label } from "@ui/elements/Label";
import { Stack } from "@ui/elements/Stack";
import { Dropdown } from "@ui/elements/Dropdown";
import { ActionButton, IconButton } from "@ui/elements/Button";

export const HttpRequestActivityEditor = observer(({ formData }) => {
    return (
        <div>
            <r-grid columns="2">
                <r-cell>
                    <Dropdown
                        label="Method"
                        required
                        selectedKey={formData.settings.method}
                        onChange={(ev, val) => {
                            formData.settings.method = val;
                        }}
                        options={["GET", "POST", "PUT", "DELETE", "MERGE"].map((m) => ({
                            key: m,
                            text: m,
                        }))}
                    />
                </r-cell>
                <r-cell span="2">
                    <TextField
                        label="Request URI"
                        required
                        value={formData.settings.requestUri || ""}
                        onChange={(ev, val) => {
                            formData.settings.requestUri = val;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <TextField
                        label="Content Type"
                        required
                        value={formData.settings.contentType || ""}
                        onChange={(ev, val) => {
                            formData.settings.contentType = val;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <TextField
                        label="Content"
                        required
                        multiline
                        style={{ minHeight: 150 }}
                        value={formData.settings.content || ""}
                        onChange={(ev, val) => {
                            if (["application/json"].indexOf(formData.settings.contentType) !== -1) {
                                formData.settings.json = JSON.parse(val);
                            } else {
                                formData.settings.content = val;
                            }
                        }}
                    />
                </r-cell>
            </r-grid>
        </div>
    );
});
