import { observable, action, computed, flow } from "mobx";
import { WorkItemLink } from './types/WorkItemLink';
import { WorkItemService } from '../../../api/workitems';
import { IdentityService } from '../../../api/identity';
import { SystemService} from '../../../api/graph'
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemRelatedWIStore {
    private workItemService: WorkItemService;
    private identityService: IdentityService;
    private systemService: SystemService;
    public parentStore: WorkItemStore;


    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public errorWhileLinkingWorkItem: boolean = false;
    @observable public loadingRelatedWorkItem: boolean = false;
    @observable public relatedWorkItem: any = {};
    @observable public workItemLinkTypes: WorkItemLink[] = [];
    @observable public selectedWorkItemId: string = null;
    @observable public relatedWorkItemFilters: any = {
        status: 'All',
        keywords: '',
        page: 1,
    };

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
        this.identityService = parentStore.identityService;
        this.systemService = parentStore.systemService;
    }

    @action public changeRelatedItemsKeywordFilter(keywords: string) {
        this.relatedWorkItemFilters = Object.assign(this.relatedWorkItemFilters, {keywords, page:0});
        return this.loadRelatedWorkItems();
    }

    @action public changeAchangeRelatedItemsStatusFilter(status: number) {
        this.relatedWorkItemFilters = Object.assign(this.relatedWorkItemFilters , {status, page: 0});
        return this.loadRelatedWorkItems();
    }

    loadworkItemLinkTypes = flow(function*() {
        try {
            if (this.workItemLinkTypes.length === 0) {
                const result = yield this.workItemService.getWorkItemLinkTypes();
                this.workItemLinkTypes = result;
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    })

    loadWorkItemsSuggestions = flow(function*(keywords: string) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: keywords });
        try {
            const result = yield this.workItemService.getWorkItems({ 
                pageSize: 25, 
                startIndex: (options.page - 1) * 25,
                keywords: options.keywords
            })
            this.relatedWorkItemSuggestion = result.items;
            this.loading = false;
            return result;
        } catch (e) {
            console.error(e);
            this.loading = false;
            return e;
        }
    })

    loadRelatedWorkItems = flow(function*() {
        this.loading = true;
        try {
            const options = Object.assign({ page: 1, keywords: null });
            const result = yield this.workItemService.getRelatedWorkItems(
                this.parentStore.selectionStore.selectedWorkItem.id,
                // {
                //     pageSize: this.pagination.itemsPerPage, 
                //     startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                //     keywords: options.keywords
                // }
                );
            this.parentStore.selectionStore.selectedWorkItem.relatedWorkItems = result;
            this.loading = false;
        } catch (e) {
            console.error(e);
            this.loading = false;
        }
        return 
    })

    linkItem = flow(function*(workItem: any, linkType: WorkItemLink) {
        this.errorWhileLinkingWorkItem = false;
        try {
            yield this.workItemService.createNewLinkWorkItem(this.parentStore.selectionStore.selectedWorkItem.id, workItem, linkType);
            this.loadRelatedWorkItems();
            this.errorWhileLinkingWorkItem = false;
        } catch (e) {
            console.error(e);
            this.errorWhileLinkingWorkItem = true
        }
    })

    updateWorkItemRelation = flow(function*(newLinkType, currentRelation) {
        try {
            yield this.workItemService.updateWorkItemRelation(currentRelation.sourceId, currentRelation.id, newLinkType.key, currentRelation.target.id);
            yield this.loadRelatedWorkItems();
        } catch (e) {
            console.error(e);
        }
    })

    deleteRelatedWorkItems = flow(function*(linkId: string) {
        try {
            yield this.workItemService.deleteRelatedWorkItems(this.parentStore.selectionStore.selectedWorkItem.id,linkId);
            yield this.loadRelatedWorkItems();
        } catch (e) {
            console.error(e);
            throw e
        }
    })

    loadRelatedWorkItem = flow(function*(workItemId: string) {
        if (!this.loadingRelatedWorkItem) {
            this.loadingRelatedWorkItem = true;
            try {
                const result = yield this.workItemService.getWorkItem(workItemId);
                const system = yield this.systemService.getSystem(result.assetId);
                result.system = system;
                this.loadingRelatedWorkItem = false;
                this.relatedWorkItem = result;
            } catch (e) {
                console.error('error', e);
                this.loadingRelatedWorkItem = false;
                this.relatedWorkItem = null;
            }   
        }
    })

    createWorkItemNoSelect = flow(function*(workItem) {
        this.saving = true;
        try {
            const result = yield this.workItemService.createWorkItem(workItem);
            this.saving = false
            return result;
        } catch (e) {
            console.error(e);
            this.saving = false;
            return e;
        }
    })
}
