import { observable, flow } from "mobx";
import { ProductStore } from './ProductStore';
import { ProductService } from '../../../api/graph';

export class ProductOptionsStore {

    private productService: ProductService;
    public parentStore: ProductStore;

    @observable public loading: boolean = false;
    @observable public categories = [];
    @observable public providers = [];
    @observable public availabilities = [];
    @observable public error: any = null;

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
    }

    public loadProductOptions = flow(function*() {
        this.loading = true;

        try {
            this.categories = yield this.productService.getCategories();
            this.providers = yield this.productService.getProviders();
            this.availabilities = yield this.productService.getAvailabilities();
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });
}
