import { observable, action } from "mobx";

import { TriageService } from '../../../api/triage';
import { TriageStore } from "./TriageStore";

export class TriageHelpStore {
    private triageService: TriageService;
    public parentStore: TriageStore;

    @observable public loading: boolean = false;
    @observable public visible: boolean = false;
    @observable public request: any = null;
    @observable public content: any = null;

    constructor(parentStore: TriageStore) {
        this.parentStore = parentStore;
        this.triageService = parentStore.triageService;
    }

    @action
    public show(options) {
        this.visible = true;
        this.loading = true;
        this.request = options.request;

        const help = this.parentStore.configuration.help;
        if(options.request.type === 'local') {
            this.content = help.local.find(h => h.id === options.request.id);
            this.loading = false;
        }
    }

    @action
    public hide(options) {
        this.request = null;
        this.content = null;
        this.visible = false;
        this.loading = false;
    }
}
