import { observable, flow } from "mobx";

import { PaginationStore } from "@modules/base/PaginationStore";
import { AssetStore } from "./AssetStore";

export class AssetTreatmentStore {
    private parentStore: AssetStore;

    @observable public loading: boolean = false;
    @observable public treatments: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.pagination = new PaginationStore();
    }

    public loadAssetTreatments = flow(function* (options: any) {
        const q = Object.assign(
            { isPending: true },
            options.query,
            options.as === "stakeholder"
                ? { stakeholder: [{ id: options.assetId }] }
                : { asset: [{ id: options.assetId }] }
        );
        const o = Object.assign({ page: 1 }, options, { query: q });

        this.loading = true;
        this.treatments = [];
        this.query = o.query;
        this.error = null;

        try {
            const riskService = this.parentStore.rootStore.riskStore.riskService;

            const result = yield riskService.searchRiskTreatments({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: o.query
            });
            this.treatments = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public exportAssetTreatments = flow(function* (options) {
        const o = Object.assign({ page: 1, query: this.query }, options);

        try {
            const riskService = this.parentStore.rootStore.riskStore.riskService;

            const result = yield riskService.exportRisks({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: o.query
            });

            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Risk export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });
}
