import { ServiceBase, Constants } from "./serviceBase";

export class PortfolioService extends ServiceBase {
    /**
     * Get active portfolios
     */
    public async getPortfolios(options) {
        return this.get(
            `${Constants.baseUrl}/portfolios?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }&activeOnly=${!!options.activeOnly}`
        );
    }

    /**
     * Get details for a portfolio
     * @param portfolioId
     */
    public async getPortfolio(portfolioId: string) {
        return this.get(`${Constants.baseUrl}/portfolios/${portfolioId}`);
    }

    /**
     * Update an existing portfolio
     * @param portfolio
     */
    public async updatePortfolio(portfolio) {
        return this.put(`${Constants.baseUrl}/portfolios/${portfolio.id}`, portfolio);
    }

    /**
     * Create portfolios
     */
    public async createPortfolio(portfolio) {
        return this.post(`${Constants.baseUrl}/portfolios`, portfolio);
    }

    public async getPortfolioPermission(portfolioId: string) {
        return this.get(`${Constants.baseUrl}/portfolios/${portfolioId}/permission`);
    }

    public async getPortfolioSharing(portfolioId: string) {
        return this.get(`${Constants.baseUrl}/portfolios/${portfolioId}/sharing`);
    }

    public async grantPortfolioSharing(portfolioId: string, actions: any[]) {
        return this.post(`${Constants.baseUrl}/portfolios/${portfolioId}/sharing/grant`, actions);
    }

    public async revokePortfolioSharing(portfolioId: string, actions: any[]) {
        return this.post(`${Constants.baseUrl}/portfolios/${portfolioId}/sharing/revoke`, actions);
    }
}

export class ProductService extends ServiceBase {
    public async getProducts(options) {
        return this.get(
            `${Constants.baseUrl}/products?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }`
        );
    }

    public async getCategories() {
        return this.get(`${Constants.baseUrl}/products/categories`);
    }

    public async getAvailabilities() {
        return this.get(`${Constants.baseUrl}/products/availabilities`);
    }

    public async getProviders() {
        return this.get(`${Constants.baseUrl}/products/providers`);
    }

    public async getProduct(id) {
        return this.get(`${Constants.baseUrl}/products/${id}`);
    }

    public async getProductSchema(id, version = null) {
        return this.get(`${Constants.baseUrl}/products/${id}/schema?version=${version || ""}`);
    }

    public async updateProduct(product) {
        return this.put(`${Constants.baseUrl}/products/${product.id}`, product);
    }

    public async createProduct(product) {
        return this.post(`${Constants.baseUrl}/products`, product);
    }
}

export class SystemService extends ServiceBase {
    public async getSystems(options) {
        return this.get(
            `${Constants.baseUrl}/systems?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }`
        );
    }

    public async getSystem(id) {
        return this.get(`${Constants.baseUrl}/systems/${id}`);
    }

    public async getSystemAssessments(id, options) {
        const keywords = options.keywords ? `&keywords=${options.keywords}` : "";
        return this.get(
            `${Constants.baseUrl}/systems/${id}/assessments?pageSize=${options.pageSize}&startIndex=${options.startIndex}${keywords}`
        );
    }

    public async getSystemAdvisories(id, options) {
        const keywords = options.keywords ? `&keywords=${options.keywords}` : "";
        const finalCompliance = options.finalCompliance ? `&query.finalCompliance=${options.finalCompliance}` : "";
        const level = options.level ? `&query.level=${options.level}` : "";
        return this.get(
            `${Constants.baseUrl}/systems/${id}/advisories?pageSize=${options.pageSize}&startIndex=${options.startIndex}${level}${keywords}${finalCompliance}`
        );
    }

    public async createSystem(data) {
        return this.post(`${Constants.baseUrl}/systems`, data);
    }
}
