import { observable, action, computed, flow } from "mobx";
import { BaseChartStoreClass } from './BaseChartStoreClass';

export class XyChartStore extends BaseChartStoreClass {

/**
 *  Expected format
[
        {
            name: 'Open',
            values: [
                {
                    x: "2020-01-01",
                    value: 30
                },
                {
                    x: "2020-01-02",
                    value: 20
                },
                {
                    x: "2020-01-03",
                    value: 50
                },
                {
                    x: "2020-01-04",
                    value: 30
                },
                {
                    x: "2020-01-05",
                    value: 20
                },
                {
                    x: "2020-01-06",
                    value: 50
                }, 
            ],
            type: 'bar',
        },
        {
            name: 'Closed',
            values: [
                {
                    x: "2020-01-01",
                    value: 30
                },
                {
                    x: "2020-01-03",
                    value: 10
                },
                {
                    x: "2020-01-04",
                    value: 30
                },
                {
                    x: "2020-01-02",
                    value: 18
                },
                {
                    x: "2020-01-05",
                    value: 0
                },
                {
                    x: "2020-01-06",
                    value: 14
                }, 
            ],
            type: 'bar',
        },
        {
            name: 'Total',
            values: [
                {
                    x: "2020-01-01",
                    value: 50
                },
                {
                    x: "2020-01-02",
                    value: 71
                },
                {
                    x: "2020-01-03",
                    value: 92
                },
                {
                    x: "2020-01-04",
                    value: 150
                },
                {
                    x: "2020-01-05",
                    value: 210
                },
                {
                    x: "2020-01-06",
                    value: 240
                }, 
            ],
            type: 'spline',
        }
    ]
 */

    private onClicks = {};
    public onClickCallbackExists = false;

    @computed public get data() {
        this.onClicks = {};
        return this.rawData
            .map((D) => {          
                return {
                    name: D.label,
                    values: D.dataSource
                        .filter(DS => {
                            if (this.hideZeros) {
                                // debugger
                                return DS[D.dataValue] ? true : false
                            } else {
                                return true
                            }
                        })
                        .sort((a, b) => {
                            const valueA = a[D.horizontalSortingField];
                            const valueB = b[D.horizontalSortingField];
                            if (D.horizontalSorting === 'temporal' && valueA && valueB) {
                                return new Date(valueA).getTime() > new Date(valueB).getTime() ? 1 : -1
                            } else if (D.horizontalSorting === 'alphabetical' && valueA && valueB) {
                                return valueA.toUpperCase() > valueB.toUpperCase() ? 1 : -1
                            } else {
                                return 1
                            }
                        })
                        .map((DS, i) => {
                            // console.log('dataValue', DS.id, D.dataValue, `${DS[D.dataX]}${D.label}`)
                            this.onClicks[`${i}${D.label}`] = this.isFunction(D.onClick) ? D.onClick.bind(this, [DS.id, D.dataValue]) : null;
                            this.onClickCallbackExists = this.isFunction(D.onClick) ? true : false;
                            return {
                                x: DS[D.dataX] || '',
                                value: DS[D.dataValue] || 0,
                                // sortField: DS[D.horizontalSortingField],
                                // sortType: D.horizontalSorting,
                            }
                        }),
                    type: D.type,
                }
            });
    }

    private sortByDate(a:string, b:string) {
        return new Date(a).getTime() - new Date(b).getTime()
    }

    private sortLabels(a, b) {
        var sortType = a.sortType; 
        if (sortType === 'temporal' && a.sortField && b.sortField) {
            return new Date(a.sortField).getTime() > new Date(b.sortField).getTime() ? 1 : -1
        } else if (sortType === 'alphabetical' && a.sortField && b.sortField) {
            return a.sortField.toUpperCase() > b.sortField.toUpperCase() ? 1 : -1
        } else {
            return 1
        }
    }

    public getXLabels():Array<any> {
        const xLabels = new Array();
        if (!this.data) return [];
        this.data.forEach(dataSet => {
                //extract all the x values
                // dataSet.values.sort(this.sortLabels);
                dataSet.values.forEach(value => {
                    if (!xLabels.find(L => L.x === value.x)) {
                        // xLabels.push({x:value.x, sortField:value.sortField, sortType:value.sortType})
                        xLabels.push(value.x)
                    }                        
                });
        });
        //xLabels.sort(this.sortLabels);
        return xLabels
    }

    public getChartData(element) {
        const types = {};
        const xLabels = this.getXLabels();
        this.data.forEach(dataSet => {
            types[dataSet.name] = dataSet.type
        });
        //pushing values to columns
        const columns = this.data.map(dataSet => [dataSet.name, ...dataSet.values.map(V=>V.value)]);
        //pushing x-axis values
        // columns.push(["x", ...xLabels.sort(this.sortByDate)]);
        columns.push(["x", ...xLabels]);
        let title = {};
        // if (this.title && this.showTitle) {
        //     title = {
        //         position: 'top-left',
        //         padding: this.titlePadding,
        //         text: this.title,
        //         textTransform: "uppercase",
        //     }
        // }
        return {
            // padding: this.padding,
            bindto: element,
            title,
            data: {
                x: 'x',
                columns: columns,
                types: types,
                onclick: (d, i) => {
                    const key = `${d.x}${d.id}`;
                    if (this.isFunction(this.onClicks[key])) this.onClicks[key](d)
                },
            },
            axis: {
                rotated: (this.orientation =='horizontal') ? true : false,
                x: {
                    type: 'category'
                },
            },
        }
    }

    public csvData() {
        const numberOfValues = this.data[0].values.length;
        const labels = Array.from(this.getXLabels());
        const csvValues = [];
        for (let i=0; i<numberOfValues; i++) {
            const csvRow = {};
            csvRow['Date'] = labels[i];
            this.data.forEach(H => {
                if (H.values[i]) csvRow[H.name] = H.values[i].value;
            });
            csvValues.push(csvRow);
        }
        return csvValues;
    }

}
