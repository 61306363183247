import { observable, computed, flow } from "mobx";
import moment from "moment";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { isUserAuthenticated, isUserInAllRoles } from "../../identity/stores/PrincipalContextUtilities";

const UnassignedResourceId = "unassigned";

export class WorkItemTimelineStore {
    public parentStore: WorkItemStore;
    public workItemService: WorkItemService;
    public principalContext: PrincipalContextStore;

    @observable public workItems: any[] = [];
    @observable public keywords: string = "";
    @observable public view: string = "Month";
    @observable public date: Date = new Date();
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        { text: "Demand Management", key: "demand", onClick: () => this._goToUrl(`/demand/dashboard`) },
        { text: "Demand Timeline", key: "timeline", isCurrentItem: true },
    ];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAgent() {
        const user = this.principalContext.current;
        return user && isUserInAllRoles(user, ["agent"]);
    }

    @computed
    public get events() {
        const now = moment.utc();
        const threeWeeksFromNow = now.add(3, "weeks").toDate();
        return this.workItems.map((a) => {
            let colour = "var(--green-9)";
            if (a.priority === "Medium") {
                colour = "var(--blue-9)";
            } else if (a.priority === "High") {
                colour = "var(--red-9)";
            }
            return {
                id: a.id,
                title: `${a.code} - ${a.title}`,
                start: a.created,
                end: a.closed
                    ? a.closed
                    : a.estimatedClose
                    ? a.estimatedClose
                    : now.isAfter(a.dueDate)
                    ? threeWeeksFromNow
                    : a.dueDate,
                assessment: { ...a },
                resourceId: `${a.product ? a.product.id : UnassignedResourceId}-${
                    a.portfolio ? a.portfolio.id : "not-set"
                }`,
                backgroundColor: colour,
                borderColor: colour,
            };
        });
    }

    @computed
    public get products() {
        if (this.workItems.length == 0) {
            return [];
        }

        const productMap = {};
        productMap[UnassignedResourceId] = {
            id: UnassignedResourceId,
            title: "Unassigned",
            user: null,
        };

        this.workItems.forEach((workItem) => {
            if (workItem.product) {
                if (!productMap[workItem.product.id]) {
                    productMap[workItem.product.id] = {
                        id: workItem.product.id,
                        title: workItem.product.name,
                        product: { ...workItem.product },
                    };
                }
            }
        });

        return Object.values(productMap);
    }

    @computed
    public get resources() {
        if (this.workItems.length == 0) {
            return [];
        }

        const resourceMap = {};

        this.workItems.forEach((assessment) => {
            const productId = assessment.product ? assessment.product.id : UnassignedResourceId;
            const productName = assessment.product ? assessment.product.name : UnassignedResourceId;
            const resourceId = `${productId}-${assessment.portfolio ? assessment.portfolio.id : "not-set"}`;

            if (!resourceMap[resourceId]) {
                resourceMap[resourceId] = {
                    id: resourceId,
                    title: assessment.portfolio ? assessment.portfolio.name : "Not Set",
                    productName: productName,
                    userId: productId,
                    groupId: productId,
                };
            }
        });

        return Object.values(resourceMap).sort((a: any, b: any) => {
            if (a.productName === UnassignedResourceId) {
                return 1;
            }
            if (b.productName === UnassignedResourceId) {
                return -1;
            }
            var nameA = a.productName.toUpperCase();
            var nameB = b.productName.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    public loadWorkItems = flow(function* (query) {
        var options = Object.assign({ keywords: null, view: "Month" }, query);
        this.loading = true;
        //this.workItems = [];

        this.view = options.view;
        this.date = moment.utc(options.date).toDate();

        try {
            const result = yield this.workItemService.searchWorkItems({
                pageSize: 250,
                startIndex: 0,
                query: {
                    keywords: options.keywords,
                    status: ["Open", "Assigned", "InProgress", "Blocked", "Resolved", "Closed"],
                    timeline: {
                        after: options.start,
                        before: options.end,
                    },
                },
            });
            this.workItems = result.items;
            this.keywords = result.keywords;
            return result;
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
