import { RootStore } from "../../base/RootStore";
import { StateFlowService } from "../../../api/stateflows";

import { StateFlowSelectionStore } from "./StateFlowSelectionStore";
import { StateFlowPermissionStore } from "./StateFlowPermissionStore";
import { StateFlowLifecycleStore } from "./StateFlowLifecycleStore";

import { StateFlowBrowseStore } from "./StateFlowBrowseStore";
import { StateFlowViewerStore } from "./StateFlowViewerStore";
import { StateFlowEditorStore } from "./StateFlowEditorStore";
import { StateFlowVersionStore } from "./StateFlowVersionStore";
import { StateFlowEditFormStore } from "./StateFlowEditFormStore";
import { StateFlowContextMenuStore } from "./StateFlowContextMenuStore";

export class StateFlowStore {
    public stateFlowService: StateFlowService;
    public rootStore: RootStore;

    public selectionStore: StateFlowSelectionStore;
    public permissionStore: StateFlowPermissionStore;
    public lifecycleStore: StateFlowLifecycleStore;

    public browseStore: StateFlowBrowseStore;
    public viewerStore: StateFlowViewerStore;
    public editorStore: StateFlowEditorStore;
    public versionStore: StateFlowVersionStore;
    public editFormStore: StateFlowEditFormStore;
    public contextMenuStore: StateFlowContextMenuStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.stateFlowService = new StateFlowService(rootStore.authProvider);

        this.selectionStore = new StateFlowSelectionStore(this);
        this.permissionStore = new StateFlowPermissionStore(this);
        this.lifecycleStore = new StateFlowLifecycleStore(this);

        this.browseStore = new StateFlowBrowseStore(this);
        this.viewerStore = new StateFlowViewerStore(this);
        this.editorStore = new StateFlowEditorStore(this);
        this.versionStore = new StateFlowVersionStore(this);
        this.editFormStore = new StateFlowEditFormStore(this);
        this.contextMenuStore = new StateFlowContextMenuStore(this);
    }
}
