import { observable, action, computed, flow } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { RiskService } from "@api/risks";
import { RiskStore } from "@modules/risks/stores/RiskStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class RiskReviewFormStore {
    private parentStore: RiskStore;
    private riskService: RiskService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        this.formOptions = {
            pageType: options.pageType || `${options.risk.type.name} Review`,
            pageDescription: options.pageDescription || `${options.risk.code} - ${options.risk.title}`,
            typeTemplate: options.risk.type.template,
        };

        const treatments = yield this.riskService.getRiskTreatments(options.risk.id, true);

        this.formData = {
            lifecycleId: options.lifecycleId,
            grossScore: { ...options.review?.grossScore },
            netScore: { ...options.review?.netScore },
            treatmentEffectiveness: options.review?.treatmentEffectiveness || "Unknown",
            treatments: treatments,
        };

        //[
        // {
        //     type: "Mitigate",
        //     description: null,
        //     startDate: null,
        //     assignedTo: null,
        //     reviewDate: null,
        //     assigned: null,
        //     removedInReview: null,
        // },
        //]
        this.formData.nextReviewDue = options.nextReviewDue;
        this.formData.monitor = options.monitor;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        const isExceptionTemplate = this.formOptions.typeTemplate === "zerodai:risks:exception";

        const review = this.formData;

        return (
            review.nextReviewDue &&
            review.treatmentEffectiveness &&
            review.grossScore.score &&
            review.netScore.score &&
            (isExceptionTemplate ||
                (review.treatments &&
                    review.treatments.length == review.treatments.filter((t) => t.type && t.description).length)) &&
            review.grossScore.description &&
            review.netScore.description
        );
    }

    public onCreateTreatment = flow(function* (options) {
        try {
            const risk = this.parentStore.selectionStore.risk;
            const lifecycle = this.parentStore.selectionStore.lifecycle;

            if (!risk || !lifecycle) {
                return false;
            }

            const store = this.parentStore.treatmentFormStore;
            const { success, formData } = yield store.show({
                risk,
                lifecycle,
                treatment: options.treatment,
            });

            if (success && formData) {
                this.saving = true;

                formData.tempId = uuidv4();
                this.formData.treatments = [formData, ...this.formData.treatments];

                store.hide();
                return { success: true };
            }
            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onEditTreatment = flow(function* (options) {
        try {
            const risk = this.parentStore.selectionStore.risk;
            const lifecycle = this.parentStore.selectionStore.lifecycle;

            if (!risk || !lifecycle) {
                return false;
            }

            const store = this.parentStore.treatmentFormStore;
            const { success, remove, formData } = yield store.show({
                risk,
                lifecycle,
                treatment: options.treatment,
            });

            if (success && formData) {
                this.saving = true;

                if (remove) {
                    if (formData.id) {
                        const treatment = this.formData.treatments.find((t) => t.id == formData.id);
                        if (treatment.removedInReview?.id === "00000000-0000-0000-0000-000000000000") {
                            treatment.removedInReview = null;
                        } else {
                            treatment.removedInReview = { id: "00000000-0000-0000-0000-000000000000" };
                        }
                        this.formData.treatments = [...this.formData.treatments];
                    } else {
                        this.formData.treatments = this.formData.treatments.filter((t) =>
                            formData.id ? t.id !== formData.id : t.tempId !== formData.tempId
                        );
                    }
                } else {
                    const tIndex = this.formData.treatments.findIndex((t) =>
                        formData.id ? t.id === formData.id : t.tempId === formData.tempId
                    );
                    if (tIndex !== -1) {
                        this.formData.treatments[tIndex] = formData;
                        this.formData.treatments = [...this.formData.treatments];
                    }
                }

                store.hide();
                return { success: true };
            }
            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });
}
