import { observable, flow, computed, action } from "mobx";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export enum ViewTabType {
    Summary = "summary",
    Children = "children",
    Milestones = "milestones",
    Properties = "properties"
}

export class StrategicControlViewerStore {
    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: ViewTabType = ViewTabType.Summary;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Governance",
            key: "governance-dashboard",
            onClick: () => this.parentStore.rootStore.routing.push(`/governance/dashboard`)
        });

        builder.push({
            text: "Control Statements",
            key: "browse-controls",
            onClick: () => this.parentStore.rootStore.routing.push(`/governance/controls`)
        });

        if (!this.control) {
            return builder;
        }

        builder.push({ text: this.control.code, key: this.control.id, isCurrentItem: true });

        return builder;
    }

    @computed
    public get control() {
        return this.parentStore.strategicSelectionStore.control;
    }

    @computed
    public get permission() {
        return this.parentStore.strategicSelectionStore.permission;
    }

    @action
    public selectTab(tab: ViewTabType) {
        this.selectedTab = tab || ViewTabType.Summary;
    }

    public loadControl = flow(function* (options) {
        const o = Object.assign({ page: 1, query: {} }, options);
        this.loading = true;
        this.error = null;

        try {
            yield this.parentStore.strategicSelectionStore.loadControl(o.id);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
