import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Droppable } from 'react-beautiful-dnd';

import { PagePart } from './PagePart';
import { PagePartDivider } from './PagePartDivider';
import { PagePartList } from './PagePartList';

const rootClassName = cx(
  'cygraph-PageZone-root',
  css`
    position: relative;
    & .page--zone {
      &.none {
        background-color: transparent;
      }
      &.neutral {
        background-color: var(--body-background);
      }
      &.soft {
        background-color: var(--body-background);
      }
      &.strong {
        background-color: var(--body-background);
      }
      &.dragging-over {
        background-color: var(--yellow-1);
      }
    }
    & .page--zone-divider {
      display: none;
      & .wrapper {
        position: relative;
      }
    }
    &.edit {
      & .page--zone {
        min-height: 200px;
        border: 1px dashed var(--border-color);
        margin: var(--spacing-xxs);
        padding: var(--spacing-xxs);
      }

      & .droppable {
      }
      &:hover,
      &.selected {
        & .page--zone-divider {
          display: block;
        }
      }
    }
  `
);

export const PageZone = observer(
  class PageZone extends PureComponent {
    render () {
        const { className, collapsible, instance, section, name, title } = this.props;
        const mode = instance.lifecycleStore.mode;
        const selected = instance.partPickerStore.formData;
        const zone = section.zones.find(zone => zone.name === name);

        const callapsed = collapsible && mode === 'view' && (!zone || !zone.parts || zone.parts.length === 0);

        if(callapsed) {
          return null;
        }

        return (
          <div className={cx(rootClassName, className, mode, selected && selected.zoneName === name && selected.sectionId === section.id && 'selected')}>
            <Droppable droppableId={`${section.id}@${name}`} type="part" isDropDisabled={mode !== 'edit'}>
                  {(provided, snapshot) => (
                    <div className={cx('page--zone', 'droppable', zone && zone.theme, snapshot.isDraggingOver && 'dragging-over', snapshot.draggingFromThisWith && 'dragging-from')} 
                      {...provided.droppableProps} ref={provided.innerRef}>
                      <PagePartList instance={instance} section={section} zone={zone} />
                      {provided.placeholder}
                      <PagePartDivider instance={instance} section={section} name={name} />
                    </div>
                )}
              </Droppable>
          </div>
        );
    }
  }
);