import { observable, flow, computed } from "mobx";

import { RiskService } from "@api/risks";
import { RiskStore } from "./RiskStore";

export class ExceptionCatalogueStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public breadcrumb: any[] = [
        {
            text: "Exception Management",
            key: "exceptions",
            onClick: () => this._goToUrl(`/exceptions/dashboard`),
        },
        { text: "New Exception", key: "new", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get error() {
        return this.parentStore.typeStore.error;
    }

    @computed
    public get loading() {
        return this.parentStore.typeStore.loading;
    }

    @computed
    public get types() {
        return this.parentStore.typeStore.exceptionTypes;
    }

    public loadTypes = flow(function* (query: any) {
        yield this.parentStore.typeStore.loadTypes({ cached: false });
        yield this.parentStore.registryStore.loadRegistries({ cached: false });
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
