import { computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import {
    RoleNames,
    isUserInAllRoles,
    isUserInAnyRoles,
} from "../../identity/stores/PrincipalContextUtilities";

import { EntityService } from "../../../api/entities";
import { EntityStore } from "./EntityStore";
import { EntitySelectionStore } from "./EntitySelectionStore";

export class EntityPermissionStore {
    public parentStore: EntityStore;
    public entityService: EntityService;
    public selectionStore: EntitySelectionStore;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
        this.selectionStore = parentStore.selectionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAgent() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Admin, RoleNames.Agent]);
    }

    @computed
    public get canEdit() {
        return (
            isUserInAllRoles(this.principalContext.current, [
                RoleNames.Agent,
                RoleNames.Entities,
            ]) || isUserInAllRoles(this.principalContext.current, [RoleNames.Admin])
        );
    }
}
