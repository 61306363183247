import { observable, action, computed, flow } from "mobx";
import { ProductStore } from "./ProductStore";
import { ProductService } from "../../../api/graph";

export class ProductEditFormStore {
    private productService: ProductService;
    public parentStore: ProductStore;

    @observable public visible: boolean = false;
    @observable public error: any = null;
    @observable public showSuccessTicker = false;
    @observable public isCloseDialogOpen = false;

    @observable public formData: any;
    @observable public originalFormData: any;

    @observable public saving: boolean = false;

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
    }

    @computed
    public get isValid() {
        return (
            this.formData &&
            this.formData.id &&
            this.formData.name &&
            this.formData.availability &&
            this.formData.category &&
            this.formData.provider &&
            this.formData.state &&
            this.formData.assignedToGroup &&
            (!this.formData.isRequestable || !this.formData.isInternal || !!this.formData.flowId) &&
            (!this.formData.autoAssignmentMode ||
                this.formData.autoAssignmentMode !== "workItem.assignedToUser" ||
                this.formData.assignedToUser)
        );
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }

    @action public show({ product }) {
        this.visible = true;
        this.formData = { ...product };
        this.originalFormData = { ...product };
    }

    @action public hide() {
        this.formData = null;
        this.originalFormData = null;
        this.visible = false;
    }

    @action public resetFormData() {
        this.formData = { ...this.originalFormData };
    }

    public updateProduct = flow(function* () {
        this.saving = true;
        this.error = null;

        try {
            if (this.isValid) {
                const result = yield this.productService.updateProduct(this.formData);
                this.parentStore.browseStore.replace(result);
                this.parentStore.selectionStore.setSelected(result);
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Product ${result.name} updated successfully`
                );
                return result;
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
