import React, { useState } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { useStores } from "../../../hooks/useStores";

import { TaskDefinitionGridView } from "../components/tasks/TaskDefinitionGridView";

import { DefaultButton } from "../../base/ui";
import { TaskResponseEditDialog } from "@modules/workflows/containers/dialogs/TaskResponseEditDialog";
import { getCoreDataSource } from "../../datasources/utils/getCoreDataSource";

const contextToLink = (context) => {
    switch (context.type) {
        case "risk":
            return `/exceptions/browse/${context.id}`;
        case "assessment":
            return `/assurance/browse/${context.id}`;
        case "work-item":
            return `/demand/browse/${context.id}`;
    }
};

const containerClassName = cx(css`
    text-align: center;

    & .showAllButton {
        width: 100%;
        margin-top: 16px;
    }
`);

export const TaskListViewerPart = observer((props) => {
    const { className, instance, part } = props;
    const { themeProvider, routing, taskStore, accountStore, dataSourceStore } = useStores();
    const { responseStore } = taskStore;
    const { overviewStore } = accountStore;
    const { user } = overviewStore;
    const { dataSourceId } = part.properties;
    const [loadedTasks, setLoadedTasks] = useState(null);
    const theme = themeProvider.getTheme();
    let tasks = [];
    let userOnly = true;
    let showAllLink = null;

    if (dataSourceId) {
        const pagePart = instance.selectionStore.getPagePart(dataSourceId);
        if (pagePart.part.properties.length > 0) {
            const useronlyProps = pagePart.part.properties.filters.find((F) => F.id === "userOnly");
            userOnly = useronlyProps ? pagePart.part.properties.filters.find((F) => F.id === "userOnly").value === "true" : false;
        } else {
            userOnly = true;
        }
        const data = instance.selectionStore.data.find((D) => {
            return D.id === dataSourceId;
        });
        if (data) {
            const numberOfResults = part.properties.limitResult || 10;
            tasks = data.data.slice(0, numberOfResults);
        }

        if (userOnly) {
            showAllLink = "/account/tasks";
        } else {
            const resourceType = pagePart.part.properties.filters.find((F) => F.id === "resourceType");
            if (resourceType) {
                switch (resourceType.value) {
                    case "workItem":
                        const workItemContext = pagePart.part.properties.filters.find((F) => F.id === "workItemContext");
                        if (workItemContext) {
                            showAllLink = `/demand/browse/${workItemContext.value[0].key}/tasks`;
                        }
                        break;
                    case "assessment":
                        const assessmentContext = pagePart.part.properties.filters.find((F) => F.id === "assessmentContext");
                        if (assessmentContext) {
                            showAllLink = `/assurance/browse/${assessmentContext.value[0].key}/tasks`;
                        }
                        break;
                }
            }
        }
    }
    // console.log('showAllLink ',showAllLink);
    return (
        <div className={cx(containerClassName, className)}>
            <TaskDefinitionGridView
                tasks={loadedTasks || tasks || []}
                displayColumns={part.properties.selectedFields}
                loading={instance.selectionStore.loading || false}
                canEdit={() => false}
                canRespond={() => false}
                onTaskAssignmentClick={(item) => {
                    taskStore.responseStore.show({
                        taskAssignment: item,
                    });
                }}
            />
            <DefaultButton
                text="Show all"
                className="showAllButton"
                onClick={() => {
                    routing.push(showAllLink);
                }}
            />
            <TaskResponseEditDialog
                {...responseStore.formData}
                formData={responseStore.formData}
                canRespond={(item) => {
                    if (user && item && item.definition.assignments) {
                        return !!item.definition.assignments.find((A) => A.assignedTo.id === user.id && A.status === "Pending");
                    }
                    return false;
                }}
                canEdit={(item) => {
                    return false;
                }}
                //   user={user}
                hidden={responseStore.hidden}
                disabled={responseStore.saving}
                formOptions={responseStore.formOptions}
                onUpdateNotes={(val) => {
                    responseStore.formData.notes = val;
                }}
                onDismiss={() => {
                    responseStore.hide();
                }}
                onResponse={async () => {
                    await taskStore.responseStore.updateAssignment();
                    const taskDataSource = instance.selectionStore.dataSources.find((D) => D.id === dataSourceId);
                    const coreDataSource = getCoreDataSource(dataSourceId, instance.selectionStore.dataSources, dataSourceStore);
                    const results = await coreDataSource.searchService(taskDataSource.properties.query, taskDataSource.properties.groupBy, true);
                    if (results.items) {
                        const numberOfResults = part.properties.limitResult || 10;
                        setLoadedTasks(results.items.slice(0, numberOfResults));
                    }
                }}
                onEntityLinkClick={() => {
                    taskStore.responseStore.hide();
                    switch (responseStore.formData.definition.context.type) {
                        case "workItem":
                            routing.push(`/demand/browse/${responseStore.formData.definition.context.id}`);
                            break;
                        case "assessment":
                            routing.push(`/assurance/browse/${responseStore.formData.definition.context.id}`);
                            break;
                    }
                }}
                entityLinkTitle={`View ${responseStore.formData ? responseStore.formData.definition.context.label : ""}`}
            />
        </div>
    );
});
