import { observable, action, computed, flow } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemLinkEditFormStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const linkTypeStore = this.parentStore.linkTypeStore;
        yield linkTypeStore.loadWorkItemLinkTypes();

        this.formOptions = {
            ...options,
            linkTypes: linkTypeStore.linkTypes,
            pageTitle: options.pageTitle || options.workItem.title,
        };

        this.formData = { ...options.link };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.processing = false;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.id && this.formData.sourceId && this.formData.target && this.formData.target.id && this.formData.linkType && this.formData.linkType.id
        );
    }
}
