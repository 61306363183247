import { observable, flow, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class ControlSetViewerStore {
    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public set: any;
    @observable public controls: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: "Control Sets", key: "control-sets", onClick: () => this.parentStore.rootStore.routing.push(`/settings/control-sets`) });

        if (this.set) {
            builder.push({ text: this.set.name, key: this.set.id, isCurrentItem: true });
        }

        return builder;
    }

    public loadControlSet = flow(function* (options: any) {
        this.loading = true;
        const o = Object.assign({ page: 1, keywords: null, parentId: null }, options);

        this.set = null;
        this.controls = [];
        this.error = null;

        try {
            this.set = yield this.controlService.getControlSet(o.setId);

            const result = yield this.controlService.getControlSetControls({
                setId: o.setId,
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                keywords: o.keywords,
                parentId: o.parentId,
            });

            this.controls = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
