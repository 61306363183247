import { observable, action } from "mobx";

import { IdentityService } from '../../../api/identity';
import { PaginationStore } from '../../base/PaginationStore';
import { PrincipalPickerStore} from '../stores/PrincipalPickerStore';

export class PrincipaGrouplPickerStore {

    private identityService: IdentityService;
    public parentStore: PrincipalPickerStore;
    @observable public selectedPrincipals: any[] = [];
    @observable public principals: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public loading: boolean = false;

    constructor(parentStore: PrincipalPickerStore, identityService: IdentityService) {
        this.parentStore = parentStore;
        this.identityService = identityService;
        this.pagination = new PaginationStore();
    }

    @action
    public searchPrincipals(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: null }, query);
        return this.identityService.searchPrincipalsGroups({ 
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords
            })
            .then(this.searchPrincipalsOnSuccess, this.searchPrincipalsOnError);
    }
    
    @action.bound
    public searchPrincipalsOnSuccess(result) {
        this.principals = result.items;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public searchPrincipalsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }

    @action
    public resolvePrincipals(principals) {
        this.loading = true;
        return this.identityService.resolvePrincipals(principals)
            .then(this.resolvePrincipalsOnSuccess, this.resolvePrincipalsOnError);
    }
    
    @action.bound
    public resolvePrincipalsOnSuccess(result) {
        this.loading = false;
        return result;
    }

    @action.bound
    public resolvePrincipalsOnError(error) {
        this.loading = false;
        throw error;
    }

    @action
    public setSelected(principals: any[]) {
        this.selectedPrincipals = principals;
    }

    @action
    public setSelectedWithId(id: string | null) {
        if(!id) {
            this.selectedPrincipals = [];
        } else {
            if(this.selectedPrincipals.length == 1 && this.selectedPrincipals[0].id == id) {
                return;
            }
            this.loadPrincipals([id]);
        }
    }

    @action
    public setSelectedWithIds(ids: string[] | null) {
        if(ids == null || ids.length == 0) {
            this.selectedPrincipals = [];
        } else {
            this.loadPrincipals(ids);
        }
    }
    
    @action
    public loadPrincipals(ids: string[]) {
        this.loading = true;
        return this.identityService.getPrincipalsByIds(ids)
            .then(this.loadPrincipalsOnSuccess, this.loadPrincipalsOnError);
    }

    @action.bound
    public loadPrincipalsOnSuccess(result) {
        this.selectedPrincipals = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadPrincipalsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }
}
