import { RootStore } from "../../base/RootStore";
import { InsightsPortfolioPageStore } from './InsightsPortfolioPageStore';
import { WorkItemService } from '../../../api/workitems';

export class InsightsStore {

    public rootStore: RootStore;
    public insightsPortfolioPageStore: InsightsPortfolioPageStore;
    public workItemService: WorkItemService;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.workItemService = new WorkItemService(rootStore.authProvider);
        this.insightsPortfolioPageStore = new InsightsPortfolioPageStore(this);
    }
}
