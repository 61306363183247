import { observable, flow, computed } from "mobx";
import moment from "moment";

import { Engine } from "json-rules-engine";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentTriageStore {
    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;

    @observable public formData: any = null;
    @observable public formOptions: any = null;
    @observable public loading: boolean = false;
    @observable public processing: boolean = false;
    @observable public selectedStep: string = "identify";
    @observable public error: any;

    @observable
    public breadcrumb: any[] = [
        { text: "Assurance Management", key: "assurance", onClick: () => this._goToUrl(`/assurance/dashboard`) },
        { text: "Security Assessment Triage", key: "triage", isCurrentItem: true },
    ];

    @computed
    public get steps() {
        return [
            {
                id: "identify",
                name: "Identify Asset",
                description:
                    "Identify information asset under assessment and related organisational or business hierarchy.",
                href: "#",
                status: this.selectedStep === "identify" ? "current" : "complete",
            },
            {
                id: "scoping",
                name: "Use Case Selection",
                description: "Customise use case modules which are in-scope.",
                href: "#",
                status:
                    this.selectedStep === "identify"
                        ? "upcoming"
                        : this.selectedStep === "preview"
                        ? "complete"
                        : "current",
            },
            {
                id: "preview",
                name: "Finalise Triage",
                description: "Finalise triage information before it is submitted.",
                href: "#",
                status: this.selectedStep === "preview" ? "current" : "upcoming",
            },
        ];
    }

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    public onInit = flow(function* (options) {
        this.error = null;
        this.loading = true;

        try {
            const formData = {
                asset: null,
                stakeholder: null,
                businessContact: null,
                technicalContact: null,
                description: null,
            };
            const formOptions = {
                modules: [],
                recommendations: {},
            };

            const assetService = this.parentStore.rootStore.assetStore.assetService;
            if (options.assetId) {
                formData.asset = yield assetService.getAsset(options.assetId);
                formData.technicalContact = formData.asset.modifiedBy;
            }
            if (options.stakeholderId) {
                formData.stakeholder = yield assetService.getAsset(options.stakeholderId);
                formData.businessContact = formData.stakeholder.modifiedBy;
            }

            const moduleResult = yield this.assessmentService.searchControlModules({ pageSize: 100 });
            formOptions.modules = moduleResult.items;

            this.formData = formData;
            this.formOptions = formOptions;
            this.selectedStep = "identify";
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    @computed
    public get hasPrevious() {
        return this.selectedStep !== "identify";
    }

    public onPrevious = flow(function* () {
        this.error = null;
        this.processing = true;

        try {
            switch (this.selectedStep) {
                case "identify":
                    break;
                case "scoping":
                    this.selectedStep = "identify";
                    break;
                case "preview":
                    this.selectedStep = "scoping";
                    break;
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.processing = false;
        }
    });

    @computed
    public get canGoNext() {
        switch (this.selectedStep) {
            case "identify":
                return (
                    this.formData &&
                    this.formData.stakeholder &&
                    this.formData.stakeholder.id &&
                    this.formData.asset &&
                    this.formData.asset.id &&
                    this.formData.description &&
                    this.formData.businessContact &&
                    this.formData.businessContact.id &&
                    this.formData.technicalContact &&
                    this.formData.technicalContact.id
                );
            case "scoping":
                return (
                    this.formData &&
                    this.formData.targetPhase &&
                    this.formData.useCase &&
                    this.formData.useCase.id &&
                    this.formData.modules &&
                    this.formData.modules.length > 0
                );
            case "preview":
                return true;
        }
        return false;
    }

    public onNext = flow(function* () {
        this.error = null;
        this.processing = true;

        try {
            switch (this.selectedStep) {
                case "identify":
                    this.formOptions.triageState = yield this.assessmentService.getAssessmentTriageState(
                        this.formData.asset.id
                    );

                    if (this.formOptions.triageState && this.formOptions.triageState.useCase) {
                        this.formData.useCase = this.formOptions.triageState.useCase;
                    }
                    if (this.formOptions.triageState && this.formOptions.triageState.modules) {
                        this.formData.modules = this.formOptions.triageState.modules;
                    }

                    const engine = new Engine();
                    this.formOptions.modules
                        .filter((m) => m.expression && m.expression.conditions && m.expression.event)
                        .forEach((m) => {
                            const rule = { ...m.expression };
                            rule.event.params.moduleId = m.id;
                            rule.event.params.moduleName = m.name;
                            engine.addRule(rule);
                        });

                    const facts = {
                        asset: this.formData.asset,
                        stakeholder: this.formData.stakeholder,
                    };

                    // Run the engine to evaluate
                    engine.run(facts).then(({ events }) => {
                        const recommendations = {};
                        this.formOptions.recommendations = events
                            .filter((e) => e.type === "recommendation")
                            .forEach((recommendation: any) => {
                                recommendations[recommendation.params.moduleId] = recommendation.params;
                            });
                        this.formOptions.recommendations = recommendations;
                        //events.map((event) => console.log(event.params.message));
                    });

                    this.selectedStep = "scoping";
                    break;
                case "scoping":
                    this.selectedStep = "preview";
                    break;
                case "preview":
                    break;
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.processing = false;
        }
    });

    public onSave = flow(function* () {
        this.error = null;
        this.processing = true;

        try {
            this.formData.title = `${this.formData.asset.name} - ${moment().format("Do MMM YYYY")}`;
            const result = yield this.assessmentService.createFromTriage({ ...this.formData });
            if (result && result.id) {
                if (result.targetPhase === "Plan") {
                    this.parentStore.rootStore.routing.push(`/assurance/browse/${result.id}/requirements`);
                } else {
                    this.parentStore.rootStore.routing.push(`/assurance/browse/${result.id}`);
                }
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.processing = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
