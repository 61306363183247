import React, { Component } from 'react';
import { SpinButton } from 'office-ui-fabric-react/lib/SpinButton';

export class SpinButtonSuffix extends Component {
    
    onValidate(value, event) {
        const { suffix, min, max } = this.props;

        let numericValue = this.getNumericPart(value);
        if (numericValue !== undefined) {
          numericValue = Math.min(numericValue, max ? max : 100);
          numericValue = Math.max(numericValue, min ? min : 0);
          return String(numericValue) + suffix;
        }
    };

    onIncrement(value, event) {
        const { suffix, max, increment, onChange } = this.props;
        const numericValue = this.getNumericPart(value);
        if (numericValue !== undefined) {
            const newValue = Math.min(numericValue + increment, max); 
            if (onChange) onChange(event, newValue)
            else return String(newValue) + suffix;
        }
    };
  
    onDecrement(value, event) {
        const { suffix, min, increment, onChange } = this.props;
        const numericValue = this.getNumericPart(value);
        if (numericValue !== undefined) {
            const newValue = Math.max(numericValue - increment, min); 
            if (onChange) onChange(event, newValue)
            else return String(newValue) + suffix;
        }
    };

    getNumericPart(value) {
        const valueRegex = /^(\d+(\.\d+)?).*/;
        if (valueRegex.test(value)) {
          const numericValue = Number(value.replace(valueRegex, '$1'));
          return isNaN(numericValue) ? undefined : numericValue;
        }
        return undefined;
      };

    render () {
        const { suffix, min, max, onChange, increment, styles, value, label } = this.props;
        
        return (
            <SpinButton
                label={label}
                labelPosition={0}
                value={`${value} ${suffix}`}
                min={min ? min : 0}
                max={max ? max : 100}
                onValidate={(value, event) => this.onValidate(value, event)}
                onIncrement={(value, event) => this.onIncrement(value, event)}
                onDecrement={(value, event) => this.onDecrement(value, event)}
                onBlur={ev => {
                    if (onChange) onChange(ev, this.getNumericPart(ev.target.value)) 
                }}
                incrementButtonAriaLabel={`Increase value by ${increment}`}
                decrementButtonAriaLabel={`Decrease value by ${increment}`}
                styles={styles}
            />
        );
    }

};