import { observable } from "mobx";

import { WorkflowService } from "../../../api/workflows";
import { WorkflowStore } from "./WorkflowStore";

import { registrations as core } from "./registrations";

export class WorkflowRegistryStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;

    @observable public registrations: any = {};

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;

        this.registrations = { ...core };
    }

    public getRegistration(activity) {
        const fullName = `${activity.uniqueId}@${activity.version || "latest"}`;
        let registration = this.registrations[fullName];

        if (typeof registration === "string") {
            registration = this.registrations[registration];
        }

        return registration;
    }
}
