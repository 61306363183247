export const JsonQueryString = {
    build: (base, query, page, ignore) => {
        const q = JsonQueryString.encode(query, ignore);
        const parts = [];
        if (q) {
            parts.push(`query=${q}`);
        }
        if (page && page > 1) {
            parts.push(`p=${page}`);
        }
        return `${base}?${parts.join("&")}`;
    },
    encode: (obj, ignore = null) => {
        const json = JSON.stringify(obj, (key, val) => {
            if (
                val === null ||
                typeof val === "undefined" ||
                val.length === 0 ||
                (ignore && ignore.indexOf(key) !== -1)
            ) {
                return undefined;
            }
            return val;
        });

        if (json == "{}") {
            return "";
        }

        return encodeURIComponent(json);
    },
    decode: (str) => {
        if (!str) {
            return {};
        }

        return JSON.parse(decodeURIComponent(str));
    },
};
