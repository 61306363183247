import React from "react";
import { observer } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Text } from "office-ui-fabric-react/lib/Text";

export const TaskResponseStatus = observer(({ definition }) => {
    const { status, useCase, response } = definition;

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            {status == "Completed" && <Icon iconName="CompletedSolid" style={{ color: "var(--green)" }} />}
            {status == "Pending" && <Icon iconName="AwayStatus" style={{ color: "var(--yellow)" }} />}
            {(status == "Cancelled" || status == "Expired") && <Icon iconName="Blocked2Solid" style={{ color: "var(--grey)" }} />}

            <Text>{status}</Text>
            {status == "Completed" && useCase !== "Completion" && <Text>({response})</Text>}
        </Stack>
    );
});
