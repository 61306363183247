import { observable, flow, action } from "mobx";

import { PropertyService } from "@api/properties";
import { PropertyStore } from "./PropertyStore";

export class PropertySelectionStore {
    public parentStore: PropertyStore;
    public propertyService: PropertyService;

    @observable public property: any = null;
    @observable public section: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
    }

    @action
    public setProperty(property) {
        this.property = property;
    }

    @action
    public setPropertySection(section) {
        this.section = section;
    }

    public loadProperty = flow(function* (id: string) {
        this.loading = true;

        if (!this.property || this.property.id !== id) {
            this.property = null;
        }

        try {
            this.property = yield this.propertyService.getProperty(id);
        } finally {
            this.loading = false;
        }
    });

    public loadPropertySection = flow(function* (id: string) {
        this.loading = true;

        if (!this.section || this.section.id !== id) {
            this.section = null;
        }

        try {
            this.section = yield this.propertyService.getPropertySection(id);
        } finally {
            this.loading = false;
        }
    });
}
