import { observable, action, computed, flow } from "mobx";

import { WorkflowService } from "../../../api/workflows";

import { WorkflowStore } from "./WorkflowStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class WorkflowHistoryViewerStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public history: any;
    @observable public activities: any[];

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    public show = flow(function* (options) {
        this.history = options.history;

        const result = yield this.workflowService.getWorkflowActivities({
            id: this.history.id,
            maxResults: 250,
        });
        this.activities = result.items;
        this.visible = true;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    public hide = flow(function* (options) {
        this.visible = false;
        this.history = null;
        this.activities = null;
        this.responseHandle = null;
    });

    @action
    public resolve(success) {
        this.responseHandle.resolve({
            success,
            history: this.history,
            activities: this.activities,
        });
    }

    @action
    public reject(error) {
        this.responseHandle.reject(error);
    }

    @computed
    public get isValid() {
        return true;
    }
}
