import moment from 'moment';

function computeTimelineFilter(queryObject) {
    // const timeLineFilterObject = {
    //     before: moment().utc().startOf('month').add(1, 'month').format(),
    //     after: moment().utc().startOf('month').subtract(6, 'months').format(),
    // }
    const timeLineFilterObject = {
        before: null,
        after: null,
    }
    // debugger
    if (queryObject.rangeType === "fixedRange") {
        timeLineFilterObject.after = queryObject.fixedRangeStart;
        timeLineFilterObject.before = queryObject.fixedRangeEnd;
    } else {
        const timeLineFilter = queryObject.dynamicRangeType || 'Last6Months';
        switch (timeLineFilter) {
            case 'WithinLast7Days': // 7 days back from today. Today is not included
                timeLineFilterObject.after = moment().utc().startOf('day').subtract(7, 'days').format();
                timeLineFilterObject.before = moment().utc().startOf('day').format();
            break;
            case 'WithinLast30Days': // 30 days back from today. Today is not included
                timeLineFilterObject.after = moment().utc().startOf('day').subtract(30, 'days').format();
                timeLineFilterObject.before = moment().utc().startOf('day').format();
            break;
            case 'WithinLast90Days': // 90 days back from today. Today is not included
                timeLineFilterObject.after = moment().utc().startOf('day').subtract(90, 'days').format();
                timeLineFilterObject.before = moment().utc().startOf('day').format();
            break;
            case 'Older1Day': //from today minus 1 days
                timeLineFilterObject.after = null
                timeLineFilterObject.before = moment().utc().startOf('day').subtract(1, 'day').format();
            break;
            case 'Older7Days': //from today minus 7 days
                timeLineFilterObject.after = null
                timeLineFilterObject.before = moment().utc().startOf('day').subtract(7, 'days').format();
            break;
            case 'Older30Days': //from today minus 7 days
                timeLineFilterObject.after = null
                timeLineFilterObject.before = moment().utc().startOf('day').subtract(30, 'days').format();
            break;
            case 'Older90Days': //from today minus 7 days
                timeLineFilterObject.after = null
                timeLineFilterObject.before = moment().utc().startOf('day').subtract(90, 'days').format();
            break;

            case 'WithinLastMonth': // Feb (current month - 1, from 1st of the current month to today (1st of the following month))
                timeLineFilterObject.after = moment().utc().startOf('month').subtract(1, 'month').format();
                timeLineFilterObject.before = moment().utc().startOf('month').format();
            break;
            case 'WithinLast3Months': // Dec - Jan - Feb - (no current month) till the 1st of current month
                timeLineFilterObject.after = moment().utc().startOf('month').subtract(3, 'months').format();
                timeLineFilterObject.before = moment().utc().startOf('month').format();
            break;
            case 'WithinLast6Months': //Sep - Oct - Nov - Dec - Jan - Feb - (no current month) till the 1st of current month
                timeLineFilterObject.after = moment().utc().startOf('month').subtract(6, 'months').format();
                timeLineFilterObject.before = moment().utc().startOf('month').format();
            break;
            case 'WithinLast12Months': //(no current month) till the 1st of current month
                timeLineFilterObject.after = moment().utc().startOf('month').subtract(12, 'months').format();
                timeLineFilterObject.before = moment().utc().startOf('month').format();
            break;

            case 'WithinLastWeek': // From last Monday to last Sunday. (api monday to monday)
                timeLineFilterObject.after = moment().utc().subtract(1, 'week').startOf('isoWeek').format();
                timeLineFilterObject.before = moment().utc().startOf('isoWeek').format();
            break;

            default:
                timeLineFilterObject.after = moment().utc().startOf('month').format();
                timeLineFilterObject.before = null;
            break;
        }
    }
    return timeLineFilterObject;
}

export { computeTimelineFilter }