import { observable, flow, computed } from "mobx";

import { PageService } from "../../../api/pages";
import { PageInstanceStore } from "./PageInstanceStore";

export class PageSelectionStore {
    public pageService: PageService;
    public parentStore: PageInstanceStore;

    @observable public loading: boolean = false;
    @observable public page: any;
    @observable public permission: any;
    @observable public library: any;
    @observable public data: any[] = [];

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
    }

    @computed
    public get dataSources() {
        return this.page.content.sections.reduce((sectionAcc, section) => {
            const zones = section.zones.reduce((zoneAcc, zone) => {
                return zoneAcc.concat(zone.parts.filter((part) => part.type === "pages:core:data-source"));
            }, []);
            return sectionAcc.concat(zones);
        }, []);
    }

    @computed
    public get chartsComponent() {
        return this.page.content.sections.reduce((sectionAcc, section) => {
            const zones = section.zones.reduce((zoneAcc, zone) => {
                return zoneAcc.concat(zone.parts.filter((part) => part.type === "pages:core:chart-viewer"));
            }, []);
            return sectionAcc.concat(zones);
        }, []);
    }

    public getPageParts(partsId: string[]) {
        return this.page.content.sections.reduce((sectionAcc, section) => {
            const zones = section.zones.reduce((zoneAcc, zone) => {
                return zoneAcc.concat(zone.parts.filter((part) => partsId.includes(part.id)));
            }, []);
            return sectionAcc.concat(zones);
        }, []);
    }

    public getPagePart(partId: string) {
        let selectedSection = null;
        let selectedZone = null;
        let selectedPart = null;
        this.page.content.sections.forEach((section) => {
            const zones = section.zones.forEach((zone) => {
                const part = zone.parts.find((part) => partId === part.id);
                if (part) {
                    selectedSection = section;
                    selectedZone = zone;
                    selectedPart = part;
                }
            });
        });
        return {
            section: selectedSection,
            zone: selectedZone,
            part: selectedPart,
        };
    }

    public loadPageContentByPath = flow(function* (path, optional) {
        this.loading = true;
        if (this.page && this.page.path !== path) {
            this.page = null;
            this.permission = null;
            this.parentStore.sharingStore.reset();
        }
        try {
            this.page = yield this.pageService.getPageContentByPath(path, optional);
            if (this.page) {
                this.permission = yield this.pageService.getPagePermission(this.page.id);
                yield this.parentStore.sharingStore.loadSharingScopes();
            }
        } catch (e) {
            if (!optional || e.status !== 404) {
                throw e;
            }
        } finally {
            this.loading = false;
        }
    });

    public loadLibrary = flow(function* (id: string) {
        if (this.library && this.library.id !== id) {
            this.library = null;
        }
        try {
            this.library = yield this.pageService.getPageLibrary(id);
        } finally {
            this.loading = false;
        }
    });
}
