import { WorkflowConstants } from "./WorkflowConstants";

export const automations: any[] = [];

WorkflowConstants.events.forEach((event) => {
    WorkflowConstants.activities.forEach((activity) => {
        automations.push({
            name: `${event.name} 🠖 ${activity.name}`,
            definition: {
                uniqueId: activity.id,
            },
            trigger: {
                type: event.trigger,
                event: event.id,
                group: event.group,
                condition: null,
            },
        });
    });
});

function shuffle(array) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

shuffle(automations);
