import { RouterStore } from "mobx-react-router";
import { LayoutStore } from "../layout/LayoutStore";
import { PageStore as PageContext } from "../layout/PageStore";
import { ConfigurationStore } from "./ConfigurationStore";
import { RoutingManager } from "./RoutingManager";

import { ActivityStore } from "../activities/stores/ActivityStore";

import { ProductStore } from "../products/stores/ProductStore";
import { WorkItemStore } from "../workitems/stores/WorkItemStore";
import { PortfolioStore } from "../portfolios/stores/PortfolioStore";
import { PropertyStore } from "../properties/stores/PropertyStore";
import { SystemStore } from "../systems/stores/SystemStore";
import { AssetStore } from "../assets/stores/AssetStore";
import { EntityStore } from "../entities/stores/EntityStore";
import { AssessmentStore } from "../assessments/stores/AssessmentStore";
import { ModellingStore } from "../modelling/stores//ModellingStore";
import { PatternStore } from "../patterns/stores/PatternStore";
import { TriageStore } from "../triage/stores/TriageStore";
import { RiskStore } from "../risks/stores/RiskStore";
import { BoardsStore } from "../boards/stores/BoardsStore";
import { PrincipalStore } from "../identity/stores/PrincipalStore";
import { PrincipalContextStore } from "../identity/stores/PrincipalContextStore";
import { PrincipalPickerStore } from "../identity/stores/PrincipalPickerStore";
import { AccountStore } from "../accounts/stores/AccountStore";
import { SettingsStore } from "../settings/stores/SettingsStore";
import { PageStore } from "../pages/stores/PageStore";
import { FilterBarStore } from "../base/filterBar/FilterBarStore";
import { InsightsStore } from "../insights/stores/InsightsStore";
import { DataSourceStore } from "../datasources/stores/DataSourceStore";
import { ControlStore } from "../controls/stores/ControlStore";
import { WorkflowStore } from "../workflows/stores/WorkflowStore";
import { TaskStore } from "../workflows/stores/TaskStore";
import { StateFlowStore } from "../stateflows/stores/StateFlowStore";

import { RagStatusStore } from "../rag_status/stores/RagStatusStore";
import { NotificationStore } from "../layout/NotificationStore";

export class RootStore {
    authProvider: object;

    routing: RouterStore;
    routingManager: RoutingManager;

    notificationStore: NotificationStore;
    layoutStore: LayoutStore;
    pageContext: PageContext;

    activityStore: ActivityStore;

    configurationStore: ConfigurationStore;
    stateFlowStore: StateFlowStore;

    productStore: ProductStore;
    workItemStore: WorkItemStore;
    portfolioStore: PortfolioStore;
    propertyStore: PropertyStore;
    systemStore: SystemStore;
    assetStore: AssetStore;
    entityStore: EntityStore;
    assessmentStore: AssessmentStore;
    modellingStore: ModellingStore;
    patternStore: PatternStore;
    triageStore: TriageStore;
    riskStore: RiskStore;
    boardsStore: BoardsStore;
    settingsStore: SettingsStore;
    pageStore: PageStore;
    insightsStore: InsightsStore;
    dataSourceStore: DataSourceStore;
    controlStore: ControlStore;
    workflowStore: WorkflowStore;
    taskStore: TaskStore;

    ragStatusStore: RagStatusStore;
    principalStore: PrincipalStore;
    principalContext: PrincipalContextStore;
    accountStore: AccountStore;
    pickerStore: PrincipalPickerStore;
    filterBarStore: FilterBarStore;

    constructor(authProvider: any) {
        this.authProvider = authProvider;

        this.routing = new RouterStore();
        this.routingManager = new RoutingManager(this);

        this.notificationStore = new NotificationStore(this);
        this.layoutStore = new LayoutStore(this);
        this.pageContext = new PageContext(this);

        this.activityStore = new ActivityStore(this);

        this.configurationStore = new ConfigurationStore(this);
        this.stateFlowStore = new StateFlowStore(this);

        this.principalStore = new PrincipalStore(this);
        this.principalContext = new PrincipalContextStore(this);

        this.productStore = new ProductStore(this);
        this.workItemStore = new WorkItemStore(this);
        this.portfolioStore = new PortfolioStore(this);

        this.propertyStore = new PropertyStore(this);
        this.systemStore = new SystemStore(this);
        this.assetStore = new AssetStore(this);
        this.entityStore = new EntityStore(this);

        this.assessmentStore = new AssessmentStore(this);
        this.modellingStore = new ModellingStore(this);
        this.patternStore = new PatternStore(this);
        this.triageStore = new TriageStore(this);
        this.riskStore = new RiskStore(this);
        this.boardsStore = new BoardsStore(this);
        this.settingsStore = new SettingsStore(this);
        this.pageStore = new PageStore(this);
        this.insightsStore = new InsightsStore(this);
        this.dataSourceStore = new DataSourceStore(this);
        this.controlStore = new ControlStore(this);

        this.pickerStore = new PrincipalPickerStore(this);
        this.accountStore = new AccountStore(this);
        this.filterBarStore = new FilterBarStore(this);

        this.workflowStore = new WorkflowStore(this);
        this.taskStore = new TaskStore(this);

        this.ragStatusStore = new RagStatusStore(this);
    }
}
