import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import RichTextEditor from '../../../editors/components/RichTextEditor';
import RichTextViewer from '../../../editors/components/RichTextViewer';

const rootClassName = cx(
  'cygraph-RichTextEditorPart-root',
  css`

    h1 {
      font-size: 20px;
      font-weight: 600;
      display: block;
      color: inherit;
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
      display: block;
      color: inherit;
    }

    [data-slate-editor] > * + * {
      margin-top: 0.3em;
    }

    &.edit {
      box-shadow: none;
      border: none;
      background: rgb(255, 255, 255);

      & div:first-child {
          padding: 5px 10px;
          border-bottom: 1px solid var(--border-color);
      }

      & div:last-child {
          padding: 6px 11px;
          min-height: 105px;
      }
    }
  `
);

export const RichTextEditorPart = observer(
  class RichTextEditorPart extends PureComponent {
    render () {
      const { className, instance, part, zone, section, registry } = this.props;
      const mode = instance.lifecycleStore.mode;
      return (
        <div className={cx(rootClassName, className, mode)}>
          { mode == 'edit' ? 
            <RichTextEditor {...part.properties} value={part.properties.value} onChange={(value) => part.properties.value = value} /> : 
            <RichTextViewer {...part.properties} value={part.properties.value} /> }
        </div>
      );
    }
  }
);