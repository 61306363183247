import React from "react";
import classNames from "classnames";
import { cx, css } from "emotion";

import {
    PrimaryButton as FabricPrimaryButton,
    IconButton as FabricIconButton,
    DefaultButton as FabricDefaultButton,
    ActionButton as FabricActionButton,
    CommandButton as FabricCommandButton,
    CommandBarButton as FabricCommandBarButton,
} from "office-ui-fabric-react/lib/Button";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";

const rootClassName = cx(
    "cygraph-GhostButton-root",
    css`
        border: 1px solid var(--background-color);
        background-color: transparent;
        padding: 0 10px;
        font-size: 13px;

        & .ms-Button-label {
            font-weight: normal;
        }

        &:hover {
            background-color: var(--white);
            border: 1px solid var(--border-color-dark);
        }
    `
);

export function Button({
    text,
    children,
    primary,
    ghost,
    block,
    simple,
    action,
    className,
    iconProps,
    size,
    menuProps,
    menuIconProps,
    onClick,
    ...rest
}) {
    const iconOnly = !children && !text;
    return (
        <button
            onClick={(ev) => {
                if (onClick) {
                    ev.preventDefault();
                    onClick(ev);
                }
            }}
            {...rest}
            className={classNames(
                {
                    "border border-solid items-center": true,
                    "flex justify-center rounded-sm focus:outline-none": true,
                    "border-transparent bg-transparent text-gray-600 hover:text-indigo-700": action && !rest.disabled,
                    "border-transparent bg-transparent text-gray-400": action && rest.disabled,
                    "border-indigo-600 bg-indigo-600 text-white hover:bg-indigo-700": primary && !rest.disabled,
                    "border-indigo-400 bg-indigo-400 text-indigo-50": primary && rest.disabled,
                    "border-gray-400 bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-800":
                        simple && !rest.disabled,
                    "border-gray-400  bg-gray-50 text-gray-300": simple && rest.disabled,
                    "bg-transparent border-transparent ": ghost,
                    "hover:bg-gray-100 hover:text-gray-800 hover:border-gray-300": ghost && !rest.disabled,
                    "w-full": block,
                    "text-xs font-light h-6": size === "xs",
                    "text-xs font-light h-7": size === "sm",
                    "text-sm font-normal h-8": !size || size === "md",
                    "text-sm font-normal h-12": size === "lg",
                },
                size === "lg" && !iconOnly && "px-5 h-9",
                size !== "lg" && !iconOnly && "px-3 h-9",
                size === "lg" && iconOnly && "px-3 h-9",
                size !== "lg" && iconOnly && "px-2 h-9",
                className
            )}
        >
            {iconProps && (
                <>
                    {iconProps.iconName && (
                        <FontIcon
                            className={classNames({
                                "text-indigo-600": !primary && !ghost && !rest.disabled,
                                "text-gray-400": !primary && !ghost && rest.disabled,
                                "h-3 w-3": size == "xs",
                                "h-4 w-4": size == "sm",
                                "h-5 w-5": !size || size == "md" || size == "lg",
                                "-ml-1 mr-2": !iconOnly,
                            })}
                            {...iconProps}
                        />
                    )}
                    {iconProps.icon && (
                        <iconProps.icon
                            {...iconProps}
                            className={classNames(
                                {
                                    "text-indigo-600": !primary && !ghost && !rest.disabled,
                                    "text-gray-400": !primary && !ghost && rest.disabled,
                                    "h-3 w-3": size == "xs",
                                    "h-4 w-4": size == "sm",
                                    "h-5 w-5": !size || size == "md" || size == "lg",
                                    "-ml-1 mr-2": !iconOnly,
                                },
                                iconProps.className
                            )}
                        />
                    )}
                </>
            )}
            <span className={classNames({ "hover:underline": action && !rest.disabled })}>{children || text}</span>
        </button>
    );
}

const PrimaryButton = (props) => {
    return <Button {...props} primary />;
};

const DefaultButton = (props) => {
    return props.menuProps ? <FabricDefaultButton {...props} /> : <Button {...props} simple />;
};

const IconButton = (props) => {
    return <Button {...props} />;
};

const GhostButton = (props) => {
    return props.menuProps ? <FabricDefaultButton {...props} /> : <Button {...props} ghost />;
};

const ActionButton = (props) => {
    return props.menuProps ? <FabricActionButton style={{ height: 32 }} {...props} /> : <Button {...props} action />;
};

const CommandButton = (props) => {
    return <FabricCommandButton style={{ height: 32 }} {...props} />;
};

const CommandBarButton = (props) => {
    return <FabricCommandBarButton style={{ height: 32 }} {...props} />;
};

const SpinnerButton = ({ loading, ...props }) => {
    if (props.primary || typeof props.primary === "undefined") {
        return (
            <Button
                {...props}
                primary
                text={loading ? <Spinner size={SpinnerSize.small} {...props.spinnerProps} /> : props.text}
            />
        );
    } else {
        return (
            <Button
                {...props}
                text={loading ? <Spinner size={SpinnerSize.small} {...props.spinnerProps} /> : props.text}
            />
        );
    }
};

export {
    PrimaryButton,
    IconButton,
    DefaultButton,
    GhostButton,
    ActionButton,
    SpinnerButton,
    CommandButton,
    CommandBarButton,
};
