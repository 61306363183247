import { observable, flow, computed } from "mobx";

import { StateFlowService } from "../../../api/stateflows";
import { StateFlowStore } from "./StateFlowStore";

export class StateFlowInstanceStore {
    public parentStore: StateFlowStore;
    public stateFlowService: StateFlowService;
    public options: any;

    @observable public loading: boolean = false;
    @observable public manifest: any;
    @observable public subject: any;
    @observable public conditions: any = {};
    @observable public error: any;

    constructor(parentStore: StateFlowStore, options: any) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
        this.options = Object.assign({}, options);
        this.conditions = this.options.conditions;
    }

    @computed
    public get state() {
        if (this.manifest && this.subject) {
            return this.subject[this.options.properties.state];
        }
    }

    @computed
    public get subState() {
        if (this.manifest && this.subject) {
            return this.subject[this.options.properties.subState];
        }
    }

    @computed
    public get subStateId() {
        if (this.manifest && this.subject && this.subject.workflowData) {
            return this.subject.workflowData.stateId;
        }
        return null;
    }

    @computed
    public get currentSubState() {
        if (!this.manifest || !this.subject) {
            return [];
        }

        let currentSubState = null;
        if (this.subStateId) {
            currentSubState = this.manifest.states.find((s) => s.id == this.subStateId);
        } else {
            const currentSubStateName = this.subject[this.options.properties.subState];
            currentSubState = this.manifest.states.find((s) => s.name == currentSubStateName);
        }

        return currentSubState;
    }

    @computed
    public get triggers() {
        if (!this.manifest || !this.subject) {
            return [];
        }

        let currentSubState = null;
        if (this.subStateId) {
            currentSubState = this.manifest.states.find((s) => s.id == this.subStateId);
        } else {
            const currentSubStateName = this.subject[this.options.properties.subState];
            currentSubState = this.manifest.states.find((s) => s.name == currentSubStateName);
        }

        if (!currentSubState) {
            return [];
        }

        return currentSubState.triggers || [];
    }

    public loadStateFlowManifest = flow(function* (options: any) {
        const o = Object.assign({}, options);

        this.loading = true;
        this.manifest = null;
        this.error = null;

        try {
            this.manifest = yield this.stateFlowService.getStateFlowVersionManifest(o.stateFlowVersionId);
            if (this.manifest && this.manifest.target !== this.options.properties.target) {
                console.error(
                    "Invariant failed - unexpected target",
                    "expected:",
                    this.options.properties.target,
                    "found:",
                    this.manifest.target
                );
            }
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public setSubject = flow(function* (subject) {
        this.subject = subject;
    });

    public onTrigger = flow(function* (trigger: any, options: any) {
        let currentState = this.manifest.states.find((s) => s.id == this.subStateId);
        if (!currentState) {
            currentState = this.manifest.states.find((s) => s.name == this.subState);
        }

        const currentTrigger = (currentState.triggers || []).find((t) => t.id == trigger.id);
        const command = this.options.commands[currentTrigger.command.type];
        if (command && typeof command === "function") {
            command(currentTrigger, options);
        }
    });
}
