import React, { useRef, useState, useCallback } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { cx, css } from "emotion";
import { useId } from "@fluentui/react-hooks";

import { IconButton } from "@ui/elements/Button";
import { Stack } from "@ui/elements/Stack";
import { DatePicker } from "@ui/elements/DatePicker";
import { ExtendedLabel } from "@ui/elements/ExtendedLabel";
import { TextField } from "@ui/elements/TextField";
import { Callout } from "@ui/elements/Callout";
import { DurationField, decodeDuration } from "@ui/elements/DurationField";
import { Checkbox } from "@ui/elements/Checkbox";
import { ChoiceGroup } from "@ui/elements/ChoiceGroup";
import { Label } from "@ui/elements/Label";

const weekdayRegex = /^\$\.weekday\((.+),(true|false)\)$/m;
const dateAddRegex = /^\$\.dateAdd\((\$\.nowUtc\(\)),'([A-Z0-9]+)'\)$/m;

const defaultFormatter = (expression, text) => {
    return `in ${text} ${expression.weekdayOnly ? "*" : ""}`;
};

const propertyMap = {
    "work-item": [],
    assessment: [],
    risk: [],
    exception: [],
};

const rootClassName = cx("cygraph-WorkflowDatePicker-root", css``);

const calloutClassName = cx(
    "cygraph-WorkflowDatePicker-callout",
    css`
        background-color: var(--white);
        padding: var(--spacing-xs);
        min-width: 270px;
        max-width: 420px;
    `
);

export const WorkflowDatePicker = observer((props) => {
    const buttonRef = useRef();
    const [visible, setVisible] = useState(false);
    const pickerId = useId("picker");

    const { label, required, disabled, placeholder, className, value, onChange, allowClear, formatter } = props;

    const onInputClick = useCallback(() => {
        setVisible(true);
    }, []);

    const onDismiss = useCallback(() => {
        setVisible(false);
    }, []);

    const encodeExpression = useCallback(
        (model) => {
            if (model.type === "date") {
                return moment.utc(model.date).format();
            }

            if (model.weekdayOnly) {
                return {
                    $eval: `$.weekday($.dateAdd($.nowUtc(),'${model.duration}'),false)`,
                };
            }

            return {
                $eval: `$.dateAdd($.nowUtc(),'${model.duration}')`,
            };
        },
        [props.propertyModel]
    );

    const decodeExpression = useCallback(
        (expr) => {
            if (!expr || expr.$eval) {
                const expression = {
                    type: "calculated",
                    expr: expr ? expr.$eval : null,
                    duration: "P14D",
                    weekdayOnly: true,
                    businessHours: false,
                    text: null,
                };

                if (expr) {
                    let exprBody = expr.$eval;
                    const weekdayMatch = weekdayRegex.exec(exprBody);

                    if (weekdayMatch && weekdayMatch.length === 3) {
                        expression.weekdayOnly = true;
                        expression.businessHours = weekdayMatch[2] === "true";
                        exprBody = weekdayMatch[1];
                    } else {
                        expression.weekdayOnly = false;
                    }

                    const bodyMatch = dateAddRegex.exec(exprBody);

                    if (bodyMatch && bodyMatch.length === 3) {
                        expression.duration = bodyMatch[2];
                    }
                }

                const { text } = decodeDuration(expression.duration);
                expression.text = (formatter || defaultFormatter)(expression, text);

                return expression;
            }

            const date = expr ? moment.utc(expr).toDate() : new Date();
            return {
                type: "date",
                date: date,
                text: moment(date).format("Do MMM YYYY"),
            };
        },
        [props.propertyModel]
    );

    const model = decodeExpression(value);
    return (
        <Stack className={cx(rootClassName, className)}>
            <ExtendedLabel label={label} required={required} disabled={disabled} htmlFor={pickerId}>
                {allowClear && !!value && (
                    <IconButton
                        styles={{ root: { width: 24, height: 24 }, icon: { fontSize: 13, lineHeight: 13 } }}
                        iconProps={{ iconName: "ClearFilter" }}
                        onClick={() => onChange(null)}
                    />
                )}
            </ExtendedLabel>
            <div ref={buttonRef} disabled={disabled} role="button">
                <TextField
                    id={pickerId}
                    readOnly
                    value={model.text || ""}
                    onClick={onInputClick}
                    iconProps={{ iconName: "DateTime" }}
                />
            </div>
            {buttonRef.current && (
                <Callout
                    isBeakVisible={false}
                    gapSpace={0}
                    hidden={!visible}
                    className={calloutClassName}
                    target={buttonRef.current}
                    onDismiss={onDismiss}
                >
                    <Stack tokens={{ childrenGap: 10 }}>
                        <ChoiceGroup
                            label="Choose"
                            required
                            selectedKey={model.type}
                            options={[
                                { key: "date", text: "a specific date" },
                                { key: "calculated", text: "a calculated time" },
                            ]}
                            onChange={(ev, option) => {
                                if (option.key === "date") {
                                    onChange(encodeExpression({ type: "date", date: new Date() }));
                                } else {
                                    onChange(
                                        encodeExpression({ type: "calculated", duration: "P14D", weekdayOnly: true })
                                    );
                                }
                            }}
                        />
                        <hr />
                        {model.type === "date" && (
                            <Stack tokens={{ childrenGap: 10 }}>
                                <DatePicker
                                    label="Select a date"
                                    required={required}
                                    disabled={disabled}
                                    value={model.date}
                                    onSelectDate={onChange}
                                    placeholder={placeholder}
                                />
                            </Stack>
                        )}
                        {model.type === "calculated" && (
                            <Stack tokens={{ childrenGap: 10 }}>
                                <DurationField
                                    label="Time span"
                                    required={required}
                                    value={model.duration}
                                    onChange={(duration) => {
                                        onChange(encodeExpression({ ...model, duration }));
                                    }}
                                    time={false}
                                />
                                <Label>
                                    If calculated date falls on a weekend
                                    <Checkbox
                                        label="select the following Monday"
                                        checked={model.weekdayOnly}
                                        onChange={(ev, weekdayOnly) => {
                                            onChange(encodeExpression({ ...model, weekdayOnly }));
                                        }}
                                    />
                                </Label>
                                {/* <ChoiceGroup
                                    label="If selected day falls on a weekend"
                                    required
                                    selectedKey={model.weekdayOnly ? "weekday" : "none"}
                                    options={[
                                        { key: "weekday", text: "use following Monday" },
                                        { key: "none", text: "allow it" },
                                    ]}
                                    onChange={(ev, option) => {
                                        onChange(encodeExpression({ ...model, weekdayOnly: option.key === "weekday" }));
                                    }}
                                /> */}
                            </Stack>
                        )}
                    </Stack>
                </Callout>
            )}
        </Stack>
    );
});
