import { observable, computed, flow } from "mobx";

import { WorkflowService } from "@api/workflows";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";

import { WorkflowStore } from "@modules/workflows/stores/WorkflowStore";
import { WorkflowSelectionStore } from "@modules/workflows/stores/WorkflowSelectionStore";
import { WorkflowPermissionStore } from "@modules/workflows/stores/WorkflowPermissionStore";

export class WorkflowLifecycleStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;
    public selectionStore: WorkflowSelectionStore;
    public permissionStore: WorkflowPermissionStore;
    public principalContext: PrincipalContextStore;

    @observable public saving: boolean = false;
    @observable public error: any;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    public onViewHistory = flow(function* (options) {
        try {
            const store = this.parentStore.historyViewerStore;
            const { success, formData } = yield store.show(options);
            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        }
    });

    public onShowBuilder = flow(function* (options) {
        try {
            const store = this.parentStore.builderStore;
            const result = yield store.show(options);

            store.hide();
            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        } finally {
            this.saving = false;
        }
    });

    public onAddActivity = flow(function* (options) {
        try {
            let instance = options.instance;
            if (!instance) {
                const builderStore = this.parentStore.builderStore;
                const result = yield builderStore.show(options);
                builderStore.hide();

                if (result.success) {
                    instance = {
                        definition: {
                            uniqueId: result.instance.definition.uniqueId,
                        },
                        trigger: {
                            event: result.instance.trigger.event,
                            group: result.instance.trigger.group,
                            condition: result.instance.trigger.condition,
                        },
                        scope: options.context,
                    };
                } else {
                    return { success: false, message: "Cancelled" };
                }
            }

            const store = this.parentStore.newFormStore;
            const { success, formData } = yield store.show(options);

            if (success && formData) {
                this.saving = true;

                const created = yield this.workflowService.createActivityInstance({
                    ...formData,
                });

                yield this.parentStore.selectionStore.setSelected(created);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Automation \'${created.name}\' created successfully.`
                );

                store.hide();

                this.parentStore.browseStore.loadActivityInstances({ context: created.scope });
                return { success, activity: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        } finally {
            this.saving = false;
        }
    });

    public onEditActivity = flow(function* (options) {
        try {
            const store = this.parentStore.editFormStore;
            const { success, formData } = yield store.show(options);

            if (success && formData) {
                this.saving = true;

                const updated = yield this.workflowService.updateActivityInstance({
                    ...formData,
                });

                yield this.parentStore.selectionStore.setSelected(updated);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Automation \'${updated.name}\' updated successfully.`
                );

                store.hide();

                return { success, activity: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        } finally {
            this.saving = false;
        }
    });

    public onDeleteActivity = flow(function* (options) {
        try {
            const store = this.parentStore.deleteFormStore;
            const { success, formData } = yield store.show(options);

            if (success && formData) {
                this.saving = true;

                const updated = yield this.workflowService.deleteActivityInstance(formData.id);

                yield this.parentStore.selectionStore.setSelected(null);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Automation \'${formData.name}\' removed successfully.`
                );

                store.hide();

                return { success, activity: formData };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error: error };
        } finally {
            this.saving = false;
        }
    });
}
