import { observable, action, computed, flow } from "mobx";

import { RiskService } from "../../../api/risks";

import { RiskStore } from "./RiskStore";

export class RiskRegistryNewFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;

    @observable public originalFormData: any;
    @observable public formData: any;
    @observable public visible: boolean = false;
    @observable public isSaving: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public hide() {
        this.visible = false;
        this.formData = {};
    }

    @action
    public show() {
        this.visible = true;
        this.formData = {};
    }

    @action
    public reset() {
        this.formData = {};
    }

    @computed public get isChanged() {
        return JSON.stringify(this.formData) !== '{}';
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        if (this.formData.codeFormat) {
            var pattern = /.*\{0(:.*)?\}.*/gi;
            var patt = new RegExp(pattern);
            if (!patt.test(this.formData.codeFormat)) {
                return false
            }
        }

        return (
            this.formData.name
        );
    }

    save = flow(function*(){
        this.isSaving = true
        try {
            this.formData.codeFormat = this.formData.codeFormat ? this.formData.codeFormat : '{0}'
            yield this.riskService.createRegistry(this.formData)
            this.hide()
        } catch (e) {
            console.error(e)
        } finally {
            this.isSaving = false
        }
    })
}
