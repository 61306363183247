import React, { useRef, useState, useCallback } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { cx, css } from "emotion";
import { useId } from "@fluentui/react-hooks";
import debounce from "lodash.debounce";

import { Stack } from "@ui/elements/Stack";
import { ExtendedLabel } from "@ui/elements/ExtendedLabel";
import { IconButton } from "@ui/elements/Button";
import { TextField } from "@ui/elements/TextField";
import { Callout } from "@ui/elements/Callout";

const rootClassName = cx("cygraph-DurationField-root", css``);

const calloutClassName = cx(
    "cygraph-DurationField-callout",
    css`
        background-color: var(--white);
        padding: var(--spacing-xs);
        min-width: 150px;
        max-width: 200px;

        & .ms-TextField input {
            text-align: right;
        }
        & .ms-TextField-suffix {
            width: 25%;
            min-width: 80px;
            font-size: 12px;
        }
    `
);

const ZeroDuration = { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };

export const decodeDuration = (duration) => {
    const d = moment.duration(duration || ZeroDuration);
    const update = {
        years: d.years(),
        months: d.months(),
        days: d.days(),
        hours: d.hours(),
        minutes: d.minutes(),
        seconds: d.seconds(),
    };
    const parts = [];
    Object.entries(update).forEach(([key, val]) => {
        if (val) {
            parts.push(`${val} ${val === 1 ? key.slice(0, -1) : key}`);
        }
    });

    const displayText = parts.join(", ");

    return {
        text: displayText,
        iso: displayText === "" ? null : d.toISOString(),
        json: update,
    };
};

export const encodeDuration = (duration) => {
    const d = moment.duration(duration);
    return d.toISOString();
};

export const DurationField = observer(
    ({ className, required, disabled, label, value, onChange, multiple, allowClear, years, months, time }) => {
        const buttonRef = useRef();
        const [visible, setVisible] = useState(false);
        const pickerId = useId("durationField");

        const debouncedOnChange = onChange; // useMemo(() => debounce(onChange, 100), [onChange]);

        const onInputClick = useCallback(() => {
            setVisible(true);
        }, []);

        const onDismiss = useCallback(() => {
            setVisible(false);
        }, []);

        const { iso, json, text } = decodeDuration(value);

        return (
            <Stack className={cx(rootClassName, className)}>
                <ExtendedLabel label={label} required={required} disabled={disabled} htmlFor={pickerId}>
                    {allowClear && (multiple ? value && value.length > 0 : !!value) && (
                        <IconButton
                            className="border-none"
                            iconProps={{ iconName: "ClearFilter" }}
                            onClick={() => onChange(multiple ? [] : null)}
                        />
                    )}
                </ExtendedLabel>
                <div ref={buttonRef} disabled={disabled} role="button">
                    <TextField
                        id={pickerId}
                        readOnly
                        value={text || ""}
                        onClick={onInputClick}
                        disabled={disabled}
                        iconProps={{ iconName: "DateTime" }}
                    />
                </div>
                {buttonRef.current && (
                    <Callout
                        isBeakVisible={false}
                        gapSpace={0}
                        hidden={!visible}
                        className={calloutClassName}
                        target={buttonRef.current}
                        onDismiss={onDismiss}
                    >
                        <Stack tokens={{ childrenGap: 5 }}>
                            {years !== false && (
                                <TextField
                                    value={json.years}
                                    suffix="Years"
                                    onChange={(ev, val) => {
                                        if (!isNaN(parseInt(val))) {
                                            debouncedOnChange(encodeDuration({ ...json, years: parseInt(val) }));
                                        }
                                    }}
                                />
                            )}
                            {months !== false && (
                                <TextField
                                    value={json.months}
                                    suffix="Months"
                                    onChange={(ev, val) => {
                                        if (!isNaN(parseInt(val))) {
                                            debouncedOnChange(encodeDuration({ ...json, months: parseInt(val) }));
                                        }
                                    }}
                                />
                            )}

                            <TextField
                                value={json.days}
                                suffix="Days"
                                onChange={(ev, val) => {
                                    if (!isNaN(parseInt(val))) {
                                        debouncedOnChange(encodeDuration({ ...json, days: parseInt(val) }));
                                    }
                                }}
                            />
                            {time !== false && (
                                <>
                                    <TextField
                                        value={json.hours}
                                        suffix="Hours"
                                        onChange={(ev, val) => {
                                            if (!isNaN(parseInt(val))) {
                                                debouncedOnChange(encodeDuration({ ...json, hours: parseInt(val) }));
                                            }
                                        }}
                                    />
                                    <TextField
                                        value={json.minutes}
                                        suffix="Minutes"
                                        onChange={(ev, val) => {
                                            if (!isNaN(parseInt(val))) {
                                                debouncedOnChange(encodeDuration({ ...json, minutes: parseInt(val) }));
                                            }
                                        }}
                                    />
                                    <TextField
                                        value={json.seconds}
                                        suffix="Seconds"
                                        onChange={(ev, val) => {
                                            if (!isNaN(parseInt(val))) {
                                                debouncedOnChange(encodeDuration({ ...json, seconds: parseInt(val) }));
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </Stack>
                    </Callout>
                )}
            </Stack>
        );
    }
);
