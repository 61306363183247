import { SendEmailActivityEditor } from "@modules/workflows/components/activities/SendEmailActivityEditor";
import { LogOutputActivityEditor } from "@modules/workflows/components/activities/LogOutputActivityEditor";
import { CreateTaskActivityEditor } from "@modules/workflows/components/activities/CreateTaskActivityEditor";
import { HttpRequestActivityEditor } from "@modules/workflows/components/activities/HttpRequestActivityEditor";

export const registrations: any = {
    "zerodai:activities:built-in:send-email@1.0.0": {
        editor: SendEmailActivityEditor,
        onInit: (activity) => {
            if (activity) {
                activity.settings = {
                    template: "generic.workflow.template",
                    subject: null,
                    body: null,
                    recipients: [],
                    ...activity.settings,
                };
            }
        },
        validator: (activity) => {
            return (
                activity &&
                activity.settings &&
                activity.settings.template &&
                activity.settings.subject &&
                activity.settings.body &&
                activity.settings.recipients &&
                activity.settings.recipients.length > 0 &&
                activity.settings.recipients.filter((r) => r.id || r.email || !!r.$eval).length ===
                    activity.settings.recipients.length
            );
        },
    },
    "zerodai:activities:built-in:send-email@latest": "zerodai:activities:built-in:send-email@1.0.0",
    "zerodai:activities:built-in:log-output@1.0.0": {
        editor: LogOutputActivityEditor,
        onInit: (activity) => {
            if (activity) {
                activity.settings = {
                    message: null,
                    ...activity.settings,
                };
            }
        },
        validator: (activity) => {
            return activity && activity.settings && activity.settings.message;
        },
    },
    "zerodai:activities:built-in:log-output@latest": "zerodai:activities:built-in:log-output@1.0.0",
    "zerodai:activities:built-in:create-task@1.0.0": {
        editor: CreateTaskActivityEditor,
        onInit: (activity, update) => {
            if (activity) {
                if (activity.settings && update) {
                    const { assignments, ...taskRest } = activity.settings.task;
                    activity.settings = {
                        task: {
                            ...taskRest,
                            assignedTos: assignments.map((a) => a.assignedTo),
                            notify: true,
                        },
                    };
                } else {
                    activity.settings = {
                        ...activity.settings,
                        task: {
                            title: null,
                            description: null,
                            context: {
                                $eval: "workflowInstance.context",
                            },
                            dueDate: null,
                            mode: null,
                            useCase: null,
                            assignedTos: [],
                            notify: true,
                            ...(activity.settings ? activity.settings.task : {}),
                        },
                    };
                }

                if (activity.settings.task) {
                    activity.settings.task.settings = activity.settings.task.settings || {};
                    activity.settings.task.settings.listeners = activity.settings.task.settings.listeners || {};
                }
            }
        },
        onResolve: (activity) => {
            const { settings, ...activityRest } = activity;
            const { assignedTos, notify, ...taskRest } = settings.task;
            return {
                ...activityRest,
                settings: {
                    ...settings,
                    task: {
                        ...taskRest,
                        assignments: assignedTos.map((assignedTo) => ({
                            assignedTo,
                            notify,
                        })),
                    },
                },
            };
        },
        validator: (activity) => {
            return (
                activity &&
                activity.settings &&
                activity.settings.task &&
                activity.settings.task.title &&
                activity.settings.task.mode &&
                activity.settings.task.useCase &&
                activity.settings.task.settings &&
                activity.settings.task.settings.listeners &&
                activity.settings.task.assignedTos &&
                // activity.settings.task.assignedTos.length > 0 &&
                activity.settings.task.assignedTos.filter((s) => !!s.id || !!s.$eval).length ===
                    activity.settings.task.assignedTos.length
            );
        },
    },
    "zerodai:activities:built-in:create-task@latest": "zerodai:activities:built-in:create-task@1.0.0",
    "zerodai:activities:built-in:http-request@1.0.0": {
        editor: HttpRequestActivityEditor,
        onInit: (activity) => {
            if (activity) {
                activity.settings = {
                    requestUri: null,
                    method: "GET",
                    contentType: null,
                    content: null,
                    json: {},
                    headers: {},
                    ...activity.settings,
                };
            }
        },
        validator: (activity) => {
            return (
                activity &&
                activity.settings &&
                activity.settings.requestUri &&
                activity.settings.method &&
                activity.settings.contentType &&
                activity.settings.content &&
                activity.settings.headers
            );
        },
    },
    "zerodai:activities:built-in:http-request@latest": "zerodai:activities:built-in:http-request@1.0.0",
};
