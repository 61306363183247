import { computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { RoleNames, isUserInAllRoles } from "../../identity/stores/PrincipalContextUtilities";

import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";
import { AssetSelectionStore } from "./AssetSelectionStore";

export class AssetPermissionStore {
    public parentStore: AssetStore;
    public assetService: AssetService;
    public selectionStore: AssetSelectionStore;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
        this.selectionStore = parentStore.selectionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAdmin() {
        const user = this.principalContext.current;
        if (!user) {
            return false;
        }
        return isUserInAllRoles(user, [RoleNames.Admin]);
    }

    @computed
    public get isAgent() {
        const user = this.principalContext.current;
        if (!user) {
            return false;
        }

        return isUserInAllRoles(user, [RoleNames.Agent, RoleNames.Assets]);
    }

    @computed
    public get canCreate() {
        return this.isAdmin || this.isAgent;
    }

    @computed
    public get canEdit() {
        return this.isAdmin || this.isAgent;
    }

    @computed
    public get canAddComment() {
        return this.isAdmin || this.isAgent;
    }

    @computed
    public get canCreateType() {
        return this.isAdmin;
    }

    @computed
    public get canEditType() {
        return this.isAdmin;
    }

    @computed
    public get canCreateClass() {
        return this.isAdmin;
    }

    @computed
    public get canEditClass() {
        return this.isAdmin;
    }
}
