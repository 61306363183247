import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { select as d3Select } from "d3";
import { generate as c3Generate } from "c3";
import { TimeSeriesStore } from "../stores/TimeSeriesStore";
import { SavePNGButton } from "./common/SavePNGButton";
import { ExportCSVButton } from "./common/ExportCSVButton";
import { generateInlineTitle } from "./common/generateInlineTitle";
import { mutationObserver } from "./common/mutationObserver";

import "c3/c3.css";

export const TimeSeries = observer((props) => {
    const timeSeriesStore = new TimeSeriesStore();
    const [svgRef, setSvgRef] = useState(null);
    const [title, setTitle] = useState();
    const [data, setData] = useState();
    const [theme, setTheme] = useState();
    const [orientation, setOrientation] = useState();
    const [width, setWidth] = useState();
    const [rendered, setRendered] = useState(false);
    const [mode, setMode] = useState();

    const chartWrapperRef = useRef();

    timeSeriesStore.showTitle = props.showTitle;
    timeSeriesStore.rawData = props.dataSeries;
    timeSeriesStore.title = props.title;
    timeSeriesStore.showCSV = props.showCSV ? true : false;
    timeSeriesStore.showPNG = props.showPNG ? true : false;
    timeSeriesStore.orientation = props.orientation;
    timeSeriesStore.setDimensions(props.width, props.height);

    if (mode !== props.mode && props.mode === "edit") {
        timeSeriesStore.setDimensions(200, props.height);
        setMode("edit");
    }

    if (props.dataSeries.length > 0) {
        if (JSON.stringify(data) !== JSON.stringify(props.dataSeries)) {
            setData(props.dataSeries);
        }
    }

    if (orientation !== props.orientation) {
        setOrientation(props.orientation);
    }

    if (title !== props.title) {
        setTitle(props.title);
    }

    if (theme !== props.theme) {
        setTheme(props.theme);
    }

    useEffect(() => {
        const element = d3Select(chartWrapperRef.current).node();
        if (!element || !(data && data.length)) return;
        c3Generate(timeSeriesStore.getChartData(element, props.type));
        let newSVG = generateInlineTitle(chartWrapperRef, theme);
        const xAxis = newSVG.getElementsByClassName("c3-axis-x");
        if (xAxis) {
            for (let item of xAxis) {
                const pathElements = item.getElementsByClassName("domain");
                for (let pathElement of pathElements) {
                    pathElement.style.stroke = theme.palette.neutralSecondary;
                    pathElement.style.color = "var(--text-color)";
                    pathElement.setAttribute("stroke-width", "1");
                    pathElement.setAttribute("stroke", theme.palette.neutralSecondary);
                }
            }
        }
        const yAxis = newSVG.getElementsByClassName("c3-axis-y");
        if (yAxis) {
            for (let item of yAxis) {
                const pathElements = item.getElementsByClassName("domain");
                for (let pathElement of pathElements) {
                    pathElement.style.stroke = theme.palette.neutralSecondary;
                    pathElement.style.color = "var(--text-color)";
                    pathElement.setAttribute("stroke-width", "1");
                    pathElement.setAttribute("stroke", theme.palette.neutralSecondary);
                }
            }
        }
        if (!timeSeriesStore.onClickCallbackExists) {
            newSVG = mutationObserver(newSVG);
        }
        setSvgRef(newSVG);
        setRendered(true);
        props.onSVGChange(newSVG, `${timeSeriesStore.filename}.png`);
    }, [data, title, theme, orientation, mode]);

    useEffect(() => {
        props.onDataChange(timeSeriesStore.csvData(), `${timeSeriesStore.filename}.csv`);
    }, [data]);

    useEffect(() => {
        props.onRendered();
    }, [rendered, data]);

    return (
        <div>
            <div ref={chartWrapperRef} />
        </div>
    );
});
