import { observable, flow, action } from "mobx";

import { EntityService } from "@api/entities";
import { EntityStore } from "./EntityStore";

export class EntitySelectionStore {
    public parentStore: EntityStore;
    public entityService: EntityService;

    @observable public entity: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
    }

    @action
    public setEntity(entity) {
        this.entity = entity;
    }

    public loadEntity = flow(function* (id: string) {
        this.loading = true;

        if (!this.entity || this.entity.id !== id) {
            this.entity = null;
        }

        try {
            this.entity = yield this.entityService.getEntity(id);
        } finally {
            this.loading = false;
        }
    });
}
