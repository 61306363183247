import { observable, flow, computed, action } from "mobx";

import { ModellingService } from "../../../api/modelling";
import { PatternStore } from "./PatternStore";

export class PatternViewerStore {
    private parentStore: PatternStore;
    private modellingService: ModellingService;

    @observable public loading: boolean = false;
    @observable public summary: any;
    @observable public error: any;
    @observable public selectedTab: string;

    constructor(parentStore: PatternStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
    }

    @computed
    public get breadcrumb() {
        const routing = this.parentStore.rootStore.routing;
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => routing.push(`/settings`) });

        builder.push({
            text: "Assurance Management",
            key: "settings-assurance",
            onClick: () => routing.push(`/settings/assurance`),
        });
        builder.push({
            text: "Patterns",
            key: "assurance-patterns",
            onClick: () => routing.push(`/settings/assurance/patterns`),
        });

        if (!this.pattern) {
            return builder;
        }

        builder.push({
            text: this.pattern.name,
            key: this.pattern.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get pattern() {
        return this.parentStore.selectionStore.pattern;
    }

    @computed
    public get manifest() {
        return this.parentStore.editorStore.manifest;
    }

    @action
    public setSelectedTab(tab) {
        this.selectedTab = tab;
    }

    public loadPattern = flow(function* (id: string, version: string = null) {
        this.error = null;
        this.summary = null;
        this.loading = true;

        try {
            yield this.parentStore.editorStore.setEditMode(false);
            yield this.parentStore.selectionStore.loadPattern(id, version);
            this.summary = yield this.modellingService.getPatternSummary(id, version);
            const manifest = this.parentStore.selectionStore.manifest;
            yield this.parentStore.editorStore.prepare(manifest);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
