export const validateTargetData = (properties, data) => {
    if(!data) {
        return { isValid: false, messages: { '$all': 'Data is null or empty' } };
    }

    const editable = properties.filter(p => p.active && !p.readOnly);

    let isValid = true;
    const messages = {};
    editable.forEach(property => {
        const value = data[property.staticName];
        if(property.required && (typeof value === 'undefined' || value === null)) {
            isValid = false;
            messages[property.statiName] = 'A value is required';
        }

        switch(property.type) {
            case 'Number':

                break;
            case 'Boolean':

                break;
            case 'Text':

                break;
            case 'Date':

                break;
            case 'Lookup':

                break;
            case 'Choice':

                break;
        }

        
    });

    return { isValid, messages };
};