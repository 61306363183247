import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };

export const TablePartActions = inject('themeProvider')(observer(
  class TablePartActions extends PureComponent {
   
    render () {
      const { className, instance, part, themeProvider} = this.props;
      const { tooltip } = part.properties;
      const theme = themeProvider.getTheme();
      const tooltipId = `tooltip_${part.id}`;
      return (
        <div className={cx(className, 'page--part-action')}>
            <div className="wrapper">
              {tooltip && <TooltipHost
                content={tooltip}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton 
                  iconProps={{iconName: 'StatusCircleQuestionMark'}} 
                  aria-describedby={tooltipId}
                />
              </TooltipHost>}
            </div>
          </div>
      );
    }
  }
));
