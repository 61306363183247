import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { PagePartPlaceholder } from  '../elements/PagePartPlaceholder';

const rootClassName = cx(
  'cygraph-GridCalendarPart-root',
  css`
  `
);

const legendClassName = cx(
  css`
    border: 1px solid;
    border-radius: 10px;
    padding: 5px;
    min-width: 150px;
    cursor: pointer;
    text-align: center;
    user-select: none;
    color: var(--white);
  `)

const legendContainer = cx(css`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
`);

const legendContainerList = cx(
  css`
    display: flex;
    align-content: space-evenly;
    align-items: center;
    cursor: pointer;
  `);

const legendContainerListTextBox = cx(
  css `
    margin-left: 15px;
    user-select: none;
    cursor: pointer;
    color: var(--black);
`);

export const GridCalendarPart = inject('dataSourceStore', 'routing')(observer(
  class GridCalendarPart extends Component {
    constructor(props) {
      super(props)
      this.state = {
        legends: []
      }
    }

    componentWillMount() {
      this.setLegend(this.props)
    }

    componentWillReceiveProps(nextProps) {
      this.setLegend(nextProps)
    }

    setLegend(props) {
      const { part } = props;
      const legends = [];
      if (part.properties.dataSeries && part.properties.dataSeries.length > 0) {
        part.properties.dataSeries.forEach((DS) => {
          legends.push({
            title: DS.label,
            color: DS.eventsColor,
            display: true,
          })
        })
      }
      this.setState({legends})
    }

    render () {
      const { className, instance, part, zone, section, registry, dataSourceStore, routing } = this.props;
      const mode = instance.lifecycleStore.mode;
      const events = [];

      if (part.properties.dataSeries && part.properties.dataSeries.length > 0) {
        part.properties.dataSeries.forEach((DS,i) => {
          const data = instance.selectionStore.data.find(D => D.id === DS.dataSourceId);
          const selectedPartDataSource = DS.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === DS.dataSourceId) : null;
          const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
          if (data) {
            data.data
              .filter(D => {
                const legend = this.state.legends.find(L => L.title === DS.label)
                return legend.display
              })
              .forEach(D => {
                const eventTitle = DS.titleField ? D[DS.titleField]: DS.label;
                if (DS.endDateField) {
                  events.push(
                    {
                      title: eventTitle,
                      start: D[DS.startDateField],
                      end: D[DS.endDateField],
                      allDay: DS.isFullDayEvent,
                      color: DS.eventsColor,
                      id: selectedCoreDataSource.getCalendarClick ? selectedCoreDataSource.getCalendarClick(D.id) : null,
                      url: selectedCoreDataSource.getCalendarClick ? '#' : null,
                    }
                  )
                } else {
                  events.push(
                    {
                      title: eventTitle,
                      start: D[DS.startDateField],
                      allDay: DS.isFullDayEvent,
                      color: DS.eventsColor,
                      id: selectedCoreDataSource.getCalendarClick ? selectedCoreDataSource.getCalendarClick(D.id) : null,
                      url: selectedCoreDataSource.getCalendarClick ? '#' : null,
                    }
                  )
                }
              })
            }          
        })
      }
      return (
        <div className={cx(rootClassName, className, mode)}>
          <PagePartPlaceholder {...this.props} isConfigured={part.properties}>
            <FullCalendar
              eventClick={(e) => {
                const eventInfo = e.event;
                if (eventInfo.id !== 'null') {
                  routing.push(eventInfo.id);
                  e.jsEvent.preventDefault()
                }
              }}
              plugins={[ dayGridPlugin ]}
              initialView="dayGridMonth"
              weekends={true}
              events={events}
            />
            {!part.properties.hideLegend && <div className={legendContainer}>
              {this.state.legends.map((legend, idx)  => {
                if (part.properties.displayAsButton) {
                  return <div 
                      key={`legend${idx}}`}
                      className={legendClassName}
                      style={{backgroundColor:legend.color?legend.color:'#3788d8', opacity: legend.display?1:0.3}}
                      onClick={() => {
                        const legends = this.state.legends;
                        legends[idx].display = !legends[idx].display;
                        this.setState({
                          legends
                        })
                      }}
                    >
                    {legend.title}
                  </div>                  
                } else {
                  return <div 
                    key={`legend${idx}}`}
                    className={legendContainerList}
                    onClick={() => {
                      const legends = this.state.legends;
                      legends[idx].display = !legends[idx].display;
                      this.setState({
                        legends
                      })
                    }}
                    >
                    <TextField 
                      readOnly
                      style={{
                          color: legend.color || '#3788d8',
                          backgroundColor: legend.color || '#3788d8',
                          opacity: legend.display?1:0.3,
                          cursor: 'pointer',
                          width: '30px',
                      }}
                    />
                    <div className={legendContainerListTextBox} >
                      {legend.title}
                    </div>
                  </div>
                }
              })}
            </div>}
          </PagePartPlaceholder>
        </div>
      );
    }
  }
));