import React from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Separator } from '@ui/elements/Separator';
import { Stack } from '@ui/elements/Stack';

const rootClassName = cx(
  'cygraph-ObservableSeparator-root',
  css`
    border-bottom: 1px solid var(--border-color);
    height: 40px;
  `
);

const separatorClassName = cx(
  'cygraph-ObservableSeparator-separator',
  css`
    padding: 0;

    &::before {
      background: transparent;
      top: 100%;
    }

    & > div {
      background: transparent;
      padding: 10px 0 !important;
      padding: var(--spacing-xxxs) var(--spacing-xxs);
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      border-bottom: 1px solid var(--nav-color-accent);
      border-radius: 0;
      margin-bottom: -1px;
    }
  `
);

export const ObservableSeparator = observer(
  ({
    children,
    className,
    alignContent,
    fullWidth,
    onRenderSuffix,
    ...rest
  }) => {
    return (
      <Stack
        horizontal
        className={cx(className, rootClassName)}
        verticalFill
        verticalAlign="center"
      >
        <Stack.Item grow>
          <Separator
            className={separatorClassName}
            alignContent={alignContent || 'start'}
            {...rest}
          >
            {children}
          </Separator>
        </Stack.Item>
        <Stack.Item>{onRenderSuffix ? onRenderSuffix() : null}</Stack.Item>
      </Stack>
    );
  }
);
