import { observable, action, computed, flow } from "mobx";

import { RiskService } from "../../../api/risks";

import { RiskStore } from "./RiskStore";

export class RiskTypeEditFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;

    @observable public originalFormData: any;
    @observable public formData: any;
    @observable public visible: boolean = false;
    @observable public isSaving: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public hide() {
        this.visible = false;
        this.formData = null;
        this.originalFormData = null;
    }

    @action
    public show(registry) {
        this.visible = true;
        this.formData = JSON.parse(JSON.stringify(registry));
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
        this.formData.inherithCodeFormat = (this.formData.codeFormat === null);
    }

    @action
    public reset() {
        this.formData = JSON.parse(JSON.stringify(this.originalFormData));
    }

    @computed public get isChanged() {
        return (JSON.stringify(this.formData) === JSON.stringify(this.originalFormData))  ? false : true;
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }
        return (
            this.formData.name
        );
    }

    save = flow(function*(){
        this.isSaving = true
        try {
            if (this.formData.inherithCodeFormat === true) this.formData.codeFormat = null;
            yield this.riskService.updateType(this.formData)
            this.hide()
        } catch (e) {
            console.error(e)
        } finally {
            this.isSaving = false
        }
    })
}
