import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { saveSvgAsPng } from 'save-svg-as-png';
import { json2csvAsync } from 'json-2-csv';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };

export const ChartViewerActions = inject('themeProvider')(observer(
  class ChartViewerActions extends PureComponent {

    _exportCSV = () => {
      const { instance, part } = this.props;
      const partData = instance.partDataStore.data[part.id];
      // debugger
      if (partData && partData.csvData)
        json2csvAsync(partData.csvData)
        .then((csv) => { 
            var uri = 'data:application/csv;charset=utf-8,' + escape(csv);
            var link = document.createElement("a");
            link.href = uri;
            link.style = "visibility:hidden";
            link.download = partData.csvFilename || 'data.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((err) => { console.error(err) });
    }

    _saveAsPng = () => {
      const { instance, part } = this.props;
      // debugger
      const partData = instance.partDataStore.data[part.id];
      if (partData && partData.svgRef && partData.svgFilename) saveSvgAsPng(partData.svgRef, partData.svgFilename || 'image.png');
    }
    
    render () {
      const { className, instance, part, themeProvider } = this.props;
      const { showPNG, showCSV, tooltip } = part.properties;
      const tooltipId = `tooltip_${part.id}`;
      const tooltipPNGId = `tooltip_${part.id}_png`;
      const tooltipCSVId = `tooltip_${part.id}_csv`;
      return (
        <div className={cx(className, 'page--part-action')}>
            <div className="wrapper">
              {tooltip && <TooltipHost
                content={tooltip}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton 
                  iconProps={{iconName: 'StatusCircleQuestionMark'}} 
                  aria-describedby={tooltipId}
                />
              </TooltipHost>}
              {showPNG && <TooltipHost
                content={`Save as PNG`}
                id={tooltipPNGId}
                calloutProps={calloutProps}
                styles={hostStyles}
              ><IconButton 
                iconProps={{iconName: 'Download'}}
                aria-describedby={tooltipPNGId}
                onClick={this._saveAsPng}
              /></TooltipHost>}
              {showCSV && <TooltipHost
                content={`Export as CSV`}
                id={tooltipCSVId}
                calloutProps={calloutProps}
                styles={hostStyles}
              ><IconButton 
                iconProps={{iconName: 'AnalyticsView'}}
                aria-describedby={tooltipCSVId}
                onClick={this._exportCSV}
              /></TooltipHost>}
            </div>
          </div>
      );
    }
  }
));
