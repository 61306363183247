import { observable } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemTabsStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    @observable public selectedTab: string = "Summary";

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }
}
