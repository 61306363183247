import { computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { RoleNames, isUserInAnyRoles } from "../../identity/stores/PrincipalContextUtilities";

import { WorkflowService } from "../../../api/workflows";
import { TaskSelectionStore } from "./TaskSelectionStore";
import { TaskStore } from "./TaskStore";

export class TaskPermissionStore {
    public parentStore: TaskStore;
    public selectionStore: TaskSelectionStore;
    public workflowService: WorkflowService;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
        this.selectionStore = parentStore.selectionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAdmin() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Admin]);
    }

    @computed
    public get isAgent() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Agent]);
    }

    @computed
    public get canRespond() {
        const user = this.principalContext.current;
        const task = this.selectionStore.task;

        if (!task || !user) {
            return false;
        }

        return task.assignments.find((a) => a.assignedTo.id === user.id && a.status === "Pending");
    }

    @computed
    public get canEdit() {
        const user = this.principalContext.current;
        const task = this.selectionStore.task;

        if (!task || !user) {
            return false;
        }

        return this.isAdmin || task.createdBy.id === user.id;
    }
}
