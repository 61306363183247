import { observable, computed, flow } from "mobx";

import { PropertyService } from "@api/properties";
import { PropertyStore } from "./PropertyStore";
import { PropertySelectionStore } from "./PropertySelectionStore";
import { PropertyPermissionStore } from "./PropertyPermissionStore";

export class PropertyLifecycleStore {
    public parentStore: PropertyStore;
    public propertyService: PropertyService;
    public selectionStore: PropertySelectionStore;
    public permissionStore: PropertyPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @computed
    public get canCreate() {
        if (this.saving) {
            return false;
        }

        return this.permissionStore.isAdmin;
    }

    public onCreate = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.newFormStore;
            const { success, formData } = yield store.show({
                ...{ property: options.property },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.propertyService.createProperty({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setProperty(created);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Property ${created.name} created successfully.`
                );

                store.hide();
                return { success, property: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canEdit() {
        const property = this.selectionStore.property;

        if (!property || this.saving) {
            return false;
        }

        return this.permissionStore.isAdmin;
    }

    public onEdit = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.editFormStore;
            const { success, formData } = yield store.show({
                ...{ property: options.property },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.propertyService.updateProperty({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setProperty(updated);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Property ${updated.name} updated successfully.`
                );

                store.hide();
                return { success, property: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canCreateSection() {
        if (this.saving) {
            return false;
        }

        return this.permissionStore.isAdmin;
    }

    public onCreateSection = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.sectionNewFormStore;
            const { success, formData } = yield store.show({
                ...{ section: options.section },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.propertyService.createPropertySection({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setPropertySection(created);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Property section ${created.name} created successfully.`
                );

                store.hide();
                return { success, section: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canEditSection() {
        const property = this.selectionStore.section;

        if (!property || this.saving) {
            return false;
        }

        return this.permissionStore.isAdmin;
    }

    public onEditSection = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.sectionEditFormStore;
            const { success, formData } = yield store.show({
                ...{ section: options.section },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.propertyService.updatePropertySection({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setPropertySection(updated);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Property section ${updated.name} updated successfully.`
                );

                store.hide();
                return { success, section: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });
}
