import { observable, flow } from "mobx";
import moment from "moment";

import { CursorArrowRaysIcon, InboxIcon, UserIcon } from "@heroicons/react/24/outline";

import { RiskService } from "@api/risks";
import { PaginationStore } from "@modules/base/PaginationStore";
import { RiskStore } from "./RiskStore";

export class RiskDashboardStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public risks: any[] = [];
    @observable public stats: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks",
            onClick: () => this._goToUrl(`/risks/dashboard`),
        },
        { text: "Risk Dashboard", key: "dashboard", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    public loadDashboard = flow(function* (query: any) {
        this.loading = true;

        this.stats = [
            {
                id: 1,
                name: "Total Open",
                value: null,
                icon: InboxIcon,
                query: {
                    status: ["Submitted", "Monitoring"],
                },
            },
            {
                id: 2,
                name: "Assigned to me",
                value: null,
                icon: UserIcon,
                query: {
                    status: ["Submitted", "Monitoring"],
                    assignedToUser: [{ id: "00000000-0000-0000-0000-000000000000" }],
                },
            },
            {
                id: 3,
                name: "Requested by me",
                value: null,
                icon: CursorArrowRaysIcon,
                query: {
                    status: ["Draft", "Submitted", "Monitoring", "Closed"],
                    requestedBy: [{ id: "00000000-0000-0000-0000-000000000000" }],
                },
            },
        ];

        const refTime = moment.utc().subtract(2, "weeks");

        const options = Object.assign(
            {
                page: 1,
                pageSize: 10,
                query: {
                    keywords: null,
                    templateId: "D930D47B-872A-4AD1-84F1-31B0337F6CFA",
                    modified: {
                        after: refTime.format(),
                    },
                    sort: [
                        {
                            property: "modified",
                            asc: false,
                        },
                    ],
                },
            },
            query
        );

        this.query = {
            ...options.query,
        };

        try {
            for (let i = 0; i < this.stats.length; i++) {
                const statResult = yield this.riskService.searchRisks({
                    pageSize: 0,
                    startIndex: 0,
                    query: {
                        ...this.stats[i].query,
                        ...{ type: [{ template: "zerodai:risks:generic" }] },
                    },
                });
                this.stats[i].value = statResult.totalItems;
            }

            const result = yield this.riskService.searchRisks({
                pageSize: options.pageSize,
                startIndex: (options.page - 1) * options.pageSize,
                keywords: options.keywords,
                query: {
                    ...this.query,
                    ...{ type: [{ template: "zerodai:risks:generic" }] },
                },
            });
            this.risks = result.items;
            this.query = result.query;
            this.keywords = result.query.keywords;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);

            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
