import { observable, action } from "mobx";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentRiskStore {
    private parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public risks: any[] = [];
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @action
    public loadAssessmentRisks(id) {
        this.loading = true;
        this.risks = [];
        return this.assessmentService
            .getAssessmentRisks(id, "zerodai:risks:generic")
            .then(this.loadAssessmentOnSuccess, this.loadAssessmentOnError);
    }

    @action.bound
    public loadAssessmentOnSuccess(result) {
        this.risks = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssessmentOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }
}
