import { observable, flow, action, computed } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { StateFlowService } from "../../../api/stateflows";
import { StateFlowStore } from "./StateFlowStore";
import { StateFlowPublishStore } from "./StateFlowPublishStore";
import { StateFlowStateEditStore } from "./StateFlowStateEditStore";
import { StateFlowStageEditStore } from "./StateFlowStageEditStore";
import { StateFlowTriggerEditStore } from "./StateFlowTriggerEditStore";
import { StateFlowPreviewStore } from "./StateFlowPreviewStore";
import { StateFlowEditorMenuStore } from "./StateFlowEditorMenuStore";

export class StateFlowEditorStore {
    public parentStore: StateFlowStore;
    public stateFlowService: StateFlowService;
    public publishStore: StateFlowPublishStore;
    public stateEditStore: StateFlowStateEditStore;
    public stageEditStore: StateFlowStageEditStore;
    public triggerEditStore: StateFlowTriggerEditStore;
    public previewStore: StateFlowPreviewStore;
    public contextMenuStore: StateFlowEditorMenuStore;

    @observable public manifest: any;
    @observable public snapshot: any;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public isEditMode: boolean = false;
    @observable public options: any = {
        showHiddenTriggers: false,
    };

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
        this.publishStore = new StateFlowPublishStore(this);
        this.stateEditStore = new StateFlowStateEditStore(this);
        this.stageEditStore = new StateFlowStageEditStore(this);
        this.triggerEditStore = new StateFlowTriggerEditStore(this);
        this.previewStore = new StateFlowPreviewStore(this);
        this.contextMenuStore = new StateFlowEditorMenuStore(this);
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: "Workflows", key: "workflows-browse", onClick: () => this.parentStore.rootStore.routing.push(`/settings/workflows`) });

        if (!this.manifest) {
            return builder;
        }

        builder.push({
            text: this.manifest.name,
            key: this.manifest.id,
            onClick: () => this.parentStore.rootStore.routing.push(`/settings/workflows/${this.manifest.id}`),
        });

        builder.push({
            text: `Editor (v${this.manifest.version})`,
            key: "workflow-editor",
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get stateFlow() {
        return this.parentStore.selectionStore.stateFlow;
    }

    @action
    public setEditMode(isEditMode) {
        this.isEditMode = isEditMode;
    }

    @action
    public onResetManifest(options) {
        this.manifest = JSON.parse(JSON.stringify(this.snapshot));
    }

    public onRunAction = flow(function* (action, options) {
        switch (action) {
            case "addState":
                yield this.onAddState(options);
                break;
            case "editState":
                yield this.onEditState(options);
                break;
            case "removeState":
                yield this.onRemoveState(options);
                break;
            case "setInitialState":
                yield this.onSetInitialState(options);
                break;
            case "previewState":
                yield this.onPreview(options);
                break;
            case "duplicateState":
                yield this.onDuplicateState(options);
                break;
            case "addStageBefore":
                yield this.onAddStage({ ...options, position: "before" });
                break;
            case "addStageAfter":
                yield this.onAddStage({ ...options, position: "after" });
                break;
            case "editStage":
                yield this.onEditStage(options);
                break;
            case "removeStage":
                yield this.onRemoveStage(options);
                break;
            case "addTrigger":
                yield this.onAddTrigger(options);
                break;
            case "editTrigger":
                yield this.onEditTrigger(options);
                break;
            case "removeTrigger":
                yield this.onRemoveTrigger(options);
                break;
        }
    });

    public onContextMenu = flow(function* (options) {
        const { success, action } = yield this.contextMenuStore.show({
            ...options,
            target: this.manifest.target,
            manifest: this.manifest,
        });

        yield this.contextMenuStore.hide();

        if (success) {
            yield this.onRunAction(action, options);
        }
    });

    public onPreview = flow(function* (options) {
        yield this.previewStore.show({ title: "Workflow Preview", ...options });
        yield this.previewStore.hide();
    });

    public onAddStage = flow(function* (options) {
        const indexOfRefStage = this.manifest.stages.findIndex((m) => m.id === options.stage.id);
        if (indexOfRefStage === -1) {
            return;
        }

        const stage = { id: uuidv4(), name: "New Stage" };

        const { success, value } = yield this.stageEditStore.show({ title: "New Stage", value: stage });
        if (success) {
            if (options.position === "before") {
                this.manifest.stages.splice(indexOfRefStage, 0, value);
            } else {
                this.manifest.stages.splice(indexOfRefStage + 1, 0, value);
            }
        }

        yield this.stageEditStore.hide();
    });

    public onEditStage = flow(function* (options) {
        const stage = this.manifest.stages.find((m) => m.id === options.stage.id);

        const { success, value, ...rest } = yield this.stageEditStore.show({ title: stage.name, value: stage });
        if (success) {
            this.onUpdateStage({ stage: value });
        }
        yield this.stageEditStore.hide();

        if (rest && rest.action) {
            yield this.onRunAction(rest.action, rest);
        }
    });

    @action
    public onUpdateStage(options) {
        const indexOfStage = this.manifest.stages.findIndex((m) => m.id === options.stage.id);
        if (indexOfStage !== -1) {
            this.manifest.stages[indexOfStage] = { ...this.manifest.stages[indexOfStage], ...options.stage };
        }
    }

    public onRemoveStage = flow(function* (options) {
        const stage = this.manifest.stages.find((s) => s.id === options.stage.id);
        if (!stage) {
            return;
        }
        const stateIdsRemoved = this.manifest.states.filter((s) => s.stage === options.stage.id).map((s) => s.id);

        this.manifest.stages = this.manifest.stages.filter((s) => s.id !== options.stage.id);
        this.manifest.states = this.manifest.states.filter((s) => s.stage !== options.stage.id);

        this.manifest.states.forEach((state) => {
            if (state.triggers) {
                state.triggers = state.triggers.filter((t) => !t.command || stateIdsRemoved.indexOf(t.command.to) === -1);
            }
        });
    });

    public onAddState = flow(function* (options) {
        const stage = this.manifest.stages.find((m) => m.id === options.stage.id);
        if (!stage) {
            return;
        }

        const state = { id: uuidv4(), name: "New State", stage: stage.id };

        const { success, value } = yield this.stateEditStore.show({
            title: `New state in stage "${stage.name}"`,
            value: state,
            mappedToEditable: true,
            manifest: this.manifest,
        });

        if (success) {
            this.manifest.states.push(value);
        }

        yield this.stateEditStore.hide();
    });

    public onEditState = flow(function* (options) {
        const state = this.manifest.states.find((m) => m.id === options.state.id);

        const { success, value, ...rest } = yield this.stateEditStore.show({
            title: state.name,
            value: state,
            mappedToEditable: false,
            manifest: this.manifest,
        });

        if (success) {
            this.onUpdateState({ state: value });
        }

        yield this.stateEditStore.hide();

        if (rest && rest.action) {
            yield this.onRunAction(rest.action, rest);
        }
    });

    @action
    public onUpdateState(options) {
        const indexOfState = this.manifest.states.findIndex((m) => m.id === options.state.id);
        if (indexOfState !== -1) {
            this.manifest.states[indexOfState] = { ...this.manifest.states[indexOfState], ...options.state };
        }
    }

    @action
    public onSetInitialState(options) {
        const indexOfState = this.manifest.states.findIndex((m) => m.id === options.state.id);
        if (indexOfState !== -1) {
            const indexOfCurrent = this.manifest.states.findIndex((m) => m.id === this.manifest.initialStateId);
            this.manifest.initialStateId = options.state.id;

            this.manifest.states[indexOfState] = { ...this.manifest.states[indexOfState] };
            this.manifest.states[indexOfCurrent] = { ...this.manifest.states[indexOfCurrent] };
        }
    }

    @action
    public onDuplicateState(options) {
        const state = this.manifest.states.find((m) => m.id === options.state.id);
        if (state) {
            const duplicate = { ...state, id: uuidv4() };
            if (duplicate.design && duplicate.design.position) {
                duplicate.design.position = {
                    x: duplicate.design.position.x + 10,
                    y: duplicate.design.position.y + 10,
                };
            }

            /// outgoing triggers
            if (duplicate.triggers) {
                duplicate.triggers = duplicate.triggers.map((trigger) => ({
                    ...trigger,
                    id: uuidv4(),
                    command: {
                        ...trigger.command,
                        id: uuidv4(),
                    },
                }));
            }
            this.manifest.states = [...this.manifest.states, duplicate];

            /// incoming triggers
            this.manifest.states
                .filter((s) => s.id !== options.state.id)
                .forEach((state) => {
                    if (state.triggers) {
                        const triggers = [];
                        state.triggers.forEach((trigger) => {
                            if (trigger.command && trigger.command.to === options.state.id) {
                                triggers.push({
                                    ...trigger,
                                    id: uuidv4(),
                                    command: {
                                        ...trigger.command,
                                        id: uuidv4(),
                                        to: duplicate.id,
                                    },
                                });
                            }
                        });
                        state.triggers = [...state.triggers, ...triggers];
                    }
                });
        }
    }

    public onRemoveState = flow(function* (options) {
        if (options.state.id === this.manifest.initialStateId) {
            return;
        }

        this.manifest.states = this.manifest.states.filter((s) => s.id !== options.state.id);
        this.manifest.states.forEach((state) => {
            if (state.triggers) {
                state.triggers = state.triggers.filter((t) => !t.command || t.command.to !== options.state.id);
            }
        });
    });

    public onMoveState = flow(function* (options) {
        const state = this.manifest.states.find((s) => s.id === options.state.id);
        state.design = { position: options.position };
    });

    public onAddTrigger = flow(function* (options) {
        const sourceId = options.source ? options.source.id : options.state.id;
        const source = this.manifest.states.find((s) => s.id === sourceId);
        if (!source) {
            return;
        }
        const targetIndex = options.target ? this.manifest.states.findIndex((s) => s.id === options.target.id) : -1;

        const trigger = {
            name: "New Trigger",
            description: null,
            iconProps: null,
            confirmProps: null,
            variant: "Default",
            location: "Overflow",
            command: { type: null, condition: null, to: options.target ? options.target.id : null, id: uuidv4() },
            id: uuidv4(),
        };

        const { success, value } = yield this.triggerEditStore.show({ title: "New Trigger", value: trigger, state: source, target: options.target });
        if (success) {
            source.triggers = source.triggers || [];
            source.triggers = [...source.triggers, value];

            if (targetIndex !== -1) {
                this.manifest.states[targetIndex] = { ...this.manifest.states[targetIndex] };
            }
        }

        yield this.triggerEditStore.hide();
    });

    public onEditTrigger = flow(function* (options) {
        const state = this.manifest.states.find((m) => m.id === options.state.id);
        const trigger = state.triggers.find((m) => m.id === options.trigger.id);

        const { success, value } = yield this.triggerEditStore.show({ title: trigger.name, value: trigger, state });
        if (success) {
            this.onUpdateTrigger({ trigger: value, state });
        }

        yield this.triggerEditStore.hide();
    });

    @action
    public onUpdateTrigger(options) {
        const state = this.manifest.states.find((m) => m.id === options.state.id);
        if (!state || !state.triggers) {
            return;
        }
        const indexOfTrigger = state.triggers.findIndex((m) => m.id === options.trigger.id);
        if (indexOfTrigger !== -1) {
            state.triggers[indexOfTrigger] = { ...state.triggers[indexOfTrigger], ...options.trigger };
        }
    }

    @action
    public onMoveTrigger(options) {
        const state = this.manifest.states.find((m) => m.id === options.trigger.state);
        if (!state || !state.triggers) {
            return;
        }
        const trigger = state.triggers.find((m) => m.id === options.trigger.id);
        const targetIndex = this.manifest.states.findIndex((s) => s.id === options.target.id);

        if (trigger && trigger.command && targetIndex !== -1) {
            trigger.command.to = options.target.id;
            this.manifest.states[targetIndex] = { ...this.manifest.states[targetIndex] };
        }
    }

    public onRemoveTrigger = flow(function* (options) {
        const state = this.manifest.states.find((s) => s.id === options.state.id);
        if (state && state.triggers) {
            state.triggers = state.triggers.filter((t) => t.id !== options.trigger.id);
        }
    });

    public onEdit = flow(function* (options) {
        const { success, value } = yield this.publishStore.show({
            title: this.manifest.name,
            value: {
                mode: options.node,
                notes: options.notes,
            },
        });

        if (success) {
            yield this.saveStateFlow(value.mode, value.notes);
        }
    });

    public onSave = flow(function* (options) {
        if (options.confirm === false) {
            yield this.saveStateFlow(options.mode, options.notes);
            return;
        }

        const { success, value } = yield this.publishStore.show({
            title: this.manifest.name,
            value: {
                mode: options.node,
                notes: options.notes,
            },
        });

        if (success) {
            yield this.saveStateFlow(value.mode, value.notes);
        }

        yield this.publishStore.hide();
    });

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.manifest);
    }

    @computed
    public get canDiscard() {
        return this.isDirty;
    }

    @computed
    public get canSave() {
        return (
            this.isDirty &&
            this.manifest &&
            this.manifest.id &&
            this.manifest.name &&
            this.manifest.initialStateId &&
            this.manifest.stages &&
            this.manifest.stages.length >= 0 &&
            this.manifest.states &&
            this.manifest.states.length >= 0 &&
            this.manifest.target
        );
    }

    @computed
    public get canPublish() {
        return this.isDirty || this.stateFlow.currentVersion !== this.stateFlow.publishedVersion;
    }

    public prepare = flow(function* (manifest) {
        this.snapshot = manifest;
        this.manifest = JSON.parse(JSON.stringify(manifest));
    });

    public reset = flow(function* () {
        const isDirty = this.isDirty;
        this.manifest = JSON.parse(JSON.stringify(this.snapshot));
        this.isEditMode = false;

        // HACK!
        yield new Promise((resolve) => setTimeout(resolve, 10));

        if (this.isDirty) {
            this.manifest = JSON.parse(JSON.stringify(this.snapshot));
        }

        if (isDirty) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Any uncommitted workflow changes discarded, showing version ${this.manifest.version}`
            );
        }
    });

    public saveStateFlow = flow(function* (mode, notes = null) {
        this.saving = true;

        try {
            const stateFlow = yield this.stateFlowService.updateStateFlow({
                mode,
                notes,
                entity: this.manifest,
            });

            yield this.parentStore.selectionStore.setStateFlow(stateFlow);
            const manifest = yield this.stateFlowService.getStateFlowManifest(stateFlow.id, stateFlow.currentVersion);
            yield this.parentStore.selectionStore.setManifest(manifest);
            this.prepare(manifest);

            this.parentStore.rootStore.layoutStore.displayToastNotification(`Workflow updated to version ${stateFlow.currentVersion}`);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public loadStateFlow = flow(function* (id: string, version: string = null) {
        this.error = null;
        this.summary = null;
        this.loading = true;

        try {
            yield this.parentStore.selectionStore.loadStateFlow(id, version);
            const manifest = this.parentStore.selectionStore.manifest;
            yield this.parentStore.editorStore.prepare(manifest);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
