import { computeTimelineFilter } from '../data/computeTimelineFilter';
import { generateAssessmentQuery } from './generateAssessmentQuery';

function generateAdvisoriesQuery(query) {

    let assessmentQuery = null;
    let assessmentFilters = {
        timeLine: query.assessment_timeLine,
        created: query.assessment_created,
        started: query.assessment_started,
        dueDate: query.assessment_dueDate,
        closed: query.assessment_closed,
        portfolios: query.assessment_portfolios,
        assessmentState: query.assessment_assessmentState,
        assignedToUser: query.assessment_assignedToUser,
        requestedBy: query.assessment_requestedBy,
        priority: query.assessment_priority,
    };
    assessmentQuery = generateAssessmentQuery(assessmentFilters);

    let createdFilter = null;
    let closedFilter = null;
    if (query.created) {
        createdFilter = computeTimelineFilter(query.created[0]?query.created[0]:query.created);
    }
    if (query.closed) {
        closedFilter = computeTimelineFilter(query.closed[0]?query.closed[0]:query.closed);
    }

    let initialCompliances = null;
    if (query.initialCompliances && query.initialCompliances.length > 0) {
        initialCompliances = query.initialCompliances;
    }
    let finalCompliances = null;
    if (query.finalCompliances && query.finalCompliances.length > 0) {
        finalCompliances = query.finalCompliances;
    }
    let level = null;
    if (query.level && query.level.length > 0) {
        level = query.finalCompliances;
    }
    let state = null;
    if (query.state && query.state.length > 0) {
        state = query.finalCompliances;
    }
    let closedBy = null;
    if (query.closedBy) {
        const selectedId = query.closedBy[0] ? query.closedBy[0].id : query.closedBy.id;
        if (selectedId) closedBy = [{id: selectedId}];
    }
    let createdFilterObject = null;
    let closedFilterObject = null;
    let timeLineFilterObject = null;
    if (query.created) {
        createdFilterObject = computeTimelineFilter(query.created[0]?query.created[0]:query.created);
    }
    if (query.closed) {
        closedFilterObject = computeTimelineFilter(query.closed[0]?query.closed[0]:query.closed);
    }
    if (query.timeLine) {
        timeLineFilterObject = computeTimelineFilter(query.timeLine[0]?query.timeLine[0]:query.timeLine);
    }
    let controlSetFilter = null;
    if (query.controlSets && query.controlSets.length > 0) {
        controlSetFilter = query.controlSets.map(CS => {return {
            id: CS
        }})
    }
    let controlsFilter = null;
    if (query.controls && query.controls.length > 0) {
        controlSetFilter = query.controls.map(C => {return {
            id: C
        }})
    }
    const sentQuery = {
        initialCompliances,
        finalCompliances,
        closedBy,
        level,
        state,
        controlSet: controlSetFilter,
        control: controlsFilter,
        timeline: timeLineFilterObject,
        created: createdFilterObject,
        closed: closedFilterObject,
        assessment: assessmentQuery,
    };
    
    return sentQuery;
}

export { generateAdvisoriesQuery }