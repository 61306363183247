import { observable, action, computed } from "mobx";

import { WorkflowService } from "../../../api/workflows";

import { WorkflowStore } from "./WorkflowStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class WorkflowDeleteFormStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    public registration: any;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    @action
    public show(options) {
        this.formOptions = {};
        this.formData = { ...options.instance };
        this.visible = true;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.responseHandle.reject(error);
    }

    @computed
    public get isValid() {
        if (!this.formData || !this.formData.id) {
            return false;
        }

        return true;
    }
}
