import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';

import { Dropdown } from '@ui/elements/Dropdown';
import { GroupedList } from 'office-ui-fabric-react/lib/GroupedList';
import { SelectionMode } from 'office-ui-fabric-react/lib/Selection';
import { IconButton} from '@ui/elements/Button';
import { cx, css } from 'emotion';
import { TextField } from '@ui/elements/TextField';
import { RegistryPicker } from '@modules/risks/containers/pickers/RegistryPicker';

import { PrincipalPicker } from '@identity/containers/PrincipalPicker';
import { DateRangePicker } from '@baseComponents/DateRangePicker';
import { DateRangeComboPicker } from './DateRangeComboPicker';
import { AsyncPicker } from '@baseComponents/asyncPicker/AsyncPicker';

const GroupedListClassName = cx(css`
   .ms-List[role="grid"] {
       margin-left: 50px;
   }
   r-cell > r-grid {
    font-size: 14px;
   }
`)

const groupHeaderClassName = cx(css`
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    :hover {
        background-color: var(--background-color);
    }
    div {
        user-select: none;
    }
    span.ms-Button-flexContainer{
        padding-left: 4px;
    }
`);

export const DataSourceFiltersForm = inject('dataSourceStore','assessmentStore', 'workItemStore')(observer(
  class DataSourceFiltersForm extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {};
    }

    onFilterChange(option, formData, filterId) {
        if (!formData.properties.filters) formData.properties.filters = [];
        const filter = formData.properties.filters.find(F => F.id === filterId);
        if (!filter) {
            formData.properties.filters.push({
                id: filterId,
                value: option.key,
            })
        } else {
            filter.value = option.key;
        }
    }

    onPlainFilterChange(value, formData, filterId) {
        if (!formData.properties.filters) formData.properties.filters = [];
        const filter = formData.properties.filters.find(F => F.id === filterId);
        if (!filter) {
            formData.properties.filters.push({
                id: filterId,
                value: value,
            })
        } else {
            filter.value = value;
        }
    }

    onUserChange(user, formData, filterId) {
        if (!formData.properties.filters) formData.properties.filters = [];
        const filter = formData.properties.filters.find(F => F.id === filterId);
        if (!filter) {
            formData.properties.filters.push({
                id: filterId,
                value: user,
            })
        } else {
            filter.value = user;
        }
    }

    onMultiSelectChange = (option, formData, filterId, options) => {
        if (!formData.properties.filters) formData.properties.filters = [];
        if (!formData.properties.filters.find(F => F.id === filterId)) {
            formData.properties.filters.push({
                id: filterId,
                value: [option.key],
            });
        } else {
            let filter = formData.properties.filters.find(F => F.id === filterId);
            if (option) {
                filter.value = option.selected ? [...filter.value, option.key] : [...filter.value.filter(key => key !== option.key)];
            }
        }
        // Make sure all the options are allowed
        // The should limit issues when an array of options changes but old options are still selected in the properties
        let filter = formData.properties.filters.find(F => F.id === filterId); 
        filter.value = filter.value.filter(V => {
            return options.find(O => O.key === V)
        })
      };

    getField(filter, formData, idx, defaultFilters) {
        const { onFilterChange, assessmentStore, workItemStore } = this.props
        if (!formData.properties.filters) formData.properties.filters = [];
        if (filter.conditions) {
            let displayFilter = true
            // console.log(`${filter.name} - ${filter.type}: `, filter.conditions);
            filter.conditions.forEach(condition => {
                // console.log('condition', condition);
                const sourceFilter = formData.properties.filters.find(F => F.id === condition.filterId)
                // console.log('sourceFilter', sourceFilter);
                if (sourceFilter) {
                    // console.log(`sourceFilter.value: ${sourceFilter.value}  condition.value: ${condition.value}`);
                    if (sourceFilter.value !== condition.value) displayFilter = false
                } else {
                    displayFilter = false
                }
            })
            if (!displayFilter) return null
        }
        switch (filter.type) {
            case 'registryPicker':
                let registryPicker = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                const v = registryPicker ? registryPicker.value : filter.default;
                return <RegistryPicker
                    label="Registry"
                    selectedId={v}
                    onChange={(registry) => {
                        this.onPlainFilterChange(registry.id, formData, filter.id);
                        onFilterChange();
                    }}
                />
            case 'numberPicker':
                let numberPicker = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                const val = numberPicker ? numberPicker.value : filter.default;
                return <TextField 
                    key={`k_${idx}`}
                    label={filter.name}
                    type="number"
                    min={filter.min || 0}
                    max={filter.max || 100}
                    value={val}
                    onChange={(ev, value) => {
                        this.onPlainFilterChange(value, formData, filter.id)
                        onFilterChange()
                    }}
                />
            case 'dateRangePicker':                
                const defaultValueDRP = defaultFilters ? defaultFilters.find(F => F.id === filter.id) : null;
                let selectedFilter = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                selectedFilter = selectedFilter ? selectedFilter : (defaultValueDRP ? defaultValueDRP : null);
                return <DateRangeComboPicker 
                    key={`k_${idx}`}
                    title={filter.name}
                    filter={selectedFilter || {}}
                    onFilterChange={filterValue => {
                        if (filterValue.rangeType === "null" && selectedFilter) {
                            selectedFilter.value = null;
                        } else {
                            if (!selectedFilter) {
                                formData.properties.filters.push({
                                    id: filter.id,
                                    value: filterValue,
                                })
                            } else {
                                selectedFilter.value = filterValue;
                            }    
                        }
                        onFilterChange()
                    }}
                />
            case 'datePicker':
                const defaultDateValue = defaultFilters ? defaultFilters.find(F => F.id === filter.id) : null;
                let selectedDate = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                selectedDate = selectedDate ? selectedDate : (defaultDateValue ? defaultDateValue : null);
                return <r-cell span="2" key={`k_${idx}`}>
                    <div>
                        <DateRangePicker 
                            inputDateChanged={(date) => {
                                const formDataFilter = formData.properties.filters.find(F => F.id === filter.id)
                                if (!formDataFilter) { 
                                    formData.properties.filters.push({
                                        id: filter.id,
                                        value: {
                                            from: date.startDate,
                                            to: date.endDate,
                                        }
                                    })
                                } else {
                                    formDataFilter.value = {
                                        from: date.startDate,
                                        to: date.endDate,
                                    }
                                }
                                onFilterChange()
                            }}
                            selectedStartDate={selectedDate && selectedDate.value ? new Date(selectedDate.value.from) : null}
                            selectedEndDate={selectedDate && selectedDate.value ? new Date(selectedDate.value.to) : null}
                            textFieldValue=""
                        />
                    </div>
                </r-cell>
            case 'list':
                const defaultKey = defaultFilters ? defaultFilters.find(F => F.id === filter.id) : null;
                let selectedKey = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                selectedKey = selectedKey ? selectedKey : (defaultKey ? defaultKey : null);
                const listStateName = `state${filter.id}`;
                if (!this.state[listStateName]) {
                    if (filter.getValues) {
                        filter.getValues().then(result => {
                            this.setState({ [listStateName]: result });
                        });
                    } else {
                        this.setState({[listStateName]:filter.values});
                    }
                }
                let listOptions = [];
                if (this.state[listStateName]) {
                    listOptions = this.state[listStateName]
                } else {
                    listOptions = filter.values
                }
                return <r-cell span="2" key={`k_${idx}`}>
                    <Dropdown
                        label={filter.hideLabel?"":filter.name}
                        selectedKey={selectedKey ? selectedKey.value: (filter.default ? filter.default : null)}
                        onChange={(ev, op) => {
                            if (op) {
                                this.onFilterChange(op,formData,filter.id)
                                onFilterChange()
                            }
                        }}
                        options={listOptions}
                    />
                </r-cell>
            case 'multiselectList':
                const defaultKeys = defaultFilters ? defaultFilters.find(F => F.id === filter.id) : null;
                let selectedKeys = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                selectedKeys = selectedKeys ? selectedKeys : (defaultKeys ? defaultKeys : null);
                const stateName = `state${filter.id}`;
                if (!this.state[stateName]) {
                    if (filter.getValues) {
                        filter.getValues().then(result => {
                            this.setState({ [stateName]: result });
                        });
                    } else {
                        this.setState({[stateName]:filter.values});
                    }
                }
                let options = [];
                if (this.state[stateName]) options = this.state[stateName];
                return <r-cell span="2" key={`k_${idx}`}>
                    <Dropdown
                        label={filter.name}
                        selectedKeys={selectedKeys && selectedKeys.value ? selectedKeys.value: null}
                        multiSelect
                        onChange={(ev, op) => {
                            if (op) {
                                this.onMultiSelectChange(op,formData,filter.id,options)
                                onFilterChange()
                            }
                        }}
                        options={options}
                    />
                </r-cell>
            case 'userpicker':
                const defaultUser = defaultFilters ? defaultFilters.find(F => F.id === filter.id) : null;
                let selectedUser = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                selectedUser = selectedUser ? selectedUser : (defaultUser ? defaultUser : null);
                return <r-cell span="2" key={`k_${idx}`}>
                    <r-grid columns="1">
                        <r-cell>
                            {filter.title}
                        </r-cell>
                        <r-cell>
                            <PrincipalPicker 
                                placeholder=""
                                onSelected={(user) => {
                                    this.onUserChange(user, formData, filter.id)
                                    onFilterChange()
                                }}
                                selected={selectedUser ? selectedUser.value || null : null}
                            />
                        </r-cell>
                    </r-grid>                    
                </r-cell>
            case 'group':
                const groupStateName = `isGroup${filter.id}Collapsed`;
                if (typeof this.state[groupStateName] === 'undefined') {
                    this.setState({[groupStateName]:true});
                }
                return <r-cell span="2" key={`k_${idx}`}>
                    <r-grid columns="1">
                        <r-cell>
                            <GroupedList
                                className={GroupedListClassName}
                                items={filter.filters}
                                compact={true}
                                onRenderCell={(nestingDepth, item, itemIndex) => {
                                    return this.getField(item, formData, `${idx}${itemIndex}`, defaultFilters)
                                }}
                                selectionMode={SelectionMode.none}
                                groups={[
                                    {
                                        count: filter.filters.length,
                                        key: filter.id,
                                        name: filter.name,
                                        startIndex: 0,
                                        isCollapsed: this.state[groupStateName],
                                    }
                                ]}
                                groupProps={{
                                    onRenderHeader: (props) =>{
                                        if (props) {
                                            return (
                                                <>
                                                    <div 
                                                        className={groupHeaderClassName}
                                                        onClick={() => {
                                                            this.setState({[groupStateName]:!this.state[groupStateName]});
                                                        }}>                                                        
                                                        <IconButton                                                                 
                                                            iconProps={{iconName: this.state[groupStateName] ? 'FlickLeft' : 'FlickUp' }}
                                                            title="Expand"
                                                        />
                                                        <div>
                                                            {props.group.name}
                                                        </div>
                                                    </div>
                                              </>
                                            )
                                        }
                                        return null
                                    }
                                }
                                }
                            />
                        </r-cell>
                    </r-grid>                    
                </r-cell>
            case 'assessmentPicker':
                const selectedAssessmentPicker = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                return <AsyncPicker
                    key={`k_${idx}`}
                    label={'Pick an assessment'}
                    onItemPicked={(item) => {
                        this.onPlainFilterChange([item], formData, filter.id)
                        onFilterChange()
                    }}
                    selected={selectedAssessmentPicker ? selectedAssessmentPicker.value : []}
                    api={async (keywords) => await assessmentStore.browseStore.searchAssessments({keywords}, {}, true)}
                    idField="id"
                    textField="title"
                    preTextField="code"
                />
            case 'workItemPicker':
                const selectedWorkItemPicker = formData.properties.filters ? formData.properties.filters.find(F => F.id === filter.id) : null;
                return <AsyncPicker
                    key={`k_${idx}`}
                    label={'Pick an assessment'}
                    onItemPicked={(item) => {
                        this.onPlainFilterChange([item], formData, filter.id)
                        onFilterChange()
                    }}
                    selected={selectedWorkItemPicker ? selectedWorkItemPicker.value : []}
                    api={async (keywords) => await workItemStore.browseStore.searchWorkItems({keywords}, {}, true)}
                    idField="id"
                    textField="title"
                    preTextField="code"
                />
            case 'riskPicker':
                return <>riskContext</>
        }
    }

    render () {
        const { formData, dataSourceStore} = this.props;
        const { selectionStore } = dataSourceStore;
        if (formData.properties.mainSource) {
            const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === formData.properties.mainSource);
            const coreDataSource = dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId);
            return coreDataSource.filters
                .filter(F => {
                    if (F.displayCondition) return F.displayCondition(formData)
                    return true
                })
                .map((F,idx) => {
                    return this.getField(F, formData, idx, coreDataSource.defaultFilters)
                })
        }
        return <></>
    }
  }
));
