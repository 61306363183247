import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx } from 'emotion';

import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Separator } from 'office-ui-fabric-react/lib/Separator';

export const PagePartDivider = observer(
  class PagePartDivider extends PureComponent {
    render () {
        const { className, instance, section, name } = this.props;

        return (
          <div className={className}>
            <div className={cx('page--zone-divider')}>
              <div className="wrapper">
                <Separator>
                  <div ref={el => (this.targetElement = el)}>
                    <IconButton 
                      iconProps={{iconName: 'CircleAdditionSolid'}} 
                      title="Add part" ariaLabel="Add part"
                      onClick={() => instance.lifecycleStore.onAddPart({
                        zoneName: name, section, targetElement: this.targetElement
                      })} />
                  </div>
                </Separator>
              </div>
            </div>
          </div>
        );
    }
  }
);