import { observable, flow, computed } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";
import { PaginationStore } from "../../base/PaginationStore";

export class RiskTypeStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public types: any[] = [];
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public keywords: string;
    @observable public pagination: PaginationStore;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
        this.keywords = "";
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings`),
        });
        builder.push({
            text: "Risk Types",
            key: "risk-type",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings/risk-types`),
        });

        return builder;
    }

    @computed
    public get promotedTypes() {
        return this.types.filter((t) => t.requestable && t.promoted);
    }

    @computed
    public get assessmentTypes() {
        return this.types.filter((t) => t.requestable && t.linkToAssessment);
    }

    @computed
    public get exceptionTypes() {
        return this.types.filter((t) => t.requestable && t.template === "zerodai:risks:exception");
    }

    @computed
    public get genericTypes() {
        return this.types.filter((t) => t.requestable && t.template === "zerodai:risks:generic");
    }

    public loadTypes = flow(function* (query: any) {
        const options = { ...{ cached: false }, ...query };
        if (options.cached && this.types.length > 0) {
            return this.types;
        }

        this.loading = true;
        this.types = [];

        try {
            const result = yield this.riskService.getTypes(query);
            this.types = result.items;
            this.keywords = result.query.keywords;

            return this.types;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
