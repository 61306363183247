import { observable, action, flow } from "mobx";
import download from "downloadjs";

import { EntityService } from "@api/entities";
import { EntityStore } from "./EntityStore";

export type DocumentGridView = "all" | "internalOnly";

export class EntityDocumentStore {
    private entityService: EntityService;
    public parentStore: EntityStore;

    @observable public documents: any[] = [];
    @observable public selectedDocument: any;
    @observable public gridView: DocumentGridView = "all";
    @observable public selectedDocuments: any;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
    }

    @action
    public setSelectedDocuments(selection) {
        this.selectedDocuments = selection;
    }

    @action
    public setGridView(view: DocumentGridView) {
        this.gridView = view;
    }

    @action
    public setSearchKeywords(keywords: string) {
        this.keywords = keywords;
    }

    @action
    public downloadDocument(document: any) {
        return this.entityService.downloadEntityDocument(document.sourceId, document.id).then((blob) => {
            download(blob, `${document.document.name}${document.document.extension}`, document.contentType);
        });
    }

    public loadDocuments = flow(function* (entityId: string) {
        this.loading = true;
        this.documents = [];

        try {
            const result = yield this.entityService.getEntityDocuments(entityId);
            this.documents = result;
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public uploadDocument = flow(function* (document) {
        this.saving = true;

        try {
            const result = yield this.entityService.uploadEntityDocument(document);
            this.updateDocumentList(result);
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.saving = false;
        }
    });

    public uploadDocuments = flow(function* (files, entityId) {
        this.saving = true;

        const documents = files
            .map((file) => ({ sourceId: entityId, internalOnly: false, mode: "Major", file: file }))
            .map((document) => this.entityService.uploadEntityDocument(document));

        try {
            const results = yield Promise.all(documents);
            results.forEach(this.updateDocumentList);

            if (results.length == 1) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `${documents.length} document uploaded successfully`
                );
            } else {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `${documents.length} documents uploaded successfully`
                );
            }

            return results;
        } catch (e) {
            console.error(e);
        } finally {
            this.saving = false;
        }
    });

    private updateDocumentList = (result) => {
        const index = this.documents.findIndex((d) => d.id == result.id);
        if (index == -1) {
            this.documents.unshift(result);
        } else {
            this.documents.splice(index, 1, result);
        }
    };
}
