import React from 'react';
import { Text as FabricText } from 'office-ui-fabric-react/lib/Text';

const Text = (props) => {

    return (
        <FabricText
            {...props}
        />
    );
}

export { Text }