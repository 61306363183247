import moment from 'moment';
import { computeTimelineFilter } from '../data/computeTimelineFilter';

function generateRiskQuery(query) {

    let timeLineFilterObject = null;
    if (query.timeLine) {
        timeLineFilterObject = computeTimelineFilter(query.timeLine[0]?query.timeLine[0]:query.timeLine);
    }
    // portfolio
    let portfolioFilter = null;
    if (query.portfolios) {
        portfolioFilter = query.portfolios.map(P => { return {
            id: P
        }})
    }
    // status
    let subStatusFilter = null;
    if (query.subStatus) {
        subStatusFilter = query.subStatus.map(P => P)
    }
    //risk type
    let riskTypeFilter = null;
    if (query.riskTypes) {
        riskTypeFilter = {
            template: query.riskTypes.map(T => T)
        }
    }
    // netScore
    let netScoreFilterObj = null;
    if (query.netScore) {
        netScoreFilterObj = query.netScore
    }
    // netImpact
    let netImpactFilterObj = null;
    if (query.netImpact) {
        netImpactFilterObj = query.netImpact
    }
    // netLikelihood
    let netLikelihoodFilterObj = null;
    if (query.netLikelihood) {
        netLikelihoodFilterObj = query.netLikelihood
    }
    // grossScore
    let grossScoreFilterObj = null;
    if (query.grossScore) {
        grossScoreFilterObj = query.grossScore
    }
    // grossImpact
    let grossImpactFilterObj = null;
    if (query.grossImpact) {
        grossImpactFilterObj = query.grossImpact
    }
    // grossLikelihood
    let grossLikelihoodFilterObj = null;
    if (query.grossLikelihood) {
        grossLikelihoodFilterObj = query.grossLikelihood
    }
    // registry
    let registryFilterObj = null;
    if (query.registry) {
        registryFilterObj = [query.registry]
    }

    const finalQuery = {
        registry: registryFilterObj,
        portfolio: portfolioFilter,
        subStatus: subStatusFilter,
        assignedToUser: null,
        type: riskTypeFilter,
        netScore: netScoreFilterObj,
        netImpact: netImpactFilterObj,
        netLikelihood: netLikelihoodFilterObj,
        grossScore: grossScoreFilterObj,
        grossImpact: grossImpactFilterObj,
        grossLikelihood: grossLikelihoodFilterObj,
    };
    return finalQuery;
}

export { generateRiskQuery }