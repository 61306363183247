import { observable, flow, computed, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { StateFlowService } from "../../../api/stateflows";
import { StateFlowStore } from "./StateFlowStore";

export class StateFlowBrowseStore {
    public parentStore: StateFlowStore;
    public stateFlowService: StateFlowService;

    @observable public stateFlows: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings`),
        });
        builder.push({ text: "Workflows", key: "workflows-browse", isCurrentItem: true });

        return builder;
    }

    @action
    public replace(stateFlow) {
        const index = this.stateFlows.findIndex((s) => s.id === stateFlow.id);
        if (index !== -1) {
            const items = Array.from(this.stateFlows);
            items[index] = stateFlow;
            this.stateFlows = items;
        } else {
            this.stateFlows = [stateFlow, ...this.stateFlows];
        }
    }

    public loadStateFlows = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null }, query);

        try {
            const result = yield this.stateFlowService.searchStateFlows({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: {
                    ...this.query,
                    ...options.query,
                },
            });
            this.stateFlows = result.items;
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
