import { observable, action, computed, toJS } from "mobx";
import moment from "moment";

import { WorkflowService } from "../../../api/workflows";

import { TaskStore } from "./TaskStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class TaskNewFormStore {
    public parentStore: TaskStore;
    public workflowService: WorkflowService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    @action
    public show(options) {
        this.visible = true;

        this.formOptions = {};

        const defaultDueDate = moment().add(1, "week").toDate();
        const defaultTask = { useCase: "Completion", mode: "First", dueDate: defaultDueDate };
        const overrides = { assignedTos: [], notify: true };
        this.formData = { ...defaultTask, ...toJS(options.task), ...overrides };
        this.formData.settings = this.formData.settings || {};
        this.formData.settings.listeners = this.formData.settings.listeners || {};

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.responseHandle.reject(error);
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.title &&
            this.formData.description &&
            this.formData.context &&
            this.formData.context.id &&
            this.formData.context.type &&
            this.formData.dueDate &&
            this.formData.mode &&
            this.formData.useCase &&
            this.formData.settings &&
            this.formData.settings.listeners &&
            this.formData.assignedTos &&
            this.formData.assignedTos.filter((s) => !!s.id || !!s.email).length === this.formData.assignedTos.length
        );
    }
}
