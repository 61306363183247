import { ServiceBase, Constants } from "../graph/serviceBase";

export class AssetService extends ServiceBase {
    public async getAssets(options) {
        return this.get(
            `${Constants.baseUrl}/assets?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchAssets(options) {
        return this.post(`${Constants.baseUrl}/assets/search`, options);
    }

    public async getAsset(id) {
        return this.get(`${Constants.baseUrl}/assets/${id}`);
    }

    public async getAssetRiskTrend(id) {
        return this.get(`${Constants.baseUrl}/assets/${id}/risks/trend`);
    }

    public async getAssetAssessments(id, options) {
        const keywords = options.keywords ? `&keywords=${options.keywords}` : "";
        return this.get(
            `${Constants.baseUrl}/assets/${id}/assessments?pageSize=${options.pageSize}&startIndex=${options.startIndex}${keywords}`
        );
    }

    public async getAssetAdvisories(id, options) {
        const keywords = options.keywords ? `&keywords=${options.keywords}` : "";
        const finalCompliance = options.finalCompliance ? `&query.finalCompliance=${options.finalCompliance}` : "";
        const level = options.level ? `&query.level=${options.level}` : "";
        return this.get(
            `${Constants.baseUrl}/assets/${id}/advisories?pageSize=${options.pageSize}&startIndex=${options.startIndex}${level}${keywords}${finalCompliance}`
        );
    }

    public async createAsset(data) {
        return this.post(`${Constants.baseUrl}/assets`, data);
    }

    public async updateAsset(data) {
        return this.put(`${Constants.baseUrl}/assets/${data.id}`, data);
    }

    public async addToAssetWatchlist(options) {
        return this.post(`${Constants.baseUrl}/assets/${options.assetId}/watchlist`, options);
    }

    public async removeFromAssetWatchlist(options) {
        return this.delete(`${Constants.baseUrl}/assets/${options.assetId}/watchlist`, options);
    }

    public async getAssetDocuments(workItemId) {
        return this.get(`${Constants.baseUrl}/assets/${workItemId}/documents`);
    }

    public async getAssetDocument(workItemId, id) {
        return this.get(`${Constants.baseUrl}/assets/${workItemId}/documents/${id}`);
    }

    public async downloadAssetDocument(workItemId, id) {
        return this.getBlob(`${Constants.baseUrl}/assets/${workItemId}/documents/${id}/content`);
    }

    public async createAssetDocument(document) {
        return this.post(`${Constants.baseUrl}/assets/${document.sourceId}/documents`, document);
    }

    public async updateAssetDocument(document) {
        return this.put(`${Constants.baseUrl}/assets/${document.sourceId}/documents/${document.id}`, document);
    }

    public async uploadAssetDocument(document) {
        const formData = new FormData();
        formData.set("internalOnly", document.internalOnly);
        formData.set("mode", document.mode);
        if (document.notes) {
            formData.set("notes", document.notes);
        }

        formData.set("entity", document.file);
        if (document.name) {
            formData.set("name", document.name);
        }

        if (!document.id) {
            return this.postForm(`${Constants.baseUrl}/assets/${document.sourceId}/documents/upload`, formData);
        }

        formData.append("id", document.id);
        return this.putForm(
            `${Constants.baseUrl}/assets/${document.sourceId}/documents/${document.id}/upload`,
            formData
        );
    }

    public async getAssetTypes(options) {
        return this.get(
            `${Constants.baseUrl}/asset-types?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchAssetTypes(options) {
        return this.post(`${Constants.baseUrl}/asset-types/search`, options);
    }

    public async getAssetType(id) {
        return this.get(`${Constants.baseUrl}/asset-types/${id}`);
    }

    public async createAssetType(data) {
        return this.post(`${Constants.baseUrl}/asset-types`, data);
    }

    public async updateAssetType(data) {
        return this.put(`${Constants.baseUrl}/asset-types/${data.id}`, data);
    }

    public async getAssetClasses(options) {
        return this.get(
            `${Constants.baseUrl}/asset-classes?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchAssetClasses(options) {
        return this.post(`${Constants.baseUrl}/asset-classes/search`, options);
    }

    public async getAssetClass(id) {
        return this.get(`${Constants.baseUrl}/asset-classes/${id}`);
    }

    public async createAssetClass(data) {
        return this.post(`${Constants.baseUrl}/asset-classes`, data);
    }

    public async updateAssetClass(data) {
        return this.put(`${Constants.baseUrl}/asset-classes/${data.id}`, data);
    }

    public async getAssetClassSummary(options) {
        return this.get(`${Constants.baseUrl}/asset-classes/summary`);
    }
}
