import { observable, action, flow } from "mobx";
import { RootStore } from '../../base/RootStore';
import { PaginationStore } from '../../base/PaginationStore';
import { SystemService } from '../../../api/graph';
import { System } from './System';
import { SystemViewPageStore } from './SystemViewPageStore';
import { SystemBrowsePageStore } from './SystemBrowsePageStore'

export class SystemStore {
    private systemService: SystemService;
    public rootStore: RootStore;
    public viewPageStore: SystemViewPageStore;
    public browsePageStore: SystemBrowsePageStore;

    @observable public selectedSystem: System;
    @observable public systems: System[] = [];
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public loading: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.pagination = new PaginationStore();
        this.systemService = new SystemService(rootStore.authProvider);
        this.viewPageStore = new SystemViewPageStore(this);
        this.browsePageStore = new SystemBrowsePageStore(this);
    }

    @action public setSelected(system: System) {
        this.selectedSystem = system;
    }

    @action public createNew(system: Partial<System>) {
        this.selectedSystem = new System(system);
    }

    @action public setSelectedWithId(id: string | null) {
        if(id == null) {
            this.selectedSystem = null;
        } else {
            if(this.selectedSystem && this.selectedSystem.id === id) {
                return;
            }            
            this.selectedSystem = this.systems.find(p => p.id === id);
            if(this.selectedSystem == null) {
                this.loadSystem(id);
            }
        }
    }

    @action public loadSystems(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: null }, query);
        return this.systemService.getSystems({ 
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                keywords: options.keywords
            })
            .then(this.loadSystemsOnSuccess, this.loadSystemsOnError);
    }
    
    @action.bound public loadSystemsOnSuccess(result) {
        this.systems = result.items;
        this.keywords = result.keywords;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound public loadSystemsOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    @action public createSystem(system: System) {
        this.loading = true;
        return this.systemService.createSystem(system)
            .then(this.createSystemOnSuccess, this.createSystemOnError);
    }

    @action.bound public createSystemOnSuccess(result) {
        this.systems = result.items;
        this.loading = false;
        return result;
    }

    @action.bound public createSystemOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    getSystem = flow(function*(id: string) {
        if (id) {
            try {
                const result  = yield this.systemService.getSystem(id);
                return new System(result);
            } catch (error) {
                console.error(error);
                return error;
            }
        }
    })

    @action public loadSystem(id: string) {
        return this.systemService.getSystem(id)
            .then(this.loadSystemOnSuccess, this.loadSystemOnError);
    }

    @action.bound public loadSystemOnSuccess(result) {
        this.selectedSystem = new System(result);
        return result;
    }

    @action.bound public loadSystemOnError(error) {
        console.error(error);
        return error;
    }

    @action public async loadSystemAssessments(id: string, options: any) {
        this.loading = true;
        const apiOptions = {
            pageSize: 25,
            startIndex: 0,
            keywords: null,
        }

        if (options.keywords) apiOptions.keywords = options.keywords;
        if (options.page) apiOptions.startIndex = (options.page - 1) * this.pagination.itemsPerPage;
        if (options.pageSize) apiOptions.pageSize = options.pageSize;
        
        if (this.selectedSystem !== undefined) {
            return this.systemService.getSystemAssessments(id,apiOptions)
                .then(this.loadSystemAssessmentOnSuccess, this.loadSystemAssessmentOnError);
        } else {
            return this.systemService.getSystem(id)
                .then(await this.systemService.getSystemAssessments(id, apiOptions)
                    .then(this.loadSystemAssessmentOnSuccess, this.loadSystemAssessmentOnError), 
                    this.loadSystemOnError);
        }
    }
    
    @action.bound public loadSystemAssessmentOnSuccess(result) {
        this.selectedSystem.assessments = result.items;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound public loadSystemAssessmentOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    @action public changeAssessmentsStatusFilter(state: string) {
        this.selectedSystem.assessmentsFilters = Object.assign(this.selectedSystem.assessmentsFilters, {state, page:0});
    }

    @action public changeAssessmentsKeywordsFilter(keywords: string) {
        this.selectedSystem.assessmentsFilters = Object.assign(this.selectedSystem.assessmentsFilters, {keywords, page:0});
        return this.loadSystemAssessments(this.selectedSystem.id, this.selectedSystem.assessmentsFilters);
    }
    
    @action public changeAssessmentsPage(page: number) {
        this.selectedSystem.assessmentsFilters = Object.assign(this.selectedSystem.assessmentsFilters, {page});
        return this.loadSystemAssessments(this.selectedSystem.id, this.selectedSystem.assessmentsFilters);
    }

    @action public async loadSystemAdvisories(id: string, options: any) {
        this.loading = true;
        const apiOptions = {
            pageSize: 25,
            startIndex: 0,
            keywords: null,
            finalCompliance: null,
            level: null,
        }

        if (options.keywords) apiOptions.keywords = options.keywords;
        if (options.finalCompliance && options.finalCompliance != 'All') apiOptions.finalCompliance = options.finalCompliance;
        if (options.level && options.level != 'All') apiOptions.level = options.level;
        if (options.page) apiOptions.startIndex = (options.page - 1) * this.pagination.itemsPerPage;
        if (options.pageSize) apiOptions.pageSize = options.pageSize;
        if (this.selectedSystem !== undefined) {
            return this.systemService.getSystemAdvisories(id,apiOptions)
                .then(this.loadSystemAdvisoriesOnSuccess, this.loadSystemAdvisoriesOnError);
        } else {
            return this.systemService.getSystem(id)
                .then(await this.systemService.getSystemAdvisories(id, apiOptions)
                    .then(this.loadSystemAdvisoriesOnSuccess, this.loadSystemAdvisoriesOnError), 
                    this.loadSystemOnError);
        }
    }

    @action.bound public loadSystemAdvisoriesOnSuccess(result) {
        this.selectedSystem.advisories = result.items;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound public loadSystemAdvisoriesOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    @action public changeAdvisoriesStatusFilter(finalCompliance: string) {
        this.selectedSystem.advisoriesFilters = Object.assign(this.selectedSystem.advisoriesFilters, {finalCompliance, page:0});
        return this.loadSystemAdvisories(this.selectedSystem.id, this.selectedSystem.advisoriesFilters);
    }

    @action public changeAdvisoriesSeverityFilter(level: string) {
        this.selectedSystem.advisoriesFilters = Object.assign(this.selectedSystem.advisoriesFilters, {level, page:0});
        return this.loadSystemAdvisories(this.selectedSystem.id, this.selectedSystem.advisoriesFilters);
    }

    @action public changeAdvisoriesKeywordFilter(keywords: string) {
        this.selectedSystem.advisoriesFilters = Object.assign(this.selectedSystem.advisoriesFilters, {keywords, page:0});
        return this.loadSystemAdvisories(this.selectedSystem.id, this.selectedSystem.advisoriesFilters);
    }

    @action public changeAdvisoriesPage(page: number) {
        this.selectedSystem.advisoriesFilters = Object.assign(this.selectedSystem.advisoriesFilters, {page});
        return this.loadSystemAdvisories(this.selectedSystem.id, this.selectedSystem.advisoriesFilters);
    }
}

