import { observable, action, computed, flow } from "mobx";
import { PageStore } from './PageStore';
import { PageService } from '../../../api/pages';

export class PageEditFormStore {
    private pageService: PageService;
    public parentStore: PageStore;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public originalFormData: any;
    @observable public formOptions: any;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any = null;

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
    }

    @computed 
    public get isValid() {
        return this.formData && this.formData.title && this.formData.summary && this.formData.path && this.formData.type;
    }

    @computed 
    public get isDirty() {
        return (JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData));
    }

    @action 
    public show(options) {
        const promise = new Promise( (resolve, reject) => {
            this.formOptions = { 
                resolve, 
                reject,
                parent: options.parent || options.page.parent 
            };
        });
        this.setPage(options.page);
        this.visible = true;

        return promise;
    }

    @action setPage(page) {
        this.formData = page ? 
        JSON.parse(JSON.stringify(page))
        : { 
            type: "Page",
            language: "en-gb",
            tags: [],
            library: { id: this.formOptions.parent.library.id },
            content: {
                sections: []
            }
        };
        this.originalFormData = {...this.formData};
    }
    
    @action 
    public hide() {
        this.formData = null;
        this.formOptions = null;
        this.originalFormData = null;
        this.error = null;
        this.visible = false;
    }

    @action 
    public reset() {
        this.formData = {...this.originalFormData};
    }

    public createPage = flow(function*(mode, page) {
        this.saving = true;

        try {
            const child = yield this.pageService.createPage({
                mode: mode || 'Build',
                notes: "Initial update",
                entity: page
            });

            this.formOptions.resolve(child);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public editPage = flow(function*(mode, page) {
        this.saving = true;

        try {
            const child = yield this.pageService.updatePage({
                mode: mode || 'Build',
                notes: "Updated properties",
                entity: page
            });

            this.formOptions.resolve(child);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });
}
