import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';

import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { ObservableSeparator } from '../../../base/components/ObservableSeparator';

export const EmbeddedContentPartEditor = observer(
  class EmbeddedContentPartEditor extends PureComponent {
    render () {
      const { formData, audiences } = this.props;

      return (
          <div>
              <r-grid columns="2">
                  <r-cell span="2">
                      <ObservableSeparator>Embed Settings</ObservableSeparator>
                  </r-cell>
                  <r-cell span="2">
                      <div>
                          <TextField 
                              label="Source URL"
                              required
                              value={formData.properties.src}
                              onChange={(ev, val) => formData.properties.src = val} 
                          />
                      </div>
                      Only YouTube and Vimeo embedded videos are currently supported.
                  </r-cell>
              </r-grid>
          </div>
      );
    }
  }
);
