import { observable, action, computed } from "mobx";
import { RootStore } from "@modules/base/RootStore";

let notificationStoreIdCounter = 1;
export class NotificationStore {
    readonly rootStore: RootStore;
    @observable public messages: any[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @computed
    public get unreadMessages() {
        let unreadMessages = false;
        this.messages.forEach((N) => {
            if (!N.read) unreadMessages = true;
        });
        return unreadMessages;
    }

    @computed
    public get notifications() {
        return this.messages; ///.sort((a, b) => a.time - b.time)
    }

    @action public markAllNotificationsRead = () => {
        this.messages.forEach((N) => {
            N.read = true;
        });
    };

    @action
    public error = (message: any) => {
        this.add("error", message);
    };

    @action
    public warn = (message: any) => {
        this.add("warn", message);
    };

    @action
    public success = (message: any) => {
        this.add("success", message);
    };

    @action
    public info = (message: any) => {
        this.add("info", message);
    };

    @action
    public add = (type: string, message: any) => {
        this.messages = [
            {
                id: notificationStoreIdCounter++,
                message,
                time: new Date().getTime(),
                visible: true,
                read: false,
                type: type || "info",
                position: "top-right",
                autoClose: 5000,
            },
            ...this.messages,
        ];
    };

    @action
    public displayToastNotification = (message: any) => {
        return this.add("success", message);
    };
}
