import React from "react";
import {
    Callout as FabricCallout,
    DirectionalHint,
    FocusTrapCallout as FabricFocusTrapCallout,
} from "office-ui-fabric-react/lib/Callout";

const Callout = (props) => {
    return <FabricCallout {...props} />;
};

const FocusTrapCallout = (props) => {
    return <FabricFocusTrapCallout {...props} />;
};

export { Callout, FocusTrapCallout, DirectionalHint };
