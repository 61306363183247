import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import ContainerDimensions from 'react-container-dimensions';

import { PagePartPlaceholder } from  '../../pages/components/elements/PagePartPlaceholder';

const fontIconClass = cx(css`
    font-size:60px;
`);

const greenColor = cx(css`
    color: green;
`);

const redColor = cx(css`
    color: red;
`);

const rootClassName = cx(
  'cygraph-ImageViewerPart-root'
);

const chartContainerClassName = cx(
  css `
    position: relative
  `
)

const divContainerClassName = cx(
  css `
    z-index: 100;    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
  `
)

const RAGElementClassName = cx(css`
  display: table-row;
  span,
  sup {
    display: table-cell;
    padding-right: 5px;
  }
`)

const RAGContainerClassName = cx(css`
    width: 100%;
    margin: auto;
    height: 100%;
    min-height: 50px;
    display: table;
    border-collapse: separate;
    border-spacing: 10px;
`)

export const RagStatusViewerPart = inject('themeProvider','dataSourceStore', 'ragStatusStore')(observer(
  class RagStatusViewerPart extends PureComponent {

    get data() {
        const { ragStatusStore, instance, part, dataSourceStore } = this.props;
        if (instance.selectionStore.data.length > 0) {
          const chartData = part.properties.dataSeries ? part.properties.dataSeries
            .filter(DS => {
              return !!DS.dataSourceId  
            })
            .map(DS => {
              const data = instance.selectionStore.data.find(D => D.id === DS.dataSourceId);
              const selectedPartDataSource = DS.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === DS.dataSourceId) : null;
              const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
              const valueField = selectedCoreDataSource.fields.find(F => F.id === DS.dataValue);
              const onClickHandler = selectedCoreDataSource && selectedCoreDataSource.onDrillDownAction && selectedCoreDataSource.onProcessQuery ? (clickedElementKey, clickedElement) => {
                selectedCoreDataSource.onDrillDownAction(
                  selectedCoreDataSource.onProcessQuery(selectedPartDataSource, clickedElement, clickedElementKey)
                )
              } : null;
              const dSFinal = {
                ...DS,
                valueFieldDecimalPositions: valueField ? valueField.decimalPositions : 0,
                dataSource: data && data.data ? data.data : [],
                dataCount: data && data.count ? data.count : null,
                onClick: onClickHandler,
              }
              return dSFinal
            }) : [];
          // debugger
          // console.log(chartData)
          if (chartData.length > 0) {
            return {
              rendered: true,
              data: ragStatusStore.getData(chartData),
            }  
          }
          return {
            rendered: false,
          }
        }
        return {
          rendered: false,
        }
    }

    isConfigured() {
      const { part } = this.props;
      let areDsOk = true;
      if (part.properties && part.properties.dataSeries && part.properties.dataSeries.length > 0) {
          part.properties.dataSeries.forEach(DS => {
              if (!DS.dataSourceId) {
                  areDsOk = false;
              }
              // console.log('DS.dataDisplayType', DS.dataDisplayType)
              // console.log('DS.dataValue', DS.dataValue)
              if (DS.dataDisplayType !== 'count') {
                  if (!DS.dataValue) areDsOk = false
                  if (!DS.labelField) areDsOk = false
              }
              if (!DS.upperLimit) {
                  areDsOk = false
              }
              if (!DS.lowerLimit) {
                  areDsOk = false
              }
          })
      }
      return part && part.properties && part.properties.dataSeries && part.properties.dataSeries.length > 0 && areDsOk;
    }

    getRGAColor(colorBand, lowerBandColor, middleBandColor, upperBandColor) {
      let RGAColor = '#FFBF00';
      if (colorBand === 'lower') RGAColor = lowerBandColor || 'red';
      else if (colorBand === 'upper') RGAColor = upperBandColor || 'green';
      else RGAColor = middleBandColor || '#FFBF00';      
      return RGAColor;
    }

    getRAG(value, key) {
      // debugger
      if (value.value !== null) {
        return <div className={RAGElementClassName} key={`key_${key}`}>
          <span className="before">{value.unitDisplay === 'pre' ? value.unit: ' '}</span>
          <span>{value.decimalPositions ? value.value.toFixed(value.decimalPositions) : value.value}</span>
          {value.isOrdered && <sup className={value.increase?greenColor:redColor}>{`(${value.increase?'+':''}${value.change}%)`}</sup>}
          <span className="after">{value.unitDisplay === 'after' ? value.unit: ' '}</span>
          <FontIcon
            iconName={`CircleFill`}
            className={fontIconClass}
            style={{color:this.getRGAColor(value.colorBand, value.lowerBandColor, value.middleBandColor, value.upperBandColor), fontSize:'11px'}}
          />
        </div>
      }
      return value.values.map((v,i) => {
        // debugger
        const val = value.decimalPositions ? v.toFixed(value.decimalPositions) : v;
        return <div className={RAGElementClassName} key={`key_${key}_${i}`}>
          <span className="before">{value.unitDisplay === 'pre' ? value.labels[i]: ' '}</span>
          <span>{val}</span>
          {value.isOrdered && <sup className={value.increase?greenColor:redColor}>{`(${value.increase?'+':''}${value.change}%)`}</sup>}
          <span className="after">{value.unitDisplay === 'after' ? value.labels[i]: ' '}</span>
          <FontIcon
            iconName={`CircleFill`}
            className={fontIconClass}
            style={{color:this.getRGAColor(value.colorBands[i], value.lowerBandColor, value.middleBandColor, value.upperBandColor), fontSize:'11px'}}
          />
        </div>
      })
    }

    render () {
      const { className, instance, part, themeProvider, dataSourceStore, ragStatusStore } = this.props;
      const mode = instance.lifecycleStore.mode;
      const theme = themeProvider.getTheme();
      const data = this.data;
      return (
        <div className={cx(rootClassName, className, mode)}>
          <PagePartPlaceholder {...this.props} isConfigured={this.isConfigured()}>
            <div className={chartContainerClassName} style={{
                height:part.height || '100%',
                width:part.width || '100%'
              }}>
              {!data.rendered && this.isConfigured() && <div className={cx(divContainerClassName,css `background: ${theme.themeLight};`)}>
                <Spinner size={SpinnerSize.large} />
              </div>}
              {data.rendered && <FontIcon
                    iconName={data.data.selectedIcon}
                    className={fontIconClass}
                /> }
              {data.rendered && 
              <div id={part.id} className={RAGContainerClassName}>
                {/* <ContainerDimensions>
                    { ({ width }) =>  */}
                    {data.data.map((value, key) => {
                        return this.getRAG(value, key)
                    })}
                    {/* }
                </ContainerDimensions> */}
              </div>}
            </div>
          </PagePartPlaceholder>
        </div>
      );
    }
  }
));
