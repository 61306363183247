import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';

import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { PhotoPersona } from '../../identity/containers/utilities/PhotoPersona';
import { PagePartPlaceholder } from  '../../pages/components/elements/PagePartPlaceholder';
import { TableField } from './components/TableField';

const rootClassName = cx(
  'cygraph-ImageViewerPart-root',
  css`
    .ms-FocusZone.ms-DetailsHeader {
      padding-top: 0
    }
  `
);

export const TablePart = inject('dataSourceStore')(observer(
  class TablePart extends PureComponent {

    getCoreData() {
      const { part, instance } = this.props;
      const dataSourceId = part.properties.dataSourceId;
      const data = instance.selectionStore.data.find(D => D.id === dataSourceId);
      return data ? data : null;
    }

    get _badgesColumns() {
      const { part, instance, dataSourceStore } = this.props;
      const { selectionStore } = dataSourceStore;
      if (part.properties.dataSourceId) {
        const parts = instance.selectionStore.getPageParts([part.properties.dataSourceId]);
        const dataSourcePart = parts[0];
        const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === dataSourcePart.properties.mainSource);
        const coreDataSource = dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId);
        const data = this.getCoreData();
        if (dataSourcePart && coreDataSource && data && data.data[0]) {
          return [{
            key: "fieldId",
            isPadded: true,
            name: "Contact",
            onRender: (item) => {
                return (
                <div>
                  <PhotoPersona
                    principalId={item ? item.principalId : null}
                    text={item.name}
                    secondaryText={item.jobTitle}
                    tertiaryText={item.portfolioName}
                    size={PersonaSize.size72}
                  />
                </div>
                );
            },
        }]
        }
      }
      return []
    }

    renderField(fieldName, fields) {
      const field = fields.find(F => F.id === fieldName);
      if (field.type === 'personBadge') {
        return {
          key: field.id,
          name: field.title,
          fieldName: field.id,                   
          minWidth: 250,
          maxWidth: 350,
          data: field.type,
          isPadded: true,
          onRender: (item) => {
            return (
              <div>
                  <PhotoPersona
                    principalId={item ? item.principalId : null}
                    text={item[field.badgeFields.text]}
                    secondaryText={item[field.badgeFields.secondaryText]}
                    size={PersonaSize.size40}
                  />
              </div>
            )
          },
        }
      } 
      return {
        key: field.id,
        name: field.title,
        fieldName: field.id,                   
        maxWidth: 150,
        data: field.type,
        isPadded: true,
        onRender: (item) => {
          // console.log('--->',field.id, field.type, item[field.id]);
          return <TableField 
            field={field}
            value={item[field.id]}
            item={item}
          />
        },
      }
    }

    get _columns() {
      const { part, instance, dataSourceStore } = this.props;
      const { selectionStore } = dataSourceStore;
      if (part.properties.dataSourceId) {
        const parts = instance.selectionStore.getPageParts([part.properties.dataSourceId]);
        const dataSourcePart = parts[0];
        const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === dataSourcePart.properties.mainSource);
        const coreDataSource = dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId);
        const data = this.getCoreData();
        if (dataSourcePart && coreDataSource && data && data.data[0]) {
          const responsesFields = Object.keys(data.data[0]);
          return part.properties.selectedFields
            .filter(fieldName => {
              const field = coreDataSource.fields.find(F => F.id === fieldName);
              return responsesFields.includes(fieldName) || field.type === 'personBadge' || field.style === 'composit'
            })
            .map(fieldName => {
              return this.renderField(fieldName, coreDataSource.fields)
          });
        }
      }
      return [];
    }

    sortThings(a, b) {
      const { part, instance, dataSourceStore } = this.props;
      const { selectionStore } = dataSourceStore;
      const parts = instance.selectionStore.getPageParts([part.properties.dataSourceId]);
      const dataSourcePart = parts[0];
      const selectedDataSource = selectionStore.dataSources.find(DS => DS.id === dataSourcePart.properties.mainSource);
      const coreDataSource = dataSourceStore.coreDataSources.find(CDS => CDS.id === selectedDataSource.coreDataSourceId);
      const field = coreDataSource.fields.find(F => F.id === part.properties.orderBy);

      let fieldA = a[part.properties.orderBy];
      let fieldB = b[part.properties.orderBy];
      let isAbigger = true;

      if (part.properties.orderBy) {

        switch(field.type) {
          case 'number':
            isAbigger = parseInt(fieldA) > parseInt(fieldB) ? true : false;
          break;
          case 'string':
            isAbigger = fieldA.localeCompare(fieldB) > 0 ? true : false;
          break;
          case 'date':
            const dateA = fieldA ? new Date(fieldA).getTime() : 0;
            const dateB = fieldB ? new Date(fieldB).getTime() : 0;
            isAbigger = parseInt(dateA) > parseInt(dateB) ? true : false;
          break;
          case 'summary':
          case 'description':
            isAbigger = true;
          break;
          case 'patternVersion':
            isAbigger = fieldA.version.localeCompare(fieldB.version) > 0 ? true : false;
          break;
          case 'person':
          case 'target':
          case 'assignedToGroup':
          case 'product':
          case 'portfolio':
          default:
            isAbigger = fieldA.name.localeCompare(fieldB.name) > 0 ? true : false;
          break;
        }
        const sortOrder = part.properties.orderType === 'descending' ? false : true
        if (sortOrder) {
          //ascending
          return isAbigger ? -1 : 1
        } else {
          //decending
          return isAbigger ? 1 : -1
        }
      } else {
        return 0
      }
    }

    render () {
      const { className, instance, part, zone, section, registry, dataSourceStore } = this.props;
      const { selectionStore } = dataSourceStore;
      const data = this.getCoreData();
      const mode = instance.lifecycleStore.mode;
      const numberOfResults = part.properties.numberOfResults || 25;
      const items = data && data.data ? data.data : [];
      const sortedItems = items.slice();//.sort(this.sortThings.bind(this));
      const sortedAndSlicedItems = sortedItems.slice(0, numberOfResults);
      // console.log('part.properties.showHeader', part.properties.showHeader)
      return (
        <div className={cx(rootClassName, className, mode)}>
          <PagePartPlaceholder {...this.props} isConfigured={part.properties.dataSourceId}>
            {part.properties.displayAsTable && (
              <div>
                <ShimmeredDetailsList
                  items={sortedAndSlicedItems}
                  compact={false}
                  columns={this._columns}
                  selectionMode={SelectionMode.none}
                  getKey={(item, index) => item ? item.id : index}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={part.properties.showHeader}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  ariaLabelForShimmer="Data being fetched"
                  ariaLabelForGrid="Sample data"
                  />
              </div>
            )}
            {!part.properties.displayAsTable && (
              <div style={{maxWidth:'500px'}}>
                <ShimmeredDetailsList
                  items={ data && data.data ? data.data : []}
                  compact={false}
                  columns={this._badgesColumns}
                  selectionMode={SelectionMode.none}
                  getKey={(item, index) => item ? item.id : index}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={part.properties.showHeader}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  ariaLabelForShimmer="Data being fetched"
                  ariaLabelForGrid="Sample data"
                  />
              </div>
            )}
          </PagePartPlaceholder>
        </div>
      );
    }
  }
));