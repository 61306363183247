import { observable, action, flow } from "mobx";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentRequirementStore {
    public parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public loading: boolean = true;
    @observable public requirements: any[] = [];
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @action
    public reset() {
        this.loading = true;
        this.requirements = [];
        this.error = null;
    }

    public loadAssessmentRequirements = flow(function* (id: string) {
        this.reset();

        try {
            this.requirements = yield this.assessmentService.getAssessmentRequirements(id);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
