import { observable, flow } from "mobx";

import { PaginationStore } from "@modules/base/PaginationStore";
import { EntityStore } from "./EntityStore";

export class EntityAssessmentStore {
    private parentStore: EntityStore;

    @observable public loading: boolean = false;
    @observable public assessments: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.pagination = new PaginationStore();
    }

    public loadAssessments = flow(function* (options: any) {
        const q = Object.assign({}, options.query, { targetType: "Entity", targetId: options.entityId });
        const o = Object.assign({ page: 1 }, options, { query: q });
        const startIndex = (o.page - 1) * this.pagination.itemsPerPage;

        this.loading = true;
        this.assessments = [];
        this.query = o.query;
        this.error = null;

        try {
            const assessmentService = this.parentStore.rootStore.assessmentStore.assessmentService;

            const result = yield assessmentService.searchAssessments({
                pageSize: this.pagination.itemsPerPage,
                startIndex: startIndex,
                query: o.query,
            });
            this.assessments = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public exportAssessments = flow(function* (options) {
        const o = Object.assign({ page: 1, query: this.query }, options);

        try {
            const assessmentService = this.parentStore.rootStore.assessmentStore.assessmentService;

            const result = yield assessmentService.exportAssessments({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });

            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Assessment export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });
}
