import { observable, action, computed, flow } from "mobx";

import { ProductService } from "../../../api/graph";
import { RootStore } from "../../base/RootStore";
import { Product } from "./Product";
import { PaginationStore } from "../../base/PaginationStore";

import { ProductOptionsStore } from "./ProductOptionsStore";
import { ProductSelectionStore } from "./ProductSelectionStore";
import { ProductCatalogueStore } from "./ProductCatalogueStore";
import { ProductViewerStore } from "./ProductViewerStore";
import { ProductBrowseStore } from "./ProductBrowseStore";
import { ProductEditFormStore } from "./ProductEditFormStore";
import { ProductNewFormStore } from "./ProductNewFormStore";
import { ProductPickerStore } from "./ProductPickerStore";

export class ProductStore {
    public productService: ProductService;
    public rootStore: RootStore;

    public optionsStore: ProductOptionsStore;
    public selectionStore: ProductSelectionStore;
    public catalogueStore: ProductCatalogueStore;
    public viewerStore: ProductViewerStore;
    public browseStore: ProductBrowseStore;
    public pickerStore: ProductPickerStore;

    private loadAllPromise = null;
    public editFormStore: ProductEditFormStore;
    public newFormStore: ProductNewFormStore;

    @observable public pagination: PaginationStore;
    @observable public selectedProductId: string;
    @observable public selectedCategory: string = "All";
    @observable public products: any[] = [];
    @observable public loading: boolean = false;
    @observable public schema = null;
    @observable public categories = [];
    @observable public providers = [];
    @observable public availabilities = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.productService = new ProductService(rootStore.authProvider);

        this.optionsStore = new ProductOptionsStore(this);
        this.selectionStore = new ProductSelectionStore(this);
        this.catalogueStore = new ProductCatalogueStore(this);
        this.viewerStore = new ProductViewerStore(this);
        this.browseStore = new ProductBrowseStore(this);

        this.editFormStore = new ProductEditFormStore(this);
        this.newFormStore = new ProductNewFormStore(this);
        this.pickerStore = new ProductPickerStore(this);
        this.pagination = new PaginationStore();
    }

    @computed public get promotedProducts() {
        return this.products.filter((p) => {
            return p.isInternal && p.isRequestable && p.state === "Operational";
        });
    }

    @computed
    public get filteredProducts() {
        const operationalProducts = this.products.filter((p) => p.state === "Operational");
        if (this.selectedCategory === "All") {
            return operationalProducts;
        } else {
            return operationalProducts.filter((P) => P.category === this.selectedCategory);
        }
    }

    @computed
    public get selectedProduct() {
        return this.products.find((p) => p.id == this.selectedProductId);
    }

    @action
    public setSelectedCategory(category: string) {
        this.selectedCategory = category;
    }

    setSelected = flow(function* (product: Product) {
        if (!this.products) yield this.loadProducts();
        this.selectedProductId = product.id;
    });

    setSelectedWithId = flow(function* (id: string) {
        if (!this.products) yield this.loadProducts();
        this.selectedProductId = id;
    });

    @action
    public clearProductSelected() {
        this.selectedProductId = null;
    }

    loadProducts = flow(function* (
        keywords: string = "",
        page: number = 1,
        pageSize: number = 100,
        returnResult: false
    ) {
        try {
            const startIndex = page ? (page - 1) * pageSize : 0;
            const result = yield this.productService.getProducts({ keywords, startIndex, pageSize });
            if (returnResult) {
                return result.items;
            } else {
                this.products = result.items;
                this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            }
        } catch (e) {
            console.error(e);
            return e;
        }
    });

    public loadProductsOptions = flow(function* () {
        try {
            this.categories = yield this.productService.getCategories();
            this.providers = yield this.productService.getProviders();
            this.availabilities = yield this.productService.getAvailabilities();
        } catch (e) {
            console.error(e);
            return e;
        }
    });

    public loadProductSchema = flow(function* (id: string, version: string = null) {
        this.loading = true;
        if (this.schema && this.schema.productId !== id) {
            this.schema = null;
        }

        try {
            const result = yield this.productService.getProductSchema(id, version);
            this.schema = result;
            return result;
        } catch (error) {
            console.error(error);
            return error;
        } finally {
            this.loading = false;
        }
    });
}
