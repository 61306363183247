import { observable, flow, action } from "mobx";

import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentSelectionStore {
    public parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public id: string;
    @observable public assessment: any;
    @observable public workItem: any;
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    public loadAssessment = flow(function* loadAssessment(id: string) {
        this.error = null;
        if (this.id !== id || !this.assessment) {
            this.loading = true;
            this.id = id;
            this.assessment = null;
            this.workItem = null;
        }

        try {
            this.assessment = yield this.assessmentService.getAssessment(id);
            if (this.assessment.version < 2 && false) {
                this.workItem = yield this.assessmentService.getAssessmentWorkItem(id);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    @action
    public setSelected(assessment) {
        this.assessment = assessment;
    }
}
