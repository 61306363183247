import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx } from 'emotion';

import { PagePart } from './PagePart';

export const PagePartList = observer(
  class PagePartList extends PureComponent {
    render () {
        const { className, instance, section, zone } = this.props;
        return (
          <div className={cx(className, 'zone-parts')}>
            { zone && zone.parts && zone.parts.map((part, index) => (
              <PagePart key={part.id} instance={instance} section={section} zone={zone} part={part} index={index} />
            )) }
          </div>
        );
    }
  }
);