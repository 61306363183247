import { observable, action } from "mobx";
import { DialogType } from "office-ui-fabric-react/lib/Dialog";

export class DialogStore {
    @observable public visible: boolean = false;
    @observable public type: DialogType = DialogType.normal;
    @observable public title: string = null;
    @observable public subText: string = null;
    @observable public isBlocking: boolean = true;
    @observable public context: any;

    @action
    public show(options) {
        this.visible = true;
        this.type = options.type || DialogType.normal;
        this.title = options.title;
        this.subText = options.subText;
        this.isBlocking = !!options.isBlocking;
        this.context = options.context;
    }

    @action
    public hide() {
        this.visible = false;
        this.title = null;
        this.subText = null;
        this.context = null;
    }
}
