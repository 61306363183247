import { observable, flow, computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { AccountStore } from "./AccountStore";

export class AccountOverviewStore {

    private parentStore: AccountStore;
    private principalContext: PrincipalContextStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        { text: 'Account', key: 'account', onClick: () => this._goToUrl(`/account/overview`) },
        { text: 'Overview', key: 'overview', isCurrentItem: true },
    ];

    constructor(parentStore: AccountStore) {
        this.parentStore = parentStore;
        this.principalContext = this.parentStore.rootStore.principalContext;
    }

    @computed
    public get user() {
        return this.principalContext.current;
    }

    @computed
    public get photo() {
        return this.principalContext.photo;
    }

    public loadOverview = flow(function*(query: any) {
        this.loading = true;
    
        try {

        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if(url) {
            this.parentStore.rootStore.routing.push(url);
        }
    }
}
