const tasksFilters = () => [
    {
        id:'numberOfItems',
        name:'Number of tasks',
        type:'numberPicker',
        default: 10,
    },
    {
        id:'userOnly',
        name:'Task context',
        type:'list',
        values: [
            { key: 'true', text: 'User\'s tasks'},
            { key: 'false', text: 'Task for a specific resource'},
        ],
        default: 'true',
    },
    {
        id:'resourceType',
        name:'Resource Type',
        type:'list',
        values: [
            { key: 'assessment', text: 'Assessment'},
            { key: 'workItem', text: 'Work Item'},
            { key: 'risk', text: 'Risk'},
        ],
        conditions: [
            {
                filterId: 'userOnly',
                value: 'false',
            }
        ]
    },
    {
        id:'assessmentContext',
        name:'Pick an assesment',
        type:'assessmentPicker',
        conditions: [
            {
                filterId: 'resourceType',
                value: 'assessment',
            },
            {
                filterId: 'userOnly',
                value: 'false',
            }
        ]
    },
    {
        id:'workItemContext',
        name:'Pick a work item',
        type:'workItemPicker',
        conditions: [
            {
                filterId: 'resourceType',
                value: 'workItem',
            },
            {
                filterId: 'userOnly',
                value: 'false',
            }
        ]
    },
    {
        id:'riskContext',
        name:'Pick a risk',
        type:'riskPicker',
        conditions: [
            {
                filterId: 'resourceType',
                value: 'risk',
            },
            {
                filterId: 'userOnly',
                value: 'false',
            }
        ]
    },
]

export { tasksFilters }