import { observable } from "mobx";

import { RootStore } from "../../base/RootStore";

import { AssessmentService } from "@api/assessments";

import { AssessmentSelectionStore } from "./AssessmentSelectionStore";
import { AssessmentCommentStore } from "./AssessmentCommentStore";
import { AssessmentDashboardStore } from "./AssessmentDashboardStore";
import { AssessmentBrowseStore } from "./AssessmentBrowseStore";
import { AssessmentCalendarStore } from "./AssessmentCalendarStore";
import { AssessmentTimelineStore } from "./AssessmentTimelineStore";
import { AssessmentWorkloadStore } from "./AssessmentWorkloadStore";
import { AssessmentPortfolioStore } from "./AssessmentPortfolioStore";
import { AssessmentProductStore } from "./AssessmentProductStore";
import { AssessmentViewerStore } from "./AssessmentViewerStore";
import { AssessmentQuestionnaireStore } from "./AssessmentQuestionnaireStore";
import { AssessmentReportStore } from "./AssessmentReportStore";
import { AssessmentModuleStore } from "./AssessmentModuleStore";
import { AssessmentAssignFormStore } from "./AssessmentAssignFormStore";

import { AssessmentDocumentStore } from "./AssessmentDocumentStore";
import { AssessmentRequirementStore } from "./AssessmentRequirementStore";
import { AssessmentEvidenceStore } from "./AssessmentEvidenceStore";
import { AssessmentAdvisoryStore } from "./AssessmentAdvisoryStore";
import { AssessmentRiskStore } from "./AssessmentRiskStore";
import { AssessmentExceptionStore } from "./AssessmentExceptionStore";
import { AssessmentPermissionStore } from "./AssessmentPermissionStore";
import { AssessmentLifecycleStore } from "./AssessmentLifecycleStore";
import { AssessmentTriageStore } from "./AssessmentTriageStore";

import { UseCaseBrowseStore } from "./UseCaseBrowseStore";
import { UseCaseNewFormStore } from "./UseCaseNewFormStore";
import { UseCaseEditFormStore } from "./UseCaseEditFormStore";
import { ControlModuleBrowseStore } from "./ControlModuleBrowseStore";
import { ControlModuleNewFormStore } from "./ControlModuleNewFormStore";
import { ControlModuleEditFormStore } from "./ControlModuleEditFormStore";
import { ControlQuestionBrowseStore } from "./ControlQuestionBrowseStore";
import { ControlQuestionNewFormStore } from "./ControlQuestionNewFormStore";
import { ControlQuestionEditFormStore } from "./ControlQuestionEditFormStore";

import { AssessmentRuleBrowseStore } from "./AssessmentRuleBrowseStore";
import { AssessmentRuleNewFormStore } from "./AssessmentRuleNewFormStore";
import { AssessmentRuleEditFormStore } from "./AssessmentRuleEditFormStore";

export class AssessmentStore {
    public rootStore: RootStore;
    public assessmentService: AssessmentService;

    public selectionStore: AssessmentSelectionStore;
    public commentStore: AssessmentCommentStore;
    public dashboardStore: AssessmentDashboardStore;
    public browseStore: AssessmentBrowseStore;
    public calendarStore: AssessmentCalendarStore;
    public timelineStore: AssessmentTimelineStore;
    public workloadStore: AssessmentWorkloadStore;
    public portfolioStore: AssessmentPortfolioStore;
    public productStore: AssessmentProductStore;
    public viewerStore: AssessmentViewerStore;
    public questionnaireStore: AssessmentQuestionnaireStore;
    public assignFormStore: AssessmentAssignFormStore;

    public reportStore: AssessmentReportStore;
    public moduleStore: AssessmentModuleStore;

    public documentStore: AssessmentDocumentStore;
    public requirementStore: AssessmentRequirementStore;
    public evidenceStore: AssessmentEvidenceStore;
    public advisoryStore: AssessmentAdvisoryStore;
    public riskStore: AssessmentRiskStore;
    public exceptionStore: AssessmentExceptionStore;
    public permissionStore: AssessmentPermissionStore;
    public lifecycleStore: AssessmentLifecycleStore;
    public triageStore: AssessmentTriageStore;

    public useCaseBrowseStore: UseCaseBrowseStore;
    public useCaseNewFormStore: UseCaseNewFormStore;
    public useCaseEditFormStore: UseCaseEditFormStore;
    public moduleBrowseStore: ControlModuleBrowseStore;
    public moduleNewFormStore: ControlModuleNewFormStore;
    public moduleEditFormStore: ControlModuleEditFormStore;
    public questionBrowseStore: ControlQuestionBrowseStore;
    public questionNewFormStore: ControlQuestionNewFormStore;
    public questionEditFormStore: ControlQuestionEditFormStore;

    public ruleBrowseStore: AssessmentRuleBrowseStore;
    public ruleNewFormStore: AssessmentRuleNewFormStore;
    public ruleEditFormStore: AssessmentRuleEditFormStore;

    @observable
    public assessmentsAgeList: any[] = [
        {
            key: "week",
            text: "Older than a week",
        },
        {
            key: "month",
            text: "Older than a month",
        },
        {
            key: "3months",
            text: "Older than 3 months",
        },
    ];

    @observable
    public assessmentsStatusList: any[] = [
        {
            key: "Draft",
            text: "Draft",
            checked: true,
        },
        {
            key: "Started",
            text: "Started",
            checked: true,
        },
        {
            key: "Remediation",
            text: "Remediation",
            checked: true,
        },
        {
            key: "Reviewing",
            text: "Reviewing",
            checked: true,
        },
        {
            key: "Closed",
            text: "Closed",
            checked: false,
        },
        {
            key: "Cancelled",
            text: "Cancelled",
            checked: false,
        },
    ];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.assessmentService = new AssessmentService(rootStore.authProvider);

        // shared stores
        this.selectionStore = new AssessmentSelectionStore(this);
        this.permissionStore = new AssessmentPermissionStore(this);
        this.lifecycleStore = new AssessmentLifecycleStore(this);

        this.assignFormStore = new AssessmentAssignFormStore(this);
        // panel and page stores
        this.commentStore = new AssessmentCommentStore(this);
        this.dashboardStore = new AssessmentDashboardStore(this);
        this.browseStore = new AssessmentBrowseStore(this);
        this.calendarStore = new AssessmentCalendarStore(this);
        this.timelineStore = new AssessmentTimelineStore(this);
        this.workloadStore = new AssessmentWorkloadStore(this);
        this.portfolioStore = new AssessmentPortfolioStore(this);
        this.productStore = new AssessmentProductStore(this);
        this.viewerStore = new AssessmentViewerStore(this);
        this.questionnaireStore = new AssessmentQuestionnaireStore(this);
        this.reportStore = new AssessmentReportStore(this);
        this.moduleStore = new AssessmentModuleStore(this);

        this.documentStore = new AssessmentDocumentStore(this);
        this.requirementStore = new AssessmentRequirementStore(this);
        this.evidenceStore = new AssessmentEvidenceStore(this);
        this.advisoryStore = new AssessmentAdvisoryStore(this);
        this.riskStore = new AssessmentRiskStore(this);
        this.exceptionStore = new AssessmentExceptionStore(this);

        this.triageStore = new AssessmentTriageStore(this);

        this.useCaseBrowseStore = new UseCaseBrowseStore(this);
        this.useCaseNewFormStore = new UseCaseNewFormStore(this);
        this.useCaseEditFormStore = new UseCaseEditFormStore(this);
        this.moduleBrowseStore = new ControlModuleBrowseStore(this);
        this.moduleNewFormStore = new ControlModuleNewFormStore(this);
        this.moduleEditFormStore = new ControlModuleEditFormStore(this);
        this.questionBrowseStore = new ControlQuestionBrowseStore(this);
        this.questionNewFormStore = new ControlQuestionNewFormStore(this);
        this.questionEditFormStore = new ControlQuestionEditFormStore(this);

        this.ruleBrowseStore = new AssessmentRuleBrowseStore(this);
        this.ruleNewFormStore = new AssessmentRuleNewFormStore(this);
        this.ruleEditFormStore = new AssessmentRuleEditFormStore(this);
    }
}
