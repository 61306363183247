import { RootStore } from "../../base/RootStore";
import { RiskService } from '../../../api/risks';

import { AccountOverviewStore } from './AccountOverviewStore';
import { AccountTaskBrowseStore } from './AccountTaskBrowseStore';

export class AccountStore {
    public riskService: RiskService;
    public rootStore: RootStore;

    public overviewStore: AccountOverviewStore;
    public taskBrowseStore: AccountTaskBrowseStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.riskService = new RiskService(rootStore.authProvider);

        this.overviewStore = new AccountOverviewStore(this);
        this.taskBrowseStore = new AccountTaskBrowseStore(this);
    }
}
