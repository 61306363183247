import React from "react";
import classNames from "classnames";

import { Stack } from "office-ui-fabric-react/lib/Stack";

export const ActionPanel = ({ className, children, onRenderLeftMenu }) => {
    return (
        <Stack className={classNames("w-full", className)} horizontal horizontalAlign="space-between">
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {onRenderLeftMenu && onRenderLeftMenu()}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {children}
            </Stack>
        </Stack>
    );
};
