import moment from "moment";
import { workItemFilters } from "../filters";
import { computeTimelineFilter } from "./computeTimelineFilter";

async function getTasks(query, searchMyTaskAssignments, searchTaskDefinitions) {
    // console.log('getTasks', JSON.stringify(query))
    // console.log('workflowsService', workflowsService)
    // debugger
    const api = query.userOnly === "true" ? searchMyTaskAssignments : searchTaskDefinitions;
    const sentQuery = {
        pageSize: parseInt(query.numberOfItems) || 25,
        startIndex: 1,
        query: {},
    };
    if (query.userOnly !== "true") {
        switch (query.resourceType) {
            case "assessment":
                sentQuery.query = {
                    context: [
                        {
                            type: "assessment",
                            id: query.assessmentContext[0].key,
                        },
                    ],
                };
                break;
        }
    }
    // console.log("sentQuery", sentQuery);
    const results = await api(sentQuery);
    // console.log("getTasks", results);
    return results;
}

export { getTasks };
