import { observable, action, computed, flow } from "mobx";
import moment from "moment";
import { UrlUtility } from "@lib/UrlUtility";

import { WorkItemService } from "@api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemRequestStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public loading: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public error: any = null;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Demand Management",
            key: "demand",
            onClick: () => this.parentStore.rootStore.routing.push(`/demand/dashboard`),
        });
        builder.push({
            text: "Service Catalogue",
            key: "demand-catalogue",
            onClick: () => this.parentStore.rootStore.routing.push(`/demand/catalogue`),
        });

        if (!this.formOptions || !this.formOptions.product) {
            return builder;
        }

        builder.push({
            text: this.formOptions.product.name,
            key: this.formOptions.product.id,
            isCurrentItem: true,
        });

        return builder;
    }

    public onInit = flow(function* (options) {
        this.loading = true;

        const productId = options.workItem.product.id;
        const defaults = yield this.workItemService.getWorkItemDefaults(productId);
        const workItem = { ...defaults, ...options.workItem, documents: [] };

        const productService = this.parentStore.rootStore.productStore.productService;
        const schema = yield productService.getProductSchema(productId, null);
        const product = yield productService.getProduct(productId);

        this.formOptions = {
            ...options,
            schema,
            product,
        };

        if (schema && schema.overrides && schema.overrides.length > 0) {
            const titleField = schema.overrides.find((o) => o.name === "title");
            const user = this.parentStore.rootStore.principalContext.current;
            if (titleField && titleField.defaultValue) {
                const now = moment();
                workItem.title = titleField.defaultValue
                    .replace("{PRODUCT-NAME}", product.name)
                    .replace("{DATE-TIME}", now.format("lll"))
                    .replace("{DATE-ONLY}", now.format("ll"))
                    .replace("{ACTOR-NAME}", user.name);
            }
            const dueDateField = schema.overrides.find((o) => o.name === "dueDate");
            if (dueDateField && dueDateField.defaultValue) {
                const dueDate = moment().add(moment.duration(dueDateField.defaultValue));
                workItem.dueDate = dueDate.toDate();
            }
        }

        this.formData = workItem;
        this.loading = false;
    });

    public onSave = flow(function* (trigger, options) {
        try {
            this.processing = true;

            if (this.formData) {
                const { documents, ...formData } = this.formData;
                const created = yield this.workItemService.createWorkItem({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setSelected(created);
                yield this.parentStore.documentStore.uploadDocuments(documents, created.id, false);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Demand request ${created.code} created successfully.`
                );

                this.parentStore.rootStore.routing.push(`/demand/browse/${created.id}`);

                return { success: true, workItem: created };
            }

            return { success: false };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.processing = false;
        }
    });

    @action
    onCancel() {
        if (this.formOptions.returnUrl && UrlUtility.isRelative(this.formOptions.returnUrl)) {
            this.parentStore.rootStore.routing.push(this.formOptions.returnUrl);
        } else {
            this.parentStore.rootStore.routing.push(`/demand/catalogue`);
        }
    }

    @action
    public cleanup(_) {
        this.loading = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData &&
            this.formData.title &&
            (!this.formData.asset || this.formData.asset.id) &&
            this.formData.stakeholder &&
            this.formData.stakeholder.id &&
            this.formData.product &&
            this.formData.product.id &&
            this.formData.priority &&
            this.formData.dueDate &&
            this.formData.source &&
            this.formData.requestedBy &&
            this.formData.requestedBy.id &&
            this.formData.documents &&
            this.formData.documents.length >= 0
        );
    }
}
