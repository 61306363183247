import { observable, action, computed } from "mobx";
import { PageStore } from './PageStore';
import { PageService } from '../../../api/pages';

export class PageNewFormStore {
    private pageService: PageService;
    public parentStore: PageStore;
    private portfolio : any;

    @observable public error: any = null;
    @observable public visible: boolean = false;
    @observable public pageId: string = 'default';
    @observable public showSuccessTicker = false;
    @observable public isCloseDialogOpen = false;
    @observable public originalFormData: any = {
        assurancePrimary: null,
        assuranceSecondary: null,
        portfolioChampion: null,
        portfolioLead: null,
        securityArchitect: null,
        dataPrivacyLead: null,
        name: '',
        isActive: true,
    };
    @observable public formData: any = {
        assurancePrimary: null,
        assuranceSecondary: null,
        portfolioChampion: null,
        portfolioLead: null,
        securityArchitect: null,
        dataPrivacyLead: null,
        name: '',
        isActive: true,
    };
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
    }

    @computed public get isValid() {
        return this.formData.name  ? true : false;
    }

    @computed public get isChanged() {
        return (JSON.stringify(this.formData) === JSON.stringify(this.originalFormData))  ? false : true;
    }

    @computed public get isVisible() {
        return this.visible
    }
    
    @action public hide() {
        this.formData = {
            assurancePrimary: null,
            assuranceSecondary: null,
            portfolioChampion: null,
            portfolioLead: null,
            securityArchitect: null,
            dataPrivacyLead: null,
            name: '',
            isActive: true,
        };
        this.originalFormData = {
            assurancePrimary: null,
            assuranceSecondary: null,
            portfolioChampion: null,
            portfolioLead: null,
            securityArchitect: null,
            dataPrivacyLead: null,
            name: '',
            isActive: true,
        };
        this.isCloseDialogOpen = false;
        this.visible = false;
    }

    @action public resetFormData() {
        this.formData = {
            id: this.originalFormData.id,
            name: this.originalFormData.name,
            assurancePrimary: this.originalFormData.assurancePrimary,
            assuranceSecondary: this.originalFormData.assuranceSecondary,
            portfolioChampion: this.originalFormData.portfolioChampion,
            portfolioLead: this.originalFormData.portfolioLead,
            securityArchitect: this.originalFormData.securityArchitect,
            dataPrivacyLead: this.originalFormData.dataPrivacyLead,
        };
    }

    @action public show() {
        this.visible = true;
    }

    @action public silenceError() {
        this.error = null;
    }

}
