import { observable, flow, action, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ModellingService } from "../../../api/modelling";
import { PatternStore } from "./PatternStore";

export class PatternBrowseStore {
    public parentStore: PatternStore;
    public modellingService: ModellingService;

    @observable public loading: boolean = false;
    @observable public patterns: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: PatternStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: "Patterns Library", key: "patterns-browse", isCurrentItem: true });

        return builder;
    }

    public loadPatterns = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);

        this.loading = true;
        this.patterns = [];
        this.query = o.query;
        this.error = null;

        try {
            const result = yield this.modellingService.searchPatterns({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            this.patterns = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    @action
    public replace(pattern) {
        const index = this.patterns.findIndex((p) => p.id === pattern.id);

        if (index !== -1) {
            this.patterns = [...this.patterns.splice(index, 1, pattern)];
        }
    }
}
