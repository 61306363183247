import { observable, action, computed, flow } from "mobx";

// import { WorkItemService } from '../../../api/workitems';
import { InsightsStore } from './InsightsStore';
import { WorkItemService } from "../../../api/workitems";

const OpenDemands = () => {
    return new Promise((resolve, reject) => {
        resolve([
            {
                x: "2020-01-02",
                value: 20
            },
            {
                x: "2020-01-01",
                value: 10
            },
            {
                x: "2020-01-03",
                value: 30
            },
            {
                x: "2020-01-04",
                value: 40
            },
            {
                x: "2020-01-05",
                value: 50
            },
            {
                x: "2020-01-06",
                value: 60
            }, 
        ])
    })
}

const ClosedDemands = () => {
    return new Promise((resolve, reject) => {
        resolve([
            {
                x: "2020-01-02",
                value: 25
            },
            {
                x: "2020-01-01",
                value: 15
            },
            {
                x: "2020-01-03",
                value: 35
            },
            {
                x: "2020-01-04",
                value: 45
            },
            {
                x: "2020-01-05",
                value: 55
            },
            {
                x: "2020-01-06",
                value: 65
            }, 
        ])
    })
}

const TotalDemands = () => {
    return new Promise((resolve, reject) => {
        resolve([
            {
                x: "2020-01-02",
                value: 55
            },
            {
                x: "2020-01-01",
                value: 56
            },
            {
                x: "2020-01-03",
                value: 50
            },
            {
                x: "2020-01-04",
                value: 10
            },
            {
                x: "2020-01-05",
                value: 78
            },
            {
                x: "2020-01-06",
                value: 65
            }, 
        ])
    })
}

const OpenValues = [
    {
        x: "2020-06-01",
        value: 15
    },
    {
        x: "2020-06-02",
        value: 6
    },
    {
        x: "2020-06-03",
        value: 10
    },
    {
        x: "2020-06-04",
        value: 12
    },
    {
        x: "2020-06-05",
        value: 8
    },
    {
        x: "2020-06-06",
        value: 5
    }, 
]

export class InsightsPortfolioPageStore {
    
    public parentStore: InsightsStore;
    public workItemService:  WorkItemService;

    constructor(parentStore: InsightsStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }
    
    searchWorkItems = flow(function*(status) {
        const result = [];
        let reducedResult = [];

        try {
            const searchOptions = {
                pageSize: 500, 
                startIndex: 0,
                // portfolio: (this.selectedPortfolio) ? this.selectedPortfolio: null,
                status: status,
                // assignedToUser: (this.selectedUser) ? this.selectedUser: null,
            }
            const result = yield this.workItemService.searchWorkItems(searchOptions);
            reducedResult = result.items.map(F => new Date(F.modified).toISOString().split('T')[0])
                .reduce((acc, value) => {
                    let selectedAcc = acc.filter(A => A.date === value);
                    if (selectedAcc.length === 0) {
                        acc.push({
                            date: value,
                            count: 1,
                        })  
                    } else { 
                        selectedAcc[0].count++;
                    };
                    return acc
                }, []);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            return reducedResult
        }
    })

    public timeSeriesChartData = {
        title: 'Title 123',
        type: 'timeSeries',
        series: [
            {
                dataSource: this.searchWorkItems.bind(this,['Open']),
                dataX: 'date',
                dataValue: 'count',
                type: 'bar',
                label: 'Open Demands',
            },
            {
                dataSource: OpenValues,
                dataX: 'x',
                dataValue: 'value',
                type: 'line',
                label: 'Closed Demands',
            },
            {
                dataSource: this.searchWorkItems.bind(this, ['Open','Assigned','InProgress','Blocked']),
                dataX: 'date',
                dataValue: 'count',
                type: 'spline',
                label: 'Total Demands',
            }
        ]
    };
    
    public gaugeData = {
        title: 'Gauge',
        type: 'gauge',    
        series: [
            {
                label: 'Open Demands 1',
                dataSource: OpenValues,
                max: 400,
                dataValue: 'value',
            },
            {
                label: 'Total Demands 1',
                dataSource: TotalDemands,
                max: 350,
                dataValue: 'value',
            },
            {
                label: 'Closed Demands 1',
                dataSource: ClosedDemands,
                max: 450,
                dataValue: 'value',
            },
        ]
    }
    
    public pieData = {
        title: 'Pie',
        type: 'pie',    
        series: [
            {
                label: 'Open Demands 1',
                dataValue: 'value',
                dataSource: OpenValues,
            },
            {
                label: 'Total Demands 1',
                dataValue: 'value',
                dataSource: TotalDemands,
            },
            {
                label: 'Closed Demands 1',
                dataValue: 'value',
                dataSource: ClosedDemands,
            },
        ]
    }
}
