import { observable, action, computed, flow } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemSelectionStore {
    public parentStore: WorkItemStore;
    private workItemService: WorkItemService;

    @observable public id: string;
    @observable public workItem: any;
    @observable public taskFilters: any[] = [];
    @observable public loading: boolean = false;
    @observable public workItems: any[] = [];
    @observable public error: any = null;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    @computed
    public get currentUser() {
        return this.parentStore.rootStore.principalContext.current;
    }

    @action
    public setSelected(workItem) {
        this.workItem = workItem;
        this.parentStore.stateFlowStore.setSubject(workItem);
    }

    @action
    public setSelectedWithId(id: string) {
        this.workItem = this.workItems.find((p) => p.id == id);
    }

    public loadWorkItem = flow(function* (id: string) {
        this.error = null;
        this.loading = true;
        if (this.workItem && this.workItem.id !== id) {
            this.workItem = null;
        }
        try {
            const result = yield this.workItemService.getWorkItem(id);
            this.setSelected(result);
            return result;
        } catch (e) {
            this.error = e;
            return e;
        } finally {
            this.loading = false;
        }
    });

    public loadWorkItemDefaults = flow(function* (productId: string) {
        this.loading = true;
        try {
            const result = yield this.workItemService.getWorkItemDefaults(productId);
            result.portfolio = result.portfolio || { id: null };
            this.workItem = result;
            this.loading = false;
            return result;
        } catch (e) {
            console.error(e);
            this.loading = false;
            return e;
        }
    });

    /*
    @action
    public displayChangeStateToast(code, state) {
        let message = "";
        let verb = "";
        switch (state) {
            case "start":
                verb = "started";
                break;
            case "resolve":
                verb = "resolved";
                break;
            case "block":
                verb = "blocked";
                break;
            case "reopen":
                verb = "opened";
                break;
            case "unblock":
                verb = "unblocked";
                break;
            case "cancel":
                verb = "cancelled";
                break;
            case "close":
                verb = "closed";
                break;
        }
        message = `Request ${code} has been ${verb} and stakeholders have been notified.`;
        this.parentStore.rootStore.layoutStore.displayToastNotification(message);
    }

    updateWorkItemStatus = flow(function* (status: string, comment: any) {
        this.saving = true;
        try {
            let commentObject = null;
            if (comment) {
                commentObject = {
                    content: comment,
                    sourceId: this.selectedWorkItem.id,
                    internalOnly: false,
                };
            }
            const result = yield this.workItemService.updateWorkItemStatus(this.selectedWorkItem.id, status, commentObject);
            this.displayChangeStateToast(result.code, status);
            this.selectedWorkItem = result;
            this.workItem = result;
            this.saving = false;
            yield this.parentStore.commentStore.loadComments(this.selectedWorkItem.id);
        } catch (e) {
            this.saving = false;
        }
    });

    updateWorkItem = flow(function* (workItem) {
        this.saving = true;
        try {
            const result = yield this.workItemService.updateWorkItem(workItem);
            this.parentStore.rootStore.layoutStore.displayToastNotification(`Request ${result.code} has been updated successfully.`);
            this.selectedWorkItem = result;
            this.workItem = result;
            this.saving = false;
        } catch (e) {
            this.saving = false;
        }
    });

    deleteWorkItem = flow(function* () {
        this.saving = true;
        try {
            const result = yield this.workItemService.deleteWorkItems(this.selectedWorkItem.id);
            this.selectedWorkItem = null;
            this.workItem = result;
            this.saving = false;
            return result;
        } catch (e) {
            console.error(e);
            this.saving = false;
            return e;
        }
    }); */
}
