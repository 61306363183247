import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

export const RoutingLink = observer(({ url, primary, children, onClickEnabled, onClick, onPreClick, ...rest }) => {
    const { routing } = useStores();

    return (
        <a
            {...rest}
            role="button"
            className={primary && "primary"}
            onClick={(e) => {
                if (onPreClick) {
                    onPreClick();
                }

                if (onClickEnabled && onClick) {
                    onClick(e, url, routing);
                } else {
                    routing.push(url);
                }
            }}
        >
            {children}
        </a>
    );
});
