import { observable, action, computed, flow } from "mobx";

import { WorkItemService } from "@api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export enum ViewTabType {
    Summary = "summary",
    Documents = "documents",
    WorkItems = "work-items",
    Properties = "properties",
}

export class WorkItemViewerStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: ViewTabType = ViewTabType.Summary;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Demand Management",
            key: "demand",
            onClick: () => this.parentStore.rootStore.routing.push(`/demand/dashboard`),
        });
        builder.push({
            text: "Browse Requests",
            key: "demand-browse",
            onClick: () => this.parentStore.rootStore.routing.push(`/demand/browse`),
        });

        if (!this.workItem) {
            return builder;
        }

        builder.push({
            text: this.workItem.code,
            key: this.workItem.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get workItem() {
        return this.parentStore.selectionStore.workItem;
    }

    @computed
    public get stateFlow() {
        return this.parentStore.stateFlowStore.manifest;
    }

    @action
    public setWorkItem(workItem) {
        this.parentStore.selectionStore.setSelected(workItem);
    }

    @action
    public selectTab(tab: ViewTabType) {
        this.selectedTab = tab || ViewTabType.Summary;
    }

    public loadWorkItem = flow(function* (id: string) {
        this.loading = true;
        this.error = null;

        try {
            yield this.parentStore.selectionStore.loadWorkItem(id);
            yield this.parentStore.stateFlowStore.loadStateFlowManifest({
                stateFlowVersionId: this.workItem.flowVersionId,
            });
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
