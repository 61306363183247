import { observable, action, computed, flow } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { BoardsService } from "../../../api/boards";
import { BoardsStore } from "./BoardsStore";

const itemPerPage: number = 10;

export class BoardsDashboardStore {
    public workItemService: WorkItemService;
    public boardsService: BoardsService;
    public parentStore: BoardsStore;
    private loading: Boolean = false;
    private assignedToMeTemplateId: string = "7D4646F6-B9E7-4B40-B0B7-AEC3B7337DC9";
    private createdByMeTemplateId: string = "862BAC09-13FC-4085-B700-90A1B84E3AC8";
    private assignedToMyTeamTemplateId: string = "CEBDE6E1-0CC1-4D07-83DD-1D69C35D6A11";

    @observable public assignedToMe: any = {};
    @observable public createdByMe: any = {};
    @observable public assignedToMyTeam: any = {};
    @observable public error: any = null;

    @observable public breadcrumb: any[] = [
        { text: "Demand Management", key: "demand", onClick: () => this.parentStore.rootStore.routing.push(`/demand/dashboard`) },
        { text: "Demand Dashboard", key: "dashboard", as: "h4", isCurrentItem: true },
    ];

    constructor(parentStore: BoardsStore, workItemService: WorkItemService, boardsService: BoardsService) {
        this.parentStore = parentStore;
        this.workItemService = workItemService;
        this.boardsService = boardsService;
        this.loading = false;
    }

    @computed public get isLoading() {
        return this.loading;
    }

    loadDashboard = flow(function* () {
        this.error = null;
        try {
            const searchOptions = {
                pageSize: itemPerPage,
                startIndex: 0,
            };
            this.assignedToMyTeam = yield this.workItemService.getWorkItems(Object.assign({ templateId: this.assignedToMyTeamTemplateId }, searchOptions));
            this.assignedToMe = yield this.workItemService.getWorkItems(Object.assign({ templateId: this.assignedToMeTemplateId }, searchOptions));
            this.createdByMe = yield this.workItemService.getWorkItems(Object.assign({ templateId: this.createdByMeTemplateId }, searchOptions));
            this.assignedToMyTeam = {
                ...this.assignedToMyTeam,
                title: "Work items assigned to your team",
                selectedPageIndex: 0,
                itemsPerPage: itemPerPage,
                pageCount: Math.ceil(this.assignedToMyTeam.totalItems / itemPerPage),
                totalItemCount: this.assignedToMyTeam.totalItems,
            };
            this.assignedToMe = {
                ...this.assignedToMe,
                title: "Work items assigned to you",
                selectedPageIndex: 0,
                itemsPerPage: itemPerPage,
                pageCount: Math.ceil(this.assignedToMe.totalItems / itemPerPage),
                totalItemCount: this.assignedToMe.totalItems,
            };
            this.createdByMe = {
                ...this.createdByMe,
                title: "Work items created by you",
                selectedPageIndex: 0,
                itemsPerPage: itemPerPage,
                pageCount: Math.ceil(this.createdByMe.totalItems / itemPerPage),
                totalItemCount: this.createdByMe.totalItems,
            };
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    changePage = flow(function* (box: string, newIndex: number) {
        this.error = null;
        try {
            const searchOptions = {
                pageSize: itemPerPage,
                startIndex: newIndex * itemPerPage,
            };
            const results = yield this.workItemService.getWorkItems(Object.assign({ templateId: this[box].query.templateId }, searchOptions));

            const responseData = {
                selectedPageIndex: newIndex,
                items: results.items,
            };
            this[box] = responseData;
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });
}
