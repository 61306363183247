import React, { useCallback, useMemo, useState, useEffect } from "react";
import { toJS } from "mobx";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate, ReactEditor } from "slate-react";
import { Editor, Transforms, createEditor } from "slate";

import { Button, Icon, Toolbar, debounce } from "./helpers";
import { Element, Leaf } from "./withBase";
import { withLinks, LinkButton, UnlinkButton } from "./withLinks";

const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const getSafeValue = (value) => {
    if (!value) {
        const defaultValue = [
            {
                type: "paragraph",
                children: [{ text: "" }],
            },
        ];

        return defaultValue;
    }

    return toJS(value);
};

const RichTextEditor = ({ value, required, disabled, placeholder, className, onChange, onKeyPress }) => {
    const debouncedOnChange = debounce(onChange ? onChange : () => undefined, 500);

    const [state, setState] = useState(getSafeValue(value));

    useEffect(() => {
        if (!value) {
            ReactEditor.deselect(editor);
            setState(getSafeValue(null));
        }
    }, [value]);

    useEffect(() => {
        debouncedOnChange(state);
    }, [state]);

    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(() => withReact(withLinks(createEditor())), []);

    return (
        <Slate
            editor={editor}
            value={state}
            onChange={(nodes) => {
                setState(nodes);
                //debouncedOnChange(nodes);
            }}
        >
            <Toolbar>
                <MarkButton format="bold" icon="Bold" />
                <MarkButton format="italic" icon="Italic" />
                <MarkButton format="underline" icon="Underline" />
                <MarkButton format="code" icon="Code" />
                <BlockButton format="heading-one" icon="Header1" />
                <BlockButton format="heading-two" icon="Header2" />
                <BlockButton format="block-quote" icon="RightDoubleQuote" />
                <BlockButton format="numbered-list" icon="NumberedList" />
                <BlockButton format="bulleted-list" icon="BulletedList" />
                <LinkButton icon="AddLink" />
                <UnlinkButton icon="RemoveLink" />
            </Toolbar>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                className={className}
                placeholder={placeholder}
                readOnly={disabled}
                required={required}
                spellCheck
                autoFocus
                onKeyPress={onKeyPress}
                onKeyDown={(event) => {
                    for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event)) {
                            event.preventDefault();
                            const mark = HOTKEYS[hotkey];
                            toggleMark(editor, mark);
                        }
                    }
                }}
            />
        </Slate>
    );
};

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) => LIST_TYPES.includes(n.type),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: (n) => n.type === format,
    });

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};

const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};

export default RichTextEditor;
