import React from "react";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { Toggle as FabricToggle } from "office-ui-fabric-react/lib/Toggle";
import { Label } from "./Label";

const Toggle = ({ className, onText, offText, inlineLabel, ...props }) => {
    return (
        <>
            {props.label && <Label required={props.required}>{props.label}</Label>}
            <Switch
                {...props}
                onChange={(checked) => {
                    if (props.onChange) {
                        props.onChange({}, checked);
                    }
                }}
                className={classNames(
                    "zerodai-toggle",
                    className,
                    props.checked ? "bg-indigo-600 disabled:bg-indigo-200" : "bg-gray-300 disabled:bg-gray-200",
                    "group/toggle cursor-pointer disabled:pointer-events-none disabled:cursor-not-allowed",
                    "relative inline-flex items-center align-middle p-1 h-6 w-11 flex-shrink-0 rounded-full border-1 border-transparent transition-colors duration-200 ease-in-out"
                )}
            >
                <span className="sr-only">{props.label}</span>
                <span
                    aria-hidden="true"
                    className={classNames(
                        props.checked ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white group-disabled/toggle:bg-gray-100 shadow ring-0 transition duration-200 ease-in-out"
                    )}
                />
            </Switch>
            {props.checked && <span className="text-sm ml-3">{onText}</span>}
            {!props.checked && <span className="text-sm ml-3">{offText}</span>}
        </>
    );
};

export { Toggle, FabricToggle };
