import { observable, flow, computed, action } from "mobx";

import { AssetService } from "@api/assets";
import { AssetStore } from "./AssetStore";

import { EyeDropperIcon, FireIcon } from "@heroicons/react/24/solid";
import { PlanningIcon } from "@modules/layout/components/icons/PlanningIcon";
import { AssuranceIcon } from "@modules/layout/components/icons/AssuranceIcon";
import { RiskIcon } from "@modules/layout/components/icons/RiskIcon";
import { DemandIcon } from "@modules/layout/components/icons/DemandIcon";
import { InventoryIcon } from "@modules/layout/components/icons/InventoryIcon";

export class AssetViewerStore {
    private parentStore: AssetStore;
    private assetService: AssetService;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: string;
    @observable public children: any[] = [];
    @observable public stats: any[] = [];

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Inventory Management",
            key: "inventory",
            onClick: () => this.parentStore.rootStore.routing.push(`/inventory/dashboard`),
        });
        builder.push({
            text: "Browse Inventory",
            key: "inventory-browse",
            onClick: () => this.parentStore.rootStore.routing.push(`/inventory/browse`),
        });

        if (!this.asset) {
            return builder;
        }

        builder.push({
            text: this.asset.name,
            key: this.asset.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get asset() {
        return this.parentStore.selectionStore.asset;
    }

    @action
    public setAsset(asset) {
        this.parentStore.selectionStore.setAsset(asset);
    }

    @action
    public setSelectedTab(tab) {
        this.selectedTab = tab;
    }

    public reset = flow(function* (id: string) {
        this.loading = true;
        this.parentStore.selectionStore.setAsset(null);
        this.stats = [];
        this.children = [];
        this.error = null;
    });

    public loadAsset = flow(function* (id: string) {
        this.error = null;

        if (!this.asset || this.asset.id !== id) {
            this.loading = true;
            try {
                yield this.parentStore.selectionStore.loadAsset(id);

                const result = yield this.assetService.searchAssets({
                    pageSize: 100,
                    startIndex: 0,
                    query: { parent: [{ id: id }] },
                });
                this.children = result.items;

                const isStakeholder = this.parentStore.selectionStore.isStakeholder;
                const isAsset = this.parentStore.selectionStore.isAsset;
                if (!isStakeholder && !isAsset) {
                    return;
                }

                const assetQuery = isStakeholder ? { stakeholder: [{ id }] } : { asset: [{ id }] };

                this.stats = [
                    {
                        id: 1,
                        name: "Open demand requests",
                        value: null,
                        icon: DemandIcon,
                        className: "bg-green-500/20 text-green-600",
                        query: {
                            ...assetQuery, //asset: { id: [id] }, //
                            status: ["Open", "Assigned", "InProgress", "Blocked"],
                        },
                        url: `/inventory/browse/${id}/work-items`,
                    },
                    {
                        id: 2,
                        name: "Open assessments",
                        value: null,
                        icon: AssuranceIcon,
                        className: "bg-purple-500/20 text-purple-600",
                        query: {
                            ...assetQuery,
                            status: ["Draft", "Started", "Remediation", "Reviewing"],
                        },
                        url: `/inventory/browse/${id}/assessments`,
                    },
                    {
                        id: 3,
                        name: "Critical risks",
                        value: null,
                        icon: FireIcon,
                        className: "bg-red-500/20 text-red-600",
                        query: {
                            ...assetQuery,
                            grossScore: ["Critical"],
                        },
                        url: `/inventory/browse/${id}/risks`,
                    },
                    {
                        id: 4,
                        name: "Pending treatments",
                        value: null,
                        icon: EyeDropperIcon,
                        className: "bg-blue-500/20 text-blue-600",
                        query: {
                            ...assetQuery,
                            isPending: true,
                        },
                        url: `/inventory/browse/${id}/treatments`,
                    },
                ];

                const demandResult = yield this.parentStore.rootStore.workItemStore.workItemService.searchWorkItems({
                    pageSize: 0,
                    startIndex: 0,
                    query: {
                        ...this.stats[0].query,
                    },
                });
                this.stats[0].value = demandResult.totalItems;

                const assessmentResult =
                    yield this.parentStore.rootStore.assessmentStore.assessmentService.searchAssessments({
                        pageSize: 0,
                        startIndex: 0,
                        query: {
                            ...this.stats[1].query,
                        },
                    });
                this.stats[1].value = assessmentResult.totalItems;

                const riskResult = yield this.parentStore.rootStore.riskStore.riskService.searchRisks({
                    pageSize: 0,
                    startIndex: 0,
                    query: {
                        ...this.stats[2].query,
                    },
                });
                this.stats[2].value = riskResult.totalItems;

                const treatmentResult = yield this.parentStore.rootStore.riskStore.riskService.searchRiskTreatments({
                    pageSize: 0,
                    startIndex: 0,
                    query: {
                        ...this.stats[3].query,
                    },
                });
                this.stats[3].value = treatmentResult.totalItems;
            } catch (error) {
                console.error(error);
                this.error = error;
            } finally {
                this.loading = false;
            }
        }
    });
}
