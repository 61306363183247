import { observable, flow, computed } from "mobx";

import { PageService } from '../../../api/pages';
import { PageInstanceStore } from "./PageInstanceStore";
import { PageSelectionStore } from "./PageSelectionStore";

export class PageViewerStore {

    public pageService: PageService;
    public parentStore: PageInstanceStore;
    public selectionStore: PageSelectionStore;

    @observable public loading: boolean = false;
    @observable public path: any[];
    @observable public error: any;

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
        this.selectionStore = parentStore.selectionStore;
    }

    @computed
    public get page() {
        return this.selectionStore.page;
    }

    @computed
    public get breadcrumb() {
        const page = this.page;
        const builder: any[] = [];

        if(!page) {
            return builder;
        }

        if(!this.path) {
            builder.push({ 
                text: page.library.name, 
                key: page.library.id, 
                onClick: () => this.parentStore.parentStore.rootStore.routing.push(page.library.path)
            });
            
            return builder;
        }

        this.path.forEach((node, index) => {
            builder.push({ 
                text: node.title, 
                key: node.id, 
                onClick: node.id === page.id ? null : () => this.parentStore.parentStore.rootStore.routing.push(node.path),
                isCurrentItem: node.id === page.id
            });
        });

        return builder;
    }

    public loadPageContentByPath = flow(function*(path) {
        this.loading = true;
        this.error = null;
        this.parentStore.lifecycleStore.setMode('view');

        if(this.page && this.page.path !== path) {
            this.path = null;
        }

        try {
            yield this.selectionStore.loadPageContentByPath(path);

            this.path = yield this.pageService.getPageBreadcrumb({
                libraryId: this.page.library.id,
                pageId: this.page.id,
                maxLevel: 10
            });
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });
}
