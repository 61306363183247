import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { cx, css} from 'emotion';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { FontIcon } from '@ui/elements/Icon';
import { PrimaryButton, DefaultButton } from '@ui/elements/Button';
import { Dropdown } from '@ui/elements/Dropdown';
import { TextField } from '@ui/elements/TextField';
import { Modal } from '@ui/elements/Modal';

import { actions as quickActions } from './QuickActionsConst';

import { IconPickerCallout } from '../../../../base/components/IconPickerCallout';

const iconClass = cx(css`
    font-size: 40px;
    height: 40px;
    width: 40px;
`);

export const QuickActionsPartEditorModalForm = observer((props) => {
    const { isOpen, onDismiss, modalMode, onSave, selectedAction } = props;
    const [actionTitle, setActionTitle] = useState(selectedAction ? selectedAction.title : null);
    const [actionUrl, setActionUrl] = useState(selectedAction ? selectedAction.action : null);
    const [actionIcon, setActionIcon] = useState(selectedAction ? selectedAction.icon : null);
    const [actionType, setActionType] = useState(selectedAction ? selectedAction.type : null);
    const [actionDescription, setActionDescription] = useState(selectedAction ? selectedAction.description : null);
    const [isIconCalloutOpen, setIsIconCalloutOpen] = useState(false);

    useEffect(() => {
        const { selectedAction } = props;
        if (selectedAction) {
            const { title, action, icon, type, description } = selectedAction;
            setActionTitle(title);
            setActionUrl(action);
            setActionIcon(icon);
            setActionType(type);
            setActionDescription(description);    
        }
      }, [props]);

    const closeQuickActionModal = () => {
        resetAction();
        if (onDismiss) onDismiss();
    }

    const resetAction = () => {
        setActionUrl(null);
        setActionTitle(null);
        setActionIcon(null);
        setActionType(null);
        setActionDescription(null);
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={false}
            dragOptions={false}
            containerClassName={cx(css`
                padding: 20px;
                min-width: 300px;
                min-height: 410px;
            `)}
        >
            <Stack verticalAlign="space-between" style={{height: '400px'}} >
                <Stack verticalAlign="start">
                    <h3>{modalMode === "edit" ?  `Edit existing action` : `Create a new action`}</h3>
                    <r-cell span="4">
                        <Dropdown
                            label={`Action type`}
                            selectedKey={actionType}
                            options={quickActions}
                            placeholder={`Please select an action type`}
                            onChange={(ev, op) => {
                                if (op) {
                                    setActionType(op.key);
                                    if (op.key === "externalUrl") {
                                        setActionUrl(null);
                                        setActionTitle(null);
                                        setActionIcon(null);
                                    } else {
                                        const actionType = quickActions.find(A => A.key === op.key);
                                        setActionIcon(actionType.icon);
                                        setActionTitle(actionType.title);    
                                    }
                                }
                            }}
                        />
                    </r-cell>
                    {actionType && <>
                        <r-cell
                            span="2"
                            style={{cursor:'pointer'}}
                            onClick={()=> setIsIconCalloutOpen(!isIconCalloutOpen)}
                        >
                            <Stack>
                                <span className="calloutTarget">Icon</span>
                                {actionIcon ? <FontIcon 
                                    iconName={actionIcon}
                                    className={iconClass}
                                /> : <span>None</span>}
                                <IconPickerCallout 
                                    target={`calloutTarget`}
                                    isCalloutOpen={isIconCalloutOpen}
                                    onIconPick={(iconName) => {
                                        setActionIcon(iconName)
                                        setIsIconCalloutOpen(!isIconCalloutOpen)
                                    }}
                                />
                            </Stack>
                        </r-cell>
                        <r-cell span="2">
                            <TextField label="Title" value={actionTitle} onChange={(ev, value) => setActionTitle(value)} />
                        </r-cell>
                        <r-cell span="2">
                            <TextField label="Description" multiline value={actionDescription} onChange={(ev, value) => setActionDescription(value)} />
                        </r-cell>
                        <r-cell span="2">
                            {actionType === 'externalUrl' && <TextField label="Url" value={actionUrl} onChange={(ev, value) => setActionUrl(value)} />}
                        </r-cell>
                        <r-cell span="2">
                            {actionType === 'internalUrl' && <TextField label="Url" value={actionUrl} onChange={(ev, value) => setActionUrl(value)} />}
                        </r-cell>
                    </>}
                </Stack>

                <Stack horizontal horizontalAlign="space-between" >
                    <DefaultButton
                        text="Cancel"
                        onClick={closeQuickActionModal}
                    />
                    <PrimaryButton
                        text={modalMode === "edit" ? "Update action" : "Add action"}
                        onClick={() => {
                            // debugger
                            if (onSave) onSave({
                                icon: actionIcon,
                                action: actionUrl,
                                type: actionType,
                                title: actionTitle,
                                description: actionDescription,
                            })
                            // if (!formData.properties.actions) formData.properties.actions = []
                            // if (modalMode === "edit") {
                            //     let action = formData.properties.actions.find(action => action.id === actionId);
                            //     if (action) {
                            //         action.icon = actionIcon;
                            //         action.action = actionUrl;
                            //         action.type = actionType;
                            //         action.title = actionTitle;
                            //         action.description = actionDescription;
                            //     }
                            // } else {
                            //     formData.properties.actions.push({
                            //         id:  uuidv4(),
                            //         icon: actionIcon,
                            //         action: actionUrl,
                            //         type: actionType,
                            //         title: actionTitle,
                            //         description: actionDescription,
                            //     });
                            // }
                            // closeQuickActionModal();
                        }}
                    />
                </Stack>
            </Stack>
        </Modal>
    );
})
