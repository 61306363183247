import React from 'react';
import { 
    Stack as FabricStack,
} from 'office-ui-fabric-react/lib/Stack';

const Stack = (props) => {
    return (
        <FabricStack 
            {...props}
        />
    );
}

Stack.Item = (props) => {
    return (
        <FabricStack.Item 
            {...props}
        />
    )
}

export { Stack }