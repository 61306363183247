import { observable, action, computed } from "mobx";

import { RiskService } from "@api/risks";
import { RiskStore } from "@modules/risks/stores/RiskStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class TreatmentProgressFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public show(options) {
        this.visible = true;

        this.formOptions = {
            pageType: options.pageTitle || `Treatment Progress`,
            pageDescription:
                options.pageDescription || (options.treatment.code && options.treatment.title)
                    ? `${options.treatment.code} - ${options.treatment.title}`
                    : null
        };

        this.formData = {
            id: options.treatment.id,
            progress: options.treatment.progress,
            effectiveness: options.treatment.effectiveness || "Unknown",
            notes: null
        };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.id &&
            this.formData.progress >= 0 &&
            this.formData.progress <= 1 &&
            this.formData.effectiveness &&
            this.formData.notes
        );
    }
}
