import React, { useRef } from "react";
import { TooltipHost as FabricTooltipHost } from "office-ui-fabric-react/lib/Tooltip";

const TooltipHost = ({ children, content }) => {
    const tooltipRef = useRef(null);
    const containerRef = useRef(null);

    return (
        <div
            ref={containerRef}
            className="relative group inline-block"
            onMouseEnter={() => {
                if (!tooltipRef.current || !containerRef.current) return;

                const tooltipRect = tooltipRef.current.getBoundingClientRect();
                const containerRect = containerRef.current.getBoundingClientRect();
                const left = containerRect.width / 2 - tooltipRect.width / 2 + "px";
                tooltipRef.current.style.left = left;
            }}
        >
            {children}

            {content && (
                <span
                    ref={tooltipRef}
                    className="absolute transition-all ease-in duration-300 invisible z-1 py-2 px-3 mb-1 bottom-full text-xs whitespace-nowrap text-gray-800 bg-white rounded-sm shadow-lg group-hover:visible opacity-0 group-hover:opacity-100"
                >
                    {content}
                </span>
            )}
        </div>
    );
};

export { TooltipHost };
