import { observable, action, computed, flow } from "mobx";
import { BaseChartStoreClass } from './BaseChartStoreClass';

export class GaugeStore extends BaseChartStoreClass {

/**
 *  Expected format
 * [
    {
        label: 'Completed',
        value: 50
    },
    {
        label: 'Open',
        value: 20
    },
    {
        label: 'Closed',
        value: 60
    },

]
*/

    private onClicks = [];
    public onClickCallbackExists = false;

    @computed public get data() {
        this.onClicks = [];
        return this.rawData.map((D,i) => {
            this.onClicks.push(this.isFunction(D.onClick) ? D.onClick.bind(this, null) : null)
            this.onClickCallbackExists = this.isFunction(D.onClick) ? true : false;
            return {
                label: D.label || '',
                value: D.dataSource ? parseFloat((D.dataSource.map(v => v[D.dataValue] || 0).reduce((acc, value) => acc + value, 0) / parseInt(D.max) * 100).toFixed(2)) : 0,
            }
        }); 
    }

    public getChartData(element) {
        let title = {};
        // if (this.title && this.showTitle) {
        //     title = {
        //         position: 'top-left',
        //         padding: this.titlePadding,
        //         text: this.title,
        //     }
        // }
        return {
            size: this.size,
            padding: this.padding,
            bindto: element,
            data: {
                columns: this.data.map(D => [D.label, D.value]),
                type: 'gauge',
                onclick: (d, i) => {
                    if (this.isFunction(this.onClicks[d.index])) this.onClicks[d.index](d)
                },
            }
            ,
            title,
        }
    }

    public csvData() {
        const labels = this.data.map(D => D.label);
        const values = this.data.map(D => D.value);
        const numberOfValues = this.data.length;
        const csvValues = [];
        for (let i=0; i<numberOfValues; i++) {
            const csvRow = {};
            csvRow['Dimension'] = labels[i];
            csvRow['Value'] = values[i];
            csvValues.push(csvRow);
        }
        return csvValues;
    }

}
