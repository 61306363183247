import { observable, computed, flow, action } from "mobx";

import { DataSourceStore } from "./DataSourceStore";

export class DataSourceLifecycleStore {
    public parentStore: DataSourceStore;

    @observable public saving: boolean = false;

    constructor(parentStore: DataSourceStore) {
        this.parentStore = parentStore;
    }
}
