import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { select as d3Select } from 'd3';
import { generate as c3Generate } from 'c3';
import { ReactRadarChart } from 'd3-radarchart';
import { RadarStore } from '../stores/RadarStore';
import { generateInlineTitle } from './common/generateInlineTitle';
import { mutationObserver } from './common/mutationObserver';

import 'c3/c3.css';

export const RadarChart = observer((props) => {
 
    const radarStore = new RadarStore();
    // const [ svgRef, setSvgRef ] = useState(null);
    // const [ title, setTitle ] = useState();
    // const [ data, setData ] = useState();
    // const [ hideZeros, setHideZeros ] = useState();
    // const [ theme, setTheme ] = useState();
    // const [ orientation, setOrientation ] = useState();
    // const [ rendered, setRendered ] = useState(false);
    // const [ mode, setMode ] = useState();
    // const [ chartData, setChartData ] = useState();
    const chartWrapperRef = useRef();

    // radarStore.showTitle = props.showTitle;
    radarStore.rawData = props.dataSeries;
    // radarStore.title = props.title;
    // radarStore.showCSV = props.showCSV ? true : false;
    // radarStore.showPNG = props.showPNG ? true : false;
    // radarStore.hideZeros = props.hideZeros ? true : false;
    // radarStore.orientation = props.orientation;
    // radarStore.setDimensions(props.width, props.height);
    
    // if (mode !== props.mode && props.mode === 'edit') {
    //     radarStore.setDimensions(200, props.height);
    //     setMode('edit');
    // }

    // if (props.dataSeries.length > 0) {
    //     if (JSON.stringify(data) !== JSON.stringify(props.dataSeries)) {
    //         setData(props.dataSeries);
    //     }
    // }

    // if (orientation !== props.orientation) {
    //     setOrientation(props.orientation)
    // }

    // if (title !== props.title) {
    //     setTitle(props.title)
    // }

    // if (hideZeros !== props.hideZeros) {
    //     setHideZeros(props.hideZeros)
    // }

    // if (theme !== props.theme) {
    //     setTheme(props.theme);
    // }

    // useEffect(() => {

    //     setChartData()
        // const element = d3Select(chartWrapperRef.current).node();
        // if (!element || !(data && data.length) ) return
        // c3Generate(radarStore.getChartData(element, props.type));
        // let newSVG = generateInlineTitle(chartWrapperRef, theme);
        // const xAxis = newSVG.getElementsByClassName("c3-axis-x");
        // if (xAxis) {
        //     for (let item of xAxis) {
        //         const pathElements = item.getElementsByClassName("domain");
        //         for (let pathElement of pathElements) {
        //             pathElement.style.stroke = theme.palette.neutralSecondary;
        //             pathElement.style.color = "var(--text-color)";
        //             pathElement.setAttribute("stroke-width", "1");
        //             pathElement.setAttribute("stroke", theme.palette.neutralSecondary);
        //         }
        //     }
        // }
        // const yAxis = newSVG.getElementsByClassName("c3-axis-y");
        // if (yAxis) {
        //     for (let item of yAxis) {
        //         const pathElements = item.getElementsByClassName("domain");
        //         for (let pathElement of pathElements) {
        //             pathElement.style.stroke = theme.palette.neutralSecondary;
        //             pathElement.style.color = "var(--text-color)";
        //             pathElement.setAttribute("stroke-width", "1");
        //             pathElement.setAttribute("stroke", theme.palette.neutralSecondary);
        //         }
        //     }
        // }
        // if (!radarStore.onClickCallbackExists) {
        //     newSVG = mutationObserver(newSVG);
        // }
        // setSvgRef(newSVG);
        // setRendered(true);
        // props.onSVGChange(newSVG, `${radarStore.filename}.png`)
    // },[data, title, hideZeros, theme, orientation, mode]);

    useEffect(() => {
        props.onDataChange(radarStore.csvData(), `${radarStore.filename}.csv`)
    },[props.dataSeries]);

    useEffect(() => {
        props.onRendered()
    },[]);

    // return <div>
    //         <div ref={chartWrapperRef}  />
    //     </div>

    return <ReactRadarChart
        rootElementProps={chartWrapperRef}
        {...radarStore.getChartData()}
    />

});
