import { generateAdvisoriesQuery } from '../queries';

async function getAdvisoriesFlatByPortfolio(query, api) {
    const computedQuery = generateAdvisoriesQuery(query);
    const sentQuery = {
        query: computedQuery
    };
    // debugger
    // console.log('sentQuery', JSON.stringify(sentQuery))
    const results = await api(sentQuery);
    return results;
}

export { getAdvisoriesFlatByPortfolio }