import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { PrimaryButton } from "@ui/elements/Button";
import { FontIcon } from "@ui/elements/Icon";

const rootClassName = cx(
    "cygraph-Placeholder-root",
    css`
        display: flex;

        & .container {
            align-items: center;
            color: var(--text-color-dark);
            background-color: var(--body-background);
            width: 100%;
            padding: 80px 0;
            box-shadow: var(--box-shadow);
        }

        & .header-container {
            height: 100%;
            white-space: nowrap;
            text-align: center;
            font-size: 28px;
            font-weight: 100;

            & .text {
                padding-left: 16px;
            }
        }

        & .icon {
            display: inline-block;
            vertical-align: middle;
            white-space: normal;
            font-size: 28px;
        }

        & .description {
            width: 65%;
            vertical-align: middle;
            margin: 0 auto;
            text-align: center;

            & span.text {
                margin: 20px 0 28px;
                line-height: 23px;
                font-size: 17px;
                font-weight: 100;
                box-sizing: content-box;
                max-height: 69px;
                overflow: hidden;
                display: inline-block;
            }
        }
    `
);

export const Placeholder = observer(
    class Placeholder extends Component {
        render() {
            const { className, iconName, description, children, iconText, buttonLabel, buttonIcon, onConfigure } =
                this.props;
            return (
                <div className={cx(rootClassName, className)}>
                    <div className="container">
                        <div className="header">
                            <div className="header-container">
                                {iconName && <FontIcon iconName={iconName} className="icon" />}
                                <span className="text">{iconText}</span>
                            </div>
                        </div>
                        <div className="description">
                            <span className="text">{description}</span>
                        </div>
                        {children}
                        <div className="description">
                            {buttonLabel && onConfigure && (
                                <PrimaryButton
                                    className="m-auto"
                                    text={buttonLabel}
                                    aria-label={buttonLabel}
                                    iconProps={buttonIcon ? { iconName: buttonIcon } : null}
                                    aria-description={description}
                                    onClick={onConfigure}
                                />
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
);
