if (
    !new (class {
        x;
    })().hasOwnProperty("x")
) {
    throw new Error("Transpiler is not configured correctly");
}
//import 'bootstrap/dist/css/bootstrap.css';
//import 'core-js';
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import { Fabric } from "office-ui-fabric-react";
//import { Router, Route, Switch } from "react-router";
import { Router, Switch, Route } from "react-router-dom";

import { AuthProvider } from "react-oidc-context";

//import { configure } from "mobx";

// import App from "./App";
import { RootStore } from "@base/RootStore";
import { ConfigStore } from "./modules/config/stores/ConfigStore";
import { ConfigModules } from "./modules/config/stores/ConfigModules";
import registerServiceWorker from "./registerServiceWorker";
import { authProvider, oidcConfig } from "./authProvider";
import { themeProvider } from "./themeProvider";
import { tenantInfo } from "./configProvider";

const SignInPage = lazy(() => import("@modules/auth/containers/pages/SignInPage"));
const SignOutPage = lazy(() => import("@modules/auth/containers/pages/SignOutPage"));
const SessionExpiredPage = lazy(() => import("@modules/auth/containers/pages/SessionExpiredPage"));
const SignedOutPage = lazy(() => import("@modules/auth/containers/pages/SignedOutPage"));
const TeamsModulePage = lazy(() => import("@modules/teams/containers/pages/TeamsModulePage"));
const ProtectedApp = lazy(() => import("@/ProtectedApp"));

import "./index.css";
import "./custom.css";

// configure({ enforceActions: 'observed' });

const browserHistory = createBrowserHistory();
const rootStore = new RootStore(authProvider);

const configProvider = new ConfigStore(rootStore);
configProvider.add(ConfigModules.Core, tenantInfo);

const stores = {
    ...rootStore,
    rootStore,
    themeProvider,
    configProvider,
    authProvider
};

const history = syncHistoryWithStore(browserHistory, rootStore.routing);
history.subscribe((location, action) => rootStore.layoutStore.menu.locationChanged(location, action));
//history.subscribe((location, action) => rootStore.settingsStore.locationChanged(location, action));

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
    <Provider {...stores}>
        <Router basename={baseUrl} history={history}>
            <Fabric className="h-full">
                <Suspense fallback="">
                    <Switch>
                        <Route exact path="/signed-out" component={SignedOutPage} />
                        <Route exact path="/session-expired" component={SessionExpiredPage} />
                        <Route path="/teams" component={TeamsModulePage} />
                        <Route path="/">
                            <AuthProvider {...oidcConfig}>
                                <Switch>
                                    <Route exact path="/sign-in" component={SignInPage} />
                                    <Route exact path="/sign-out" component={SignOutPage} />
                                    <Route path="/" component={ProtectedApp} />
                                </Switch>
                            </AuthProvider>
                        </Route>
                    </Switch>
                </Suspense>
            </Fabric>
        </Router>
    </Provider>,
    rootElement
);

registerServiceWorker();
