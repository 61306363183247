import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { cx, css} from 'emotion';

import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { SelectionMode } from "office-ui-fabric-react/lib/DetailsList";
import { v4 as uuidv4 } from 'uuid';

import { FontIcon } from '@ui/elements/Icon';
import { PrimaryButton, IconButton } from '@ui/elements/Button';
import { TextField } from '@ui/elements/TextField';

import { actions as quickActions } from './QuickActionsConst';

import { ObservableSeparator } from '@baseComponents/ObservableSeparator';

import { QuickActionsPartEditorModalForm } from './QuickActionsPartEditorModalForm';

const iconClassSmall = cx(css`
    font-size: 20px;
    height: 20px;
    width: 20px;
`);

const onRenderItemColumn = (item, index, column) => {
    if (column.key === 'icon') {
      return <FontIcon 
        iconName={item.icon}
        className={iconClassSmall}
    />;
    }
    return item[column.key];
  };

export const QuickActionsPartEditor = observer((props) => {
    const { formData } = props;
    const [selectedAction, setSelectedAction] = useState(null);
    const [showContextualMenu, setShowContextualMenu] = useState(null);
    const [isQuickActionModalOpen, setIsQuickActionModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('edit');

    return (
        <div>
            <r-grid columns="4">
                <r-cell span="4">
                    <ObservableSeparator>Quick Actions Setup</ObservableSeparator>
                </r-cell>
                <r-cell span="4">
                    <TextField 
                        type="number"
                        label="Number of columns"
                        value={formData.properties.columns || 2}
                        onChange={(ev, value) => formData.properties.columns = value}
                    />
                </r-cell>
                <r-cell span="4">
                    <PrimaryButton
                        text="Add action"
                        onClick={() => {
                            setIsQuickActionModalOpen(true);
                            setModalMode('new');
                        }}
                    />
                </r-cell>
                <r-cell span="4">
                    <ObservableSeparator>Quick Actions</ObservableSeparator>
                </r-cell>
                <r-cell span="4">
                {formData.properties.actions && <ShimmeredDetailsList 
                    items={formData.properties.actions}
                    compact={true}
                    onRenderItemColumn={onRenderItemColumn}
                    columns={[
                        {
                            key: "icon",
                            isPadded: true,
                            name: "Icon",
                            minWidth: 20,
                            maxWidth: 20,
                            isIconOnly: true,
                            iconName: 'Page',
                        },
                        {
                            key: "title",
                            isPadded: true,
                            name: "Title",
                            onRender: (item) => {
                                return <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className={cx(css` height: 36px;`)}>
                                    {item.title}
                                    <div className="contextualMenuContainer">
                                    {showContextualMenu === item.id && <>
                                        <IconButton
                                            iconProps={{ iconName: 'Edit' }}
                                            onClick={(ev) => {                                                
                                                setIsQuickActionModalOpen(true);
                                                setModalMode('edit');
                                                setSelectedAction(item);
                                                }
                                            }
                                            title={'Edit Quick Action'}
                                            ariaLabel={'Edit Quick Action'}
                                        />
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            onClick={(ev) => { 
                                                formData.properties.actions.splice(formData.properties.actions.indexOf(item), 1);
                                                setShowContextualMenu(null)
                                                }
                                            }
                                            title={'Remove Quick Action'}
                                            ariaLabel={'Remove Quick Action'}
                                        />
                                    </>}
                                    </div>
                                </Stack>
                            },
                        },
                        {
                            key: "action",
                            isPadded: true,
                            name: "Action",
                            minWidth: 170,
                            onRender: (item) => {
                                const actionType = quickActions.find(A => A.key === item.type)
                                if (actionType) {
                                    if (actionType.key === 'externalUrl' || actionType.key === 'internalUrl') {
                                        return <>{actionType.text} <br /> ({item.action})</>
                                    }
                                    return `${actionType.text}`
                                } 
                                return item.action
                            },
                        },
                    ]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={(props, defaultRender) => <div 
                            onMouseEnter={() => setShowContextualMenu(props.item.id)}
                            onMouseLeave={() => setShowContextualMenu(null)}
                        >
                            {defaultRender(props)}
                        </div> 
                    }
                />}
                </r-cell>
        </r-grid>
        <QuickActionsPartEditorModalForm
            isOpen={isQuickActionModalOpen}
            modalMode={modalMode}
            onDismiss={() => setIsQuickActionModalOpen(false)}
            selectedAction={selectedAction}
            onSave={(action) => {
                setIsQuickActionModalOpen(false);
                if (!formData.properties.actions) formData.properties.actions = [];
                if (modalMode === "edit") {
                    let actionToUpdate = formData.properties.actions.find(action => action.id === selectedAction.id);
                    if (actionToUpdate) {
                        actionToUpdate.icon = action.icon;
                        actionToUpdate.action = action.action;
                        actionToUpdate.type = action.type;
                        actionToUpdate.title = action.title;
                        actionToUpdate.description = action.description;
                    }
                } else {
                    formData.properties.actions.push({
                        id:  uuidv4(),
                        icon: action.icon,
                        action: action.action,
                        type: action.type,
                        title: action.title,
                        description: action.description,
                    });
                }
                setSelectedAction(null);
            }}
        />
    </div>
    );
})
