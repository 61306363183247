import { observable, flow, action } from "mobx";

import { IdentityService } from "@api/identity";
import { PrincipalStore } from "@modules/identity/stores/PrincipalStore";
import { PaginationStore } from "@modules/base/PaginationStore";

export class IdentityUserBrowseStore {
    public parentStore: PrincipalStore;
    public identityService: IdentityService;

    @observable public users: any[] = [];
    @observable public keywords: string = null;
    @observable public loading: boolean = false;
    @observable public pagination: PaginationStore;
    @observable public error: any = null;

    constructor(parentStore: PrincipalStore) {
        this.parentStore = parentStore;
        this.identityService = parentStore.identityService;
        this.pagination = new PaginationStore();
    }

    public loadUsers = flow(function* (options) {
        const o = Object.assign({ page: 1, pageSize: 25, keywords: null }, options);
        this.keywords = o.keywords;
        this.loading = true;
        this.error = null;

        try {
            const result = yield this.identityService.searchUsers({
                pageSize: o.pageSize,
                startIndex: (o.page - 1) * o.pageSize,
                keywords: o.keywords,
            });
            this.users = result.items;
            this.keywords = result.keywords;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            return result;
        } catch (error) {
            this.error = error;
            return error;
        } finally {
            this.loading = false;
        }
    });

    public reload = flow(function* () {
        return yield this.loadUsers({
            page: this.pagination.selectedPageIndex + 1,
            pageSize: this.pagination.itemsPerPage,
            keywords: this.keywords,
        });
    });

    @action
    public replace(user) {
        const index = this.users.findIndex((p) => p.id === user.id);

        if (index !== -1) {
            this.users.splice(index, 1, user);
            //this.users = [...this.users];
        }
    }
}
