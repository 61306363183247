import { observable, flow, computed, action } from "mobx";

import { ResponseHandle } from "../../base/types/ResponseHandle";

import { PaginationStore } from "../../base/PaginationStore";
import { ProductService } from "../../../api/graph";
import { ProductStore } from "./ProductStore";
import { ProductStatus } from "./ProductContants";

export class ProductPickerStore {
    public parentStore: ProductStore;
    public productService: ProductService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public loading: boolean = false;
    @observable public processing: boolean = false;
    @observable public query: any;
    @observable public products: any[] = [];
    @observable public categories: any[] = [];
    @observable public error: any;

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
    }

    public show = flow(function* (options) {
        this.query = { ...{ category: "All", keywords: null }, ...options.query };
        this.visible = true;
        this.loading = true;

        const result = yield this.productService.getProducts({ pageSize: 250, startIndex: 0 });
        this.categories = yield this.productService.getCategories();

        this.products = result.items;
        this.loading = false;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.loading = false;
        this.processing = false;
        this.query = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success, product) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            product,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
        });
    }

    @computed
    public get filteredProducts() {
        let operationalProducts = this.products.filter((p) => p.state === ProductStatus.Operational);

        if (!this.query) {
            return operationalProducts;
        }

        if (this.query.category !== "All") {
            operationalProducts = operationalProducts.filter((p) => p.category === this.query.category);
        }

        if (this.query.keywords) {
            const k = this.query.keywords.toLowerCase();
            operationalProducts = operationalProducts.filter(
                (p) =>
                    p.name.toLowerCase().indexOf(k) !== -1 ||
                    p.provider.toLowerCase().indexOf(k) !== -1 ||
                    p.category.toLowerCase().indexOf(k) !== -1 ||
                    (p.friendlyName && p.friendlyName.toLowerCase().indexOf(k) !== -1) ||
                    (p.summary && p.summary.toLowerCase().indexOf(k) !== -1)
            );
        }

        if (this.query.isRequestable === true || this.query.isRequestable === false) {
            operationalProducts = operationalProducts.filter((p) => p.isRequestable === this.query.isRequestable);
        }

        if (this.query.isInternal === true || this.query.isInternal === false) {
            operationalProducts = operationalProducts.filter((p) => p.isInternal === this.query.isInternal);
        }

        return operationalProducts;
    }
}
