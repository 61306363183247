import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';

import { Placeholder } from '../../../base/components/Placeholder';

export const PagePartPlaceholder = observer(
  class PagePartPlaceholder extends PureComponent {
    render () {
        const { isConfigured, instance, section, zone, part, registration, children } = this.props;
        const mode = instance.lifecycleStore.mode;

        return (
          <>
            {  mode === 'edit' && !isConfigured ? ( 
                <Placeholder 
                  iconName={registration.icon || 'Edit'}
                  iconText={registration.name}
                  description={registration.description}
                  buttonLabel="Configure"                  
                  onConfigure={() => instance.lifecycleStore.onEditPart(section, zone, part)} />
            ) : children }
          </>
        );
    }
  }
);