import { observable, flow } from "mobx";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class StrategicControlSelectionStore {
    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public control: any;
    @observable public controls: any[];
    @observable public permission: any;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    public reset = flow(function* (options) {
        this.control = null;
        this.permission = null;
    });

    public loadControl = flow(function* (id) {
        this.loading = true;

        if (this.control && this.control.id !== id) {
            this.control = null;
            this.permission = null;
        }
        try {
            this.control = yield this.controlService.getControl(id);
            this.permission = {
                canContribute: true,
                canDelete: true,
            };
        } finally {
            this.loading = false;
        }
    });
}
