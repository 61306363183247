import { observable, action, computed, flow } from "mobx";

import { IdentityService } from "@api/identity";
import { PrincipalStore } from "@modules/identity/stores/PrincipalStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class IdentityUserNewFormStore {
    public parentStore: PrincipalStore;
    public identityService: IdentityService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public loading: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: PrincipalStore) {
        this.parentStore = parentStore;
        this.identityService = parentStore.identityService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        this.formOptions = {
            ...options,
            pageType: options.pageType || "Add Users",
        };

        this.formData = { users: [] };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.resolve({
                success,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @action
    public reject(error) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.reject({
                error,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return this.formData && this.formData.users && this.formData.users.length > 0;
    }
}
