import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { select as d3Select } from 'd3';
import { generate as c3Generate } from 'c3';
import ContainerDimensions from 'react-container-dimensions';
import { PieStore } from '../stores/PieStore';
import { SavePNGButton } from './common/SavePNGButton';
import { ExportCSVButton } from './common/ExportCSVButton';
import { generateInlineTitle } from './common/generateInlineTitle';
import { mutationObserver } from './common/mutationObserver';

import 'c3/c3.css';

export const Pie = observer((props) => {
 
    const pieStore = new PieStore();
    const [ svgRef, setSvgRef ] = useState(null);
    const chartWrapperRef = useRef();
    const [ title, setTitle ] = useState();
    const [ data, setData ] = useState();
    const [ theme, setTheme ] = useState();
    const [ hideZeros, setHideZeros ] = useState();
    const [ rendered, setRendered ] = useState(false);
    const [ mode, setMode ] = useState();

    pieStore.showTitle = props.showTitle;
    pieStore.rawData = props.dataSeries;
    pieStore.title = props.title;
    pieStore.showPNG = props.showPNG ? true : false;
    pieStore.showCSV = props.showCSV ? true : false;
    pieStore.hideZeros = props.hideZeros ? true : false;
    pieStore.setDimensions(props.width, props.height);

    if (props.dataSeries.length > 0) {
        if (JSON.stringify(data) !== JSON.stringify(props.dataSeries)) {
            setData(props.dataSeries);
        }
    }

    if (mode !== props.mode && props.mode === 'edit') {
        pieStore.setDimensions(200, props.height);
        setMode('edit');
    }

    if (title !== props.title) {
        setTitle(props.title)
    }

    if (hideZeros !== props.hideZeros) {
        setHideZeros(props.hideZeros)
    }

    if (theme !== props.theme) {
        setTheme(props.theme);
    }

    useEffect(() => {
        const element = d3Select(chartWrapperRef.current).node();
        if (!element) return
        c3Generate(pieStore.getChartData(element));
        let newSVG = generateInlineTitle(chartWrapperRef, theme);
        //pie paths neutralLight
        const pathsElements = newSVG.getElementsByClassName("c3-chart-arc");
        if (pathsElements) {
            for (let element of pathsElements) {
                const pathsElements = element.getElementsByTagName("path");
                for (let path of pathsElements) {
                    path.style.stroke = props.theme.palette.neutralLight;
                }
            }
        }
        if (!pieStore.onClickCallbackExists) {
            newSVG = mutationObserver(newSVG);
        }
        setSvgRef(newSVG);
        setRendered(true);
        props.onSVGChange(newSVG, `${pieStore.filename}.png`);
    },[data, title, hideZeros, theme, mode]);

    useEffect(() => {
        props.onDataChange(pieStore.csvData(), `${pieStore.filename}.csv`)
    },[data]);

    useEffect(() => {
        props.onRendered()
    },[rendered, data]);
    
    return <>
            {data && data.length > 0 && <div ref={chartWrapperRef} /> }
        </>
    ;
});
