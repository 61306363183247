import { observable, action } from "mobx";
import { RootStore } from '../../base/RootStore';

export class RagStatusStore {

    public onClick = null;
    public onClickCallbackExists = false;
    rootStore: RootStore;
    @observable public rawData: any = {};
    @observable public title: string = null;
    @observable public orientation: string = 'vertical';
    @observable public showTitle: boolean = true;
    @observable public showPNG: boolean = true;
    @observable public hideZeros: boolean = false;
    @observable public size: any = {
        height: undefined,
        width: undefined,
    };
    @observable public padding: any = {
        top: 0,
        right: 0,
        bottom: 30,
        left: 40
    };
    @observable public titlePadding: any = {
        left: 20,
        top: 10,
        bottom: 20,
        right: 0
    };
    @observable public rendered: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    getColorBand(value, lowerLimit, upperLimit) {
        if (parseFloat(value) < parseFloat(lowerLimit)) {
            return 'lower'
        } else if(parseFloat(value) > parseFloat(upperLimit)) {
            return 'upper'
        } else {
            return 'middle'
        }
    }

    @action public getData(rawData) {
        if (rawData) {
            // this.onClick = this.data.onClick;
            // debugger
            return rawData.map(data => {
                if (data.dataDisplayType === "count") {
                    return {
                        labels: null,
                        values: null,
                        label: data.label || '',
                        value: data.dataCount ? data.dataCount : 0,
                        unit: data.unit,
                        unitDisplay: data.unitDisplay,
                        selectedIcon: data.selectedIcon,
                        isOrdered: false,
                        lowerLimit: data.lowerLimit,
                        upperLimit: data.upperLimit,
                        middleBandColor: data.middleBandColor,
                        upperBandColor: data.upperBandColor,
                        lowerBandColor: data.lowerBandColor,
                        colorBand: this.getColorBand(data.dataCount, data.lowerLimit, data.upperLimit),
                    }
                } else if (data.orderField === "comparison") {
                    const value = (data.dataSource[0]) ? data.dataSource[0][data.dataValue] : 0;
                    const prevValue = (data.dataSource[1]) ? data.dataSource[1][data.dataValue] : 0;
                    const change = prevValue > 0 ? (value / prevValue) * 100 : 0;
                    const changePrecent = change !== 0 ? (change > 0 ? change - 100 : 100 - change) : 0;
                    return {
                        labels: null,
                        values: null,
                        label: data.label || '',
                        value, 
                        prevValue, 
                        change: Math.round(changePrecent).toFixed(0),
                        increase: change >= 0 ? true : false,
                        unit: data.unit,
                        unitDisplay: data.unitDisplay,
                        selectedIcon: data.selectedIcon,
                        isOrdered: true,
                        lowerLimit: data.lowerLimit,
                        upperLimit: data.upperLimit,
                        middleBandColor: data.middleBandColor,
                        upperBandColor: data.upperBandColor,
                        lowerBandColor: data.lowerBandColor,
                        colorBand: this.getColorBand(data.dataCount, data.lowerLimit, data.upperLimit),
                    }
                } else {
                    return {
                        labels: data.dataSource.map(v => v[data.labelField]),
                        values: data.dataSource.map(v => v[data.dataValue]),
                        colorBands: data.dataSource.map(v => this.getColorBand(v[data.dataValue], data.lowerLimit, data.upperLimit)),
                        colorBand: null,
                        label: null,
                        value: null,
                        //label: data.label || '',
                        //value: data.dataSource ? parseFloat((data.dataSource.map(v => v[data.dataValue] || 0).reduce((acc, value) => acc + value, 0)).toFixed(2)) : 0,
                        unit: data.unit,
                        unitDisplay: data.unitDisplay,
                        selectedIcon: data.selectedIcon,
                        isOrdered: false,
                        lowerLimit: data.lowerLimit,
                        upperLimit: data.upperLimit,
                        middleBandColor: data.middleBandColor,
                        upperBandColor: data.upperBandColor,
                        lowerBandColor: data.lowerBandColor,
                        decimalPositions: data.valueFieldDecimalPositions,
                    }
                }
            })
        }
    }
}
