import { observable, flow, computed } from "mobx";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";
import { PaginationStore } from "@modules/base/PaginationStore";

export class AuditActivityBrowseStore {
    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public audits: any[] = [];
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public pagination: PaginationStore;
    @observable public error: any = null;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Control Registry",
            key: "controls-dashboard",
            onClick: () => this.parentStore.rootStore.routing.push(`/controls/dashboard`),
        });

        builder.push({
            text: "Audit Activities",
            key: "audit-activities",
            onClick: () => this.parentStore.rootStore.routing.push(`/controls/audits`),
        });

        return builder;
    }

    public loadAudits = flow(function* (options) {
        const o = Object.assign({ page: 1, query: {} }, options);
        this.query = o.query;
        this.loading = true;
        this.error = null;

        try {
            // TODO: pretending to have real activities
            const result = yield this.parentStore.rootStore.workItemStore.workItemService.searchWorkItems({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: { ...o.query, sort: [{ property: "dueDate", asc: true }], status: ["Open"] },
            });
            this.audits = result.items;
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
            return error;
        } finally {
            this.loading = false;
        }
    });
}
