import { observable, action, computed, flow } from "mobx";

export class BaseChartStoreClass {

    @observable public rawData: any = {};
    @observable public title: string = null;
    @observable public orientation: string = 'vertical';
    @observable public showTitle: boolean = true;
    @observable public showPNG: boolean = true;
    @observable public hideZeros: boolean = false;
    @observable public size: any = {
        height: undefined,
        width: undefined,
    };
    @observable public padding: any = {
        top: 0,
        right: 0,
        bottom: 30,
        left: 40
    };
    @observable public titlePadding: any = {
        left: 20,
        top: 10,
        bottom: 20,
        right: 0
    };

    constructor() {
    }

    public isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    public setDimensions(width, height) {
        if (width) this.size.width = parseInt(width);
        if (height) this.size.height = parseInt(height);
    }

    @computed public get filename() {
        const dateReference = new Date().toISOString().slice(0,10);
        return `${this.title? this.title:''}-${dateReference}`;
    }

}
