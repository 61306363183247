import { observable, flow, action, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { EntityService } from "../../../api/entities";
import { EntityStore } from "./EntityStore";

export class EntityBrowseStore {
    private parentStore: EntityStore;
    private entityService: EntityService;

    @observable public loading: boolean = false;
    @observable public entities: any[] = [];
    @observable public query: any = {};
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Entity Management",
            key: "entities",
            onClick: () => this.parentStore.rootStore.routing.push(`/entities/dashboard`),
        });
        builder.push({ text: "Browse Entities", key: "entities-browse", isCurrentItem: true });

        return builder;
    }

    public loadEntities = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: {} }, options);
        this.query = o.query;
        this.loading = true;
        this.error = null;

        try {
            const result = yield this.entityService.searchEntities({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            this.entities = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
            return result;
        } catch (e) {
            this.error = e;
            console.error(e);
            return e;
        } finally {
            this.loading = false;
        }
    });

    public toggleWatchlist = flow(function* (item: any, add: boolean) {
        try {
            item.inWatchlist = add;
            if (add) {
                yield this.entityService.addToEntityWatchlist({ entityId: item.id });
            } else {
                yield this.entityService.removeFromEntityWatchlist({ entityId: item.id });
            }
        } catch {
            item.inWatchlist = !add;
        }
        this.entities = [...this.entities];
    });

    @action
    public replace(asset) {
        const index = this.entities.findIndex((p) => p.id === asset.id);

        if (index !== -1) {
            this.entities = [...this.entities.splice(index, 1, asset)];
        }
    }

    @action
    public clear() {
        this.loading = false;
        this.entities = [];
        this.query = {};
        this.pagination = new PaginationStore();
        this.error = null;
    }
}
