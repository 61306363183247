import { observable, flow } from "mobx";

import { PaginationStore } from "@modules/base/PaginationStore";
import { AssessmentService } from "@api/assessments";
import { AssessmentStore } from "./AssessmentStore";
export class AssessmentBrowseStore {
    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;

    @observable public assessments: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;

    @observable public breadcrumb: any[] = [
        { text: "Assurance Management", key: "assurance", onClick: () => this._goToUrl(`/assurance/dashboard`) },
        { text: "Browse Assessments", key: "browse", isCurrentItem: true },
    ];

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
        this.pagination = new PaginationStore();
    }

    public loadAssessments = flow(function* (options) {
        const o = Object.assign({ page: 1, pageSize: 25, query: {} }, options);
        this.query = o.query;
        this.loading = true;

        try {
            const result = yield this.assessmentService.searchAssessments({
                pageSize: o.pageSize,
                startIndex: (options.page - 1) * o.pageSize,
                query: o.query,
            });
            this.assessments = result.items;
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
            return error;
        } finally {
            this.loading = false;
        }
    });

    public exportAssessments = flow(function* (options) {
        const o = Object.assign({ page: 1, query: this.query }, options);

        try {
            const result = yield this.assessmentService.exportAssessments({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });

            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Assessment export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
