import { observable, flow, computed } from "mobx";
import { metaModel } from "@/configProvider";

import { PaginationStore } from "@modules/base/PaginationStore";

import { RiskService } from "@api/risks";
import { RiskStore } from "./RiskStore";

export class RiskCatalogueStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public scenarios: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks",
            onClick: () => this._goToUrl(`/risks/dashboard`)
        },
        { text: "Risk Catalogue", key: "risk-catalogue", isCurrentItem: true }
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get error() {
        return this.parentStore.typeStore.error;
    }

    @computed
    public get loading() {
        return this.parentStore.typeStore.loading;
    }

    @computed
    public get types() {
        return this.parentStore.typeStore.genericTypes;
    }

    @computed
    public get defaultType() {
        return this.parentStore.typeStore.genericTypes[0];
    }

    public loadRiskCatalogue = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: {} }, options);

        yield this.parentStore.typeStore.loadTypes({ cached: false });

        const assetService = this.parentStore.rootStore.assetStore.assetService;
        const result = yield assetService.searchAssets({
            pageSize: this.pagination.itemsPerPage,
            startIndex: (o.page - 1) * this.pagination.itemsPerPage,
            query: {
                ...o.query,
                ...metaModel.lossScenario.query
            }
        });
        this.scenarios = result.items;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);

        yield this.parentStore.registryStore.loadRegistries({ cached: false });
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
