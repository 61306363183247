import { computed } from "mobx";

import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { RoleNames, isUserInAllRoles, isUserInAnyRoles } from "../../identity/stores/PrincipalContextUtilities";

export class AssessmentPermissionStore {
    private assessmentService: AssessmentService;
    public parentStore: AssessmentStore;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get canViewAdvisoryPages() {
        const user = this.principalContext.current;

        if (!user) {
            return false;
        }

        return (
            isUserInAllRoles(user, [RoleNames.Agent, RoleNames.Assessments]) ||
            isUserInAllRoles(user, [RoleNames.Admin])
        );
    }

    @computed
    public get canViewResourcePages() {
        const user = this.principalContext.current;

        if (!user) {
            return false;
        }

        return isUserInAllRoles(user, [RoleNames.Admin]);
    }

    @computed
    public get canViewCalendarPages() {
        const user = this.principalContext.current;

        if (!user) {
            return false;
        }

        return isUserInAnyRoles(user, [RoleNames.Admin, RoleNames.Agent]);
    }

    @computed
    public get isAssignedToUser() {
        const user = this.principalContext.current;
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment || !user) {
            return false;
        }

        if (assessment.assignedToUser && assessment.assignedToUser.id == user.id) {
            return true;
        }

        return false;
    }

    @computed
    public get isWorkItemContact() {
        const user = this.principalContext.current;
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment || !user) {
            return false;
        }

        if (assessment.businessContact && assessment.businessContact.id == user.id) {
            return true;
        }

        if (assessment.technicalContact && assessment.technicalContact.id == user.id) {
            return true;
        }

        return false;
    }

    @computed
    public get isAssessmentContact() {
        const user = this.principalContext.current;
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment || !user) {
            return false;
        }

        if (assessment.createdBy && assessment.createdBy.id == user.id) {
            return true;
        }

        if (assessment.portfolioContact && assessment.portfolioContact.id == user.id) {
            return true;
        }

        if (assessment.portfolioTechnical && assessment.portfolioTechnical.id == user.id) {
            return true;
        }

        return false;
    }

    @computed
    public get canCreate() {
        return this.isAssignedToUser || this.isWorkItemContact;
    }

    @computed
    public get canRead() {
        return (
            this.isAssignedToUser ||
            this.isWorkItemContact ||
            this.isAssessmentContact ||
            isUserInAllRoles(this.principalContext.current, [RoleNames.Agent, RoleNames.Assessments])
        );
    }

    @computed
    public get canUpdateValues() {
        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canUpdateAdvisories() {
        return this.isAssignedToUser;
    }

    @computed
    public get canAddEvidence() {
        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canAddComments() {
        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canDelete() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment || assessment.state != "Draft") {
            return false;
        }

        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canStart() {
        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canSubmit() {
        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canReview() {
        return this.isAssignedToUser;
    }

    @computed
    public get canClose() {
        return this.isAssignedToUser;
    }

    @computed
    public get canCancel() {
        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canUpload() {
        return this.isAssignedToUser || this.isWorkItemContact || this.isAssessmentContact;
    }

    @computed
    public get canAssign() {
        return (
            this.isAssignedToUser ||
            isUserInAllRoles(this.principalContext.current, [RoleNames.Admin]) ||
            isUserInAllRoles(this.principalContext.current, [RoleNames.Agent, RoleNames.Assessments])
        );
    }

    @computed
    public get canTest() {
        return this.isAssignedToUser;
    }

    @computed
    public get canManageTasks() {
        return this.isAssignedToUser || isUserInAllRoles(this.principalContext.current, [RoleNames.Admin]);
    }

    @computed
    public get canManageAutomations() {
        return this.isAssignedToUser || isUserInAllRoles(this.principalContext.current, [RoleNames.Admin]);
    }

    @computed
    public get canManageUseCases() {
        const user = this.principalContext.current;

        if (!user) {
            return false;
        }

        return isUserInAnyRoles(user, [RoleNames.Admin]);
    }

    @computed
    public get canManageControlModules() {
        const user = this.principalContext.current;

        if (!user) {
            return false;
        }

        return isUserInAnyRoles(user, [RoleNames.Admin]);
    }

    @computed
    public get canManageControlQuestions() {
        const user = this.principalContext.current;

        if (!user) {
            return false;
        }

        return isUserInAnyRoles(user, [RoleNames.Admin]);
    }

    @computed
    public get canManageAssessmentRules() {
        const user = this.principalContext.current;

        if (!user) {
            return false;
        }

        return isUserInAnyRoles(user, [RoleNames.Admin]);
    }
}
