import { ServiceBase, Constants } from "../graph/serviceBase";

export class TriageService extends ServiceBase {
    public async getTriage(id) {
        return this.get(`${Constants.baseUrl}/triage/${id}`);
    }

    public async registerTriage(data) {
        return this.post(`${Constants.baseUrl}/triage`, data);
    }

    public async getTriageSchema(version: string = null) {
        return this.get(`${Constants.baseUrl}/triage/schema?version=${encodeURIComponent(version || "")}`);
    }
}
