import { observable, action, computed } from "mobx";

import { RiskService } from "@api/risks";
import { RiskStore } from "@modules/risks/stores/RiskStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class RiskEditFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public show(options) {
        this.visible = true;

        this.formOptions = {
            pageType: options.pageTitle || `Edit ${options.risk.type.name}`,
            pageDescription: options.pageDescription || `${options.risk.code} - ${options.risk.title}`,
            editMode: true,
        };

        this.formData = { ...options.risk, documents: [] };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.id &&
            this.formData.title &&
            this.formData.type &&
            this.formData.trigger &&
            this.formData.type.id &&
            this.formData.registry &&
            this.formData.registry.id &&
            this.formData.asset &&
            this.formData.asset.id &&
            this.formData.stakeholder &&
            this.formData.stakeholder.id &&
            this.formData.lifecycle &&
            this.formData.lifecycle.businessContact &&
            this.formData.lifecycle.businessContact.id &&
            this.formData.lifecycle.technicalContact &&
            this.formData.lifecycle.technicalContact.id &&
            this.formData.description &&
            this.formData.context
        );
    }
}
