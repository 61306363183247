import { observable, action, computed, flow } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class MilestoneNewFormStore {
    public controlService: ControlService;
    public parentStore: ControlStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const result = yield this.controlService.searchControls({
            pageSize: 250,
            startIndex: 0,
            query: { parent: [{ id: options.control.id }], level: [2], activeOnly: true }
        });

        this.formOptions = {
            ...options,
            pageType: options.pageType || `Deploy`
        };

        const milestone = {
            ...options.milestone,
            controls: result.items.map((control) => ({
                control,
                capabilityLevel: "Baseline"
            }))
        };
        this.formData = milestone;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions
        });

        this.hide({}); //TODO : just for demo should be done in lifecycle store
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData &&
            this.formData.title &&
            this.formData.portfolio &&
            this.formData.portfolio.id &&
            this.formData.product &&
            this.formData.product.id &&
            this.formData.priority &&
            this.formData.dueDate &&
            this.formData.source &&
            this.formData.requestedBy &&
            this.formData.requestedBy.id
        );
    }
}
