import { observable, action, computed, flow } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { AssetService } from "@api/assets";
import { AssetStore } from "./AssetStore";

import { WellKnownSectionIds } from "@modules/properties/stores/PropertyConstants";

export class AssetEditFormStore {
    private assetService: AssetService;
    public parentStore: AssetStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const asset = yield this.assetService.getAsset(options.asset.id);
        asset.type = yield this.assetService.getAssetType(asset.type.id);

        if (asset.code && asset.code.toLowerCase() === asset.id) {
            asset.code = null;
        }

        if (typeof asset.extension !== "object") {
            asset.extension = {};
        }

        //const typeId = options.asset.type.id;
        this.formData = { ...asset, documents: [] };

        //const productService = this.parentStore.rootStore.productStore.productService;
        //const schema = yield productService.getProductSchema(productId, null);

        this.formOptions = {
            ...options,
            pageType: options.pageType || `Edit ${asset.type.name}`,
            pageDescription: asset.name,
            advancedEditing: false,
            groupedBySections: null,
            filteredSections: null,
            supportsLifecycle: options.supportsLifecycle,
        };

        // if (schema && schema.overrides && schema.overrides.length > 0) {
        //     const titleField = schema.overrides.find((o) => o.name === "title");
        //     const user = this.parentStore.rootStore.principalContext.current;
        //     if (titleField && titleField.defaultValue) {
        //         const now = moment();
        //         workItem.title = titleField.defaultValue
        //             .replace("{PRODUCT-NAME}", options.workItem.product.name)
        //             .replace("{DATE-TIME}", now.format("lll"))
        //             .replace("{DATE-ONLY}", now.format("ll"))
        //             .replace("{ACTOR-NAME}", user.name);
        //     }
        //     const dueDateField = schema.overrides.find((o) => o.name === "dueDate");
        //     if (dueDateField && dueDateField.defaultValue) {
        //         const dueDate = moment().add(moment.duration(dueDateField.defaultValue));
        //         workItem.dueDate = dueDate.toDate();
        //     }
        // }

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    public onToggleAdvanced = flow(function* (options) {
        this.formOptions.advancedEditing = !this.formOptions.advancedEditing;

        if (this.formOptions.advancedEditing && !this.formOptions.properties) {
            const propertyStore = this.parentStore.rootStore.propertyStore;
            const propertyService = propertyStore.propertyService;
            const resultProperties = yield propertyService.searchProperties({
                pageSize: 100,
                startIndex: 0,
                query: {
                    target: ["Asset"],
                    active: true,
                    readOnly: false,
                    hidden: false,
                    locked: false,
                    scope: ["00000000-0000-0000-0000-000000000000", this.formData.type.id, this.formData.type.class.id],
                },
            });
            const resultSections = yield propertyService.searchPropertySections({
                pageSize: 100,
                startIndex: 0,
                query: {
                    target: ["Asset"],
                },
            });

            const viewable = resultProperties.items.filter((p) => !p.readOnly && !p.hidden && p.active && !p.locked);
            const groupedBySections = viewable
                .sort((a, b) => a.order - b.order)
                .reduce((acc, curr) => {
                    if (!acc[curr.section.id]) acc[curr.section.id] = [];
                    acc[curr.section.id].push(curr);
                    return acc;
                }, {});

            this.formOptions.groupedBySections = groupedBySections;
            this.formOptions.filteredSections = resultSections.items
                .filter((s) => !!groupedBySections[s.id])
                .sort((a, b) => a.order - b.order);
        }
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return this.formData && this.formData.id && this.formData.name && this.formData.status;
    }
}
