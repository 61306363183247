import { observable, action, computed, flow } from "mobx";

import { RiskService } from "@api/risks";
import { RiskStore } from "@modules/risks/stores/RiskStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class RiskTreatmentFormStore {
    private parentStore: RiskStore;
    private riskService: RiskService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        this.formData = {
            type: "Mitigate",
            title: null,
            description: null,
            startDate: null,
            reviewDate: null,
            assignedTo: null,
            assigned: null,
            ...options.treatment
        };

        this.formOptions = {
            pageType: options.pageType || `${options.risk.type.name} Treatment`,
            pageDescription: options.pageDescription || `${options.risk.code} - ${options.risk.title}`,
            disabled: !!this.formData.completed
        };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success, remove) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            remove,
            formData: this.formData,
            formOptions: this.formOptions
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return this.formData.type && this.formData.title && this.formData.description && this.formData.startDate;
    }
}
